import { SurveyAdminContext } from "./SurveyAdminContext";
import React, { useContext, useEffect, useState } from "react";
import { InstanceContext } from "./InstanceContext";
import axios from "axios";

export function SurveyAdminContextProvider({ children }) {

    const { instanceData, setInstanceData } = useContext(InstanceContext)

    const [sdata, setSdata] = useState({
        Survey: {
            id: -1,
            Question: [],
            AcceptingResponses: false,
            AcceptingResponsesMessage: "This survey is no longer accepting responses.",
            isTemplate: false
        }
    })

    const surveyFromTemplate = (templateId) => {
        if (templateId !== -1) {
            const SurveySettingModel = {
                surveyId: templateId,
                surveyTitle: "",
                surveyDescription: "",
                acceptingResponses: false,
                acceptingResponsesMessage: ""
            }

            axios.post('api/GetSurveySettings', SurveySettingModel)
                .then(response => {
                    setSdata({ "Survey": JSON.parse(response.data.jsonresponse)[0] })
                })
                .catch(function (error) { console.log(error); })
        }
    }

    useEffect(() => {
        const fetchData = async () => {
            if (instanceData.surveyId && instanceData.hasSurvey) {
                const SurveySettingModel = {
                    surveyId: instanceData.surveyId,
                    surveyTitle: "",
                    surveyDescription: "",
                    acceptingResponses: false,
                    acceptingResponsesMessage: ""
                }

                axios.post('api/GetSurveySettings', SurveySettingModel)
                    .then(response => {
                        setSdata({ "Survey": JSON.parse(response.data.jsonresponse)[0] })
                    })
                    .catch(function (error) { console.log(error); })
            }
        }
        fetchData()
    }, [instanceData.surveyId])

    return (<SurveyAdminContext.Provider value={{ sdata, setSdata, surveyFromTemplate }}>
        {children}
    </SurveyAdminContext.Provider>
    )
}