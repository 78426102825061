import axios from 'axios';

//Generic get all posts for this meeting. Also gets ownership flag.
export async function OpenChatGetAllPosts() {
    return false;
}

//Get paged posts for this meeting. Also gets ownership flag.
export async function OpenChatGetPagedPosts(postId, vMeetingId) {
    let responder;
    const idata = {
        VMeetingId: vMeetingId,
        PostId: postId
    };
    await axios.post("api/OpenChatGetPagedPosts", idata).then(response => {
        if (response && response.data && response.data.openChatJson) {
            responder = JSON.parse(response.data.openChatJson);
        }

    }).catch(error => {
        responder = "Error"
    });
    return responder;
}

//Get deleted posts for this meeting
export async function OpenChatGetDeletedPosts(postId, vMeetingId) {
    let responder;
    const idata = {
        VMeetingId: vMeetingId,
        PostId: postId
    };
    await axios.post("api/OpenChatGetDeletedPostsForAdmin", idata).then(response => {
        if (response && response.data && response.data.openChatJson) {
            responder = JSON.parse(response.data.openChatJson);
        }

    }).catch(error => {
        responder = "Error"
    });
    return responder;
}

//Get flagged posts for this meeting.
export async function OpenChatGetFlaggedPosts(vMeetingId) {
    let responder;
    const idata = {
        VMeetingId: vMeetingId
    };
    await axios.post("api/OpenChatGetFlaggedPostsForAdmin", idata).then(response => {
        if (response && response.data && response.data.openChatJson) {
            responder = JSON.parse(response.data.openChatJson);
        }

    }).catch(error => {
        responder = "Error"
    });
    return responder;
}

//Get single post.
export async function OpenChatGetSinglePost(postId, vMeetingId) {
    let responder;
    const idata = {
        VMeetingId: vMeetingId,
        PostId: postId
    };
    await axios.post("api/OpenChatGetSinglePost", idata).then(response => {
        if (response && response.data && response.data.openChatJson) {
            responder = JSON.parse(response.data.openChatJson);
        }

    }).catch(error => {
        responder = "Error"
    });
    return responder;
}

//Add new post or reply to chat
export async function OpenChatNewPost(targetId, postContent, vMeetingId) {
    let responder;
    const idata = {
        VMeetingId: vMeetingId,
        ParentPostId: targetId,
        PostContent: postContent
    };
    await axios.post("api/OpenChatAddPost", idata).then(response => {
        if (response && response.data) {
            responder = response.data;
        }
    }).catch(error => {
        responder = "Error"
    });
    return responder;
}

//Edit own post
export async function OpenChatEditPost(targetId, postContent, vMeetingId) {
    let responder;
    const idata = {
        VMeetingId: vMeetingId,
        PostId: targetId,
        PostContent: postContent
    };
    await axios.post("api/OpenChatEditPost", idata).then(response => {
        if (response && response.data) {
            responder = response.data;
        }
    }).catch(error => {
        responder = "Error"
    });
    return responder;
}


//Add new likes and dislikes to posts
export async function OpenChatNewLike(targetId, actionType) {
    return false;
}


//Gets likes and flags submitted by current user
export async function OpenChatGetMyActions() {
    return false;
}



/**
 * Function will get all the emojis for open chat
 * */
export async function OpenChatGetEmojis() {
    let responder;
    await axios.get('api/OpenChatGetEmojis').then(response => {
        responder = JSON.parse(response.data.openChatJson);
    }).catch((error) => {
        return "Error";
    });
    return responder;
}


export async function OpenChatGetStats(vMeetingId) {
    let quantity = 10;
    const request = { vMeetingId, quantity };
    let resp = [];
    await axios.post('api/OpenChatGetStats', request)
        .then(response => {
            resp = JSON.parse(response.data.jsoNvalue);
        })
        .catch(function (error) { console.log('there was an error'); })
    return resp[0];
}