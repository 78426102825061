import { Component, Fragment } from 'react';
import { Row, Col } from 'react-bootstrap';
import axios from 'axios';


class AggregateResult extends Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: true,
            submitting: false,
            submitted: false,
            message: '',
            formData: []
        }
        this.VmeetingId = parseInt(sessionStorage.getItem('VMId'));
        this.UserId = parseInt(sessionStorage.getItem('UserId'));
    }

    componentDidMount() {
        this.getAggregateData();
    }

    async componentDidUpdate(prevProps) {
        if (this.props.data.contestId !== prevProps.data.contestId) {
            this.setState({
                loading: true
            })
            await Array.from(document.querySelectorAll("input")).forEach(
                input => {
                    input.value = '';
                    this.setState({
                        [input.name]: null
                    })
                }                
            );
            this.getAggregateData();
        }
    }

    getAggregateData = async () => {
        var formData = this.state.formData;   
        var meetingContest = {
            VmeetingId: this.VmeetingId,
            ContestId: this.props.data.contestId
        }
        await axios.post("VMGetAggregateData", meetingContest).then(response => {
            response.data.forEach(result => {
                var index = formData.findIndex(x => x.VoteValue === result.voteValue); 
                if (index === -1) {
                    formData.push({ UserId: this.UserId, VmeetingId: this.VmeetingId, ContestId: this.props.data.contestId, VoteValue: result.voteValue, VoteResult: result.voteResult })
                } else {
                    formData[index].VoteResult = result.voteResult
                }
                this.setState({
                    [result.voteValue.toString()]: result.voteResult ? result.voteResult : ''
                })
            })
            this.setState({
                loading: false,
                formData: formData
            })
            return;
        })
    }

    changeValue = (e) => {
        var formData = this.state.formData        

        var voteValue = parseInt(e.target.name);
        var name = e.target.name.toString();
        var value = parseInt(e.target.value);
        var index = formData.findIndex(x => x.VoteValue === voteValue); 

        if (index === -1) {
            formData.push({ UserId: this.UserId, VmeetingId: this.VmeetingId, ContestId: 0, VoteValue: parseInt(voteValue), VoteResult: parseInt(value) })
        } else {
            formData[index].VoteResult = parseInt(value)
        }

        this.setState({
            formData: formData,
            [name]: value
        })
    }


    handleSubmit = (e) => {
        this.setState({ submitting: true, submitted: false, message: ''})
        e.preventDefault();
        var submitdata = this.state.formData;
        submitdata.forEach(row => row.ContestId = this.props.data.contestId);
        axios.post("VMUpdateAggregateData", submitdata).then(response => {
            if (response.data.statusID === 1) {
                this.setState({
                    submitted: true,
                    message: "Update successful"
                })
            } else if (response.data.statusID === 2) {
                this.setState({
                    submitted: true,
                    message: "Update partially successful"
                })
            } else {
                this.setState({
                    submitted: true,
                    message: "Update failed"
                })
            }
            
        })
        this.setState({ submitting: false })
    }

    render() {
        if (this.state.loading) {
            return (
                <Fragment>
                    <div className="aggregateData cloud">
                        <div className="loaderholder"> < div className="loader" /></div>
                    </div>
                    </Fragment>
                )
        }
        return (
            <Fragment>
                <div className="aggregateData cloud">
                    <form onSubmit={this.handleSubmit} >
                        <p>Add aggregate data from in room counts into the fields below. All fields must be completed even if the value is 0.</p>
                        {this.props.data.options.map((option, index) => (
                            <Row key={index}>
                                <Col><label htmlFor={"agdata" + option.voteValue}> {option.voteOption} </label></Col>
                                <Col><input id={"agdata" + option.voteValue} min="0" step="1" type="number" name={option.voteValue} value={this.state[option.voteValue.toString()]} onChange={(e) => this.changeValue(e)} required/></Col>
                            </Row>
                        ))

                        }
                        <button type="submit" className="btn btn-primary" disabled={this.state.submitting}>{this.state.submitting ? <div className="loaderholder"> < div className="loader" /></div> : 'Update'}</button>{this.state.submitted && <p>{this.state.message}</p>}
                    </form>
                </div>
            </Fragment>
        )
    }
}
export default AggregateResult;

