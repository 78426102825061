import React, { Component, Fragment } from 'react';
import Switch from "react-switch";
import { Container, Col, Form, FormGroup, Button, Modal } from 'react-bootstrap';
import axios from 'axios';
import { NotificationManager } from 'react-notifications';
import SignalRController from './../SignalRController';
import Select from 'react-select';
import SimpleReactValidator from 'simple-react-validator';
import AdminContext from '../context/AdminContext';
import SeenAndHeard from './SeenAndHeard/SeenAndHeard';
import swal from 'sweetalert';
import { SeenAndHeardContextProvider } from './SeenAndHeard/Context/SeenAndHeardContextProvider';
import '../Picker.less';
import { InlineLoader } from '../SecondaryComponents';

export default class Switches extends Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: true,
            documents: false,
            voting: false,
            chat: false,
            rescindproxy: false,
            allowguestreg: false,
            audiovisual: false,
            enabledarktheme: false,
            enableSurvey: false,
            settingsId: null,
            includeTestMetrics: false,
            enableWeightedVoting: false,
            publishTypeId: 0,
            publishTypes: [],
            selectedOption: null,
            displayVoteWeight: false,
            hideAttendeeType: false,
            hybrid: false,
            voteMethodId: 0,
            selectedVoteMethod: null,
            voteMethods: [],
            enableReactPlayer: false,
            enableConstituencies: false,
            isConstituencySetupExists: false,
            zeroAsBlank: false,
            inRoomDifferent: false,
            inRoomDifferentWelcome: false,
            inRoomVoting: true,
            remoteVoting: true,
            results: true,
            inRoomResults: true,
            inRoomAudiovisual: false,
            inRoomChat: true,
            inRoomdocuments: true,
            ticket: false,
            demo: false,
            outofhours: false,
            attendees: 0,
            seenAndHeard: false,
            inRoomSeenAndHeard: false,
            showSeenAndHeardSetup: false,
            hasPreMeetingData: false,
            preMeetingDataType: null,
            enableQrCode: true,
            attendeesOwnDevice: true,
            enableOpenChat: false,
            inRoomOpenChat: false,
            restrictedMeeting: false,
            enableRealTimeResults: false,
            EnableSecondaryFeed: false,
            PrimaryFeed: "",
            SecondaryFeed: "",
            startDateTime: new Date(),
            closeDateTime: new Date(),
            isMeetingTypeModalOpen: false,
            meetingType: 3,
            zoomMeetingId: "",
            zoomMeetingPasscode: "",
            enableComfortMonitor: false
        };

        this.proxyRescindOptionsList = [
            //{ value: 1, label: "User Select" },
            { value: 2, label: "Auto Rescind" },
            { value: 3, label: "Auto Keep" }
        ];

        this.publishTypes = [
            { value: 1, label: "Standard" },
            { value: 2, label: "Weighted" },
            { value: 3, label: "Both" }
        ];

        this.preMeetingTypeList = [
            { value: 1, label: "Pre-Meeting Votes" },
            { value: 2, label: "Proxy Votes" }
        ]

        this.meetingTypeList = [
            { value: 1, label: "Zoom" },
            { value: 3, label: "Streaming" }            
        ]

        this.validator = new SimpleReactValidator({ autoForceUpdate: this, element: (message, className) => <div className="text-danger">{message}</div> });


    }
    componentDidMount() {
        SignalRController.addCallbackAdm2Adm_SwitchUpdate(this.populateswitchdata);
        this.populateswitchdata();
        this.getConstituency();        
    }

    componentWillUnmount() {
        SignalRController.removeCallbackAdm2Adm_SwitchUpdate(this.populateswitchdata);
        this.setState = (state, callback) => {
            return;
        };
    }

    getConstituency = () => {
        if (this.context.isAuthorized('VoteConst', 'Read')) {
            const VMId = parseInt(sessionStorage.getItem('VMId'))
            const options = {
                url: 'VMGetMeetingConstituencyList',
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                data: VMId
            };
            axios(options)
                .then(response => {
                    if (response.data.length > 0) {
                        let constituencyExists = response.data;
                        this.setState({ isConstituencySetupExists: constituencyExists.length <= 1 && constituencyExists[0].id <= 0 ? false : true });
                    }
                }).catch(function (error) {
                    NotificationManager.error("Sorry, there was a problem getting the constituency list.", "Constituency list error", 4000);
                })
        } else {
            this.setState({
                isConstituencySetupExists: true
            });
        }
    }

    populateswitchdata = () => {

        const id = { VMId: this.props.VmeetingId };

        axios.get('VMGetMeetingSwitches?VMId=' + id.VMId)
            .then(response => {
                let switchesdata = response.data;
                this.setState({
                    documents: switchesdata.enableDocuments,
                    voting: switchesdata.enableVoting,
                    chat: switchesdata.enableQA,
                    rescindproxy: switchesdata.enableProxyVoting,
                    allowguestreg: switchesdata.enableGuestReg,
                    audiovisual: switchesdata.enableAudioVideo,
                    enabledarktheme: switchesdata.enableDarkTheme,
                    enableSurvey: switchesdata.enableSurvey,
                    settingsId: switchesdata.settingsID,
                    includeTestMetrics: switchesdata.includeTestMetrics,
                    enableWeightedVoting: switchesdata.enableWeightedCount,
                    publishTypeId: Number(switchesdata.publishTypeId),
                    displayVoteWeight: switchesdata.displayVoteWeightToUser,
                    selectedOption: this.publishTypes.filter((x) => x.value === switchesdata.publishTypeId)[0],

                    selectedProxyOption: this.proxyRescindOptionsList.filter((x) => x.value === switchesdata.proxySettingId)[0],
                    enableReactPlayer: switchesdata.enableReactPlayer,
                    enableConstituencies: switchesdata.enableConstituencies,
                    zeroAsBlank: switchesdata.zeroAsBlank,
                    ticket: switchesdata.ticket,
                    results: switchesdata.results,
                    inRoomDifferent: switchesdata.inRoomDifferent,
                    inRoomVoting: switchesdata.inRoomVoting,
                    inRoomResults: switchesdata.inRoomResults,
                    inRoomAudiovisual: switchesdata.inRoomAudiovisual,
                    inRoomDocuments: switchesdata.inRoomDocuments,
                    inRoomChat: switchesdata.inRoomChat,
                    inRoomDifferentWelcome: switchesdata.inRoomDifferentWelcome,
                    demo: switchesdata.demo,
                    outofhours: switchesdata.outofhours,
                    attendees: switchesdata.attendees,
                    remoteVoting: switchesdata.remoteVoting,
                    hybrid: switchesdata.hybrid,
                    seenAndHeard: switchesdata.seenAndHeard,
                    inRoomSeenAndHeard: switchesdata.inRoomSeenAndHeard,
                    hasPreMeetingData: switchesdata.hasPreMeetingData,
                    preMeetingDataType: this.preMeetingTypeList.filter((x) => x.value === switchesdata.preMeetingDataType)[0],
                    enableQrCode: switchesdata.enableQrCode,
                    attendeesOwnDevice: switchesdata.attendeesOwnDevice,
                    enableOpenChat: switchesdata.enableOpenChat,
                    inRoomOpenChat: switchesdata.inRoomOpenChat,
                    restrictedMeeting: switchesdata.restrictedMeeting,
                    enableRealTimeResults: switchesdata.enableRealTimeResults,
                    meetingType: switchesdata.meetingType === 0 ? 3 : switchesdata.meetingType,
                    primaryFeed: switchesdata.primaryFeed,
                    secondaryFeed: switchesdata.secondaryFeed,
                    enableSecondaryFeed: switchesdata.enableSecondaryFeed,
                    startDateTime: switchesdata.startDateTime,
                    closeDateTime: switchesdata.closeDateTime,
                    zoomMeetingId: switchesdata.zoomMeetingId,
                    zoomMeetingPasscode: switchesdata.zoomMeetingPasscode,
                    enableComfortMonitor: switchesdata.enableComfortMonitor,
                    loading: false
                });
            }).catch(function (error) { console.log('error getting settings'); this.setState({loading: false}) });
    }

    handleVoteChange = (voting) => {
        this.setState({ voting });
    }

    handleDocChange = (documents) => {
        this.setState({ documents });
    }

    handleChatChange = (chat) => {
        this.setState({ chat });
    }
    handleAVChange = (audiovisual) => {
        this.setState({ audiovisual });
    }

    handlerescChange = (rescindproxy) => {
        this.setState({ rescindproxy });
    }
    handleguestChange = (allowguestreg) => {
        this.setState({ allowguestreg });
    }
    handlethemeChange = (enabledarktheme) => {
        this.setState({ enabledarktheme });
    }
    handlesurveyChange = (enableSurvey) => {
        this.setState({ enableSurvey });
    }
    handletestChange = (includeTestMetrics) => {
        this.setState({ includeTestMetrics });
    }
    handleWeightedChange = (enableWeightedVoting) => {
        this.setState({ enableWeightedVoting });
    }

    handlePublishTypeChange = (selectedOption) => {
        this.setState({ selectedOption });
    };

    handleDisplayVoteWeightChange = (displayVoteWeight) => {
        this.setState({ displayVoteWeight });
    }

    handleEnableConstituencyChange = (enableConstituencies) => {
        this.setState({ enableConstituencies });
    }

    handleVoteMethodChange = (selectedVoteMethod) => {
        this.setState({ selectedVoteMethod });
    };

    selectedProxyOptionChange = (selectedProxyOption) => {
        this.setState({ selectedProxyOption });
    };

    handleAttendeesChange = (e) => {
        this.setState({ attendees: e.target.value });
    };

    hideSeenAndHeardSetup = (changes) => {
        if (changes === true) {
            swal({
                title: "Are you sure?", text: "Unsaved changes will be lost", icon: "warning", buttons: true, dangerMode: true
            }).then((value) => {
                if (value) {
                    this.setState({
                        showSeenAndHeardSetup: false
                    })
                }
            })
        }
        else {
            this.setState({
                showSeenAndHeardSetup: false
            })
        }
    }

    switchChange = (name, e) => {
        this.setState({ [name]: e });
    }

    handleCheckboxChange = (event) => {
        this.setState({ enableSecondaryFeed: event.target.checked })
    }

    handleFromDateChange = (e) => {
        let newDate = e.target.value;
        this.setState({ startDateTime: newDate });
    }

    handleToDateChange = (e) => {
        let newDate = e.target.value;
        this.setState({ closeDateTime: newDate });
    }

    handleMeetingTypeChange = (selectedOption) => {
        this.setState({ meetingType: selectedOption.value });
    };

    closeMeetingTypeModal = () => {
        this.setState({ isMeetingTypeModalOpen: false });
    }

    getMeetingTypeLabel = (meetingType) => {
        const matchingItem = this.meetingTypeList.find((item) => item.value === meetingType);
        return matchingItem ? matchingItem.label : "Off";
    }

    handleInputChange = (event) => {
        const { name, value } = event.target;
        this.setState({ [name]: value })
    }

    avSetupSubmit = async (evt) => {
        evt.preventDefault();
        if (this.state.meetingType === 1) {
            if (!this.state.zoomMeetingId || !this.state.zoomMeetingPasscode.trim()) {
                swal({
                    title: "Are you sure?", text: "Insufficient setup, the Zoom meeting will not operate in attendee", icon: "warning", buttons: true, dangerMode: true
                })
                    .then((value) => {
                        if (value) {
                            this.submitAVSetup();
                        } else {
                            return;
                        }
                    })
            }
        }
        else if (this.state.meetingType === 3) {
            if (this.state.primaryFeed.length === 0) {
                swal({
                    title: "Invalid entry", text: "Please enter a valid primary feed", icon: "warning", buttons: true, dangerMode: true
                })
                return;
            }
            else if (this.state.enableSecondaryFeed && this.state.secondaryFeed.length === 0) {
                swal({
                    title: "Invalid entry", text: "Please enter a secondary feed", icon: "warning", buttons: true, dangerMode: true
                })
                return;
            }
        }
        this.submitAVSetup();
    }

    submitAVSetup = () => {
        const mtswitches = {
            VMeetingId: this.props.VmeetingId,
            UserId: this.props.UserId,
            MeetingType: parseInt(this.state.meetingType),
            PrimaryFeed: this.state.primaryFeed ?? "",
            SecondaryFeed: this.state.secondaryFeed ?? "",
            EnableSecondaryFeed: this.state.enableSecondaryFeed,
            ZoomMeetingId: parseInt(this.state.zoomMeetingId),
            ZoomMeetingPasscode: this.state.zoomMeetingPasscode ?? "",
        };

        const options = {
            url: 'VMUpdateMeetingTypeSetup',
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            data: mtswitches
        };
        axios(options)
            .then(response => {
                if (response.status === 202) {
                    NotificationManager.error("Please use one of the approved feed sites, contact your administrator for details or to have a site approved.", "Switches", 2000);
                }
                else if (response.data.statusID === 1) {
                    NotificationManager.success("Meeting type switches updated for meeting", "Switches", 2000);
                    this.closeMeetingTypeModal();
                } else {
                    NotificationManager.error("Sorry, meeting type switches could not be updated", "Switches", 2000);
                }
            })

        this.context.populateswitchdata(true)
    }

    //Zoom/streaming settings
    renderModalContent = () => {
        //1. Zoom
        //3. Streaming
        return (
            <React.Fragment>
                <Form>
                    <Modal.Header closeButton>
                        <Modal.Title>AV Setup</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="p-2">
                        {this.state.meetingType === 1 &&
                            <Fragment>
                                <Form.Group controlId="meetingId">
                                    <Form.Label>Meeting Id</Form.Label>
                                    <Form.Control
                                        type="number"
                                        name="zoomMeetingId"
                                        value={this.state.zoomMeetingId}
                                        onChange={this.handleInputChange}
                                    />
                                </Form.Group>

                                <Form.Group controlId="meetingPasscode">
                                    <Form.Label>Meeting Passcode</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="zoomMeetingPasscode"
                                        value={this.state.zoomMeetingPasscode}
                                        onChange={this.handleInputChange}
                                    />
                                </Form.Group>
                            </Fragment>
                        }
                        {this.state.meetingType === 3 &&
                            <Fragment>
                                <Form.Group>
                                    <Form.Label>Primary feed:</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="primaryFeed"
                                        value={this.state.primaryFeed}
                                        onChange={this.handleInputChange}
                                        id="txtPrimaryFeed"
                                        required
                                    />
                                </Form.Group>
                                <Form.Group>
                                    <Form.Check id="enableSecondaryFeed">
                                        <Form.Check.Input
                                            type="checkbox"
                                            name="enableSecondaryFeed"
                                            id="checkEnableSecondaryFeed"
                                            onChange={this.handleCheckboxChange}
                                            checked={this.state.enableSecondaryFeed}
                                        />
                                        <Form.Check.Label>Enable secondary feed</Form.Check.Label>
                                    </Form.Check>
                                </Form.Group>
                                <Form.Group>
                                    <Form.Label>Secondary feed:</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="secondaryFeed"
                                        value={this.state.secondaryFeed}
                                        onChange={this.handleInputChange}
                                        id="txtSecondaryFeed"
                                        required={this.state.enableSecondaryFeed}
                                    />
                                </Form.Group>
                            </Fragment>
                        }

                    </Modal.Body>
                    <Modal.Footer>
                        <button type="button" className="btn btn-secondary" onClick={this.closeMeetingTypeModal}>Close</button>
                        <button type="button" className="btn btn-primary" onClick={this.avSetupSubmit}>Save changes</button>
                    </Modal.Footer>
                </Form>
            </React.Fragment >
        );
    };

    /* styles.css */

    onSubmit = async (e) => {
        e.preventDefault();
        const isValid = e.target.checkValidity();
        if (!this.validator.allValid() || !isValid) {
            this.validator.showMessages();
        } else {
            let diff = this.state.hybrid && this.state.inRoomDifferent;
            const switches = {
                VMeetingId: this.props.VmeetingId,
                UserId: this.props.UserId,
                EnableDocuments: this.state.documents,
                EnableVoting: this.state.voting,
                EnableQA: this.state.chat,
                EnableGuestReg: this.state.allowguestreg,
                EnableAudioVideo: this.state.audiovisual,
                EnableDarkTheme: this.state.enabledarktheme,
                EnableSurvey: this.state.enableSurvey,
                IncludeTestMetrics: this.state.includeTestMetrics,
                EnableWeightedCount: this.state.enableWeightedVoting,
                PublishTypeId: this.state.enableWeightedVoting ? this.state.selectedOption?.value : null,
                DisplayVoteWeightToUser: this.state.displayVoteWeight,
                EnableReactPlayer: this.state.enableReactPlayer,
                EnableProxyVoting: this.state.voting && this.state.hasPreMeetingData ? this.state.rescindproxy : false,
                ProxySettingId: this.state.rescindproxy ? 1 : this.state.selectedProxyOption?.value,
                EnableConstituencies: this.state.enableConstituencies,
                ZeroAsBlank: this.state.hasPreMeetingData ? Number(this.state.preMeetingDataType.value) === 1 ? true : this.state.zeroAsBlank : false,
                Ticket: this.state.hybrid ? this.state.ticket : false,
                Results: this.state.results,
                InRoomDifferent: this.state.hybrid && this.state.inRoomDifferent,
                InRoomVoting: diff ? this.state.inRoomVoting : this.state.voting,
                RemoteVoting: diff ? this.state.remoteVoting : this.state.voting,
                InRoomResults: diff ? this.state.inRoomResults : this.state.results,
                InRoomAudiovisual: diff ? this.state.inRoomAudiovisual : this.state.audiovisual,
                InRoomDocuments: diff ? this.state.inRoomDocuments : this.state.documents,
                InRoomChat: diff ? this.state.inRoomChat : this.state.chat,
                InRoomDifferentWelcome: diff ? this.state.inRoomDifferentWelcome : false,
                Demo: this.state.demo,
                Outofhours: this.state.outofhours,
                Attendees: parseInt(this.state.attendees),
                Hybrid: this.state.hybrid,
                SeenAndHeard: this.state.seenAndHeard,
                InRoomSeenAndHeard: diff ? this.state.inRoomSeenAndHeard : this.state.seenAndHeard,
                hasPreMeetingData: this.state.hasPreMeetingData,
                preMeetingDataType: this.state.hasPreMeetingData ? this.state.preMeetingDataType?.value : 0,
                EnableQrCode: this.state.enableQrCode,
                AttendeesOwnDevice: this.state.attendeesOwnDevice,
                EnableOpenChat: this.state.enableOpenChat,
                inRoomOpenChat: diff ? this.state.inRoomOpenChat : this.state.enableOpenChat,
                RestrictedMeeting: this.state.restrictedMeeting,
                EnableRealTimeResults: this.state.enableRealTimeResults,
                MeetingType: parseInt(this.state.meetingType),
                PrimaryFeed: this.state.primaryFeed,
                SecondaryFeed: this.state.secondaryFeed,
                EnableSecondaryFeed: this.state.enableSecondaryFeed,
                StartDateTime: this.state.startDateTime,
                CloseDateTime: this.state.closeDateTime,
                ZoomMeetingId: parseInt(this.state.zoomMeetingId),
                ZoomMeetingPasscode: this.state.zoomMeetingPasscode,
                EnableComfortMonitor: this.state.enableComfortMonitor
            };

            //throw an error if VMeetingId is not supplied
            const options = {
                url: 'VMUpdateSwitches',
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                data: switches
            };
            await axios(options)
                .then(response => {
                    if (response.data.statusID === 1) {
                        NotificationManager.success("Switches updated for meeting", "Switches", 2000);
                    } else {
                        NotificationManager.error("Sorry, switches could not be updated", "Switches", 2000);
                    }
                })
        }
        this.context.populateswitchdata(true);
    }

    render() {
        this.validator.purgeFields();
        const { isAuthorized } = this.context;
        if (this.state.loading) {
            return (
                <Container fluid>
                    <InlineLoader />
                </Container>
            )
        }
        else return (
            <Container fluid>
                {this.state.settingsId ?
                    <Form onSubmit={this.onSubmit}>
                        <h2 className="chat-heading-am">Meeting</h2>

                        <FormGroup className="row mb-2">
                            <Col sm={7}><span id="siteOpen-switch">Meeting start date</span></Col>
                            <Col sm={2}>
                                <input
                                    type="datetime-local"
                                    onChange={this.handleFromDateChange}
                                    value={this.state.startDateTime}
                                    required
                                />
                            </Col>
                        </FormGroup>

                        <FormGroup className="row mb-2">
                            <Col sm={7}><span id="siteClose-switch">Meeting end date</span></Col>
                            <Col sm={2}>
                                <input
                                    type="datetime-local"
                                    onChange={this.handleToDateChange}
                                    value={this.state.closeDateTime}
                                    min={this.state.startDateTime}
                                    required
                                />
                            </Col>
                        </FormGroup>

                        <FormGroup className="row mb-2">
                            <Col sm={7}><span id="restrictedMeeting-switch">Restricted Meeting (Administrators cannot add themselves)</span></Col>
                            <Col sm={2}>
                                <Switch
                                    onChange={(e) => this.switchChange("restrictedMeeting", e)}
                                    checked={this.state.restrictedMeeting}
                                    className="react-switch"
                                    aria-labelledby="restrictedMeeting-switch"
                                />
                            </Col>
                        </FormGroup>

                        <FormGroup className="row mb-2">
                            <Col sm={7}><span id="testusers-switch">Include test users in reports</span></Col>
                            <Col sm={2}>
                                <Switch
                                    onChange={this.handletestChange}
                                    checked={this.state.includeTestMetrics}
                                    className="react-switch"
                                    aria-labelledby="testusers-switch"
                                />
                            </Col>
                        </FormGroup>

                        <FormGroup className="row mb-2">
                            <Col sm={7}><span id="demo-switch">Meeting is demo</span></Col>
                            <Col sm={2}>
                                <Switch
                                    onChange={(e) => this.switchChange("demo", e)}
                                    checked={this.state.demo}
                                    className="react-switch"
                                    aria-labelledby="demo-switch"
                                />
                            </Col>
                        </FormGroup>

                        <FormGroup className="row mb-2">
                            <Col sm={7}><span id="outofhours-switch">Meeting runs out of hours</span></Col>
                            <Col sm={2}>
                                <Switch
                                    checked={this.state.outofhours}
                                    onChange={(e) => this.switchChange("outofhours", e)}
                                    className="react-switch"
                                    aria-labelledby="outofhours-switch"
                                />
                            </Col>
                        </FormGroup>

                        <FormGroup className="row mb-2">
                            <Col sm={7}><span id="attendees-number">Expected number of attendees</span></Col>
                            <Col sm={2}>
                                <input
                                    type="number"
                                    onChange={(e) => this.handleAttendeesChange(e)}
                                    value={this.state.attendees}
                                    aria-labelledby="attendees-number"
                                    min="0" step="1"
                                    required
                                />
                            </Col>
                        </FormGroup>


                        <FormGroup className="row mb-2">
                            <Col sm={7}><span id="guest-switch">Allow on the day guest registrations</span></Col>
                            <Col sm={2}>
                                <Switch
                                    onChange={this.handleguestChange}
                                    checked={this.state.allowguestreg}
                                    className="react-switch"
                                    aria-labelledby="guest-switch"
                                />
                            </Col>
                        </FormGroup>

                        <FormGroup className="row mb-2">
                            <Col sm={7}><span id="Survey-switch">Enable survey</span></Col>
                            <Col sm={2}>
                                <Switch
                                    onChange={this.handlesurveyChange}
                                    checked={this.state.enableSurvey}
                                    className="react-switch"
                                    aria-labelledby="Survey-switch"
                                />
                            </Col>
                        </FormGroup>

                        <h2 className="chat-heading-am">Hybrid and Registration</h2>
                        <FormGroup className="row mb-2">
                            <Col sm={7}><span id="hybrid-switch">Meeting is hybrid (members will be in attendance)</span></Col>
                            <Col sm={2}>
                                <Switch
                                    onChange={(e) => this.switchChange("hybrid", e)}
                                    checked={this.state.hybrid}
                                    className="react-switch"
                                    aria-labelledby="hybrid-switch"
                                />
                            </Col>
                        </FormGroup>
                        {this.state.hybrid &&
                            <Fragment>
                                <FormGroup className="row mb-2">
                                    <Col sm={7}><span id="enableQrCode-switch">Enable QR Codes</span></Col>
                                    <Col sm={2}>
                                        <Switch
                                            onChange={(e) => this.switchChange("enableQrCode", e)}
                                            checked={this.state.enableQrCode}
                                            className="react-switch"
                                            aria-labelledby="enableQrCode-switch"
                                        />
                                    </Col>
                                </FormGroup>
                                <FormGroup className="row mb-2">
                                    <Col sm={7}><span id="attendeesOwnDevice-switch">Attendees will have their own devices</span></Col>
                                    <Col sm={2}>
                                        <Switch
                                            onChange={(e) => this.switchChange("attendeesOwnDevice", e)}
                                            checked={this.state.attendeesOwnDevice}
                                            className="react-switch"
                                            aria-labelledby="attendeesOwnDevice-switch"
                                        />
                                    </Col>
                                </FormGroup>

                                <FormGroup className="row mb-2">
                                    <Col sm={7}><span id="ticket-switch">Ticket required for in person attendance</span></Col>
                                    <Col sm={2}>
                                        <Switch
                                            onChange={(e) => this.switchChange("ticket", e)}
                                            checked={this.state.ticket}
                                            className="react-switch"
                                            aria-labelledby="ticket-switch"
                                        />
                                    </Col>
                                </FormGroup>

                                <FormGroup className="row mb-2">
                                    <Col sm={7}><span id="inRoomDifferent-switch">Enable different display options for in room and remote attendees</span></Col>
                                    <Col sm={2}>
                                        <Switch
                                            onChange={(e) => this.switchChange("inRoomDifferent", e)}
                                            checked={this.state.inRoomDifferent}
                                            className="react-switch"
                                            aria-labelledby="inRoomDifferent-switch"
                                        />
                                    </Col>
                                </FormGroup>

                                {this.state.inRoomDifferent &&
                                    <FormGroup className="row mb-2">
                                        <Col sm={7}><span id="inRoomDifferent-switch">Enable different welcome messages for in room and remote attendees</span></Col>
                                        <Col sm={2}>
                                            <Switch
                                                onChange={(e) => this.switchChange("inRoomDifferentWelcome", e)}
                                                checked={this.state.inRoomDifferentWelcome}
                                                className="react-switch"
                                                aria-labelledby="inRoomDifferent-switch"
                                            />
                                        </Col>
                                    </FormGroup>
                                }
                            </Fragment>
                        }


                        <h2 className="chat-heading-am">Voting and Results</h2>
                        <FormGroup className="row mb-2">
                            <Col sm={7}><span id="vote-switch">Enable voting</span></Col>
                            <Col sm={2}>
                                <Switch
                                    onChange={this.handleVoteChange}
                                    checked={this.state.voting}
                                    className="react-switch"
                                    aria-labelledby="vote-switch"
                                />
                            </Col>
                        </FormGroup>

                        {this.state.voting && this.state.inRoomDifferent && this.state.hybrid &&
                            <FormGroup className="row mb-2">
                                <Col sm={7}><span id="remoteVoting-switch">Enable voting for remote attendees</span></Col>
                                <Col sm={2}>
                                    <Switch
                                        checked={this.state.remoteVoting}
                                        onChange={(e) => this.switchChange("remoteVoting", e)}
                                        className="react-switch"
                                        aria-labelledby="remoteVoting-switch"
                                    />
                                </Col>
                            </FormGroup>
                        }
                        {this.state.voting && this.state.inRoomDifferent && this.state.hybrid &&
                            <FormGroup className="row mb-2">
                                <Col sm={7}><span id="inRoomVoting-switch">Enable voting for attendees in room</span></Col>
                                <Col sm={2}>
                                    <Switch
                                        onChange={(e) => this.switchChange("inRoomVoting", e)}
                                        checked={this.state.inRoomVoting}
                                        className="react-switch"
                                        aria-labelledby="inRoomVoting-switch"
                                    />
                                </Col>
                            </FormGroup>
                        }

                        {this.state.voting &&
                            <FormGroup className="row mb-2">
                                <Col sm={7}><span id="enable-constituencies-switch">Enable constituencies</span></Col>
                                <Col sm={2}>
                                    <Switch
                                        onChange={this.handleEnableConstituencyChange}
                                        checked={this.state.enableConstituencies}
                                        className="react-switch"
                                        aria-labelledby="enable-constituencies-switch"
                                        disabled={this.state.isConstituencySetupExists && this.state.enableConstituencies}
                                    />
                                </Col>
                            </FormGroup>
                        }

                        {this.state.voting &&
                            <FormGroup className="row mb-2">
                                <Col sm={7}><span id="setvoteweight-switch">Enable weighted voting</span></Col>
                                <Col sm={2}>
                                    <Switch
                                        onChange={this.handleWeightedChange}
                                        checked={this.state.enableWeightedVoting}
                                        className="react-switch"
                                        aria-labelledby="setvoteweight-switch"
                                    />
                                </Col>
                            </FormGroup>
                        }

                        {this.state.enableWeightedVoting && this.state.voting &&
                            <FormGroup className="row mb-2">
                                <Col sm={7}><span id="displayvoteweight-switch" className="ml-4">Display attendees's vote weight in vote panel</span></Col>
                                <Col sm={2}>
                                    <Switch
                                        onChange={this.handleDisplayVoteWeightChange}
                                        checked={this.state.displayVoteWeight}
                                        className="react-switch"
                                        aria-labelledby="voteweight-switch"
                                    />
                                </Col>
                            </FormGroup>
                        }

                        {this.state.enableWeightedVoting && this.state.voting &&
                            <FormGroup className="row mb-2">
                                <Col sm={5}><span id="publish-type-switch" className="ml-4">Default publish type</span></Col>
                                <Col sm={3}>
                                    <Select
                                        value={this.state.selectedOption}
                                        onChange={this.handlePublishTypeChange}
                                        options={this.publishTypes}
                                    />
                                    {this.validator.message('PublishTypes', this.state.selectedOption, 'required')}
                                </Col>
                            </FormGroup>
                        }

                        {this.state.voting &&
                            <FormGroup className="row mb-2">
                                <Col sm={7}><span id="results-switch">Enable results {this.state.hybrid && this.state.inRoomDifferent ? ' for remote attendees' : ''}</span></Col>
                                <Col sm={2}>
                                    <Switch
                                        onChange={(e) => this.switchChange("results", e)}
                                        checked={this.state.results}
                                        className="react-switch"
                                        aria-labelledby="results-switch"
                                    />
                                </Col>
                            </FormGroup>
                        }

                        {this.state.voting && this.state.inRoomDifferent && this.state.hybrid &&
                            <FormGroup className="row mb-2">
                                <Col sm={7}><span id="inRoomResults-switch">Enable results for attendees in room</span></Col>
                                <Col sm={2}>
                                    <Switch
                                        onChange={(e) => this.switchChange("inRoomResults", e)}
                                        checked={this.state.inRoomResults}
                                        className="react-switch"
                                        aria-labelledby="inRoomResults-switch"
                                    />
                                </Col>
                            </FormGroup>
                        }
                        {this.state.voting &&
                            <FormGroup className="row mb-2">
                                <Col sm={7}><span id="enableRealTimeResults-switch">Enable real time results</span></Col>
                                <Col sm={2}>
                                    <Switch
                                        onChange={(e) => this.switchChange("enableRealTimeResults", e)}
                                        checked={this.state.enableRealTimeResults}
                                        className="react-switch"
                                        aria-labelledby="enableRealTimeResults-switch"
                                    />
                                </Col>
                            </FormGroup>
                        }
                        <h2 className="chat-heading-am">Premeeting</h2>
                        <FormGroup className="row mb-2">
                            <Col sm={7}><span id="premeetingdata-switch">Enable pre-meeting voting</span></Col>
                            <Col sm={2}>
                                <Switch
                                    checked={this.state.hasPreMeetingData}
                                    onChange={(e) => this.switchChange("hasPreMeetingData", e)}
                                    className="react-switch"
                                    aria-labelledby="premeetingdata-switch"
                                />
                            </Col>
                        </FormGroup>

                        {this.state.hasPreMeetingData &&
                            <FormGroup className="row mb-2">
                                <Col sm={5}><span id="premeeting-type-switch" className="ml-4">Pre-meeting vote type</span></Col>
                                <Col sm={3}>
                                    <Select
                                        value={this.state.preMeetingDataType}
                                        onChange={(e) => this.switchChange("preMeetingDataType", e)}
                                        options={this.preMeetingTypeList}
                                        aria-labelledby="premeeting-type-switch"
                                    />
                                    {this.validator.message('pre-meetingVoteType', this.state.preMeetingDataType, 'required')}
                                </Col>
                            </FormGroup>
                        }
                        {this.state.hasPreMeetingData && this.state.preMeetingDataType && Number(this.state.preMeetingDataType.value) === 2 &&
                            <FormGroup className="row mb-2">
                                <Col sm={7}><span id="premeetingZero-switch">Treat premeeting 0 as blank vote instead of discretionary</span></Col>
                                <Col sm={2}>
                                    <Switch
                                        onChange={(e) => this.switchChange("zeroAsBlank", e)}
                                        checked={this.state.zeroAsBlank}
                                        className="react-switch"
                                        aria-labelledby="premeetingZero-switch"
                                    />
                                </Col>
                            </FormGroup>
                        }

                        {this.state.voting && this.state.hasPreMeetingData &&
                            <FormGroup className="row mb-2">
                                <Col sm={7}><span id="rescind-switch">Enable rescind screen for attendee</span></Col>
                                <Col sm={2}>
                                    <Switch
                                        onChange={this.handlerescChange}
                                        checked={this.state.rescindproxy}
                                        className="react-switch"
                                        aria-labelledby="rescind-switch"
                                    />
                                </Col>
                            </FormGroup>
                        }

                        {!this.state.rescindproxy && this.state.voting && this.state.hasPreMeetingData &&
                            <FormGroup className="row mb-2">

                                <Col sm={5}><span id="proxy-rescind-switch" className="ml-4">Auto rescind options</span></Col>
                                <Col sm={3}>
                                    <Select
                                        value={this.state.selectedProxyOption}
                                        onChange={this.selectedProxyOptionChange}
                                        options={this.proxyRescindOptionsList}
                                    />
                                    {this.validator.message('Auto rescind setting', this.state.selectedProxyOption, 'required')}
                                </Col>
                            </FormGroup>
                        }
                        <h2 className="chat-heading-am">Attendee Interface</h2>
                        <FormGroup className="row mb-2">
                            <Col sm={7}><span id="av-switch">Enable audio/visual streams {this.state.hybrid && this.state.inRoomDifferent ? ' for remote attendees' : ''}</span></Col>
                            <Col sm={2}>
                                <Switch
                                    onChange={this.handleAVChange}
                                    checked={this.state.audiovisual}
                                    className="react-switch"
                                    aria-labelledby="av-switch"
                                />
                            </Col>
                        </FormGroup>
                        {this.state.inRoomDifferent && this.state.hybrid &&
                            <FormGroup className="row mb-2">
                                <Col sm={7}><span id="inRoomAudiovisual-switch">Enable audio/visual streams for attendees in room</span></Col>
                                <Col sm={2}>
                                    <Switch
                                        onChange={(e) => this.switchChange("inRoomAudiovisual", e)}
                                        checked={this.state.inRoomAudiovisual}
                                        className="react-switch"
                                        aria-labelledby="inRoomAudiovisual-switch"
                                    />
                                </Col>
                            </FormGroup>
                        }


                        {(this.state.audiovisual || (this.state.hybrid && this.state.inRoomDifferent && this.state.inRoomAudiovisual)) && <FormGroup className="row mb-2">
                            <Col sm={5}><span id="theme-switch">Select meeting type</span></Col>
                            <Col sm={3}>
                                <Select
                                    value={this.meetingTypeList.find((item) => item.value === this.state.meetingType)}
                                    onChange={this.handleMeetingTypeChange}
                                    options={this.meetingTypeList}
                                    aria-labelledby="meeting-type-switch"  
                                    required
                                />
                            </Col>
                            <Col sm={5}>
                                <span id="theme-switch">
                                    {this.getMeetingTypeLabel(this.state.meetingType) !== "Off" && (
                                        <React.Fragment>
                                            <button type="button" className="btn btn-primary" onClick={() => this.setState({ isMeetingTypeModalOpen: true })}>AV Setup</button>
                                            <Modal show={this.state.isMeetingTypeModalOpen} onHide={this.closeMeetingTypeModal} centered size="xl">
                                                <Container className="pt-3">
                                                    {this.renderModalContent()}
                                                </Container>
                                            </Modal>
                                        </React.Fragment>
                                    )}
                                </span>
                            </Col>
                        </FormGroup>}

                        <FormGroup className="row mb-2">
                            <Col sm={7}><span id="doc-switch">Enable documents {this.state.hybrid && this.state.inRoomDifferent ? ' for remote attendees' : ''}</span></Col>
                            <Col sm={2}> <Switch
                                onChange={this.handleDocChange}
                                checked={this.state.documents}
                                className="react-switch"
                                aria-labelledby="doc-switch"
                            /></Col>
                        </FormGroup>

                        {this.state.inRoomDifferent && this.state.hybrid &&
                            <FormGroup className="row mb-2">
                                <Col sm={7}><span id="inRoomDocuments-switch">Enable documents for attendees in room</span></Col>
                                <Col sm={2}>
                                    <Switch
                                        checked={this.state.inRoomDocuments}
                                        onChange={(e) => this.switchChange("inRoomDocuments", e)}
                                        className="react-switch"
                                        aria-labelledby="inRoomDocuments-switch"
                                    />
                                </Col>
                            </FormGroup>
                        }
                        <FormGroup className="row mb-2">
                            <Col sm={7}><span id="theme-switch">Enable dark theme</span></Col>
                            <Col sm={2}>
                                <Switch
                                    onChange={this.handlethemeChange}
                                    checked={this.state.enabledarktheme}
                                    className="react-switch"
                                    aria-labelledby="theme-switch"
                                />
                            </Col>
                        </FormGroup>

                        <h2 className="chat-heading-am">Attendee Participation</h2>
                        <FormGroup className="row mb-2">
                            <Col sm={7}><span id="chat-switch">Enable questions {this.state.hybrid && this.state.inRoomDifferent ? ' for remote attendees' : ''}</span></Col>
                            <Col sm={2}>
                                <Switch
                                    onChange={this.handleChatChange}
                                    checked={this.state.chat}
                                    className="react-switch"
                                    aria-labelledby="chat-switch"
                                />
                            </Col>
                        </FormGroup>
                        {this.state.inRoomDifferent && this.state.hybrid &&
                            <FormGroup className="row mb-2">
                                <Col sm={7}><span id="inRoomChat-switch">Enable questions for attendees in room</span></Col>
                                <Col sm={2}>
                                    <Switch
                                        onChange={(e) => this.switchChange("inRoomChat", e)}
                                        checked={this.state.inRoomChat}
                                        className="react-switch"
                                        aria-labelledby="inRoomChat-switch"
                                    />
                                </Col>
                            </FormGroup>
                        }
                        {this.state.chat &&
                            <FormGroup className="row mb-2">
                                <Col sm={7}><span id="sah-switch">Enable requests {this.state.hybrid && this.state.inRoomDifferent ? ' for remote attendees' : ''}</span></Col>
                                <Col sm={2}>
                                    <Switch
                                        onChange={(e) => this.switchChange("seenAndHeard", e)}
                                        checked={this.state.seenAndHeard}
                                        className="react-switch"
                                        aria-labelledby="sah-switch"
                                    />
                                </Col>
                            </FormGroup>
                        }


                        {this.state.inRoomChat && this.state.inRoomDifferent && this.state.hybrid &&
                            <FormGroup className="row mb-2">
                                <Col sm={7}><span id="inRoomsah-switch">Enable requests for attendees in room</span></Col>
                                <Col sm={2}>
                                    <Switch
                                        onChange={(e) => this.switchChange("inRoomSeenAndHeard", e)}
                                        checked={this.state.inRoomSeenAndHeard}
                                        className="react-switch"
                                        aria-labelledby="inRoomsah-switch"
                                    />
                                </Col>
                            </FormGroup>
                        }
                        {((this.state.chat && this.state.seenAndHeard) || (this.state.inRoomDifferent && this.state.inRoomChat && this.state.inRoomSeenAndHeard)) &&
                            <button type="button" className="btn btn-primary" onClick={() => this.setState({ showSeenAndHeardSetup: true })} >Set up requests</button>
                        }

                        <FormGroup className="row mb-2">
                            <Col sm={7}><span id="openchat-switch">Enable OpenChat {this.state.hybrid && this.state.inRoomDifferent ? ' for remote attendees' : ''}</span></Col>
                            <Col sm={2}>
                                <Switch
                                    onChange={(e) => this.switchChange("enableOpenChat", e)}
                                    checked={this.state.enableOpenChat}
                                    className="react-switch"
                                    aria-labelledby="openchat-switch"
                                />
                            </Col>
                        </FormGroup>
                        {this.state.inRoomDifferent && this.state.hybrid &&
                            <FormGroup className="row mb-2">
                                <Col sm={7}><span id="inRoomOpenChat-switch">Enable OpenChat for attendees in room</span></Col>
                                <Col sm={2}>
                                    <Switch
                                        onChange={(e) => this.switchChange("inRoomOpenChat", e)}
                                        checked={this.state.inRoomOpenChat}
                                        className="react-switch"
                                        aria-labelledby="inRoomOpenChat-switch"
                                    />
                                </Col>
                            </FormGroup>
                        }

                        <h2 className="chat-heading-am">Presenter Interface</h2>
                        <FormGroup className="row mb-2">
                            <Col sm={7}><span id="comfortMonitor-switch">Enable Comfort Monitor</span></Col>
                            <Col sm={2}>
                                <Switch
                                    onChange={(e) => this.switchChange("enableComfortMonitor", e)}
                                    checked={this.state.enableComfortMonitor}
                                    className="react-switch"
                                    aria-labelledby="comfortMonitor-switch"
                                />
                            </Col>
                        </FormGroup>


                        <FormGroup className="row mb-2">
                            <Col sm={6}>
                                <Button disabled={!isAuthorized('Switches', 'Write')} type="submit" color="success">Submit</Button>{' '}
                            </Col>
                        </FormGroup>
                    </Form>
                    :
                    <div>No data found for this meeting</div>
                }
                <SeenAndHeardContextProvider>
                    <SeenAndHeard show={this.state.showSeenAndHeardSetup} close={this.hideSeenAndHeardSetup} isAuthorized={isAuthorized('Switches', 'Write')} />
                </SeenAndHeardContextProvider>
            </Container>
        );
    }
}

Switches.contextType = AdminContext;