//import React, { Component, Fragment } from 'react';
import * as signalR from '@microsoft/signalr'
import { EventEmitter } from 'events';
import axios from 'axios';
import { NotificationManager } from 'react-notifications';

const SRConstants = {
    SETUP: 'setup',  // Used to start the SignalR connections
    CLOSE: 'close',  // Used to stop the SignalR connections
    CONNECTED: 'connected', // Used to highlight when SignalR connects (may not be working yet)
    MESSAGES: 'messages', // Used to notify when Messages need to refresh
    VOTEUPDATE: 'voteupdate', // Used to notify when voting status's change
    ADMINMSG: 'administratormessage', // Used to notify Admin users of a message change
    SWITCHES: 'switches', // Used to notify other admins when switches have changed
    SIGNALR: 'signalr', // Used to notify components of state change in signalr
    ATTCOUNT: 'attcount', // Used to count attendees still connected
    QUICKPOLL: 'quickpoll', // USed to refresh the QuickPoll screens
    OPENCHATMSG: 'openchat' // Used to signal an update to the OpenChat
}

var joinedGroups = null;
var attendeeCountTimer = null;
var retryTimer = null;
export var connection;

//export var token = 

export class SignalRController extends EventEmitter {

    constructor(props) {
        super(props);
        this.setupNotifications = this.setupNotifications.bind(this);
        this.TimerReStart = this.TimerReStart.bind(this);
        this.getAccessToken = this.getAccessToken(this);
    }

    async getAccessToken() {
        return "test";
    }

    addCallbackHub2Adm_Attendance(callback) {
        this.addListener(SRConstants.ATTCOUNT, callback);
        return;
    }

    removeCallbackHub2Adm_Attendance(callback) {
        this.removeListener(SRConstants.ATTCOUNT, callback);
        return;
    }

    // Used to flag any significant switch changes (such as Test Users counted)
    addCallback_SignalRStateChange(callback) {
        this.addListener(SRConstants.SIGNALR, callback);
        return;
    }

    // Used to flag any significant switch changes (such as Test Users counted)
    removeCallback_SignalRStateChange(callback) {
        this.addListener(SRConstants.SIGNALR, callback);
        return;
    }

    // Sends to MenuLogin Bar and others who are registered that the SignalR status has changed
    Invoke_SignalRChange() {
        this.emit(SRConstants.SIGNALR, connection._connectionState);
        return;
    }

    // Return the connection SignalR state + if we are connected to the groups
    // Implications are that if we are connected we can send signals but if we are not part of a group we don't recieve them
    connectionState(hub) {
        if (connection) {
            if (joinedGroups === connection.connectionId) return (connection._connectionState + " Groups");
            else return (connection._connectionState)
        } else return "Not Connected";
    }

    removeCallbackAdm2Adm_SwitchUpdate(callback) {
        this.removeListener(SRConstants.SWITCHES, callback);
        return;
    }

    // Used to flag any significant switch changes (such as Test Users counted)
    addCallbackAdm2Adm_SwitchUpdate(callback) {
        this.addListener(SRConstants.SWITCHES, callback);
        return;
    }

    InvokeAdm2Adm_SwitchUpdate() {
        connection.invoke("srHubAdm2Adm_SwitchUpdate").catch(err => (
            console.log("Unable to send switch update to adm", err.message)));
        return;
    }
    // Admin in this context covers all users of the Administration app - including Moderators, Presenters, Admins

    addCallbackAdm2Adm_MessageUpdate(callback) {
        this.addListener(SRConstants.ADMINMSG, callback);
        return;
    }

    removeCallbackAdm2Adm_MessageUpdate(callback) {
        this.removeListener(SRConstants.ADMINMSG, callback);
        return;
    }

    addCallbackHub2Adm_OpenChat(callback) {
        this.addListener(SRConstants.OPENCHATMSG, callback);
        return;
    }

    removeCallbackHub2Adm_OpenChat(callback) {
        this.removeListener(SRConstants.OPENCHATMSG, callback);
        return;
    }

    // Callbacks used by the Admin screens to identify when they need to refresh their Voting elements
    addCallbackAdm2Adm_VoteUpdate(callback) {
        this.addListener(SRConstants.VOTEUPDATE, callback);
        return;
    }

    removeCallbackAdm2Adm_VoteUpdate(callback) {
        this.removeListener(SRConstants.VOTEUPDATE, callback);
        return;
    }

    InvokeAdm2Adm_QuickPolllUpdate() {
        // Send a message to the Hub to relay to all Admin Users that they need to refresh their message queues
        connection.invoke("srHubAdm2Adm_QuickPollUpdate").catch(err => (
            console.log("Unable to send update to ", err.message)));
        return;
    }


    // Callbacks used by the Admin screens to identify when they need to refresh their Voting elements
    addCallbackAdm2Adm_QuickPollUpdate(callback) {
        this.addListener(SRConstants.QUICKPOLL, callback);
        return;
    }

    removeCallbackAdm2Adm_QuickPollUpdate(callback) {
        this.removeListener(SRConstants.QUICKPOLL, callback);
        return;
    }

    // Used by admin to refresh all Attendee's browsers so that they pick up new settings
    InvokeAdm2Att_ForceLogout(message) {
        let vmID = parseInt(sessionStorage.getItem('VMId'));

        this.CheckSignalRReady() ? connection.invoke("srHubAdm2Att_ForceLogout", message).catch(err => (
            console.log("Unable to send force logout to meeting ", vmID, err.message))) : console.error("srHubAdm2Att_ForceLogout - SignalR not ready")

        return;
    }

    // Used by admin to refresh all Attendees' browsers so that they pick up new settings
    InvokeAdm2Att_RefreshBrowser(message) {
        let vmID = parseInt(sessionStorage.getItem('VMId'));

        this.CheckSignalRReady() ? connection.invoke("srHubAdm2Att_RefreshBrowser", message).catch(err => (
            console.log("Unable to send refresh to meeting ", vmID, err.message))) : console.error("srHubAdm2Att_RefreshBrowser - SignalR not ready")

        return;
    }

    // Used by admin to soft refresh all Attendees' meetings so that they pick up new settings
    InvokeAdm2Att_SoftRefresh(message) {
        let vmID = parseInt(sessionStorage.getItem('VMId'));

        this.CheckSignalRReady() ? connection.invoke("srHubAdm2Att_SoftRefresh", message).catch(err => (
            console.log("Unable to send soft refresh to meeting ", vmID, err.message))) : console.error("srHubAdm2Att_SoftRefresh - SignalR not ready")

        return;
    }

    TimerReStart() {
        clearTimeout(retryTimer);
        retryTimer = null;
        this.ConnectionStart();
    }

    ConnectionSetup(token) {
        if (!connection || !token) {
            axios.get('VMGetSignalRHubURL')
                .then(response => {

                    connection = new signalR.HubConnectionBuilder()
                        .withUrl(response.data, {
                            accessTokenFactory: () => { return token }
                        })
                        .configureLogging(signalR.LogLevel.Warning)
                        .withAutomaticReconnect({
                            nextRetryDelayInMilliseconds: 5000
                        })
                        .build();
                }).then(() => {
                    this.ConnectionStart();
                }).catch(function (error) {
                    console.log('unable to get SignalR hub');
                });

        }
    }

    async joinSRGroups(ConnectionId) {
        const options = {
            url: 'Adm2Hub_AdminGroupAdd',
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            data: JSON.stringify(ConnectionId)
        };
        await axios(options).catch(function (error) {
            NotificationManager.error("Error trying to contact server", "SignalR Join Group" + error.message, 4000);
            typeof (this.state) != 'undefined' && clearInterval(this.state.countTimer);
        })
    }

    async ConnectionStart() {

        if (!connection) {
            console.log("SignalR not ready - try later");
            return;
        }

        var vmID = sessionStorage.getItem('VMId');
        if (retryTimer == null && (vmID == null || parseInt(vmID) === 0)) {
            // Try again in 2 seconds to see if vmID is available
            retryTimer = setTimeout(() => { this.TimerReStart() }, 2000);
        }
        else {
            if (!connection || connection._connectionState === "Disconnected") {
                console.log("SignalR - Connecting")
                connection.start().then(() => {
                    this.Invoke_SignalRChange();
                    this.setupNotifications();
                    this.ConnectionStart(); // Ok now we have connected come back into this function to try and join the groups
                    return;
                }).catch(err => {
                    console.error("Error connecting to SignalR - retrying in 10 seconds");
                    this.Invoke_SignalRChange();
                    setTimeout(() => this.ConnectionStart(), 10000);
                });
            }
        }

        if (connection && connection._connectionState === "Connected") {
            if (joinedGroups === null && parseInt(vmID) > 0) {
                await this.joinSRGroups(connection.connectionId);
                joinedGroups = connection.connectionId;
                this.Invoke_SignalRChange();
                this.sendPingRequest();
                if (!attendeeCountTimer)
                    attendeeCountTimer = setInterval(() => { this.sendPingRequest(); }, 30000);
            } else {
                joinedGroups === null && setTimeout(() => { this.ConnectionStart() }, 5000);
            }
        }
        return;
    }

    // Replacement call for SignalR ping routine so we can get the vMeeting ID from the header
    async sendPingRequest() {
        const options = {
            url: 'Adm2HubAttendancePing',
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        };

        await axios(options).catch(function (error) {
            NotificationManager.error("Error trying to contact server", "SignalR Ping ", 4000); //Real error message removed, user should not see this
            clearInterval(attendeeCountTimer);
        })

    }


    setupNotifications() {
        // Coming from an Attendee's
        connection.on("srSignalAtt2Adm_MessageUpdate", (message) => {
            this.emit(SRConstants.ADMINMSG, message);
        });

        // Coming from other Admin Users

        connection.on("srHubAdm2Adm_MessageUpdate", (message) => {
            //This is what everything is apparently sending
            this.emit(SRConstants.ADMINMSG, message);
        });

        connection.on("srSignalAdm2Adm_MessageUpdate", (message) => {
            //Is anything still sending on this one? I don't want to remove if it's still in use
            this.emit(SRConstants.ADMINMSG, message);
        });

        connection.on("srSignalAdm2Adm_VoteUpdate", (message) => {
            this.emit(SRConstants.VOTEUPDATE, message);
        });

        connection.on("srSignalAdm2Adm_QuickPollUpdate", (message) => {
            this.emit(SRConstants.QUICKPOLL, message);
        });

        connection.on("srSignalAdm2Adm_SwitchUpdate", (message) => {
            this.emit(SRConstants.SWITCHES, message);
        });

        connection.on("srSignalHub2Adm_PingCount", (nPingback) => {
            this.emit(SRConstants.ATTCOUNT, nPingback);
        });

        connection.onreconnecting(() => {
            this.Invoke_SignalRChange();
        })

        connection.onreconnected(() => {
            this.ConnectionStart();
        })

        connection.onclose(async () => {
            joinedGroups = null;
            this.Invoke_SignalRChange();
            // Might need some validation on this. What happens when we log out?
            this.ConnectionStart();
        })


        return;
    }

    CheckSignalRReady() {
        if (sessionStorage.getItem('VMId') == null) return false;
        if (parseInt(sessionStorage.getItem('VMId')) < 1) return false;
        if (connection._connectionState !== "Connected") return false;
        if (joinedGroups !== connection.connectionId) return false;
        return true;
    }


    InvokeAdm2Att_QuickPollUpdate(groupname, value) {

        this.CheckSignalRReady() ? connection.invoke("srHubAdm2Att_QuickPollUpdate", groupname, value).catch(err => (
            console.log("Unable to send update to ", groupname, err.message))) : console.error("InvokeAdm2Att_QuickPollUpdate - SignalR not ready");

        return;
    }

    InvokeAdm2Adm_QuickPollUpdate(groupname, value) {

        this.CheckSignalRReady() ? connection.invoke("srHubAdm2Adm_QuickPollUpdate", groupname, value).catch(err => (
            console.log("Unable to send update to ", groupname, err.message))) : console.error("InvokeAdm2Adm_QuickPollUpdate - SignalR not ready");

        return;
    }


    InvokeAdm2All_VoteClose(resol) {
        if (this.CheckSignalRReady()) {
            connection.invoke("srHubAdm2Att_VoteClose", parseInt(sessionStorage.getItem('VMId'))).catch(err => console.error(err));// To update the Attendees
            connection.invoke("srHubAdm2Adm_VoteUpdate", parseInt(sessionStorage.getItem('VMId'))).catch(err => console.error(err)); // To Update the Moderators and Presenters
        } else {
            console.error("InvokeAdm2All_VoteClose - SignalR not ready")
        }
        return;
    }

}

//SignalRController.contextType = AdminContext; //Not ReactJS class, can't use context

export default new SignalRController();