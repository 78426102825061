import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';
import React from "react";
import Validate from "./Validate";

export function ShortAnswer({ question = [],
    handleChange = () => { },
    disabled = false,
    handleSave = () => { } }) {

    let errorMessage = Validate(question, handleChange, disabled)

    function onChange(evt) {
        let qst = { ...question, Response: evt.target.value }
        handleChange(qst)
    }

    function onBlur(evt) {
        let qst = { ...question, Response: evt.target.value }
        handleSave(qst)
    }

    return (
        errorMessage === "" ? <Form.Group className='form-div'>
            <Form.Label htmlFor={question.id}>{question.QuestionTitle}{question.Mandatory ? "*" : ""}</Form.Label>
            <Form.Control
                type="text"
                id={question.id}
                placeholder="Enter your text here"
                maxLength={question.MaximumLength}
                name={question.id}
                data-testid={question.id}
                onChange={(evt) => onChange(evt)}
                onBlur={(evt) => onBlur(evt)}
                value={question.Response ? question.Response : ""}
                required={question.Mandatory}
                disabled={disabled} />
            <Form.Control.Feedback type="invalid">Please provide a response.</Form.Control.Feedback>
        </Form.Group> : <Alert variant="danger" className='alert-margin'>{errorMessage}</Alert>
    )
}