import React, { useContext, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import AdminContext from '../../context/AdminContext';
import { MessageAddNewAdminMessage } from './ApiFunctions';

export function NewMessageModal() {
    const [show, setShow] = useState(false);
    const { meetingId, userId } = useContext(AdminContext);
    const [messageText, setMessageText] = useState('');

    const handleClose = () => setShow(false);
    const handleShow = () => {
        setMessageText('');
        setShow(true);
    }
    const saveChanges = async() => {
        let response = await MessageAddNewAdminMessage(meetingId, userId, messageText);
        if (response.statusID === 1) {
            setShow(false);
        }
    }

    return (
        <>
            <button className="btn border btn-sm" title="Add admin message" onClick={handleShow}> <FontAwesomeIcon icon={faPlus} /></button>

            <Modal show={show} onHide={handleClose}
                size="lg"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>Add message to presenter</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group controlId="newMessageText" className="m-2">
                        <Form.Label>
                            {`Add a message (${messageText.length}/500)`}
                        </Form.Label>
                        <Form.Control as="textarea" rows={7}
                            placeholder="New message"
                            value={messageText}
                            onChange={(e) => setMessageText(e.target.value)}
                            maxLength="500"
                            autoFocus
                        />
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={saveChanges}>
                        Add message
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

