import React, { Fragment } from 'react';
import { MessageGroup } from './MessageGroup';
import { Droppable } from 'react-beautiful-dnd';

export function MessageGroupDataBlock(props) {
    let { data, currentInbox, showHidden } = props;

    return (
        <Droppable droppableId={currentInbox.toString()} type="Group">
            {(provided, snapshot) => (
                <div ref={provided.innerRef} {...provided.droppableProps} className={`chat-box ${snapshot.isDraggingOver ? 'drag-over' : ''}`} >
                    {(data?.length > 0) ?
                        <Fragment>
                            {data.map((group, index) => <Fragment key={group.messageGroupId}>
                                {(showHidden || (!group.displayHidden && group.currentInbox === currentInbox)) ?
                                    <MessageGroup index={index} group={group} {...props} />
                                    :
                                    <div />
                                }
                            </Fragment>)}
                        </Fragment>
                        : <div>No messages found</div>
                    }
                    {provided.placeholder}
                </div>
            )}
        </Droppable>
    )
}