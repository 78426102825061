import React, { useContext, useState, useEffect } from 'react';
import { NotificationManager } from 'react-notifications';
import UserPermissionContext from '../UserPermissionContext';
import UserAccess from './UserAccess';
import { userActions }  from './AccessList';
import { UserTypes } from './UserAdminTypes';

const UserAccessSection = ({handlePermissionAction }) => {
    const [isClientsLoading, setIsClientLoading] = useState(false);
    const [isMeetingsLoading, setIsMeetingsLoading] = useState(false);
    const [client, setClient] = useState();

    

    //Contexts//
    const Context = useContext(UserPermissionContext);
    const { selectedUser } = Context;

    /**
     * Component hooks
     * */
    useEffect(() => {
        GetUserDetails();
        return () => {
            Context.setUserClients([]);
            Context.setUserMeetings([]);
        }
    }, [selectedUser])


    /**
     * Component methods
     **/
    const GetUserDetails = async () => {
        if (selectedUser) {
            setIsClientLoading(true);
            await Context.getUserAccessDetails(selectedUser.Id);
            setIsClientLoading(false);
        }
    }

    /**
     * Function to handle client selection.
     * It will make an api call to get users meetings 
     * list.
     * */
    const handleClientSelection = async (selectedClient) => {
        setClient(selectedClient);
        setIsMeetingsLoading(true);
        await Context.GetUserMeetingsAccessDetails(selectedUser.Id, selectedClient);
        setIsMeetingsLoading(false);
    }

    /**
     * Function to handle users action 
     * clicks.
     * */
    const handleActionClick = async (sectionId, accessItem, userAction) => {
        let userParams = {
            Id: selectedUser.Id,
            PermissionLevel: sectionId === 1 ? UserTypes.CLIENT_USER : UserTypes.MEETING_USER,
            ClientId: sectionId === 1 ? accessItem.Id : null,
            MeetingId: sectionId === 2 ? accessItem.Id : null,
        };
        if (userAction === userActions.PERMISSION) {
            
            handlePermissionAction(userParams, getNavigateToTabIndex(sectionId));
            updateContext(sectionId, accessItem);
          
        }
        if (userAction === userActions.ADDACCESS) {
            const status = await Context.AddUserAccessPermission(userParams);
            if (status?.errorMessage === '') {
                updateContext(sectionId, accessItem);
                handlePermissionAction(userParams, getNavigateToTabIndex(sectionId));
            }
            else {
                NotificationManager.error(status?.errorMessage, "Permissions", 4000);
            }
        }
        if (userAction === userActions.DELETE) {            
            let removeStatus = await deleteUserAccess(sectionId, accessItem);
            if (removeStatus) {
                updateContext(sectionId, accessItem);
                NotificationManager.success("User permissions deleted", "Permissions", 2000);
                await loadUserDetails(sectionId, accessItem)
            }
            else {
                NotificationManager.error("Sorry, there was a problem while deleting user permissions", "Permissions", 4000);

            }
        }
    }


    const loadUserDetails = async(sectionId, accessItem) => {
        if (sectionId === 1) {
            Context.setUserMeetings([]);
            await GetUserDetails();
        }
        if (sectionId == 2) {
            await GetUserDetails();
            //await handleClientSelection(client)
        }
    }

    const updateContext = (sectionId, accessItem) => {
        if (sectionId === 1) {
            Context.setSelectedClient({ value: accessItem.Id, name: accessItem.Name });
        }
        if (sectionId == 2) {
            Context.setUserMeeting({ value: accessItem.Id, name: accessItem.Name });
        }
    }


    /**
     * Perform delete request.
     * Based on section and users selection 
     * make delete access item request at appropriate
     * level.
     * */
    const deleteUserAccess = async (sectionId, accessItem) => {
        const userParams = paramsForDeleteAction(sectionId, accessItem);
        return await Context.DeleteMeetingPermissions(userParams);
    }

    /**
     * Get user's parameters for delete request
     * */
    const paramsForDeleteAction = (sectionId, accessItem) => {
        return {
            Id: selectedUser.Id,
            meetingId: sectionId === 2 ? accessItem.Id : null,
            clientId: sectionId === 1 ? accessItem.Id : null
        }
    }

    /**
     * Get tabs index based on users action on 
     * client or meeting section
     * */
    const getNavigateToTabIndex = (sectionId) => {
        if (sectionId === 1) {
            return 2;
        }
        else if (sectionId === 2) {
            return  1;
        }
    }


    return (
        <div className="d-flex justify-content-between">
            <UserAccess
                isLoading={isClientsLoading}
                accessList={Context.userClients}
                accessLevel={'Client(s)'}
                sectionId={1}
                handleClientSelection={handleClientSelection}
                handleActionClick={handleActionClick}
                selectedUser={selectedUser}
            />
            <UserAccess
                isLoading={isMeetingsLoading}
                accessList={Context.userMeetings}
                accessLevel={'Meeting(s)'}
                sectionId={2}
                handleActionClick={handleActionClick}
                selectedUser={selectedUser}
            />
        </div>
    )
}

export default UserAccessSection;