import React from 'react';
import { Row } from 'react-bootstrap';

export const ResCard = (props) => {
    function handleVoteOptionChange(e) {
        if (e.target.value.length <= 100) {
            var resData = props.Data;
            resData.voteOption = e.target.value;
            props.Update(props.Index, resData);
        }
    }

    function handleVoteValueChange(e) {
        var resData = props.Data;
        resData.voteValue = parseInt(e.target.value);
        props.Update(props.Index, resData);
    }

    function handleRemoveButton(e) {
        e.preventDefault();
        props.DeleteResOption(e, props.Index, props.Data.voteOptionId)
    }

    return (
        <Row key={'cc' + props.Index} className="candidateSetup" >
            <div className="col">
                <Row className="align-items-center">
                    <div className="col-md-2 col-lg-2 col-xl-1 align-items-center">
                        <input disabled={props.disabled || props.Sortable} type="number" className="form-control" name="voteValue" value={props.Data.voteValue} onChange={(e) => handleVoteValueChange(e)} placeholder="Value" autoComplete="off" />
                    </div>
                    <div className="col align-items-center">
                        <input disabled={props.disabled || props.Sortable} type="text" className="form-control" name="voteOption" value={props.Data.voteOption} onChange={(e) => handleVoteOptionChange(e)} placeholder="Option" autoComplete="off" />
                    </div>
                </Row>

            </div>
            <div className="col-md-2">
                {props.OptionCount > 1 && props.Index > 0 && !props.disabled &&
                    <button className="btn btn-danger mr-2" onClick={(e) => handleRemoveButton(e)}>Delete</button>
                }
            </div>
        </Row >
    )
}


