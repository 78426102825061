import React, { useEffect } from "react";
import { useMsal } from "@azure/msal-react";
import { getControllerRequestScopes } from "../../authConfig";

async function handleLogin(instance) {
    /*instance.loginPopup(loginRequest)
        .then(function (loginResponse) { console.log(loginResponse) })
        .catch(e => {
         console.error(e);
        });
        */
    // Try SSO before trying the loginPopup.
    //instance.ssoSilent(loginRequest).catch(e => {
    let request = await getControllerRequestScopes();
    instance.loginRedirect(request).catch(error => {
        errorHandler(instance, error);
    });
    //});
    //instance.loginPopup(request).catch(error => {
    //instance.loginRedirect(request).catch(e => {
    //    console.log(e);
    //});



}

function errorHandler(instance, error) {
    //The system is designed to generate errors and let you handle them, everything needs to pass its errors to an error handler to decide how to respond.
    console.log("Log in error", error.message);  
    //This error is "User cancelled forgot password flow"
    if (error.message.includes('AADB2C90091')) {
        handleLogin(instance);
    }
}



/**
 * Renders a button which, when selected, will open a popup for login
 */
export const SignInButton = () => {
    const { instance, inProgress } = useMsal();
    useEffect(() => {
        if (window.location.toString().includes("id_token")) {
            handleLogin(instance)
        }
    }, [])

    return (
        <button className="ml-auto btn btn-primary" onClick={() => handleLogin(instance)}>Login</button>
    );
}