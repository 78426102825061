import { InstanceContextProvider } from "./InstanceContextProvider";
import { SurveyAdminContextProvider } from "./SurveyAdminContextProvider";
import StartPage from "./StartPage";

export default function SurveyAdminParent() {
    return(
        <InstanceContextProvider>
            <SurveyAdminContextProvider>
                <StartPage/>
            </SurveyAdminContextProvider>
        </InstanceContextProvider>)
}