import React, { Fragment, useState, useContext } from 'react';
import { faEllipsisH, faFlag } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Dropdown, DropdownButton, OverlayTrigger, Popover } from 'react-bootstrap';
import OpenChatContext from './Common/OpenChatContext/OpenChatContext';
import { ModeratorDelete, ModeratorFlag } from './ModeratorFlag';
import { EditPost } from './EditPost';
import AdminContext from '../context/AdminContext';



//Three dots for edit, flag, delete etc.
export function ContextMenu(props) {
    const context = useContext(OpenChatContext);
    const authContext = useContext(AdminContext);
    const [show, setShow] = useState(false);
    const [showEdit, setShowEdit] = useState(false);
    const [showDelete, setShowDelete] = useState(false);
    const isAuthorized = authContext.isAuthorized('OpenChatMod', 'Write');
    let post = props.post;
    

    const handleClose = () => setShow(false);
    const handleDeleteClose = () => setShowDelete(false);
    const handleEditClose = () => setShowEdit(false);
    const handleShow = () => setShow(true);
    const handleDeleteShow = () => setShowDelete(true);
    const handleEditShow = () => setShowEdit(true);

    const buttonTitle = post.Flags ?
        <Fragment>
            <FontAwesomeIcon icon={faFlag} color="red" />
            &nbsp;
            <span className="likeCountDisplay">{post.TotalFlags && post.TotalFlags[0].FlagCount}</span>
        </Fragment>
        : <FontAwesomeIcon icon={faEllipsisH} title="moderator options" />

    const deleteOrFlag = post.Flags ?
        <Dropdown.Item
            as="button"
            className="sm small"
            title="Resolve flag"
            onClick={handleShow}>
            Resolve flag
        </Dropdown.Item> :
        <Dropdown.Item
            className="sm small"
            onClick={handleDeleteShow}
            as="button">
            Delete
        </Dropdown.Item>

    const popover =
        <Popover>
            <Popover.Header as="h3">Flag breakdown</Popover.Header>
            <Popover.Body>
                {post.Flags && post.Flags.map(flag => {
                    return (
                        <div key={flag.FlagType}>{flag.Description}: {flag.FlagCount} </div>
                    )
                })}
            </Popover.Body>
        </Popover>




    return (
        <Fragment>
            <OverlayTrigger trigger={post.Flags ? ["hover", "focus"] : ''} placement="top" overlay={popover}>
                <DropdownButton disabled={!isAuthorized}
                    id="dropdown-item-button"
                    size="sm"
                    drop="start"
                    variant="transparent"
                    title={buttonTitle}
                    className="btn btn-sm p-0">
                    {post.Hidden ?
                        <Dropdown.Item
                            as="button"
                            className="sm small"
                            onClick={() => context.OpenChatUnDeletePost(post.PostId)}
                        >Undelete
                        </Dropdown.Item>
                        :
                        <Fragment>
                            <Dropdown.Item
                                as="button"
                                className="sm small"
                                onClick={() => context.setReplyTarget(post.PostId)}>Reply
                            </Dropdown.Item>
                            {post.IsPostOwner &&
                                <Dropdown.Item
                                    as="button"
                                    className="sm small"
                                    onClick={handleEditShow}>Edit
                                </Dropdown.Item>
                            }
                            {deleteOrFlag}
                        </Fragment>
                    }

                </DropdownButton>
            </OverlayTrigger>
            {show && <ModeratorFlag show={show} handleClose={handleClose} post={post} />}
            {showEdit && <EditPost show={showEdit} handleClose={handleEditClose} post={post} />}
            {showDelete && <ModeratorDelete show={showDelete} handleClose={handleDeleteClose} post={post} />}
        </Fragment>
    )
}

