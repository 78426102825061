import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Fragment, useContext, useState, useRef, useEffect } from 'react';
import OpenChatContext from './OpenChatContext/OpenChatContext';
import { QuotedChatMessage } from './OpenChatPost';
import EmojiPicker from '../EmojiPicker'



export function NewPostInterface(props) {
    const runAfterUpdate = useRunAfterUpdate();
    const context = useContext(OpenChatContext);
    const [textField, setTextField] = useState('');
    const [disableSend, setDisableSend] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const ref = useRef(null);
    const refEmojiPicker = useRef(null);
    let targetId = 0;
    const [isEmojiListVisible, setEmojiVisibility] = useState(false);

    useEffect(() => {
        document.addEventListener('click', handleClickOutside);
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    });

    useEffect(() => {
        ref.current.focus();
    }, [context.replyTarget])

    const handleClickOutside = (event) => {
        if (refEmojiPicker.current && !refEmojiPicker.current.contains(event.target) && !event.target.classList.contains('spanEmojiPicker')) {
            setEmojiVisibility(false);
        }
    };

    if (props.level < 2) {
        targetId = props.postId;
    } else if (props.level === 2) {
        targetId = props.parentPostId;
    }

    async function onSubmit(e) {
        setDisableSend(true);
        setErrorMessage('')
        let success = await context.OpenChatNewPost(e, textField)
        if (success === true) {
            props.scrollToBottom();
            setTextField('');
            setDisableSend(false);
        } else if (success === "profanity") {
            setErrorMessage("A profanity filter is enabled")
            setDisableSend(false)
        }

    }

    const handleEmojiClick = (emoji) => {
        const cursor = ref.current.selectionStart;
        const text = textField.slice(0, cursor) + (emoji.E).trim() + textField.slice(cursor);
        setTextField(text);
        runAfterUpdate(() => {
            ref.current.selectionStart = cursor + (emoji.E).trim().length;
            ref.current.selectionEnd = cursor + (emoji.E).trim().length;
            ref.current.focus();
        })
    }

    const getEmojis = () => {
        context.OpenChatGetEmojis();
        setEmojiVisibility(!isEmojiListVisible);
    }

    return (
        <Fragment>
            <div className="newPostInterface">
                <form autoComplete="off" onSubmit={(e) => onSubmit(e)}>
                    <div className="d-flex py-1">
                        <div className="flex-grow-1 border border-secondary rounded">
                            {context.replyTarget > 0 &&
                                <div className="d-flex">
                                    <div className="flex-grow-1 p-1">
                                        <QuotedChatMessage postId={context.replyTarget} />
                                    </div>
                                    <div>
                                        <button type="button" className="btn btn-sm" onClick={() => context.setReplyTarget(0)}><FontAwesomeIcon icon={faTimes} /></button>
                                    </div>
                                </div>
                            }
                            <EmojiPicker
                                refEmojiPicker={refEmojiPicker}
                                isEmojiListVisible={isEmojiListVisible}
                                handleEmojiClick={handleEmojiClick}
                            />
                            <div className="form-floating border-0">
                                <input type="text" ref={ref} className="form-control border-0" id={"newPostInput" + targetId} maxLength="500"
                                    value={textField} onChange={e => { setTextField(e.target.value); setErrorMessage("") }}
                                    placeholder={`Enter your ${context.replyTarget > 0 ? 'reply' : 'message'} `} required
                                />
                                <label htmlFor={"newPostInput" + targetId}>Enter your {context.replyTarget > 0 ? 'reply' : 'message'}</label>
                            </div>
                            
                         
                        </div>
                    </div>
                    <div className="d-flex">
                        <button type="button" className="spanEmojiPicker emoji-icon btn btn-sm p-0" onClick={() => getEmojis()}>😀</button>
                        <div className="flex-grow-1 text-danger align-self-center px-2">
                            {errorMessage}
                        </div>
                        <div>
                            <button disabled={!props.isAuthorized || disableSend} type="submit" className="btn btn-sm btn-primary">Send</button>
                        </div>
                    </div>
                </form>
            </div>
        </Fragment>
    )
}

function useRunAfterUpdate() {
    const afterPaintRef = React.useRef(null);
    React.useLayoutEffect(() => {
        if (afterPaintRef.current) {
            afterPaintRef.current();
            afterPaintRef.current = null;
        }
    });
    return fn => (afterPaintRef.current = fn);
}
