import React, { Component, useContext } from 'react';
import axios from 'axios';
import { Container, Row, Col, Form, Button, FloatingLabel, AccordionContext, useAccordionButton, Accordion } from 'react-bootstrap';
import SimpleReactValidator from 'simple-react-validator';
import { NotificationManager } from 'react-notifications';

import { CandidateSort } from './CandidateComponents/CandidateSort';
import ContestContext from './Context/ContestContext';
import Select from 'react-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinus, faPlus } from '@fortawesome/free-solid-svg-icons';
import { HelpButton } from '../MeetingSetup/HelpButton';



export default class CandidateVoteSetup extends Component {
    constructor(props) {
        super(props);

        this.publishTypes = [
            { value: 1, label: "Standard" },
            { value: 2, label: "Weighted" },
            { value: 3, label: "Both" }
        ];

        this.state = {
            ContestId: 0,
            ContestName: '',
            Seats: undefined,
            MaxVotes: undefined,
            ShowStatement: false,
            ShowPhoto: false,
            RandomiseOrder: false,
            ShowNumSeats: true,
            ShowMaxVotes: true,
            ShowCountSelected: true,
            VMeetingId: this.props.meeting,
            AdditionalText: '',
            VotingFor: 'candidate',
            options: [],
            errorMessage: "",
            votesErrorMessage: "",
            seatsErrorMessage: "",
            showButton: true,

            publishtypeid: this.props.publishtypeid,
            enableweightedcount: this.props.enableweightedcount,
            selectedPublishType: this.publishTypes.filter((x) => x.value === this.props.publishtypeid)[0]
        };



        this.validator = new SimpleReactValidator({ autoForceUpdate: this, element: (message, className) => <div className="text-danger">{message}</div> });
        this.submitted = false;
    }



    componentDidMount() {
        if (this.props.contestdata && this.props.actionType === "edit") {
            this.setState({
                contestData: this.props.contestdata,
                ContestId: this.props.id,
                ContestName: this.props.name,
                Seats: this.props.contestdata.contestSeats,
                MaxVotes: this.props.contestdata.maxVotes,
                ShowStatement: this.props.contestdata.showStatement,
                ShowPhoto: this.props.contestdata.showPhoto,
                RandomiseOrder: this.props.contestdata.randomiseCandidates,
                options: this.props.contestdata.options,
                AdditionalText: this.props.contestdata.additionalResolutionText,
                ShowNumSeats: this.props.contestdata.showNumSeats,
                ShowMaxVotes: this.props.contestdata.showMaxVotes,
                ShowCountSelected: this.props.contestdata.showCountSelected,
                VotingFor: this.props.contestdata.votingFor ? this.props.contestdata.votingFor : 'candidate'
            });

            this.context.SortCandidateList(this.props.actionType, this.props.contestdata.options);
        } else if (this.props.actionType === "new") {
            this.context.SortCandidateList(this.props.actionType, null);
        }
    }


    onSubmit = (e) => {
        e.preventDefault();
        this.setState({
            showButton: false,
            ContestName: this.state.ContestName.trim()
        })
        var success = false;

        let voteOption = this.context.voteOptions.map((vo) => (vo.candidateForename.trim() + ' ' + vo.candidateSurname.trim()).trim());
        let maxvotes = 0;
        if (parseInt(this.state.MaxVotes) > 0) {
            maxvotes = parseInt(this.state.MaxVotes);
        }

        else if (parseInt(this.props.type) === 2) {
            maxvotes = parseInt(this.state.Seats) > 0 ? parseInt(this.state.Seats) : 1;
        }
        else if (parseInt(this.props.type) === 3) {
            maxvotes = this.context.voteOptions.length;
        }

        let invalidVotes = false;
        let invalidSeats = false;
        let emptyVoteOption = voteOption.includes('');
        if (emptyVoteOption || voteOption[0] === undefined || voteOption[0] === "undefined undefined" || this.context.voteOptions.length === 0) {
            emptyVoteOption = true;
            this.setState({
                errorMessage: 'Please enter a valid candidate.'
            })
        }

        if (this.state.MaxVotes !== null && this.state.MaxVotes < 1) {
            invalidVotes = true;
            this.setState({
                votesErrorMessage: 'Please enter valid number of votes.'
            })
        }

        if (this.state.Seats !== null && this.state.Seats < 1) {
            invalidSeats = true;
            this.setState({
                seatsErrorMessage: 'Please enter valid number of seats.'
            })

        }

        if (!this.validator.allValid() || emptyVoteOption === true || this.dupVoteOption(voteOption) === true || invalidSeats || invalidVotes) {
            this.validator.showMessages();
        }
        else {
            this.setState({
                errorMessage: '',
                votesErrorMessage: '',
                seatsErrorMessage: ''
            })

            let objops = this.context.voteOptions;
            const formData = new FormData();
            formData.append("VmeetingId", this.context.meetingId);
            formData.append("UserID", this.context.userId);
            formData.append("ContestId", this.props.actionType === "edit" ? this.props.id : 0);
            formData.append("ContestName", this.state.ContestName.trim());
            formData.append("ShowPhoto", this.state.ShowPhoto);
            formData.append("ShowStatement", this.state.ShowStatement);
            formData.append("RandomiseCandidates", this.state.RandomiseOrder);
            if (this.state.Seats !== undefined) {
                formData.append("Seats", parseInt(this.state.Seats));
            } else { formData.append("Seats", 1) }
            formData.append("MaxVotes", maxvotes);
            formData.append("StatusId", 1);
            formData.append("TypeID", this.props.type);
            formData.append("IsActive", true);
            formData.append("DisplayOrder", this.props.actionType === "edit" ? this.props.display : 999);
            formData.append("Options", JSON.stringify(objops));
            formData.append("ActionType", this.props.actionType);

            formData.append("AdditionalText", this.state.AdditionalText);
            formData.append("ShowNumSeats", this.state.ShowNumSeats);
            formData.append("ShowMaxVotes", this.state.ShowMaxVotes);
            formData.append("ShowCountSelected", this.state.ShowCountSelected);
            formData.append("VotingFor", this.state.VotingFor);
            formData.append("PublishTypeId", (this.state.selectedPublishType === undefined || this.props.type !== 2) ? 1 : parseInt(this.state.selectedPublishType.value));

            const headers1 = { "Content-Type": "multipart/form-data" };

            if (this.submitted === false) {
                this.submitted = true;
                axios.post('VMSaveContestXvote', formData, { headers: headers1 }).then(response => {
                    if (response.data.statusDescription === 'Success') {
                        NotificationManager.success(this.props.actionType === "edit" ? "Item updated successfully" : "Item created successfully", "Vote Item", 4000);
                        success = true;
                        this.props.onHide(true);
                    } else if (response.data.statusID === -2) {
                        NotificationManager.error("Sorry, a duplicate item exists", "Vote Item", 4000);
                    } else {
                        NotificationManager.error(this.props.actionType === "edit" ? "Sorry, there was a problem updating the item" : "Sorry, there was a problem creating the item", "Vote Item", 4000);
                    }
                }).catch(
                    (error) => {
                        this.setState({
                            errorMessage: error.response.data
                        })
                        NotificationManager.error(this.props.actionType === "edit" ? "Sorry, there was a problem updating the item" : "Sorry, there was a problem creating the item", "Vote Item", 4000);
                    }
                );
            }
        }
        let parentThis = this;
        if (!success) {
            setTimeout(function () {
                parentThis.submitted = false;
                parentThis.setState({
                    showButton: true
                })
            }, 250);
        }
    }


    handlePublishTypeChange = selectedPublishType => {
        this.setState({ selectedPublishType });
    };

    onChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value,
            errorMessage: '',
            votesErrorMessage: '',
            seatsErrorMessage: ''
        });
    }

    dupVoteOption = (voteOption) => {
        for (var i = 0; i < voteOption.length; i++) {
            for (var j = 0; j < voteOption.length; j++) {
                if (i !== j && voteOption[i].trim().toLowerCase() === voteOption[j].trim().toLowerCase()) {
                    this.setState({
                        errorMessage: 'Candidates are duplicated.'
                    })
                    return true;
                }
            }
        }
        return false;
    }


    render() {
        let votingFor = this.state.VotingFor.length > 3 ? this.state.VotingFor : 'candidate'
        this.validator.purgeFields();
        return (

            <div>
                <Container fluid>
                    <form onSubmit={this.onSubmit}>
                        <div className="modalContestContent">
                            <div className="modalContestBody">
                                <div>
                                    <Form.Group>
                                        <Row>
                                            <Col>
                                                <div className="form-group my-3">
                                                    <FloatingLabel label="Item name">
                                                        <input className="form-control" type="text" name="ContestName" style={{ width: '100%' }} onChange={this.onChange} value={this.state.ContestName} placeholder="Item name" onBlur={() => this.validator.showMessageFor('ContestName')} />
                                                    </FloatingLabel>
                                                    {this.validator.message('ContestName', this.state.ContestName, 'required|max:1000', { messages: {} })}
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md="6">
                                                <div className="xVoteCheck ">
                                                    <div className="form-group mb-3">
                                                        <input type="checkbox" className="form-check-input" name="ShowPhoto" onChange={this.onChange} checked={this.state.ShowPhoto} />
                                                        <Form.Label htmlFor="ShowPhoto" className="form-check-label">Show Photo</Form.Label> <HelpButton helpText="Photos will be displayed at no more than 150px wide" />
                                                    </div>
                                                    <div className="form-group mb-3">
                                                        <input type="checkbox"  className="form-check-input" name="ShowStatement" onChange={this.onChange} checked={this.state.ShowStatement} />
                                                        <Form.Label htmlFor="ShowStatement" className="form-check-label">Show Statement</Form.Label>
                                                    </div>
                                                    <div className="form-group mb-3">
                                                        <input type="checkbox"  className="form-check-input" name="RandomiseOrder" onChange={this.onChange} checked={this.state.RandomiseOrder} />
                                                        <Form.Label htmlFor="RandomiseOrder" className="form-check-label">Randomise Order</Form.Label>
                                                    </div>
                                                </div>
                                            </Col>
                                            <Col md="6">
                                                <div>
                                                    <div className="form-group mb-3" >
                                                        <FloatingLabel label="Number to elect (Defaults to 1)">
                                                            <input className="form-control" type="number" step="1" min="0" name="Seats" style={{ width: '100%' }} onChange={this.onChange} value={this.state.Seats} placeholder="Number to elect (Defaults to 1)" />
                                                        </FloatingLabel>
                                                        {this.state.seatsErrorMessage && <span className="error-message"> {this.state.seatsErrorMessage}</span>}
                                                    </div>
                                                    <div className="form-group mb-3">
                                                        <FloatingLabel label={`Maximum votes (Defaults to number ${parseInt(this.props.type) === 2 ? " to elect" : "of candidates"})`}>
                                                            <input className="form-control" type="number" step="1" min="0" name="MaxVotes" style={{ width: '100%' }} onChange={this.onChange} value={this.state.MaxVotes} placeholder={`Maximum votes (Defaults to number ${parseInt(this.props.type) === 2 ? " to elect" : "of candidates"})`} />
                                                        </FloatingLabel>
                                                        {this.state.votesErrorMessage && <span className="error-message">{this.state.votesErrorMessage}</span>}
                                                    </div>
                                                    {
                                                        this.state.enableweightedcount === "true" && this.props.type === 2 ?
                                                            <Form.Group className="mb-3">
                                                                <Form.Label htmlFor="PublishTypes">Publish Types</Form.Label>
                                                                <Select
                                                                    name="PublishTypes"
                                                                    value={this.state.selectedPublishType}
                                                                    onChange={this.handlePublishTypeChange}
                                                                    options={this.publishTypes}
                                                                />
                                                                {this.validator.message('PublishTypes', this.state.selectedPublishType, 'required')}

                                                            </Form.Group>
                                                            : null
                                                    }
                                                </div>
                                            </Col>
                                        </Row>
                                    </Form.Group>
                                </div>
                                <div className="mb-3">
                                    <Accordion>
                                        <div>
                                            <ContextAwareToggle eventKey="1" isAuthorized={this.props.isAuthorized} />
                                        </div>

                                        <Accordion.Collapse eventKey="1" >

                                            <div className="pt-2">
                                                <div className="form-group mb-3">
                                                    <FloatingLabel label="Additional text" >
                                                        <textarea className="form-control" rows="3" name="AdditionalText" style={{ width: '100%' }} onChange={this.onChange} value={this.state.AdditionalText} placeholder="Additional text" />
                                                    </FloatingLabel>
                                                    {this.state.votesErrorMessage && <span className="error-message">{this.state.votesErrorMessage}</span>}
                                                </div>
                                                <Row>
                                                    <Col md="6" className="xVoteCheck ">
                                                        <div className="form-group mb-3">
                                                            <input type="checkbox" className="form-check-input" name="ShowNumSeats" onChange={this.onChange} checked={this.state.ShowNumSeats} />
                                                            <Form.Label htmlFor="RandomiseOrder" className="form-check-label">Show Number of Seats</Form.Label>
                                                        </div>
                                                        <div className="form-group mb-3">
                                                            <input type="checkbox" className="form-check-input" name="ShowMaxVotes" onChange={this.onChange} checked={this.state.ShowMaxVotes} />
                                                            <Form.Label htmlFor="RandomiseOrder" className="form-check-label">Show Maximum Votes</Form.Label>
                                                        </div>
                                                        <div className="form-group mb-3">
                                                            <input type="checkbox" className="form-check-input" name="ShowCountSelected" onChange={this.onChange} checked={this.state.ShowCountSelected} />
                                                            <Form.Label htmlFor="RandomiseOrder" className="form-check-label">Show Count of Selected</Form.Label>
                                                        </div>
                                                    </Col>
                                                    <Col md="6">
                                                        <div className="form-group mb-3">
                                                            <FloatingLabel label="Voting for (Singular, defaults to 'candidate')" >
                                                                <input className="form-control" name="VotingFor" style={{ width: '100%' }} onChange={this.onChange} value={this.state.VotingFor} placeholder="Voting for (Singular, defaults to 'candidate')" />
                                                            </FloatingLabel>
                                                        </div>
                                                        <div className="error-message">{this.state.VotingFor.slice(-1) === 's' && <span>Voting for text should be singular</span>}</div>
                                                    </Col>
                                                </Row>
                                            </div>

                                        </Accordion.Collapse>
                                    </Accordion>
                                </div>


                                <div>

                                    <Form.Group>
                                        <CandidateSort
                                            Changed={this.handleInputChange}
                                            aType={this.props.actionType}
                                            ShowPhoto={this.state.ShowPhoto}
                                            ShowStatement={this.state.ShowStatement}
                                            CandidateCount={this.context.voteOptions.length}
                                            optionReferences={Object.keys(this.context.voteOptions)}
                                            VotingFor={votingFor}
                                        />
                                    </Form.Group>
                                    <button disabled={!this.props.isAuthorized} className="btn btn-dark" onClick={(e) => this.context.AddCandidate(e, this.props.actionType)}>Add {votingFor}</button>
                                </div>
                            </div>

                            <div className="modalUpdateFooter">
                                <div className="d-flex justify-content-between">

                                    <div className="error-message">{this.state.errorMessage && <span>{this.state.errorMessage}</span>}</div>

                                    <div className="modalUpdateFooterSubmit">
                                        {this.state.showButton
                                            ? <Button
                                                className="btn btn-dark text-nowrap"
                                                disabled={!this.props.isAuthorized}
                                                type="submit"
                                                color="success">{this.props.actionType === "new" ? "Create" : "Update"} and close</Button>
                                            : <Button className="btn btn-dark text-nowrap" disabled><div className="loaderholder"><div className="loader"></div></div></Button>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>

                    </form>
                </Container>
            </div >
        );

    }

}

CandidateVoteSetup.contextType = ContestContext;


function ContextAwareToggle({ eventKey, callback, isAuthorized }) {
    const { activeEventKey } = useContext(AccordionContext);

    const decoratedOnClick = useAccordionButton(
        eventKey,
        () => callback && callback(eventKey),
    );

    const isCurrentEventKey = activeEventKey === eventKey;

    const buttonOpen = () => {
        return (
            <div className="readMore">
                More options&nbsp;
                <FontAwesomeIcon icon={faPlus} />
            </div>
        )
    }
    const buttonClose = () => {
        return (
            <div className="readMore">
                Hide options&nbsp;
                <FontAwesomeIcon icon={faMinus} />
            </div>
        )
    }

    return (
        <button
            type="button"
            onClick={decoratedOnClick}
            className="btn btn-sm btn-primary"
            disabled={!isAuthorized}
            aria-label={isCurrentEventKey ? "Collapse to hide additional options" : "Expand for additonal options"}
        >
            {isCurrentEventKey ? buttonClose() : buttonOpen()}
        </button>
    );
}
