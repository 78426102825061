import React, { Component } from 'react';
import ReactDatatable from './DataTable';
import { Button, ButtonToolbar } from 'react-bootstrap';
import axios from 'axios';
import { NotificationManager } from 'react-notifications';
import { AttendeesModal } from './AttendeesModal';
import AdminContext from './context/AdminContext';
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
var validMembers = ["votingmember", "nonvotingmember", "guest"];  // Make sure all these are lower case

export default class AttendeesList extends Component {

    constructor(props) {
        super(props);

        this.state = {
            attendeesList: []
            , show: false
            , adModalShow: false
            , editModalShow: false
            , attendeeId: 0
            , emailAddress: ''
            , attendeeName: ''
            , error: ''
            , attendeeMemberTypeDetail: []
            , showCodes: false
            , loading: true
        };

        this.config = {
            page_size: 10,
            length_menu: [10, 20, 50],
            no_data_text: 'No users found',
            language: {
                length_menu: "Show _MENU_ result per page",
                filter: "Search users...",
                info: "Showing _START_ to _END_ of _TOTAL_ users",
                pagination: {
                    first: "First",
                    previous: <span>&#9668;</span>,
                    next: <span>&#9658;</span>,
                    last: "Last"
                }
            }
        };

        this.getAttendeesList = this.getAttendeesList.bind(this);
    }

    componentDidMount() {
        this.getAttendeesList('', 0, 250);
        this.getAttendeesCount();
    }


    getColumns = () => {
        var columns = [
            {
                key: "name",
                text: "Name",
                align: "center",
                sortable: true
            },
            (this.state.showCodes ? {
                key: "id",
                text: "Pin Id",
                align: "center",
                sortable: false
            } : {}),
            (this.state.showCodes ? {
                key: "securityCode1",
                text: "SC1",
                align: "center",
                sortable: false
            } : {}),
            (this.state.showCodes ? {
                key: "securityCode2",
                text: "SC2",
                align: "center",
                sortable: false
            } : {}),
            {
                key: "emailAddress",
                text: "Email",
                align: "center",
                sortable: true
            },
            {
                key: "memberNo",
                text: "Member No",
                align: "center",
                sortable: true
            },
            {
                key: "attendeeRole",
                text: "Attendee Role",
                align: "center",
                sortable: true
            },
            (this.context.switches.enableWeightedCount ? {
                key: "voteWeight",
                text: "Vote Weight",
                align: "center",
                sortable: true
            } : {}),
            {
                key: "action",
                text: "Action",
                align: "center",
                sortable: false,
                cell: row => {
                    return (
                        <ButtonToolbar className="justify-content-center">
                            <Button size="sm" variant="dark" disabled={(!this.props.isAuthorized || row.emailAddress === null) ? true : false} onClick={() => this.setState({ editModalShow: true, attendeeName: row.name, emailAddress: row.emailAddress })} className="mr-2"><i className="fa fa-envelope"></i></Button>
                            <AttendeesModal
                                show={this.state.editModalShow}
                                onHide={this.editModalClose}
                                attendeeName={this.state.attendeeName}
                                emailAddress={this.state.emailAddress}
                                isAuthorized={this.props.isAuthorized}
                            />
                        </ButtonToolbar>

                    )
                }
            },
        ];
        var returnColumns = columns.filter(value => Object.keys(value).length !== 0);
        return returnColumns;
    }


    getAttendeesList = (filter_value, page_number, page_size) => {
        /* changed as the table pages */
        //page_number = 0;
        //page_size = 250; // Max return for performance

        const VMAttendeeListRequest = {
            pageLength: parseInt(page_size), pageNumber: parseInt(page_number) > 0 ? parseInt(page_number) - 1 : 0, filter: filter_value, VMId: parseInt(sessionStorage.getItem('VMId'))
        }
        const options = {
            url: 'VMGetAttendeesList',
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            data: VMAttendeeListRequest
        };
        this.setState({ loading: true });
        axios(options)
            .then(response => {
                if (response.data.length > 0) {
                    this.setState({
                        attendeesList: response.data,
                    });
                }
                this.setState({
                    loading: false
                });
            }).catch(function (error) {
                NotificationManager.error("Sorry, there was a problem in getting attendees list.", "Attendees list", 4000);
                this.setState({
                    loading: false
                });
            })
    }

    getAttendeesCount = () => {
        const VMId = parseInt(sessionStorage.getItem('VMId'))
        const options = {
            url: 'VMGetAttendeesCount',
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            data: VMId
        };
        axios(options)
            .then(response => {
                if (response.data.length > 0) {
                    this.setState({ attendeeMemberTypeDetail: response.data })
                }
            }).catch(function (error) {
                NotificationManager.error("Sorry, there was a problem in getting member type detail.", "Attendee MemberType Detail", 4000);
            })
    }


    componentDidUpdate() {
    }

    tableChangeHandler = data => {
        this.getAttendeesList(data.filter_value, 0, 250)
    }

    toggleCodes = () => this.setState({ showCodes: !this.state.showCodes });

    editModalClose = () => this.setState({ editModalShow: false });
    render() {
        let attendeeMemberTypeDetail = this.state.attendeeMemberTypeDetail;
        let attendeeMemberType = attendeeMemberTypeDetail && attendeeMemberTypeDetail.length > 0 ?
            attendeeMemberTypeDetail.sort((a, b) => a.memberType > b.memberType ? 1 : -1) : null;

        return (
            <div>
                <div className="chat-heading-am">Eligible Attendees List</div>

                <div className="d-flex attendee-lists justify-content-between my-3">
                    {attendeeMemberTypeDetail.length === 0 &&
                        <div className="col-lg-4 col-md-6 pl-0 pr-0">
                            <div className="cloud">
                                Loading attendee role counts...
                                <div className="loader"></div>
                            </div>
                        </div>}
                    {attendeeMemberType && attendeeMemberType.length > 0 &&
                        <table className="table table-fit table-bordered tableMemberType">
                            <thead className="thead-light">
                                <tr>
                                    <th scope="col" width="200px">Attendee Role</th>
                                    <th scope="col" width="200px">Total</th>
                                </tr>
                            </thead>
                            <tbody>
                        {attendeeMemberType ?
                                attendeeMemberType.map((attendee, i) =>
                                    <tr key={i} className={validMembers.filter(x => x === attendee.memberType.toLowerCase()).length > 0 ? "" : "errorMessage"}>
                                            <td width="200px">{attendee.memberType}</td>
                                            <td width="200px">{attendee.total}</td>

                                    </tr>)
                                : null
                            }
                            </tbody>
                        </table>
                    }
                    <div>
                        <Button onClick={() => this.toggleCodes()}
                            variant="contained"
                            className="mr-2 btn btn-primary btn-sm"
                            /* this is on read rather than write: disabled={!this.props.isAuthorized}*/
                        >
                            {this.state.showCodes ? "Hide Codes" : "Show Codes"}
                        </Button>
                    </div>
                </div>

                <div className="attendee-lists hMargin" >

                    <ReactDatatable className="table small table-hover" config={this.config} columns={this.getColumns()} loading={this.state.loading} records={this.state.attendeesList}
                        onChange={this.tableChangeHandler} />

                    {
                        this.state.attendeesList && this.state.attendeesList.length > 249 && "Results limited to 250 entries - please refine your search"
                    }

                </div>
            </div>

        )
    }
}
AttendeesList.contextType = AdminContext;