import React, { useContext, useState, useEffect, useRef } from 'react';
import OpenChatContext from './OpenChatContext';
import { OpenChatNewPost, OpenChatNewLike, OpenChatGetMyActions, OpenChatGetEmojis, OpenChatGetPagedPosts, OpenChatGetSinglePost, OpenChatGetFlaggedPosts, OpenChatGetDeletedPosts, OpenChatEditPost } from '../../JsFunctions/APIFunctions';
import { OpenChatGetModeratorFlagOptions, OpenChatResolveFlag, OpenChatAdminDeletePost, OpenChatAdminUnDeletePost } from '../../JsFunctions/ModeratorFlagFunctions';
import AdminContext from '../../../context/AdminContext';
import { connection } from '../../../SignalRController';

export function OpenChatContextProvider(props) {
    const [localState, setLocalState] = useState({
        Posts: [],
        FlaggedPosts: [],
        DeletedPosts: [],
        Quoted: [],
        Likes: [],
        Flags: [],
        EmojiList: [],
        AcceptTerms: 0
    });
    //https://stackoverflow.com/questions/57847594/react-hooks-accessing-up-to-date-state-from-within-a-callback/
    //Callback functions see the state as was when the callback was set up, they will always see a current ref
    //This use of ref fixes the problem with the state being stale at the point of setting up the callback
    const stateRef = useRef();
    stateRef.current = localState;
    const [replyTarget, setReplyTarget] = useState(0);
    const [minCurrentPost, setMinCurrentPost] = useState(0);
    const [minVisiblePost, setMinVisiblePost] = useState(0);
    const [updateMuted, setUpdateMuted] = useState(false);
    const aContext = useContext(AdminContext);

    useEffect(() => {
        if (aContext.isAuthorized('OpenChat', 'Read')) {
            GetOpenChatPosts();
            GetOpenChatMyActions();
            GetModeratorFlagOptions();
        }
    }, [])


    useEffect(() => {
        if (connection) {
            SRconnectionAction();
        }
    }, [connection])

    const SRconnectionAction = () => {
        connection.on("srSignalHub2Adm_OpenChat", (message) => {
            SignalRUpdatePosts(message);
        });
    }


    //Posts from SignalR
    const SignalRUpdatePosts = (newdata) => {
        let postData = newdata.posts;
        let adminData = newdata.adminOnlyDetails;
        postData.forEach((post) => {
            let adminPost = adminData.find(a => a.PostId === post.PostId);
            let mergedPost = { ...post, ...adminPost }
            MergeNewPostData("Posts", [mergedPost]);
            if (mergedPost.Flags) {
                MergeNewPostData("FlaggedPosts", [mergedPost]);
            }
            if (mergedPost.Hidden) {
                MergeNewPostData("DeletedPosts", [mergedPost]);
            }
        })
    }

    //Quoted posts not in existing data
    const GetSinglePost = async (postId) => {
        let newdata = await OpenChatGetSinglePost(postId, aContext.meetingId);
        MergeNewPostData("Quoted", newdata);
    }

    const AddNewPost = async (event, text) => {
        event.preventDefault();
        let postResponse = await OpenChatNewPost(replyTarget, text, aContext.meetingId);
        if (postResponse && postResponse.statusId === 200) {
            //if successful and data not yet update by SR, add to local list of posts
            let posts = [...localState.Posts];
            const index = posts.findIndex(post => post.PostId === postResponse.PostId);
            if (index === -1) {
                let newPost = {
                    PostId: postResponse.returnValue,
                    PostContent: text,
                    IsPostOwner: true,
                    Like: 0,
                    Dislike: 0,
                    DateTimeNow: Date.now(),
                    ParentPostId: replyTarget,
                    CreatedDateTime: Date.now(),
                    UserName: "You",
                    AdminUserId: 1
                }
                posts.push(newPost);
                updateState("Posts", posts);
            }
            setReplyTarget(0);
            return true;
        } else if (postResponse && postResponse.statusId === 403 && postResponse.returnValue === 2) {
            //profanity filter
            return "profanity";
        }
        else {
            //add failure notification
            return false;
        }
    }
    const EditPost = async (event, postId, text) => {
        event.preventDefault();
        let postResponse = await OpenChatEditPost(postId, text, aContext.meetingId);
        if (postResponse && postResponse.statusId === 200) {
            let newPost = {
                PostId: postId,
                PostContent: text
            }

            MergeNewPostData("Posts", [newPost])
            return true;
        } else if (postResponse && postResponse.statusId === 403 && postResponse.returnValue === 2) {
            //profanity filter
            return "profanity";
        }
        else {
            //add failure notification
            return false;
        }
    }

    //Get paged posts
    const GetOpenChatPosts = async (targetId) => {
        let posts = await OpenChatGetPagedPosts(targetId ? targetId : minCurrentPost, aContext.meetingId);
        if (posts && posts !== "Error" && posts.length > 0 && !!posts[0].PostId) {
            MergeNewPostData("Posts", posts);
            const min = posts.reduce((prev, curr) => prev.PostId < curr.PostId ? prev : curr);
            if (min) {
                setMinCurrentPost(min.PostId);
            }
            let visibleCount = posts.filter(a => !!!a.Hidden)
            if (visibleCount.length > 0) {
                const minVisible = visibleCount.reduce((prev, curr) => prev.PostId < curr.PostId ? prev : curr);
                setMinVisiblePost(minVisible.PostId);
            }
        }
    }

    //Get flagged posts
    const GetFlaggedPosts = async () => {
        let posts = await OpenChatGetFlaggedPosts(aContext.meetingId);
        if (posts && posts !== "Error" && posts.length > 0 && !!posts[0].PostId) {
            MergeNewPostData("FlaggedPosts", posts);
        }
    }


    //Get deleted posts
    const GetDeletedPosts = async () => {
        let target = 0;
        let minCurrent = Math.min(...localState.DeletedPosts.map(a => a.PostId))
        if (minCurrent > 0 && isFinite(minCurrent)) {
            target = minCurrent;
        }
        let posts = await OpenChatGetDeletedPosts(target, aContext.meetingId);
        if (posts && posts !== "Error" && posts.length > 0 && !!posts[0].PostId) {
            MergeNewPostData("DeletedPosts", posts);
        }
    }

    const DeletePost = async (targetPostId, attendeeId, action, messageToAttendee) => {
        let response = await OpenChatAdminDeletePost(targetPostId, attendeeId, action, aContext.meetingId, messageToAttendee);
        if (response && response.statusId === 200) {
            //remove post from state
            let posts = localState.Posts;
            let thisPost = posts.filter(a => a.PostId === targetPostId);
            MergeNewPostData("DeletedPosts", thisPost);
            posts.forEach(post => {
                if (post.PostId === targetPostId) {
                    post.Hidden = true;
                }
            })
            updateState("Posts", posts);
            if (localState.FlaggedPosts.filter(a => a.PostId === targetPostId)[0]) {
                updateState("FlaggedPosts", posts);
            }
        } else {
            return false;
        }
        return response.statusDescription;
    }

    const UnDeletePost = async (targetPostId) => {
        let response = await OpenChatAdminUnDeletePost(targetPostId, aContext.meetingId);
        if (response && response.statusId === 200) {
            //remove post from state
            let posts = localState.DeletedPosts;
            let thisPost = posts.filter(a => a.PostId === targetPostId);
            MergeNewPostData("Posts", thisPost);
            posts.forEach(post => {
                if (post.PostId === targetPostId) {
                    post.Hidden = false;
                }
            })
            updateState("DeletedPosts", posts);
            //add success notification
        } else {
            //add failure notification
            return false;
        }
        return response.statusDescription;
    }

    const MergeNewPostData = (list, postData) => {
        //https://stackoverflow.com/questions/57847594/react-hooks-accessing-up-to-date-state-from-within-a-callback/
        //Using the ref ensures the state is never stale when called by the callback
        let statePosts = stateRef.current[list] ? [...stateRef.current[list]] : [];
        postData.forEach((updatePost) => {
            const index = statePosts.findIndex(post => post.PostId === updatePost.PostId);
            if (index >= 0) {
                let combinedPost = { ...statePosts[index], ...updatePost };
                statePosts[index] = combinedPost;
            }
            else {
                statePosts.push(updatePost);
            }
        });
        statePosts.sort((a, b) => a.PostId - b.PostId);
        updateState(list, statePosts);
    }

    const updateState = (key, value) => {
        setLocalState(localState => ({ ...localState, [key]: value }));
    }

    const AddNewLike = async (targetId, actionType) => {
        let postResponse = await OpenChatNewLike(targetId, actionType);
        if (postResponse && postResponse !== "Error" && postResponse.statusId === 200) {
            let likeList = [...localState.Likes];
            if (likeList && likeList.length > 0) {
                if (likeList.find(a => a.PostId === targetId)) {
                    likeList.forEach(obj => {
                        if (obj.PostId === targetId) { obj.ActionType = actionType }
                    });
                    updateState("Likes", likeList);
                } else {
                    var obj = { "PostId": targetId, "ActionType": actionType }
                    likeList.push(obj)
                    updateState("Likes", likeList);
                }
            }
        }
        return postResponse;
    }

    const GetOpenChatMyActions = async () => {
        let actions = await OpenChatGetMyActions();
        if (actions && actions !== "Error") {
            let likes = actions[0].Like;
            if (likes && likes.length > 0) {
                updateState('Likes', likes);
            }
            let flags = actions[0].Flag;
            if (flags && flags.length > 0) {
                updateState('Flags', flags);
            }
        }
    }

    const GetModeratorFlagOptions = async () => {
        let options = await OpenChatGetModeratorFlagOptions();
        if (options !== "Error") {
            updateState("FlagOptions", options);
        }
    }

    const ResolveFlag = async (targetPostId, attendeeId, action, messageToAttendee) => {
        let response = await OpenChatResolveFlag(targetPostId, attendeeId, action, aContext.meetingId, messageToAttendee);
        if (response.statusId === 200) {
            let currentState = localState.FlaggedPosts;
            currentState.forEach(post => {
                if (post.PostId === targetPostId) {
                    post.Flags = false;
                }
            })
            updateState("FlaggedPosts", currentState);
            //if valid remove message from flag list locally
        }
        return response.statusDescription;
    }

    const SetContextReplyTarget = (target) => {
        setReplyTarget(target)
    }

    const SetContextUpdateMuted = (value) => {
        setUpdateMuted(value)
    }

    const GetOpenChatGetEmojis = async () => {
        let EmojiList = await OpenChatGetEmojis();
        if (EmojiList && EmojiList !== "Error" && EmojiList.length > 0) {
            updateState("EmojiList", EmojiList);
        }
    }

    return (
        <OpenChatContext.Provider value={{
            //state
            contextState: localState,
            replyTarget: replyTarget,
            minVisiblePost: minVisiblePost,
            vMeetingId: aContext.meetingId,
            updateMuted: updateMuted,

            //functions
            OpenChatGetAllPosts: GetOpenChatPosts,
            OpenChatGetFlaggedPosts: GetFlaggedPosts,
            OpenChatGetDeletedPosts: GetDeletedPosts,
            OpenChatGetSinglePost: GetSinglePost,
            OpenChatGetMyActions: GetOpenChatMyActions,
            GetModeratorFlagOptions: GetModeratorFlagOptions,
            OpenChatNewPost: AddNewPost,
            OpenChatEditPost: EditPost,
            OpenChatDeletePost: DeletePost,
            OpenChatUnDeletePost: UnDeletePost,
            OpenChatNewLike: AddNewLike,
            SignalRUpdatePosts: SignalRUpdatePosts,
            setReplyTarget: SetContextReplyTarget,
            setUpdateMuted: SetContextUpdateMuted,
            OpenChatGetEmojis: GetOpenChatGetEmojis,
            ResolveFlag: ResolveFlag
        }}
        >
            {props.children}
        </OpenChatContext.Provider>

    )
}

