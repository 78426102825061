import React, { useState } from 'react';
import { CSVLink, CSVDownload } from 'react-csv';
import axios from 'axios';




// Return true if it is an abstain option
function ExportChat() {

    const [chatData, setChatData] = useState([]);


    const headers = [
        { label: "Message Id", key: "messageId" },
        { label: "Attendee Id", key: "attendeeId" },
        { label: "Member No", key: "memberNumber" },
        { label: "Attendee name", key: "attendeeName" },
        { label: "Message text", key: "messageText" },
        { label: "Message notes", key: "messageNotes" },
        { label: "Message date", key: "messageDateTime" },
        { label: "Admin name", key: "adminName" },
        { label: "Direction", key: "messageDirection" },
        { label: "Passed back", key: "passedBack" },
        { label: "Answered", key: "answered" }
    ];

    function getChatData() {
        let vmID = parseInt(sessionStorage.getItem('VMId'));
        axios.post(`api/GetFilteredMessages`, { VMeetingId: vmID, filter: '', MaxReturn: 500000 }).then(res => {
            setChatData(res.data);
        });
    }

    return (
        <span>
            { chatData.length === 0 ? <span className="exportChat" onClick={() => getChatData()}>Prepare chat for export</span> : null}
            {chatData.length > 0 ? <CSVLink data={chatData} target="_blank" headers={headers} className="exportChat" filename={"chatexport.csv"}> Export </CSVLink> : null}
        </span>
    );
}


export default ExportChat