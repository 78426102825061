import React, { Fragment, useState, useContext, useEffect } from 'react';
import OpenChatContext from './Common/OpenChatContext/OpenChatContext';
import { OpenChatAdminUnMuteUser, OpenChatGetMutedUserList } from './JsFunctions/ModeratorFlagFunctions';

export function MutedUserList(props) {
    const [muteList, setMuteList] = useState([]);
    const context = useContext(OpenChatContext);

    const getMuteList = async () => {
        let list = await OpenChatGetMutedUserList(context.vMeetingId);
        setMuteList(list);
    }

    const unMuteUser = async (userId) => {
        let response = await OpenChatAdminUnMuteUser(context.vMeetingId, userId);
        if (response.statusId == 200) {
            getMuteList();
        } else {
            //notification an error occurred
        }
    }

    useEffect(() => {
        getMuteList();
        context.setUpdateMuted(false);
    }, [])

    useEffect(() => {
        if (context.updateMuted) {
            context.setUpdateMuted(false);
            getMuteList();
        }
    },[context.updateMuted])

    return (
        <Fragment>

            {muteList.length > 0 && muteList[0].Id ? muteList.map(user => {
                return (
                    <MutedUser user={user} key={user.Id} unMuteUser={unMuteUser}/>
                )
            }) : 
             "No muted users"}
        </Fragment>
    )
}

function MutedUser(props) {
    return (
        <div className="p-1 m-1 border rounded border-secondary mutedUser">
            <div className="d-flex">
                <div className="flex-grow-1">
                    <div>
                        Attendee: {props.user.AttendeeName}
                    </div>
                    <div>
                        Muted by: {props.user.MutedBy}
                    </div>
                    <div>
                        For: {props.user.MuteReason}
                    </div>
                </div>
                <div>
                    <button className="btn btn-outline-secondary" onClick={() => props.unMuteUser(props.user.AttendeeUserId) }>UnMute</button>
                </div>
            </div>
        </div>
    )
}

