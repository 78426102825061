import React, { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUp, faDatabase, faQuestion, faServer } from '@fortawesome/free-solid-svg-icons';
//import './AzureScale.css'

const AzureScale = () => {

    //Component state//
    const [isLoading, setIsLoading] = useState(true);
    const [azureItems, setAzureItems] = useState([]);

    //Context//
    const getAzureItems = async () => {
        const axoptions = {
            url: `VMGetAzureScale`,
            method: 'GET'
        };
        try {
            let response = await axios(axoptions);
            setAzureItems(response.data);
            setIsLoading(false);
        }
        catch (error) {
            console.log('axios get user data', error);
            return [];
        }

    }
    useEffect(() => {
        getAzureItems();
    }, []);

    function azureItemIcon(azureItem) {
        switch (azureItem.type) {
            case 'SignalR': {
                return <span style={{ color: "blue", fontSize: "3rem" }}><FontAwesomeIcon icon={faArrowUp} /></span>
            }
            case 'servers/databases': {
                return <span style={{ color: "orange", fontSize: "3rem" }}><FontAwesomeIcon icon={faDatabase} /></span>
            }
            case 'serverFarms': {
                return <span style={{ color: "purple", fontSize: "3rem" }}><FontAwesomeIcon icon={faServer} /></span>
            }
            default: {
                return <span style={{ color: "red", fontSize: "3rem" }}><FontAwesomeIcon icon={faQuestion} /></span>
            }
        }
    }

    function azureItemSize(azureItem) {
        switch (azureItem.type) {
            case 'SignalR': {
                return <span>{azureItem.capacity * 10000} Users</span>
            }
            case 'servers/databases': {
                if (azureItem.capacity > 1000) return <span>10,000 users</span>
                else if (azureItem.capacity > 500) return <span>2,000 users</span>
                else if (azureItem.capacity > 100) return <span>500 users</span>
                else return <div>Demo Mode only</div>
            }

            case 'serverFarms': {
                if (azureItem.capacity > 3) return <span>10,000 users</span>
                else if (azureItem.capacity > 1) return <span>2,000 users</span>
                else return <span>Demo mode only</span>
            }
            default: {
                return <span style={{ color: "red", fontSize: "3rem" }}><FontAwesomeIcon icon={faQuestion} /></span>
            }
        }
    }

    const renderAzureItems = () => {
        return (
            <div className="container-fluid">
                <div className="row mb-4">
                    {azureItems.map((azureItem, azureItemIndex) => {
                        return (

                            <div key={azureItemIndex} className="col-lg-6 col-md-12 col-sm-12 col-xs-12 mb-2">
                                <div className="switchBox" style={{ backgroundColor: "#fcfcfc", border: "1px solid #e0e0e0", height: "100%", padding: "10px", borderRadius: "3px" }}>
                                    <div className="row">
                                        <div className="col-md-2" style={{ lineHeight: "100px", textAlign: "center", height: "100px", margin: "0", display: "block" }}>{azureItemIcon(azureItem)}</div>
                                        <div className="col-md-8">
                                            <div className="switchBoxHeading">
                                                <h5 style={{ color: "#9b59b6" }} >{azureItem.name}</h5>
                                            </div>
                                            <div className="row d-flex align-items-center mb-2 rowLine">
                                                <div><span className="h6">Item Type:</span> {azureItem.type}</div>
                                                <div><span className="h6">Capacity:</span> {azureItem.capacity}</div>
                                                <div><span className="h6">Tier of Service:</span> {azureItem.tier}</div>
                                            </div>
                                        </div>
                                        <div className="col-md-2" style={{ textAlign: "center", borderLeft: "2px", verticalAlign: "center", display: "block", height: "100px" }}><strong>
                                            {azureItemSize(azureItem)}
                                        </strong></div>
                                    </div>

                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>
        )
    }

    return (
        <div>
            <h2 className="chat-heading-am">Azure Components Scale</h2> 
            <div className="p-2">
                <div className="container-fluid border shadow-sm p-2">
                    <div className="d-flex flex-row justify-content-between p-2">
                        <div className="row p-2">
                            {renderAzureItems()}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default AzureScale;