import React, { Fragment, useContext, useState } from 'react';
import AdminContext from '../context/AdminContext';
import { ResetAttendeeIp } from './ExternalMethods';
import { NotificationManager } from 'react-notifications';

export function IpResetter(props) {
    const { isAuthorized } = useContext(AdminContext);
    const [attendeeIp, setAttendeeIp] = useState('');
    const [validationMessage, setValidationMessage] = useState();
    const [submitting, setSubmitting] = useState(false);



    const FormSubmit = async (e) => {
        e.preventDefault();
        setSubmitting(true);
        setValidationMessage();
        if (attendeeIp?.length > 2) {
            var response = await ResetAttendeeIp(attendeeIp);
            if (response?.statusID === 1) {
                //success action
                NotificationManager.success("IP Reset successfully", "Reset IP", 2000);
                setAttendeeIp('');
            } else {
                //failure action
                NotificationManager.error("An error occurred, please try again", "Reset IP", 2000);
            }
        } else {
            setValidationMessage("Please enter the affected user's IP");
        }
        setSubmitting(false);
    }

    if (isAuthorized("IpUnlock", "Read")) {
        return (
            <Fragment>
                <div className="chat-heading-am">Reset Attendee IP</div>
                <div>An attendee may become IP locked if they fail their login too many times. If this occurs {isAuthorized("IpUnlock", "Write") ? "you" : "an authorised user"} can use this functionality to reset their IP and allow them to try again.</div>
                {isAuthorized("IpUnlock", "Write") &&
                    <form onSubmit={FormSubmit}>
                        <div className="my-2">
                            <label htmlFor="AttendeeIpField">Attendee IP to reset: </label>
                            <input
                                className="form-control"
                                type="text"
                                id="AttendeeIpField"
                                value={attendeeIp}
                                onChange={(e) => setAttendeeIp(e.target.value)}
                                placeholder="000.000.0.00"
                            />
                            {validationMessage && <div className="alert alert-warning mt-2">
                                {validationMessage}
                            </div>}
                        </div>
                        <div className="mb-2">
                            <button type="submit" className="btn btn-primary" disabled={!attendeeIp || submitting}>Submit</button>
                        </div>
                    </form>
                }
            </Fragment>
        )
    } else return <div> <h2>Not Authorised!</h2><p>You are not authorised to view this page.</p></div>
}