import React, { Component, Fragment } from 'react';
import abstain from './abstain';
import AdminContext from './context/AdminContext';

export default class ResolutionTable extends Component {


    render() {

        var grandTotal = [];
        var grandTotalWithAbstain = [];
        var grandTotalVotes = 0;
        var grandTotalVotesWithAbstain = 0;
        var gtCount = 0;
        var gtCountWithAbstain = 0;
        var weightGrandTotal = [];
        var weightGrandTotalWithAbstain = [];
        var weightGrandTotalVotes = 0;
        var weightGrandTotalVotesWithAbstain = 0;
        var weightGtCount = 0;
        var weightGtCountWithAbstain = 0;
        let inx = this.props.inx;
        var dOutcome = '';
        var sort = 0
        var grandTotalSpoit = 0;
        var grandTotalBlanks = 0;
        var zeroAsBlankMeetingFlag = this.context.switches.zeroAsBlank;

        return (
           
              <Fragment>
                {this.props.res.publishTypeId !== 2 &&
                    
                    <tbody key={"#svr" + this.props.res.ContestID + inx}>
                        <tr key={this.props.res.resolutionText}>
                            <th key={'svr' + this.props.res.resolutionText} style={{ width: '50%' }} >{this.props.res.resolutionText}{this.props.res.publishTypeId === 3 && " - Standard Vote Result"}</th>
                            {zeroAsBlankMeetingFlag ? <th>Blanks</th> : null}
                            <th>Spoilt</th>
                                {
                                this.props.options.map((opt, i) => {
                                        return (<th key={'hh' + i}>{opt.votingOption}</th>)
                                    })
                                }
                                {
                                    // Don't need an Abstain %
                                this.props.options.map((opt, i) => {
                                        return (
                                            abstain(opt.votingOption) ? null : < th key={'hh' + i}> {opt.votingOption} %</th>
                                        )
                                    })
                                }
                                <th>Total Votes</th>
                            </tr>
                            {this.props.options.map((opt, indx) => {
                                grandTotal[indx] = 0;
                                grandTotalWithAbstain[indx] = 0;
                            })}
                            {
                                this.props.tablelines.map((rep, inc) => {
                                    var total = 0;
                                    var totalWithAbstain = 0;
                                    if (rep.sortOrder == 4 && this.props.res.enableShowOfHands == true) { dOutcome = rep.declaredOutcome; }    
                                    sort = rep.sortOrder;
                                    grandTotalSpoit += rep.spoilt;
                                    grandTotalBlanks += rep.zerosAsBlank;
                                    return (
                                        <Fragment key={"fr" + inc}>
                                            <tr key={"#ir" + rep.cesVotersId + inc}>
                                                <td>{rep.cesVotersId}</td>

                                                {rep.hasAggregated == 0 && sort == 4 && this.props.res.enableShowOfHands == true  ?  //only show this is no aggregate data, is meeting attendee in-room & is a show of hands contest
                                                    <td colSpan="100%">{rep.inRoomOutcome}</td>
                                                    :                                                    
                                                    <Fragment>
                                                        {zeroAsBlankMeetingFlag ? <td>{rep.zerosAsBlank}</td> : null}
                                                        <td>{rep.spoilt}</td>
                                                        {this.props.options.map((opt, indx) => {
                                                        var key = 'countOptionValue' + opt.votingValue
                                                        if (!abstain(opt.votingOption))
                                                            total = total + rep[key] ;
                                                        totalWithAbstain = totalWithAbstain + rep[key] ;

                                                        // If Abstain then don't count the vote
                                                        abstain(opt.votingOption) ? gtCount = 0 : gtCount = rep[key];
                                                        gtCountWithAbstain = rep[key];

                                                        grandTotal[indx] ? grandTotal[indx] = grandTotal[indx] + gtCount : grandTotal[indx] = gtCount;
                                                        grandTotalVotes = grandTotalVotes + gtCount;
                                                        grandTotalWithAbstain[indx] ? grandTotalWithAbstain[indx] = grandTotalWithAbstain[indx] + gtCountWithAbstain : grandTotalWithAbstain[indx] = gtCountWithAbstain;
                                                        return (<td key={'i' + indx}>{
                                                            rep[key]
                                                            }</td>)

                                                        })

                                                        }
                                                        {
                                                            this.props.options.map((opt, indx) => {
                                                                var key = 'countOptionValue' + opt.votingValue;
                                                                return (abstain(opt.votingOption) ? null : rep[key] > 0 && total > 0 ? <td key={'t' + indx}>{Math.round((rep[key] / total) * 10000, 2) / 100}%</td> : <td key={'t' + indx}> 0% </td>)
                                                            })
                                                        }
                                                        <td>{zeroAsBlankMeetingFlag ? totalWithAbstain + rep.spoilt + rep.zerosAsBlank : totalWithAbstain + rep.spoilt }</td>

                                                    </Fragment>

                                                    
                                                }
                 
                                            </tr>
                                        </Fragment>
                                    )
                                }
                                )
                            }
                            <tr>
                        
                        {this.props.res.enableShowOfHands == true ?
                            <Fragment><th>Declared result</th>{<th colSpan="100%">{dOutcome}</th>}</Fragment>
                            : 
                            
                            <Fragment><th>Total</th>
                                {zeroAsBlankMeetingFlag ? <th>{grandTotalBlanks}</th> : null}
                                <th>{grandTotalSpoit}</th>
                              {
                                    grandTotalWithAbstain.map((gt, i) => {
                                        grandTotalVotesWithAbstain = grandTotalVotesWithAbstain + gt;
                                        return (<th key={'gt' + i}>
                                            {gt}
                                        </th>)
                                    })

                                }
                                {
                                    this.props.options.map((opt, indx) => {
                                        return (
                                            abstain(opt.votingOption) ? null : grandTotal[indx] > 0 && grandTotalVotes > 0 ? <th key={'na' + indx}> {Math.round((grandTotal[indx] / grandTotalVotes) * 10000, 2) / 100}%</th> : <th key={'na' + indx}>0%</th>
                                        )
                                    })
                                }
                                <th>{zeroAsBlankMeetingFlag ? grandTotalVotesWithAbstain + grandTotalSpoit + grandTotalBlanks : grandTotalVotesWithAbstain + grandTotalSpoit}</th>
                            </Fragment>
                            }


                            </tr>
                        </tbody>
                    //</Table>
                }
                {this.props.res.publishTypeId !== 1 &&
                    
                        <tbody key={"#wvr" + this.props.res.ContestID + inx}>
                        <tr key={this.props.res.resolutionText}>
                                <th key={'wvr' + this.props.res.resolutionText} style={{ width: '50%' }} >{this.props.res.resolutionText}{this.props.res.publishTypeId === 3 && " - Weighted Vote Result"}</th>
                                {zeroAsBlankMeetingFlag ? <th>Blanks</th> : null}
                                <th>Spoilt</th>
                                {
                                this.props.options.map((opt, i) => {
                                        return (<th key={'hh' + i}>{opt.votingOption}</th>)
                                    })
                                }
                                {
                                    // Don't need an Abstain %
                                this.props.options.map((opt, i) => {
                                        return (
                                            abstain(opt.votingOption) ? null : < th key={'hh' + i}> {opt.votingOption} %</th>
                                        )
                                    })
                                }
                                <th>Total Votes</th>
                            </tr>
                            {this.props.options.map((opt, indx) => {
                                weightGrandTotal[indx] = 0;
                                weightGrandTotalWithAbstain[indx] = 0;
                            })}
                            {
                                this.props.tablelines.map((rep, inc) => {
                                    var total = 0;
                                    var totalWithAbstain = 0;
                                    if (rep.sortOrder == 4 && this.props.res.enableShowOfHands == true) { dOutcome = rep.declaredOutcome;}                                                           
                                    sort = rep.sortOrder;
                                    grandTotalSpoit += rep.spoilt;
                                    grandTotalBlanks += rep.zerosAsBlank;
                                    return (
                                        <Fragment key={"fr" + inc}>
                                            <tr key={"#ir" + rep.cesVotersId + inc}>
                                                <td>{rep.cesVotersId}</td>

                                                {rep.hasAggregated == 0 && sort == 4 && this.props.res.enableShowOfHands == true ?  //only show this is no aggregate data, is meeting attendee in-room & is a show of hands contest

                                                    <Fragment>
                                                        <td colSpan="100%">{rep.inRoomOutcome}</td>
                                                    </Fragment>
                                                    :
                                                    <Fragment>
                                                        {zeroAsBlankMeetingFlag ? <td>{rep.zerosAsBlank}</td> : null}
                                                        <td>{rep.spoilt}</td>
                                                        { this.props.options.map((opt, indx) => {
                                                                var key = 'weightOptionValue' + opt.votingValue
                                                                if (!abstain(opt.votingOption))
                                                                    total = total + rep[key];
                                                                totalWithAbstain = totalWithAbstain + rep[key];
                                                                // If Abstain then don't count the vote
                                                                abstain(opt.votingOption) ? weightGtCount = 0 : weightGtCount = rep[key];
                                                                weightGtCountWithAbstain = rep[key];

                                                                weightGrandTotal[indx] ? weightGrandTotal[indx] = weightGrandTotal[indx] + weightGtCount : weightGrandTotal[indx] = weightGtCount;
                                                                weightGrandTotalVotes = weightGrandTotalVotes + weightGtCount;
                                                                weightGrandTotalWithAbstain[indx] ? weightGrandTotalWithAbstain[indx] = weightGrandTotalWithAbstain[indx] + weightGtCountWithAbstain : weightGrandTotalWithAbstain[indx] = weightGtCountWithAbstain;
                                                                return (<td key={'i' + indx}>{
                                                                    rep[key]
                                                                }</td>)
                                                            })
                                                        }
                                                        {
                                                            this.props.options.map((opt, indx) => {
                                                                var key = 'weightOptionValue' + opt.votingValue;
                                                                return (abstain(opt.votingOption) ? null : rep[key] > 0 && total > 0 ? <td key={'t' + indx}>{Math.round((rep[key] / total) * 10000, 2) / 100}%</td> : <td key={'t' + indx}> 0% </td>)
                                                            })
                                                        }
                                                        <td>{zeroAsBlankMeetingFlag ? totalWithAbstain + rep.spoilt + rep.zerosAsBlank : totalWithAbstain + rep.spoilt}</td>
                                                    </Fragment>
                                                    
                                                    }

                                            </tr>
                                        </Fragment>
                                    )
                                }
                                )
                            }
                            <tr>
                                
                            {this.props.res.enableShowOfHands == true ?
                            <Fragment><th>Declared result</th>{
                                <th colSpan="100%">{dOutcome}</th>
                            }</Fragment>

                                : 
                            <Fragment><th>Total</th>
                                {zeroAsBlankMeetingFlag ? <th>{grandTotalBlanks}</th> : null}
                                    <th>{grandTotalSpoit}</th>
                                    {
                                        weightGrandTotalWithAbstain.map((gt, i) => {
                                            weightGrandTotalVotesWithAbstain = weightGrandTotalVotesWithAbstain + gt;
                                            return (<th key={'gt' + i}>
                                                {gt}
                                            </th>)
                                        })
                                    }
                                    {
                                        this.props.options.map((opt, indx) => {
                                            return (
                                                abstain(opt.votingOption) ? null : weightGrandTotal[indx] > 0 && weightGrandTotalVotes > 0 ? <th key={'na' + indx}> {Math.round((weightGrandTotal[indx] / weightGrandTotalVotes) * 10000, 2) / 100}%</th> : <th key={'na' + indx}>0%</th>
                                            )
                                        })
                                    }
                                <th>{zeroAsBlankMeetingFlag ? weightGrandTotalVotesWithAbstain + grandTotalSpoit + grandTotalBlanks : weightGrandTotalVotesWithAbstain + grandTotalSpoit}</th>
                                </Fragment>                      
                                }

                            </tr>
                        </tbody>
                    //</Table>
                }
              
                </Fragment>
        
        )
    }
} ResolutionTable.contextType = AdminContext;