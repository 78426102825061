import { LogLevel } from "@azure/msal-browser";


export async function GetAuthConfig() {
    let returnValue = '{}';
    try {
        await fetch('api/GetAuthConfig', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(response => {
            if (!response.ok) {
                throw new Error("Can't get config details");
            }
            return response.json();
        }).then(data => {
            returnValue = data;            
        })
        return returnValue;
    }
    catch {
        return returnValue;
    }
}

export async function getControllerRequestScopes() {
    let returnValue = { scopes: [], prompt: 'select_account' };
    try {
        await fetch('api/GetControllerRequestScopes', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(response => {
            if (!response.ok) {
                throw new Error("Can't get config details");
            }
            return response.json();
        }).then(data => {            
            let scopes = [];
            scopes.push(data?.scopes);
            returnValue.scopes = scopes;
        })
        return returnValue;

    }
    catch {
        return returnValue;
    }
}

export async function msalConfig() {
    let response = {
        //auth: {
        //    clientId: "03974c1e-a281-4c92-8ff5-fedd2ca06696",
        //    //authority: "https://login.microsoftonline.com/7e5b78aa-d0ae-44a8-8303-26314638501c", // This is a URL (e.g. https://login.microsoftonline.com/{your tenant ID})
        //    authority: b2cPolicies.authorities.signUpSignIn.authority,
        //    knownAuthorities: [b2cPolicies.authorityDomain],
        //    redirectUri: "/",
        //    postLogoutRedirectUri: '/',
        //    navigateToLoginRequestUrl: false, // If "true", will navigate back to the original request location before processing the auth code response.
        //},
        auth: await GetAuthConfig(),
        cache: {
            cacheLocation: "sessionStorage", // This configures where your cache will be stored
            storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
        },
        //system: {
        //    loggerOptions: {
        //        loggerCallback: (level, message, containsPii) => {
        //            if (containsPii) {
        //                return;
        //            }
        //            switch (level) {
        //                case LogLevel.Error:
        //                    console.error(message);
        //                    return;
        //                case LogLevel.Info:
        //                    console.info(message);
        //                    return;
        //                case LogLevel.Verbose:
        //                    console.debug(message);
        //                    return;
        //                case LogLevel.Warning:
        //                    console.warn(message);
        //                    return;
        //                default:
        //                    return;
        //            }
        //        },
        //    },
        //}
    }
    return response;
};

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
//For reference, no longer in use
export const loginRequest = {
    //scopes: ["User.Read"],
    scopes: ["https://CESDevTestAD.onmicrosoft.com/8b36bf20-2a07-4bb5-be29-9f541a36652a/VMAdmin.Controller"],
    prompt: 'select_account'
};

// Add the endpoints here for Microsoft Graph API services you'd like to use.
export const graphConfig = {
    graphMeEndpoint: "https://graph.microsoft.com/v1.0/me"
};


//For reference, no longer in use
export const controllerRequest = {
    scopes: ["https://CESDevTestAD.onmicrosoft.com/8b36bf20-2a07-4bb5-be29-9f541a36652a/VMAdmin.Controller"]
};


//export const b2cPolicies = {
//    names: {
//        signUpSignIn: 'B2C_1A_SIGNUP_SIGNIN',
//        forgotPassword: 'B2C_1_reset_v3',
//        editProfile: 'B2C_1_edit_profile_v2',
//    },
//    authorities: {
//        signUpSignIn: {
//            authority: 'https://CESDevTestAD.b2clogin.com/CESDevTestAD.onmicrosoft.com/B2C_1A_SIGNUP_SIGNIN',
//        },
//        forgotPassword: {
//            authority: 'https://CESDevTestAD.b2clogin.com/CESDevTestAD.onmicrosoft.com/B2C_1_reset_v3',
//        },
//        editProfile: {
//            authority: 'https://CESDevTestAD.b2clogin.com/CESDevTestAD.onmicrosoft.com/b2c_1_edit_profile_v2',
//        },
//    },
//    authorityDomain: 'CESDevTestAD.b2clogin.com',
//};