import axios from 'axios';

export async function auditLogin(action) {
    const axoptions = {
        url: 'api/UserAdminAdminLoginAudit',
        method: 'POST',
        data: {
            Action: action
        }
    };

    await axios(axoptions)
        .then(response => {
            //console.log(response)
        }).catch((error) => {
            console.log('axios login audit error', action);
        });
}


export async function UserAdminGetUserMeetingPermissions(vmeetingId) {
     
    const axoptions = {
        url: 'api/UserAdminGetUserMeetingPermissions',
        method: 'POST',
        data: {
            VMeetingId: vmeetingId
        }
    };

    let returnValue;
    await axios(axoptions)
        .then(response => {
            returnValue = response;
            return (response);
        }).catch((error) => {
            console.log('axios user permissions error');
            returnValue = "error"
            return ('error');
        })
    return returnValue;
}

export async function UserAdminGetUserGlobalPermissions() {
    const axoptions = {
        url: 'api/UserAdminGetUserGlobalPermissions',
        method: 'POST',
    };

    let returnValue;
    await axios(axoptions)
        .then(response => {
            returnValue = response;
            return (response);
        }).catch((error) => {
            console.log('axios global permissions error');
            returnValue = "error"
            return ('error');
        })
    return returnValue;
}

//export async function GetUserPages(vmeetingId) {
//    const axoptions = {
//        url: 'GetUserPages',
//        method: 'POST',
//        data: {
//            VMeetingId: vmeetingId
//        }
//    };

//    try {
//        const response = await axios(axoptions);
//        return JSON.parse(response?.data?.templatePermissionJson);
//    }
//    catch (error) {
//        return [];
//    }

//}