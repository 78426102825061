import React, { useState, useEffect, useContext } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRecycle } from '@fortawesome/free-solid-svg-icons';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, registerables } from 'chart.js';
import 'chartjs-adapter-date-fns';
import AdminContext from '../context/AdminContext';
import axios from 'axios';


export default function GraphAttendance() {
    const [loading, setLoading] = useState(true);

    const [datasets, setDataSets] = useState([]);
    const [nHours, setNHours] = useState(2);
    const context = useContext(AdminContext);
    const backgroundColor = [
        'rgba(255, 99, 132, 0.2)',
        'rgba(54, 162, 235, 0.2)',
        'rgba(255, 206, 86, 0.2)',
        'rgba(75, 192, 192, 0.2)',
        'rgba(153, 102, 255, 0.2)',
    ];
    const options = {
        maintainAspectRatio: true,
        layout: {
            padding: {
                left: 5, right: 5, top: 5, bottom: 5
            }
        },
        interaction: {
            intersect: false,
            axis: 'x'
        },
        legend: {
            display: true
        }, scales: {
            y: {
                ticks: {
                    display: true,
                    steps: 1,
                    precision: 0,
                    suggestedMin: 0,
                    beginAtZero: false
                },
                stacked: true,
                grid: {
                    display: false
                },
                plugins: {
                    datalabels: {
                        anchor: 'end',
                        align: 'top',
                        formatter: Math.round,
                        font: {
                            weight: 'bold'
                        }
                    }
                }
            },
            x: {
                ticks: {
                    display: true,
                    beginAtZero: true,
                    min: 0,
                },
                stacked: true,
                type: 'time'
            }
        }
    };

    useEffect(() => {
        ChartsGetAttendance(nHours);
        ChartJS.register(...registerables);
        return (() => {
        })
    }, []);

    function ChartsGetAttendance(timeinhours) {
        let VMId = context.meetingId;
        if (timeinhours === 0 || timeinhours==null) timeinhours = nHours;
        axios.post('ChartsGetAttendance', { VMeetingId: VMId, NHours: typeof(timeinhours)==='number' ? timeinhours : parseInt(timeinhours) })
            .then(response => {
                let resp = response.data;
                CreateDatasets(JSON.parse(resp.chartsJson));
                setLoading(false);
                setNHours(parseInt(timeinhours));
            }).catch(function (error) { console.log(error); })
    }

    function CreateDatasets(chartdata) {
        if (chartdata == null || chartdata == '{}') return;
        let countData = [];
        let newData = [];
        let leftData = [];
        let labels = [];

        chartdata.forEach((x, i) => {
            countData.push(x.y-x.n);
            newData.push(x.n);
            leftData.push(0-x.l);
            labels.push(x.t);
        });

        let ds = {
            labels: labels,
            datasets: [{
                label: 'Active Attendees',
                fill: true,
                backgroundColor: backgroundColor[0],
                hoverBorderColor: 'rgba(100,100,100,.5)',
                borderWidth: 1,
                scaleOverride: false,
                data: countData
            }, {
                label: 'New Attendees',
                fill: true,
                backgroundColor: backgroundColor[1],
                hoverBorderColor: 'rgba(100,100,100,.5)',
                borderWidth: 1,
                scaleOverride: false,
                data: newData
            },
            {
                label: 'Lost Attendees',
                fill: true,
                backgroundColor: backgroundColor[2],
                hoverBorderColor: 'rgba(100,100,100,.5)',
                borderWidth: 1,
                scaleOverride: false,
                data: leftData
            }
            ]
        }

        setDataSets(ds);
    };

    if (loading || typeof (datasets) === 'undefined' || datasets == null)
        return (<div data-testid="loadingDiv">loading....</div>);
    else {
        if (datasets.datasets[0].data.length< 2) return (<div>Not enough data to display at the moment</div>);
        return (
            <div data-testid="chartsDiv">
                <select value={nHours} onChange={e => ChartsGetAttendance(e.target.value)} style={{"float":"right"}}>
                    <option value="2">2 Hours</option>
                    <option value="4">4 Hours</option>
                    <option value="8">8 Hours</option>
                    <option value="12">12 Hours</option>
                </select>
                <button data-testid='btnChart' className="btn btn-sm me-1" style={{ "float": "right" }} onClick={e => ChartsGetAttendance(e.target.value)}> <FontAwesomeIcon icon={faRecycle} flip="horizontal"/></button>
                <div data-testid="barChartDiv" className="hMargin">
                    <Bar options={options} data={datasets} />
                </div>
            </div>
        )
    }
}

