import React, { Component } from 'react';
import AdminContext from './context/AdminContext';

export class Home extends Component {
    static displayName = Home.name;

    componentDidMount() {
        this.context.populateswitchdata(true);
    }

    render() {
        return (
            <div>
                <h4 className="welcomeHeading">Welcome</h4>
                <p>Please use the navigation menu to the left to access the sections available to you.</p>
            </div>
        );
    }
}
Home.contextType = AdminContext;