import React, { Component, Fragment } from 'react';
import './chat.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faFlag, faReply} from '@fortawesome/free-solid-svg-icons'
import Moment from 'react-moment';
import ChatContext from './../context/ChatContext';
import { VariableSizeList } from 'react-window';

export class ChatCard extends Component {
    constructor(props) {
        super(props);

        this.state = {
            chats: this.props.chatItems,
            inbox: this.props.inbox,
            messagesSorted: [],
            showReturnButton: 0,
            dimensions: null
        }
        this.Initials = this.Initials.bind(this)
        this.Row = this.Row.bind(this)
        this.getItemSize = this.getItemSize.bind(this);
    }

    componentDidMount() {
        this.setState({
            dimensions: {
                width: this.container !== undefined ? this.container.offsetWidth : 0,
                height: this.container !== undefined ? this.container.offsetHeight : 0,
            },
        });

    }

    componentWillUnmount() {
        // fix Warning: Can't perform a React state update on an unmounted component
        this.setState = (state, callback) => {
            return;
        };
    }

    Initials(chat) {
        let trimmedName = chat.attendeeName ? chat.attendeeName.trim() : '';
        let namesSplit = trimmedName.split(' ');
        let initials = '';
        if (namesSplit.length > 1) {
            initials = namesSplit[0][0] + namesSplit[1][0];
        }

        let number = chat.attendeeId % 9;
        if (initials !== '')
            return (
                <span className={"initialsBlock n" + number}>
                    {initials.toUpperCase()}
                </span>
            );
        else
            return (
                <span className={"initialsBlock n" + number}>
                    <FontAwesomeIcon icon={faUser} />
                    {chat.attendeeId === -1 ? <div className="adminNameLabel">{chat.adminName}</div> : ''}
                </span>
            )
    }

    forwardMessage = (e, chat) => {
        this.setState({
            showReturnButton: chat.messageId
        })
        this.props.forward(e, chat)

        let holdthis = this
        setTimeout(function () {
            holdthis.setState({
                showReturnButton: 0
            })
        }, 350);
    }

    getItemSize = (index) => {
        let height = 0;
        let chat = this.props.chatItems[index];

        if (!chat) {
            return (0);
        }

        if (chat.messageNotes.length === 0) {
            height = 75
        }
        else {
            height = 95
        }
        return height;
    }

    onChatFocusKey = (e, chat, inbox) => {
        if ((e.keyCode === 13 || e.keyCode === 32) && this.props.respond && e.target.className === 'grid-container') {
            this.props.respond(chat, inbox, this.props.type);
        }
    }

    removeFromInbox = (e, chat) => {
        e.stopPropagation();
        this.props.removeChatFromInbox(chat, this.props.inbox)
    }

    InboxIcons = (chat) => {
        const { adminInboxItems, moderatorInboxItems, techInboxItems } = this.props;
        let requestExists = !!(adminInboxItems?.find(a => a.attendeeId === chat.attendeeId && a.messageTypeId > 1));
        let cmGroupExists = !!(adminInboxItems?.find(a => a.attendeeId === chat.attendeeId && a.messageGroupId > 0));

        if (this.props.inbox !== 4) {
            return (
                <div className="d-flex align-items-baseline chatInboxIcons mx-1">
                    {techInboxItems?.find((msgs) => msgs.attendeeId === chat.attendeeId) &&
                        <div className="InTechMailbox">S</div>}
                    {moderatorInboxItems?.find((msgs) => msgs.attendeeId === chat.attendeeId) &&
                        <div className="InModeratorMailbox">M</div>}
                    {requestExists && <div className="InRequestMailbox">R</div>}
                    {cmGroupExists && <div className="InPresenterMailbox">T</div>}
                </div>
            )
        }
    }

    MessageFlags = (chat) => {
        let unreadCount = this.props.unreadCount ?? [];
        let numberUnread = unreadCount?.find((count) => count.user === chat.attendeeId)?.unread ?? 0;
        if (this.props.inbox !== 4) {
            if (this.props.search) {
                return (
                    <div>                    
                        {!!chat.passedBack && <span className="ignoredFlag"><FontAwesomeIcon icon={faFlag} title="Passed back by presenter" /></span>}
                        {!!chat.answered && <span className="answeredFlag"><FontAwesomeIcon icon={faFlag} title="Answered in meeting" /></span>}
                    </div>
                )
            } else {
                return (
                    <div>
                        {numberUnread > 0 && <div className="unreadcnt text-center mx-1">{numberUnread} unread</div>}
                        {this.context.chats.messages.find((msgs) => msgs.attendeeId === chat.attendeeId && msgs.passedBack > 0) &&
                            <span className="ignoredFlag"><FontAwesomeIcon icon={faFlag} title="Passed back by presenter" /></span>}
                        {this.context.chats.messages.find((msgs) => msgs.attendeeId === chat.attendeeId && msgs.answered > 0) &&
                            <span className="answeredFlag"><FontAwesomeIcon icon={faFlag} title="Answered in meeting" /></span>}
                    </div>
                )
            }
        }
    }

    InboxRemoveReturn = (chat) => {
        if (this.props.hideClose) {
            return (typeof (this.props.forward) === "function" && this.state.showReturnButton !== chat.messageId ?
                <button
                    disabled={!this.props.isAuthorized}
                    title="Return to inbox"
                    className="recovermsg btn btn-primary"
                    onClick={(e) => { this.forwardMessage(e, chat); }}>
                    <span><FontAwesomeIcon icon={faReply} /> Inbox</span>
                </button> :
                <button title="Return to inbox" className="recovermsg btn btn-primary" disabled>
                    <div className="chatloaderholder"><div className="loader" /></div>
                </button>)
        } else if (this.props.removeChatFromInbox && this.props.isAuthorized) {
            return (
                <button
                    className="btn btn-sm Close"
                    title="Remove from inbox"
                    key={chat.messageId + "_" + this.props.inbox}
                    onClick={(e) => this.removeFromInbox(e, chat)}>
                    &times;
                </button>
            )
        }
    }

    Row = ({ index, style }) => {
        let chats = this.props.chatItems;
        let inbox = this.props.inbox;

        let chat = chats[index];
        return (
            <div style={style} key={'row' + index} className={'row' + index}
                onClick={this.props.respond ? () => this.props.respond(chat, inbox, this.props.type) : null}
            >
                <div tabIndex={this.props.respond ? "0" : "-1"}
                    onKeyDown={(e) => this.onChatFocusKey(e, chat, inbox)}
                    key={chat.messageId + '_' + chat.attendeeId + '_' + inbox}
                    className="grid-container"  >

                    <div className="d-flex" >
                        <div className={"Title-Bar inbox" + inbox} >
                            {chat.messageDirection === 0 ? <span>From: </span> : <span>To: </span>}
                            {chat.attendeeId === -1 ? 'Group Chat' : chat.attendeeName ?? <span>User ID: {chat.attendeeId}</span>}
                        </div>
                        {this.InboxIcons(chat)}
                        {this.MessageFlags(chat)}
                        <div className="flex-fill" />
                        <div className="Time" >
                            <Moment format="D MMM HH:mm:ss" date={chat.messageDateTime} />
                        </div>
                        {this.InboxRemoveReturn(chat)}
                    </div>
                    <div className={this.props.presenter ? "MessageFull" : "Message"}
                        title={chat.messageText}>
                        {chat.messageText}
                    </div>
                    {chat.messageNotes && <div className="ModeratorMessage">
                        {chat.messageNotes}
                    </div>}
                </div>
            </div>
        )
    };


    render() {
        if (!this.state.dimensions)
            return (<div className="fullheight" ref={el => (this.container = el)}>Loading...</div>);

        if (this.props.chatItems && this.props.chatItems.length < 1)
            return (<div className="fullheight" ref={el => (this.container = el)}>Mailbox Empty</div>);

        return (
            <Fragment>
                {
                    <div className="fullheight" ref={el => (this.container = el)}>
                        {this.props.inbox !== 4 ?
                            <Fragment>
                                <VariableSizeList
                                    height={this.state.dimensions.height}
                                    itemCount={this.props.chatItems.length}
                                    itemSize={(index) => this.getItemSize(index)}
                                >
                                    {this.Row}
                                </VariableSizeList></Fragment>
                            :
                            <Fragment>
                                {this.props.chatItems.map((chat, i) => this.Row({ index: i, style: null }))}
                            </Fragment>
                        }
                    </div>
                }
            </Fragment>
        )
    }
}
ChatCard.contextType = ChatContext;

export default ChatCard
