export class DateTimeManager {

    constructor(datetime) {
        this.date = new Date(datetime);
    }

	getTimeDefaultFormat() {
		return ('0' + this.date.getHours()).slice(-2) + ':' +
			('0' + this.date.getMinutes()).slice(-2);
	}

    getDefaultDateFormat() {
        return this.date.getFullYear() + "-" + ('0' + parseInt(this.date.getMonth() + 1)).slice(-2) + "-" + ('0' + this.date.getDate()).slice(-2);
    }

    getDefaultDateTimeFormat() {
        return this.date.getFullYear() + "-" + ('0' + parseInt(this.date.getMonth() + 1)).slice(-2) + "-" + ('0' + this.date.getDate()).slice(-2) + " " +
            ('0' + this.date.getHours()).slice(-2) + ':' +('0' + this.date.getMinutes()).slice(-2);
    }

    static getDateFormats() {

        return [
            "dd.MM.yyyy",
            "d.M.yyyy",

            "dd.M.yyyy",
            "d.MM.yyyy",

            "dd.MM.yy",
            "d.M.yy",

            "dd.M.yy",
            "d.MM.yy"
        ];
    }

}

export default DateTimeManager;