import React, { Component, Fragment } from 'react';
import { Row, Col } from 'react-bootstrap';
import axios from 'axios';
import AdminContext from './context/AdminContext';

export class MeetingAttendance extends Component {
    constructor(props) {
        super(props);

        this.loggedInCurrent = true;
        this.VmeetingId = parseInt(sessionStorage.getItem('VMId'));
        this.state = {
            intervalTimer: null,
            loading: true
        };

        this.getReport = this.getReport.bind(this);
        this.getReport();
    }

    componentDidMount() {
        this.setState({ intervalTimer: setInterval(() =>this.getReport(), 5000) });
    }

    componentWillUnmount() {
        if (this.state.intervalTimer != null)
            clearInterval(this.state.intervalTimer);

        this.setState = (state, callback) => {
            return;
        };
    }

    getReport() {
        axios.get('VMGetMeetingReportStats', { params: { VMId: this.VmeetingId, loggedInCurrent: true } })
            .then(response => {
                this.setState({
                    attendanceStats: response.data,
                    loading: false
                });
            }).catch(function (error) { console.log(error); })
    }

    render() {
        let voting = [];
        let nonVoting = [];
        let guests = [];

        let regOnlyvoting = [];
        let regOnlynonVoting = [];
        let regOnlyguests = [];

        let isHybrid = this.context.switches.hybrid; 
        if (!this.state.loading) {

            // these are not dependent on logins status as we are looking at members who have registered only and not logged in at all.
            // we will add these to both the current and post totals
            regOnlyvoting = this.state.attendanceStats.voting.registeredInRoomOnly ? this.state.attendanceStats.voting.registeredInRoomOnly : null;
            regOnlynonVoting = this.state.attendanceStats.nonVoting.registeredInRoomOnly ? this.state.attendanceStats.nonVoting.registeredInRoomOnly : null;
            regOnlyguests = this.state.attendanceStats.guest.registeredInRoomOnly ? this.state.attendanceStats.guest.registeredInRoomOnly : null;

            if (this.props.postMeeting && this.props.postMeeting===true) {
                voting = this.state.attendanceStats.voting.loggedIn ? this.state.attendanceStats.voting.loggedIn :  null;
                nonVoting = this.state.attendanceStats.nonVoting.loggedIn ? this.state.attendanceStats.nonVoting.loggedIn : null;
                guests = this.state.attendanceStats.guest.loggedIn ? this.state.attendanceStats.guest.loggedIn : null;

                
            } else {
                voting = this.state.attendanceStats.voting.loggedInCurrent ? this.state.attendanceStats.voting.loggedInCurrent : null;
                nonVoting = this.state.attendanceStats.nonVoting.loggedInCurrent ? this.state.attendanceStats.nonVoting.loggedInCurrent : null;
                guests = this.state.attendanceStats.guest.loggedInCurrent ? this.state.attendanceStats.guest.loggedInCurrent : null;
            }

            if( (voting && nonVoting && guests) || (regOnlyvoting && regOnlynonVoting && regOnlyguests))  // this should cater for registration only meeting when there's no login to the attendee, paper pollcard for example
            {
                return (
                    <Fragment>
                        {this.props.showTitle && this.props.showTitle === true ? <div className="chat-heading-open">TOTAL ATTENDANCE {this.props.postMeeting && this.props.postMeeting === true ? null : " FOR QUORUM"}</div> : null}
                        <div className="px-2 my-1">
                            <Row className="text-center">
                                <Col className="attendeeSummaryBoxPres mx-1">
                                    <div className="summaryBoxText">Voting Members</div>
                                    <div>
                                        <div className="statH">
                                            <h3 className="hMargin">{isHybrid == true ? voting.inRoom + voting.remote + regOnlyvoting.inRoom : voting.inRoom + voting.remote}</h3>
                                            </div>
                                        <Row className="subStats">
                                            <Col sm={7} className="leftStat">Remote</Col>
                                            <Col sm={5} className="leftStat">{voting.remote}</Col>
                                        </Row>
                                        <Row className="subStats">
                                            <Col sm={7} className="leftStat">In Room (S)</Col>
                                            <Col sm={5} className="leftStat">{voting.inRoom}</Col>
                                        </Row>
                                        {isHybrid == true ? <Row className="subStats">
                                            <Col sm={7} className="leftStat">In Room</Col>
                                            <Col sm="5" className="leftStat">{regOnlyvoting.inRoom}</Col>
                                        </Row> : null}
                                    </div>
                                </Col>
                                <Col className="attendeeSummaryBoxPres mx-1">
                                    <div className="summaryBoxText">NonV Members</div>
                                    <div style={{ top: 50 }}>
                                        <div className="statH">
                                            <h3 className="hMargin">{isHybrid == true ? nonVoting.inRoom + nonVoting.remote + regOnlynonVoting.inRoom : nonVoting.inRoom + nonVoting.remote}</h3>
                                            </div>
                                        <Row className="subStats">
                                            <Col sm={7} className="leftStat">Remote</Col>
                                            <Col sm={5} className="leftStat">{nonVoting.remote}</Col>
                                        </Row>
                                        <Row className="subStats">
                                            <Col sm={7} className="leftStat">In Room (S)</Col>
                                            <Col sm={5} className="leftStat">{nonVoting.inRoom} </Col>
                                        </Row>
                                        {isHybrid == true ? <Row className="subStats">
                                            <Col sm={7} className="leftStat">In Room</Col>
                                            <Col sm={5} className="leftStat">{regOnlynonVoting.inRoom}</Col>
                                        </Row> : null}
                                    </div>
                                </Col>

                                <Col className="attendeeSummaryBoxPres mx-1">
                                    <div className="summaryBoxText">Total Members</div>
                                    <div>
                                        <div className="statH">
                                        <h3 className="hMargin">{isHybrid == true ? voting.inRoom + voting.remote + nonVoting.inRoom + nonVoting.remote + regOnlyvoting.inRoom + regOnlynonVoting.inRoom : voting.inRoom + voting.remote + nonVoting.inRoom + nonVoting.remote}</h3>
                                        </div>
                                        <Row className="subStats">
                                            <Col sm="7" className="leftStat">Remote</Col>
                                            <Col sm="5" className="leftStat">{voting.remote + nonVoting.remote}</Col>
                                        </Row>
                                        <Row className="subStats">
                                            <Col sm="7" className="leftStat">In Room (S)</Col>
                                            <Col sm="5" className="leftStat">{voting.inRoom + nonVoting.inRoom} </Col>
                                        </Row>
                                        {isHybrid == true ? <Row className="subStats">
                                            <Col sm="7" className="leftStat">In Room</Col>
                                            <Col sm="5" className="leftStat">{regOnlyvoting.inRoom + regOnlynonVoting.inRoom}</Col>
                                        </Row> : null}
                                    </div>
                                </Col>

                                {this.props.showGuests && this.props.showGuests === true ?
                                    <Col className="attendeeSummaryBoxPres mx-1">
                                        <div className="summaryBoxText">Guests</div>
                                        <div>
                                            <div className="statH">
                                                <h3 className="hMargin">{isHybrid == true ? guests.inRoom + guests.remote + regOnlyguests.inRoom : guests.inRoom + guests.remote}</h3>
                                            </div>
                                            <Row className="subStats">
                                                <Col sm="7" className="leftStat">Remote</Col>
                                                <Col sm="5" className="leftStat">{guests.remote}</Col>
                                            </Row>
                                            <Row className="subStats">
                                                <Col sm="7" className="leftStat">In Room (S)</Col>
                                                <Col sm="5" className="leftStat">{guests.inRoom} </Col>
                                            </Row>
                                            {isHybrid == true ? <Row className="subStats">
                                                <Col sm="7" className="leftStat">In Room</Col>
                                                <Col sm="5" className="leftStat">{regOnlyguests.inRoom}</Col>
                                            </Row> : null}
                                        </div>
                                    </Col>
                                    : null}
                                {this.props.showGuests && this.props.showGuests === true ? <div className="whitecolumn"></div> : null}
                            </Row>
                        </div>
                    </Fragment>
                )
            }
            else return (<div>Loading...</div>);
        } else return (<div></div>)
    }
}

MeetingAttendance.contextType = AdminContext;