import React, { useContext, useState } from 'react';
import AdminContext from '../context/AdminContext';
import Switch from "react-switch";
import { Modal } from 'react-bootstrap';
import { VMUpdateCommonLink } from './ApiFunctions';
import { NotificationManager } from 'react-notifications';
import { useEffect } from 'react';

export default function CommonRequestLink(props) {
    const { switches, populateswitchdata } = useContext(AdminContext);
    const [enableCommonLink, setEnableCommonLink] = useState(switches.enableCommonLink);
    const [commonLink, setCommonLink] = useState(switches.commonLink);
    const [showLinkModal, setShowLinkModal] = useState(false);
    const [saveLink, setSaveLink] = useState(false);
    const [linkError, setLinkError] = useState("");

    const updateCommonLink = async () => {
        setLinkError("");
        setSaveLink(true);
        if (enableCommonLink && commonLink) {
            //if true and no link, notification: set link to finalise this action
            try {
                let response = await VMUpdateCommonLink(enableCommonLink, commonLink);
                if (response.statusID < 1) {
                    NotificationManager.error("An error occurred", "Common Link", 2000);
                } else {
                    populateswitchdata(true);
                    setShowLinkModal(false);
                }
            }
            catch (error) {
                console.log(error.response)
                if (error.response.status === 412) {
                    setLinkError("Invalid Endpoint");
                } else if (error.response.status === 422) {
                    setLinkError("Unable to verify URL, allowlisting may be required");
                } else if (error.response.status === 406) {
                    setLinkError("Invalid URL");
                }
            }
        } else if (enableCommonLink) {
            NotificationManager.warning("Set a link to complete the process", "Common Link", 2000);
        } else if (!enableCommonLink) {
            setCommonLink("");
            let response = await VMUpdateCommonLink(enableCommonLink, "");
            if (response.statusID < 1) {
                NotificationManager.error("An error occurred", "Common Link", 2000);
            } else {
                populateswitchdata(true);
            }
        }
        setSaveLink(false);
    }

    const handleLinkSave = async (event) => {
        if (!props.isAuthorized) {
            return;
        }
        event.preventDefault();
        await updateCommonLink();
    }

    const showModal = () => {
        setLinkError("");
        setCommonLink(switches.commonLink);
        setShowLinkModal(true);
    }

    useEffect(() => {
        if (enableCommonLink !== switches.enableCommonLink) {
            updateCommonLink();
        }
    },[enableCommonLink])

    return (
        <div className="row align-items-center">
            <div className="col">
                <span id="commonLink">Use&nbsp;Common&nbsp;Link</span>
            </div>
            <div className="col">
                <Switch
                    onChange={() => setEnableCommonLink(!enableCommonLink)}
                    checked={enableCommonLink}
                    className="react-switch"
                    aria-labelledby="commonLink"
                />
            </div>
            {enableCommonLink &&
                <div className="col">
                    <button disabled={!props.isAuthorized} className="btn btn-primary btn-block" title={commonLink ? commonLink : 'Set link address'} onClick={() => showModal()}>Link</button>
                    <Modal show={showLinkModal} onHide={() => setShowLinkModal(false)}
                        aria-labelledby="contained-modal-title-vcenter"
                        centered
                    >
                        <form onSubmit={(e) => handleLinkSave(e)}>
                            <Modal.Header closeButton>
                                <Modal.Title>Update Link</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <div className="m-1">
                                    <div className="mx-1 mb-1">Include "https://" in the url</div>
                                    <div className="form-group">
                                        <label className="sr-only" htmlFor="linkvalue">Link</label>
                                        <input type="url"
                                            pattern="https://.*"
                                            id="linkvalue"
                                            name="linkvalue"
                                            className="linkvalue form-control"
                                            value={commonLink}
                                            onChange={(e) => setCommonLink(e.target.value)}
                                            placeholder="Enter speak live link here"
                                            disabled={saveLink}
                                            required />
                                        {linkError && <div className="alert alert-danger my-1" role="alert">{linkError}</div>}
                                    </div>
                                </div>
                            </Modal.Body>
                            <Modal.Footer>
                                {!saveLink ?
                                    <button disabled={!props.isAuthorized} className="btn btn-primary" name="savelink" type="submit">Update</button>
                                    : <div className="loaderholder"><div className="loader" /></div>
                                }
                            </Modal.Footer>
                        </form>
                    </Modal>
                </div>
            }
        </div>
    );
}