import React, { Component, Fragment, useContext } from 'react';
import axios from 'axios';
import { Table } from 'react-bootstrap';
import { Row, Col } from 'reactstrap';
import ResolutionTable from './ResolutionTable';
import { exportTableData } from './Table2CSV';
import AdminContext from './context/AdminContext';

function ResolutionItem(props) {
    const { isAuthorized } = useContext(AdminContext);
    return (
        <Fragment>
            <Row className="row justify-content-start">
                <Col sm="11">{props.tabletitle}</Col>
                {isAuthorized('DataExport', 'Read') &&
                    <Col className="text-right">
                        <button
                            id="export"
                            onClick={() => exportTableData('#' + props.tableId, props.csvfilename)}
                            className="btn btn-primary">
                            <i className="fa fa-file-text-o" aria-hidden="true" title="Export to CSV"></i>
                        </button>
                    </Col>
                }
            </Row>
            <Row className="ls">
                <Col >
                    <Table id={props.tableId} className="postReportsTable table-sm" striped bordered hover size="sm">
                        {
                            props.resolutions.map((res, inx) => {
                                var Options = props.options.filter((opt) => opt.contestID === res.contestID);
                                var tableLines = props.rreport.filter((rfil) => rfil.contestId === res.contestID);
                                return <ResolutionTable key={"ri_" + inx} res={res} options={Options} tablelines={tableLines} inx={inx} />
                            })
                        }
                    </Table>
                </Col>

            </Row>
        </Fragment>
    )
}

export class RReport extends Component {
    constructor(props) {
        super(props);

    }

    render() {
        const params = {
            rreport: this.props.rrpt
            , resolutions: this.props.res
            , resolutionOptions: this.props.resoptions
            , polls: this.props.polls
            , showofhands: this.props.sof
        }


        return (<div>
            <div>
                <h2 className="chat-heading-am">RESOLUTION RESULTS BREAKDOWN</h2>
                {params.resolutions && params.resolutions.length ?
                    <div>
                        {params.polls && params.polls.length ?
                            <Fragment>
                                <ResolutionItem
                                    authorized={this.props.authorized}
                                    resolutions={params.polls}
                                    options={params.resolutionOptions}
                                    rreport={params.rreport}
                                    tableId={'polls'}
                                    tabletitle={'Standard count'}
                                    csvfilename={'resolution.csv'} />
                            </Fragment>
                            : null
                        }
                        {params.showofhands && params.showofhands.length ?
                            <Fragment>
                                <ResolutionItem
                                    authorized={this.props.authorized}
                                    resolutions={params.showofhands}
                                    options={params.resolutionOptions}
                                    rreport={params.rreport}
                                    tableId={'showhands'}
                                    tabletitle={'Show of hands'}
                                    csvfilename={'showofhands.csv'} />
                            </Fragment>
                            : null}
                    </div>
                    :
                    <Row><Col>No results currently available, only contests that are closed or published will be displayed here<br /><br /></Col></Row>
                }
            </div>

        </div>
        )
    }
}

export default RReport;