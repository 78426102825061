import { faThumbsDown, faThumbsUp, faThumbsDown as regFaThumbsDown, faThumbsUp as regFaThumbsUp } from '@fortawesome/free-solid-svg-icons';
// import { faThumbsDown as regFaThumbsDown, faThumbsUp as regFaThumbsUp } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Fragment, useContext, useEffect, useState } from 'react';
import OpenChatContext from './Common/OpenChatContext/OpenChatContext';


//thumbs up thumbs down and counters
export function LikeDislike(props) {
    const context = useContext(OpenChatContext);
    let didILikeIt = 0;
    const [likes, setLikes] = useState(props.likes);
    const [dislikes, setDislikes] = useState(props.dislikes);

    useEffect(() => {
        setLikes(props.likes);
    }, [props.likes])

    useEffect(() => {
        setDislikes(props.dislikes);
    }, [props.dislikes])

    async function LikeIt(actionType) {
        let takeAction;
        if (didILikeIt === actionType) {
            takeAction = 0;
        } else {
            takeAction = actionType;
        }

        let response = await context.OpenChatNewLike(props.postId, takeAction);
        if (response.statusId === 200) {
            if (didILikeIt === actionType) {
                switch (actionType) {
                    case 1:
                        setLikes(likes - 1);
                        break;
                    case 2:
                        setDislikes(dislikes - 1);
                        break;
                    default:
                        return;
                }
            } else {
                switch (actionType) {
                    case 1:
                        setLikes(likes + 1);
                        if (didILikeIt === 2) {
                            setDislikes(dislikes - 1);
                        }
                        break;
                    case 2:
                        setDislikes(dislikes + 1);
                        if (didILikeIt === 1) {
                            setLikes(likes - 1);
                        }
                        break;
                    default:
                        return;
                }
            }
        } else {
            //generate error notification
        }
        return;
    }


    return (
        <Fragment>
            <div className="d-flex openChatLikeDislike">
                <button disabled className="btn btn-sm text-success p-0 " onClick={() => LikeIt(1)}>{didILikeIt === 1 ? <FontAwesomeIcon icon={faThumbsUp} /> : <FontAwesomeIcon icon={regFaThumbsUp} />}</button>
                <div className="d-flex align-items-center">
                    <div className="my-1 me-1 likeCountDisplay">
                        {likes}
                    </div>
                </div>
                <button disabled className="btn btn-sm text-danger p-0 " onClick={() => LikeIt(2)}>{didILikeIt === 2 ? <FontAwesomeIcon icon={faThumbsDown} flip="horizontal" /> : <FontAwesomeIcon icon={regFaThumbsDown} flip="horizontal" />}</button>
                <div className="d-flex align-items-center">
                    <div className="my-1 likeCountDisplay">
                        {dislikes}
                    </div>
                </div>
            </div>
        </Fragment>
    )
}