import React, { Fragment, useState, useEffect, useContext } from 'react';
import AdminContext from '../context/AdminContext';
import axios from 'axios';

export function MeetingDetails(props) {
    const context = useContext(AdminContext);
    const [meetingDetails, setMeetingDetails] = useState();

    useEffect(() => {
        if (context.meetingPermissions && (!meetingDetails || meetingDetails.vMeetingId !== parseInt(sessionStorage.getItem('VMId')))) {
            getData();
        }
    }, [context.meetingPermissions])


    async function getData() {
        const VMId = parseInt(sessionStorage.getItem('VMId'));
        if (VMId > 0) {
            try {
                await axios.get("api/GetTitleAndClient").then(response => {
                    if (response.status === 200) {
                        setMeetingDetails(response.data);
                    }
                });
            } catch {
                console.log('Error getting meeting title');
            }
        }
    }


    return (
        <Fragment>
            {meetingDetails && meetingDetails.meetingTitle &&
                <span className="form-control form-control-sm" >{meetingDetails.clientName}: {meetingDetails.meetingTitle} ({meetingDetails.vMeetingId})</span>
            }
        </Fragment>

    )
}
