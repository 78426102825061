import React, { Fragment, useContext, useState } from 'react';
import AdminContext from '../../context/AdminContext';
import { AddToComfortSystem } from './ApiFunctions';

const textValues=['Make1Index', "Triage", "Presenter"]

export function ForwardToComfortSystem(props) {
    let [status, setStatus] = useState(false);
    let { meetingId, userId } = useContext(AdminContext);

    const AddIt = async () => {
        let response = await AddToComfortSystem(meetingId, userId, props.messageId, props.note, props.tags, props.targetCmInbox);
        if (response.statusID > 0) {
            setStatus(true);
            if (props.close) {
                props.close();
            }
        }
    }

    return (
        <Fragment>
            {!status ?
                <button className="btn btn-sm btn-virtual-meeting" onClick={() => AddIt()}>{textValues[props.targetCmInbox]}</button>
                :
                <span>Sent</span>
            }
        </Fragment>
    );
}