import React, { useState } from 'react';
import UserPermissionContext from './UserPermissionContext';
import axios from 'axios';

const UserPermissionContextProvider = (props) => {

    const [templates, setTemplates] = useState([]);
    const [templatePermissions, setTemplatePermissions] = useState([]);
    const [userDefaultPermissions, setDefaultPermissions] = useState([]);
    const [clients, setClient] = useState([]);
    const [meetings, setMeetings] = useState([]);
    const [permissions, setPermissions] = useState([]);
    const [userAdminPermissions, setUserAdminPermissions] = useState({});
    const [userRevokePermissions, setUserRevokePermissions] = useState({});
    const [userAccessPermission, setUserAccessPermission] = useState({});
    const [selectedUserMeeting, setUserMeeting] = useState();
    const [defaultClient, setSelectedClient] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const [isSingleClientUser, setIsSingleCientUser] = useState(false);
    const [isSingleMeetingUser, setIsSingleMeetingUser] = useState(false);
    const [userClients, setUserClients] = useState([]);
    const [userMeetings, setUserMeetings] = useState([]);
    const [accessClients, setAccessClients] = useState([]);
    const [selectedUser, setUser] = useState([]);
    const [userList, setUserList] = useState([]);

    const getUserClients = async () => {
        const axoptions = {
            url: `api/GetUserClients`,
            method: 'GET'
        };
        try {
            let response = await axios(axoptions);
            let sortedList = JSON.parse(response.data.userMeetingJson).sort((a, b) => { return a.ClientName > b.ClientName ? 1 : -1 })
            //console.log(sortedList)
            setClient(sortedList);
            return JSON.parse(response.data.userMeetingJson);
        }
        catch (error) {
            console.log('axios get user data');
            return [];
        }

    }

    /**
     * 
     * @param {any} clientId
     */
    const getUserMeetings = async (clientId) => {
        const axoptions = {
            url: `api/GetUserMeetings/${clientId}`,
            method: 'GET'
        };
        try {
            let { data } = await axios(axoptions);
            setMeetings(data);
            return data
        }
        catch (error) {
            console.log('axios get user data');
        }

    }


    /**
    * 
    * @param {any} meetingId
    */
    const GetUsersByMeeting = async (meetingId, clientId = null) => {
        const axoptions = {
            url: `api/GetUsersByMeeting`,
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            data: {
                meetingId,
                clientId
            }
        };
        try {
            let { data } = await axios(axoptions);
            setUserList(JSON.parse(data.userJson))
            return JSON.parse(data.userJson);
        }
        catch (error) {
            console.log('axios get user data');
        }

    }


    /**
     * Get user list for adding/updating custom permissions
     * */
    const getUsers = async () => {
        const axoptions = {
            url: 'api/GetUsers',
            method: 'GET'
        };
        try {
            let response = await axios(axoptions);
            return JSON.parse(response.data.userJson)
        }
        catch (error) {
            console.log('axios get user data');
            return [];
        }
    }


    /**
     * Get standard template list
     * */
    const getTemplates = async () => {
        const axoptions = {
            url: 'api/GetTemplates',
            method: 'GET'
        };
        try {
            let response = await axios(axoptions);
            setTemplates(JSON.parse(response.data.templateListJson))
        }
        catch (error) {
            console.log('axios get user data');
        }
    };

    /**
     * Get seleced user permissions
     * @param {any} userId
     */
    const getUserPermissions = async (userId, vMeetingId, selectedClient) => {
        const axoptions = {
            url: `api/GetUserPermissions`,
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            data: {
                MeetingId: vMeetingId,
                ClientId: selectedClient,
                Id: userId
            }
        };
        try {
            let response = await axios(axoptions);
            setDefaultPermissions(JSON.parse(response.data.templatePermissionJson));
            return response;
        }
        catch (error) {
            console.log('axios get user data');
        }
    };

    /**
    * Get seleced user permissions
    * @param {any} vMeetingId
    */
    const getSelfPermissions = async (MeetingId = null, ClientId = null) => {
        const axoptions = {
            url: `api/GetAccessibleFeatures`,
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            data: {
                MeetingId,
                ClientId
            }
        };
        try {
            let response = await axios(axoptions);
            setPermissions(JSON.parse(response.data.templatePermissionJson));
            setUserPermissions(JSON.parse(response.data.templatePermissionJson))
            return JSON.parse(response.data.templatePermissionJson);

        }
        catch (error) {
            console.log('axios get user data');
        }
    };



    /**
    * Get seleced user permissions
    * @param {any} templateId
    */
    const getTemplatePermissions = async (templateId) => {
        const axoptions = {
            url: `api/GetTemplatePermissions/${templateId}`,
            method: 'GET'
        };
        try {
            let response = await axios(axoptions);
            setTemplatePermissions(JSON.parse(response.data.templatePermissionJson));
            return JSON.parse(response.data.templatePermissionJson);
        }
        catch (error) {
            console.log('axios get user data');
        }
    };

    /**
     * Function will call api endpoint to post the data.
     * It will save user permissions for selected meeting
     * @param {any} data
     */
    const SaveMeetingPermissions = async (userPermisiionList) => {
        const axoptions = {
            url: `api/SaveUserMeetingPermissions`,
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            data: userPermisiionList
        };
        try {
            let response = await axios(axoptions);
            return true;
        }
        catch (error) {
            console.log('axios get user data');
            return false;
        }
    }


    /**
     * Function will call api endpoint to post the data.
     * It will delete user's permissions for selected meeting
     * @param {any} meeting
     */
    const DeleteMeetingPermissions = async (userParams) => {
        const axoptions = {
            url: `api/DeleteUserMeetingPermissions`,
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            data: userParams
        };
        try {
            let response = await axios(axoptions);
            return true;
        }
        catch (error) {
            console.log('axios delete users meeting permission ');
            return false;
        }
    }

    /**
     * Function will call api endpoint to post the data.
     * It will Add user permissions for selected meeting
     * @param {any} meeting
     */
    const AddUserMeetingPermissions = async (meeting) => {
        const axoptions = {
            url: `api/AddMeetingUser`,
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            data: meeting
        };
        try {
            let response = await axios(axoptions);
            return true;
        }
        catch (error) {
            console.log('axios delete users meeting permission ');
            return false;
        }
    }

    /**
     * Function to add user access permission for selected meeting
     * @param {any} userParams
     */
    const AddUserAccessPermission = async (userParams) => {
        const axoptions = {
            url: `api/AddUserAccessPermissions`,
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            data: userParams
        };
        try {
            let response = await axios(axoptions);
            return response.data;
        }
        catch (error) {
            console.log('AddUserAccessPermissions');
            return null;
        }
    }



    /**
     * API call to get user's access permissions at different levels
     * e.g. Client Level
     * Meeting Level 
     * Global level
     * @param {any} userParams
     */
    const GetUserAccessPermission = async (userParams) => {
        const axoptions = {
            url: `api/GetUserAccessPermission`,
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            data: userParams
        };
        try {
            let response = await axios(axoptions);
            let userAceess = JSON.parse(response.data.templatePermissionJson);
            if (userAceess?.length > 0) {
                setUserAccessPermission(userAceess[0]);
                return userAceess[0]?.WriteAccess;
            }

        }
        catch (error) {
            console.log('axios get user data');
        }
    };

    const getUserAccessDetails = async(userId) => {
        const axoptions = {
            url: `api/GetUserAccessDetails/${userId}`,
            method: 'GET'
        };
        try {
            let { data } = await axios(axoptions);
            setUserClients(JSON.parse(data.userMeetingJson));
            return JSON.parse(data.userMeetingJson);
        }
        catch (error) {
            console.log('axios get user data');
        }
    }

    /**
     * API call to get user's access permissions at different levels
     * e.g. Client Level
     * Meeting Level 
     * Global level
     * @param {any} userParams
     */
    const GetUserMeetingsAccessDetails = async (selectedUser, selectedclient) => {
        const axoptions = {
            url: `api/GetUserMeetingsAccessDetails`,
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            data: {
                Id: selectedUser,
                ClientId: selectedclient
            }
        };
        try {
            let { data } = await axios(axoptions);
            setUserMeetings(JSON.parse(data.userMeetingJson));

        }
        catch (error) {
            console.log('axios get user data');
        }
    };


    /**
     * API call to get user's access permissions at different levels
     * e.g. Client Level
     * Meeting Level 
     * Global level
     * @param {any} userParams
     */
    const getAddAccessClientList = async () => {
        const axoptions = {
            url: `api/GetAddAccessClientMeetingList`,
            method: 'GET',
        };
        try {
            let { data } = await axios(axoptions);
            let sortedList = JSON.parse(data.userMeetingJson).sort((a, b) => { return a.Name > b.Name ? 1 : -1 } )
            //console.log(sortedList)
            setAccessClients(sortedList);

        }
        catch (error) {
            console.log('axios get user data');
        }
    };


    /**
     * Function to add user access permission for selected client/meeting
     * @param {any} userParams
     */
    const AddClientMeetingAccessPermissions = async (userParams) => {
        const axoptions = {
            url: `api/AddClientMeetingAccessPermissions`,
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            data: userParams
        };
        try {
            let response = await axios(axoptions);
            return response.data;
        }
        catch (error) {
            console.log('AddUserAccessPermissions');
            return null;
        }
    }

    const InviteUser = async (request) => {
        try {
            return await axios.post("api/InviteNewUser", request);
        } catch (e) {
            return e?.response;
        }
    }

    /**
     * Set user permission list
     * */
    const setUserPermissions = (permissionList) => {
        const userAdminPermission =
            permissionList.reduce((prev, nex) => prev.concat(nex.FeatureList), []).find(x => x.ShortName == 'UserAdmin');

        const revokePermission =
            permissionList.reduce((prev, nex) => prev.concat(nex.FeatureList), []).find(x => x.ShortName == 'Revoke');
        setUserAdminPermissions(userAdminPermission);
        setUserRevokePermissions(revokePermission)
    }

    /**
     * Permission request based on user selection
     * Function will get all the required permission of logged in
     * user as well as user they are editing
     * */
    const GetReqestedPermissions = async (userParams) => {
        setIsLoading(true)
        let accessPermission = await GetUserAccessPermission(userParams);
        if (accessPermission) {
            await getSelfPermissions(userParams.MeetingId, userParams.ClientId);
            await getUserPermissions(userParams.Id, userParams.MeetingId, userParams.ClientId);
        }
        setIsLoading(false);
    }

    /**
     * Function will check if user has only one meeting
     * */
    const getUserAccessibleMeetings = async () => {
        try {
            const { data } = await axios.get("api/GetClientMeetingStatus");
            const userMeetings = JSON.parse(data?.userMeetingJson);
            if (userMeetings != undefined && userMeetings.length > 0) {
                setIsSingleCientUser(userMeetings[0]?.IsSingleClient);
                setIsSingleMeetingUser(userMeetings[0]?.IsSingleMeeting);
            }
        } catch (error) {
        }
    }

    return (
        <UserPermissionContext.Provider value={{
            templates,
            getTemplates,
            getUserPermissions,
            templatePermissions,
            getTemplatePermissions,
            SaveMeetingPermissions,
            getUsers,
            clients,
            getUserClients,
            getUserMeetings,
            GetUsersByMeeting,
            getSelfPermissions,
            permissions,
            userAdminPermissions,
            userDefaultPermissions,
            userRevokePermissions,
            DeleteMeetingPermissions,
            AddUserMeetingPermissions,
            userAccessPermission,
            AddUserAccessPermission,
            selectedUserMeeting,
            defaultClient,
            setSelectedClient,
            setUserMeeting,
            isLoading,
            setMeetings,
            meetings,
            setIsLoading,
            GetReqestedPermissions,
            isSingleMeetingUser,
            getUserAccessibleMeetings,
            isSingleClientUser,
            getUserAccessDetails,
            userClients,
            userMeetings,
            GetUserMeetingsAccessDetails,
            setUserClients,
            setUserMeetings,
            getAddAccessClientList,
            accessClients,
            AddClientMeetingAccessPermissions,
            InviteUser,
            setUser,
            selectedUser,
            userList
        }}>
            {props.children}
        </UserPermissionContext.Provider>
    )
}

export default UserPermissionContextProvider
