export default function Validate(question, handleChange, disabled) {

    let errorMessage = ""

    if (question.QuestionTitle === "No title") {
        errorMessage = "No title passed."
    }

    if (typeof (handleChange) != "function") {
        errorMessage = "Handle change parameter does not point to a function."
    }

    if (question.id < 1) {
        errorMessage = "Id must be a positive number."
    }

    if (typeof (disabled) != "boolean") {
        errorMessage = "Disabled must be a boolean."
    }

    if (typeof (question.Mandatory) != "boolean") {
        errorMessage = "Mandatory must be a boolean."
    }

    return errorMessage
}