import React from 'react';
export function Tag(props) {
    const { tag, edit, RemoveTag } = props;
    const stylingConditional = () => {
        if (tag.messageTagId < 1) {
            return "bg-warning text-dark";
        } else {
            return "bg-brand-primary text-light"
        }
    }
    const stylingBase = "me-1 px-1 tag-lozenge border rounded d-flex align-items-center smaller " + stylingConditional();
    const titleText = () => {
        if (tag.messageTagId < 1) {
            return "New tag will be created";
        }
    }
    if (edit) {
        return (
            <div className={stylingBase}
                title={titleText()}
            >
                {tag.tagText}
                <button
                    type="button"
                    onClick={() => RemoveTag(tag.messageTagId)}
                    title="Remove tag"
                    className="btn btn-sm Close py-0 px-1">
                    &times;
                </button>
            </div>
        )
    }
    else {
        return (
            <div
                className={stylingBase}
            >
                {tag.tagText}
            </div>
        )
    }
}