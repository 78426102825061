import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'font-awesome/css/font-awesome.min.css';
import 'react-notifications/lib/notifications.css';
import React, { useEffect } from 'react';
import App from './App';
import { AdminContextProvider } from './components/context/AdminContextProvider';
import "core-js";

import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig, GetAuthConfig } from "./authConfig";

import { useState } from 'react';
import { Loader } from './components/SecondaryComponents';
import './custom.css';


export function AuthLayer(props) {
    const [configValues, setConfigValues] = useState({});
    const [msalInstance, setMsalInstance] = useState();

    useEffect(() => {
        (async () => {
            let getConfigValues = await msalConfig();
            setConfigValues(getConfigValues);
        })();
    }, [])

    useEffect(() => {
        if (configValues && configValues.auth) {
            setMsalInstance(new PublicClientApplication(configValues))
        }
    }, [configValues])

    if (msalInstance) {
        return (
            <MsalProvider instance={msalInstance}>
                <AdminContextProvider>
                    <App />
                </AdminContextProvider>
            </MsalProvider>
        );
    }
    else return <Loader />


}


