import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import { ContestContext } from './Context/ContestContext';
import ResolutionSetup from './ResolutionSetup';
import CandidateVoteSetup from './CandidateVoteSetup';
import ResolutionView from './ResolutionView';
import CandidateVoteView from './CandidateVoteView';


function showTitle(props) {
    let contestType = props.type; // gives you the vote method
    let actionType = props.atype; // gives you the new or edit
    let sHeader = actionType ? actionType[0].toUpperCase() + actionType.slice(1) : ''

    if (parseInt(contestType) === 1) {
        sHeader = sHeader + " Question"
    } else if (parseInt(contestType) === 2) {
        sHeader = sHeader + " xVote"
    } else if (parseInt(contestType) === 3) {
        sHeader = sHeader + " Preferential Vote"
    }
    return sHeader;
}

function showcontestOps(props) {

    let actionType = props.atype;
    let meetingId = props.meeting;
    let contestId = props.id;
    let contestName = props.cname
    let contestType = props.type;
    let publishtypeid = props.publishtypeid;
    let enableWeightedCount = props.enableweightedcount ? props.enableweightedcount.toString() : false;
    let onhide = props.onHide;
    let editedPublishTypeId = props.contestdata ? props.contestdata.publishTypeId : publishtypeid;

    if (actionType === "view") {
        if (parseInt(contestType) === 1) {
            return <ResolutionView
                meeting={meetingId}
                id={contestId}
                name={contestName}
                atext={props.atext}
                status={props.status}
                active={props.active}
                display={props.display}
                type={props.type}
                options={props.contestoptions}
                publishtypeid={editedPublishTypeId}
                enableweightedcount={enableWeightedCount.toString()}
                onHide={onhide}
                actionType={actionType}
            />;
        }

        else {
            return <CandidateVoteView
                meeting={meetingId}
                type={contestType}
                onHide={onhide}
                actionType={actionType}
                publishtypeid={editedPublishTypeId}
                enableweightedcount={enableWeightedCount.toString()}

                id={contestId}
                name={contestName}
                contestdata={props.contestdata}
                display={props.display}
            />
        }
    }

    else if (parseInt(contestType) === 1) { //question
        return <ResolutionSetup
            meeting={meetingId}
            type={contestType}
            options={props.contestoptions}
            enableweightedcount={enableWeightedCount.toString()}
            publishtypeid={actionType === "new" ? publishtypeid : editedPublishTypeId}
            onHide={onhide}
            actionType={actionType}
            {...(actionType === "edit" && {
                id: contestId,
                name: contestName,
                atext: props.atext,
                status: props.status,
                active: props.active,
                display: props.display
            })}
            isAuthorized={props.isAuthorized}
        />;
    } else if (parseInt(contestType) === 2 || parseInt(contestType) === 3) { //vxote and preference vote
        return <CandidateVoteSetup
            meeting={meetingId}
            type={contestType}
            onHide={onhide}
            actionType={actionType}
            publishtypeid={editedPublishTypeId}
            enableweightedcount={enableWeightedCount.toString()}

            {...(actionType === "edit" && {
                id: contestId,
                name: contestName,
                display: props.display,
                contestdata: props.contestdata
            })}
            isAuthorized={props.isAuthorized}
        />;
    } else {
        return null;
    }

}

export class ContestModal extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isOpen: false,
            isLoaded: false
        }
    }

    componentDidMount() {
        this.setState({
            isLoaded: false
        });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.show !== this.props.show) {
            this.setState({
                isLoaded: true
            })            
        }
    }

    render() {
        if (this.state.isLoaded) {
            return (
                <Modal scrollable={true}
                    {...this.props}
                    dialogClassName="modal-80w"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered>
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">
                            {showTitle(this.props)}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {showcontestOps(this.props)}
                    </Modal.Body>
                </Modal>
            )
        }
        else {
            return null
        }
    }

}

ContestModal.contextType = ContestContext;