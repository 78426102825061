import React, { Component, Fragment } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import axios from 'axios';
import './chat.css';
import { ChatCard } from './ChatCard';
import { NotificationManager } from 'react-notifications';
import SignalRController from '../SignalRController.js';
import ChatContext from './../context/ChatContext';
import ExportChat from '../exports/ExportChat';

export class ChatList extends Component {
    constructor(props) {
        super(props)


        this.state = {
            chats: [],
            activeChat: [],
            errors: {},
            isLoaded: false,
            filterText: '',
            filteredChat: [],
            vmID: null, recordCount: 0
        };
        this.filterTextChange = this.filterTextChange.bind(this);
        this.getFilteredChat = this.getFilteredChat.bind(this);
        this.forward = this.forward.bind(this);

    }

    async componentDidMount() {
        let vmID = parseInt(sessionStorage.getItem('VMId'));
        const chatData = await this.getFilteredChat('');
        this.setState({
            chats: chatData,
            vmID: vmID
        });
    }

    componentWillUnmount() {
        // fix Warning: Can't perform a React state update on an unmounted component
        this.setState = (state, callback) => {
            return;
        };
    }

    async getFilteredChat(filter) {
        let vmID = parseInt(sessionStorage.getItem('VMId'));
        axios.post(`api/GetFilteredMessages`, { VMeetingId: vmID, filter: filter, MaxReturn: 50 }).then(res => {
            let filterData = res.data.filter(message => message.attendeeId > 0);
            this.setState({
                chatList: filterData, recordCount: filterData.length
            })
        })
    }

    filterTextChange(event) {
        this.setState({ filterText: event.target.value })
        this.getFilteredChat(event.target.value);
    }

    forward(e, chat) {
        let inbox = 1;
        let VMeetingId = parseInt(sessionStorage.getItem('VMId'));
        axios.get(`api/AddChatToInbox`, { params: { vmId: VMeetingId, chatId: chat.messageId, inbox: inbox } }).then(res => {
            NotificationManager.success("Message sent back to Inbox", "Message Sent", 4000);
        })
    }

    render() {
        let { chatList, filterText, recordCount } = this.state;

        return (
            <Container>
                <Row>
                    <Col>
                        <div className="chat-box-am" style={{ width: "100%" }}>
                            <div className="chat-heading">All Messages {recordCount > 0 ? <span> ({recordCount}) </span> : null}</div>
                            <div className="row search">
                                <input className="form-control form-control-sm ml-3 w-50" type="text" id="txtFilter"
                                    value={filterText} placeholder="Search for message Id, message contents or the users name" onChange={this.filterTextChange} aria-label="Search">
                                </input>
                                <div>Note: Limited to a maximum of 50 results returned</div>
                            </div>
                            {(chatList && chatList.length > 0) ?
                                <div className="chat-box-search">
                                    {
                                        <ChatCard
                                            chatItems={chatList}
                                            hideClose={true}
                                            forward={this.forward}
                                            search={true}
                                            isAuthorized={this.props.isAuthorized} />
                                    }
                                    {this.context.isAuthorized("DataExport","Read") ? <div style={{ marginTop: 10, marginBottom: 10 }}><ExportChat /></div> : null}
                                </div>
                                :
                                <Fragment>
                                    <div className="mx-3">
                                        <p> No chats found </p>
                                    </div>
                                </Fragment>
                            }
                        </div>
                    </Col>
                </Row>
            </Container>
        );
    }
}
ChatList.contextType = ChatContext;

export default ChatList