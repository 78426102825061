import React, { Component } from 'react';
import axios from 'axios';
import './chat/chat.css';
import { OpenResolution, ResolutionHeading, FinalisedResolution, ActiveCounted, PublishedShowOfHands } from './ResolutionBlocks';
import { FinalisedXvote } from './XvoteBlocks';
import { Fragment } from 'react';
import { FinalisedPreferentialVote } from './PreferentialVoteBlocks';

export class PresenterResolutions extends Component {
    constructor(props) {
        super(props)
        this.VmeetingId = parseInt(sessionStorage.getItem('VMId'));
        this.userId = parseInt(sessionStorage.getItem('UserId'));
        this.state = {
            resolutionBuild: [],
            resolutions: [],
            votingupdate: this.props.vote,
            voteInterval: [],
            requestFinished: true,
            carouselTrigger: 0,
            chartData: [],
            chartOptions: [],
            graphSize: 50
        };

    }

    componentDidMount() {
        this.getAllResolutionsInfo();

        //this.setState({ voteInterval: setInterval(() => this.getAllResolutionsInfo(), 5000) });
    }

    componentWillUnmount() {
        this.setState = (state, callback) => {
            return;
        };
    };

    componentDidUpdate() {
        if (this.props.vote !== this.state.votingupdate) {
            this.setState({ votingupdate: this.props.vote });
            this.getAllResolutionsInfo();
        }
    }


    getAllResolutionsInfo = () => {
        const promises = [];
        let votesOpen = false;

        // Get all the resolutions from the system including their current state and details

        if (this.state.requestFinished === true) {
            this.setState({
                requestFinished: false,
                resolutionBuild: []
            });
            axios.get("VMGetAllResolution", { params: { VMId: this.VmeetingId } })
                .then(response => {
                    const resData = response.data ? response.data : null;
                    if (resData !== null) {
                        resData.map(res => {
                            const idata = { ContestID: parseInt(res.contestID), VMID: this.VmeetingId, UserID: this.userId };
                            // If resolution is open for voting so check how many have voted
                            if (res.status === 3 || res.status === 4) {
                                promises.push(new Promise((resolve, reject) => {
                                    axios.post("VMOpenResolutionVoteResult", idata).then(openres => {
                                        const openData = openres.data;
                                        if (openData != null && openData != "") {
                                            var combinedObj = {
                                                resolution: res,
                                                resultBlock: null,
                                                liveCount: openres.data
                                            }
                                            resolve(combinedObj);
                                        }
                                    })
                                }))
                                if (this.state.voteInterval.length === 0 && votesOpen === false && res.status === 3) {
                                    votesOpen = true;
                                    this.setState({ voteInterval: this.state.voteInterval.concat(setInterval(() => this.getAllResolutionsInfo(), 3000)) });
                                }
                            }

                            // If resolution is new or just being displayed
                            else {
                                promises.push(
                                    new Promise((resolve, reject) => {
                                        var combinedObj;
                                        combinedObj = {
                                            resolution: res,
                                            resultBlock: null,
                                            liveCount: null
                                        }
                                        resolve(combinedObj);
                                    })
                                )
                            };
                        });
                        
                        Promise.all(promises).then((results) => {
                            this.setState({
                                resolutions: results
                            });
                        });
                    }
                    this.setState({ requestFinished: true });
                }).catch((error) => {
                    console.log("Get all resolutions failed");
                    this.setState({ requestFinished: true });
                })
            if (votesOpen === false && this.state.voteInterval.length > 0) {
                this.state.voteInterval.forEach((poll) => clearInterval(poll));
                this.setState({ voteInterval: [] });
            }
        }
    }

    activeTitleToUse = (activeStatus) => {
        switch (activeStatus) {
            case 2:
                return <div className="chat-heading-open">DISPLAYING</div>
            case 3:
                return <div className="chat-heading-open">OPEN</div>
            case 4:
                return <div className="chat-heading-open">PAUSED</div>
            default:
                return <div />
        }
    }

    render() {
        let resolutions = this.state.resolutions ?? null;
        let closedResolutionsFiltered = resolutions.filter((res) => res.resolution.status > 4);
        let activeResolutionStatus = resolutions.filter((res) => res.resolution.isActive)[0]?.resolution?.status ?? -1;
        let closedResolutions = closedResolutionsFiltered.sort(function (a, b) { return new Date(b.resolution.contestCloseDate) - new Date(a.resolution.contestCloseDate) });

        return (
            <div className="container-fluid h-100">
                <div className="row h-100">
                    <div className={closedResolutions.length > 0 ? "col-md-12 col-xl-6" : "col"}>
                        {this.props.hidePresentorLabel === undefined && <div className="chat-heading">ACTIVE ITEMS</div>}
                        <div className={this.props.hidePresentorLabel === undefined ? "presenter-left--box" : "message-body Mod"}>
                            {this.activeTitleToUse(activeResolutionStatus)}

                            {//Show the active open contest
                                resolutions?.filter(res => (res.resolution.status >= 2 && res.resolution.status <= 4 && !res.resolution.isContinuous)).sort((a, b) => a.resolution.displayOrder > b.resolution.displayOrder ? 1 : -1).map((res) => {
                                    return (
                                        <div className="resolutionBox" key={res.resolution.contestID}>
                                            <ResolutionHeading resolution={res.resolution} />
                                            {(res.resolution.status === 3 || res.resolution.status === 4) &&
                                                <Fragment>
                                                    <OpenResolution resolution={res} liveCount={res.liveCount} />
                                                    {(res.resolution.enableShowOfHands || res.resolution.enableRealTimeResults) &&
                                                        <ActiveCounted contestId={res.resolution.contestID} publishTypeId={res.resolution.publishTypeId} />}
                                                </Fragment>}

                                        </div>
                                    )
                                }) ?? null
                            }


                            {resolutions?.filter(res => res.resolution.status === 3 && res.resolution.isContinuous).length > 0
                                && <div className="chat-heading-open"> ACTIVE CONTINUOUS ITEMS: VOTING PROGRESS</div>}
                            {//Show the results of the real time result continuous contests
                                resolutions?.filter(res => res.resolution.status === 3
                                    && res.resolution.isContinuous                                    
                                )
                                    .sort((a, b) => a.resolution.displayOrder > b.resolution.displayOrder ? 1 : -1)
                                    .map((res) =>
                                    (<div className="resolutionBox" key={res.resolution.contestID}>
                                        <ResolutionHeading resolution={res.resolution} />
                                        <Fragment>
                                            <OpenResolution resolution={res} liveCount={res.liveCount} />
                                            {(res.resolution.enableShowOfHands || res.resolution.enableRealTimeResults) &&
                                                <ActiveCounted contestId={res.resolution.contestID} publishTypeId={res.resolution.publishTypeId} />}
                                        </Fragment>
                                    </div>)
                                    ) ?? null
                            }
                        </div>
                    </div>
                    {
                        closedResolutions.length > 0 ?
                            <Fragment>
                                <div className="col-md-12 col-xl-6">
                                    <div className="chat-heading">CLOSED ITEMS</div>
                                    <div className={this.props.hidePresentorLabel === undefined ? "presenter-left--box" : "message-body Mod"}>
                                        {
                                            closedResolutions.map((res, i) => {
                                                return <div index={i} key={"caro" + res.resolution.contestID} >
                                                    {res.resolution.status === 5 ? <div className="chat-heading-open">CLOSED</div> : ''}
                                                    {res.resolution.status === 6 ? <div className="chat-heading-open">FINALISED</div> : ''}
                                                    {res.resolution.status === 7 ? <div className="chat-heading-open">PUBLISHED</div> : ''}
                                                    <div className="resolutionBox" key={res.resolution.contestID}>
                                                        <ResolutionHeading resolution={res.resolution} />
                                                        {res.resolution.status === 5 && <div className="awaitfinalised"><span>This item is closed, results will be available once they are finalised</span></div>}
                                                        {res.resolution.contestTypeId === 1 && res.resolution.status > 5 && <FinalisedResolution votingFor={res.resolution.votingFor} resolutionStatus={res.resolution.status} enableShowOfHands={res.resolution.enableShowOfHands} publishTypeId={res.resolution.publishTypeId} resolutionId={res.resolution.contestID} />}
                                                        {/*res.resolution.contestTypeId === 1 && res.resolution.status === 7 && res.resolution.enableShowOfHands && <PublishedShowOfHands publishTypeId={res.resolution.publishTypeId} resolutionId={res.resolution.contestID} />*/}
                                                        {res.resolution.contestTypeId === 2 && res.resolution.status > 5 && <FinalisedXvote votingFor={res.resolution.votingFor} publishTypeId={res.resolution.publishTypeId} resolutionId={res.resolution.contestID} />}
                                                        {res.resolution.contestTypeId === 3 && res.resolution.status > 5 && <FinalisedPreferentialVote votingFor={res.resolution.votingFor} resolutionId={res.resolution.contestID} />}
                                                    </div>
                                                </div>
                                            })
                                        }

                                    </div>
                                </div>
                            </Fragment> : null}

                </div>

            </div>
        )
    }
}

export default PresenterResolutions