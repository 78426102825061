import axios from 'axios';

export const AddToComfortSystem = async (vmId, userId, messageId, note, tags, targetInbox) => {
    let comfortMonitorMessage = {
        UserId: 0,
        VmeetingId: 0,
        MessageId: messageId,
        GroupId: 0,
        TargetInbox: targetInbox,
        MessageNote: note ?? "",
        TagList: tags ?? []
    }
    let response = await axios.post("ComfortMonitor/AddMessageToNewComfortGroup", comfortMonitorMessage).then(res => { return res.data });
    return response;
}

export const RemoveFromComfortSystem = async (vmId, userId, messageId) => {
    let comfortMonitorMessage = {
        UserId: 0,
        VmeetingId: 0,
        MessageId: messageId,
        GroupId: 0,
        TargetInbox: 0
    }
    let response = await axios.post("ComfortMonitor/RemoveMessageFromComfortSystem", comfortMonitorMessage).then(res => { return res.data });
    return response;
}

export const GetMessageGroupsForComfortInbox = async (vmId, currentInboxId) => {
    let vmObj = {
        VmeetingId: 0,
        CurrentInboxId: currentInboxId
    };

    let response = await axios.post("ComfortMonitor/GetMessageGroupsForComfortInbox", vmObj).then(res => { return res.data });
    return response;
}

export const GetMessagesForComfortGroup = async (vmId, messageGroupId) => {
    let vmObj = {
        VmeetingId: 0,
        MessageGroupId: messageGroupId
    };

    let response = await axios.post("ComfortMonitor/GetMessagesForComfortGroup", vmObj).then(res => { return res.data });
    return response;
}

export const GetMessageDetailsForComfort = async (vmId, messageId) => {
    let vmObj = {
        VmeetingId: 0,
        MessageId: messageId
    };

    let response = await axios.post("ComfortMonitor/GetMessageDetailsForComfort", vmObj).then(res => { return res.data });
    return response;
}

export const MessageGroupForward = async (vmId, userId, messageGroupId, targetInboxId, displayOrder = -1) => {
    let vmObj = {
        VmeetingId: 0,
        UserId: 0,
        MessageGroupId: messageGroupId,
        TargetInboxId: targetInboxId,
        DisplayOrder: displayOrder
    };

    let response = await axios.post("ComfortMonitor/MessageGroupForward", vmObj).then(res => { return res.data });
    return response;
}

export const MessageGroupReorder = async (vmId, userId, messageGroupId, currentInboxId, oldDisplayOrder, newDisplayOrder) => {
    let vmObj = {
        VmeetingId: 0,
        UserId: 0,
        MessageGroupId: messageGroupId,
        CurrentInbox: currentInboxId,
        OldDisplayOrder: oldDisplayOrder,
        NewDisplayOrder: newDisplayOrder
    };

    let response = await axios.post("ComfortMonitor/MessageGroupReorder", vmObj).then(res => { return res.data });
    return response;
}

export const MessageReorder = async (vmId, userId, messageGroupId, messageId, oldDisplayOrder, newDisplayOrder) => {
    let vmObj = {
        VmeetingId: 0,
        UserId: 0,
        MessageGroupId: messageGroupId,
        MessageId: messageId,
        CurrentInbox: 0,
        OldDisplayOrder: oldDisplayOrder,
        NewDisplayOrder: newDisplayOrder
    };

    let response = await axios.post("ComfortMonitor/MessageReorder", vmObj).then(res => { return res.data });
    return response;
}

export const MessageMoveToOtherGroup = async (vmId, userId, messageId, targetGoupId, displayOrder) => {
    let vmObj = {
        VmeetingId: 0,
        UserId: 0,
        MessageId: messageId,
        GroupId: targetGoupId,
        TargetInbox: 0,
        DisplayOrder: displayOrder
    }
    let response = await axios.post("ComfortMonitor/MessageMoveToOtherGroup", vmObj).then(res => { return res.data });
    return response;
}

export const MessageRemoveFromGroup = async (vmId, userId, messageId) => {
    let vmObj = {
        VmeetingId: 0,
        UserId: 0,
        MessageId: messageId,
        GroupId: 0,
        TargetInbox: 0
    }
    let response = await axios.post("ComfortMonitor/MessageRemoveFromGroup", vmObj).then(res => { return res.data });
    return response;
}

export const MessageGroupShowHide = async (vmId, userId, messageGroupId, displayHidden) => {
    let vmObj = {
        VmeetingId: 0,
        UserId: 0,
        MessageGroupId: messageGroupId,
        DisplayHidden: displayHidden
    }
    let response = await axios.post("ComfortMonitor/MessageGroupShowHide", vmObj).then(res => { return res.data });
    return response;
}

export const MessageUpdateNoteAndTags = async (vmId, userId, messageGroupId, messageId, messageNote, tagList) => {
    let vmObj = {
        VmeetingId: 0,
        UserId: 0,
        MessageGroupId: messageGroupId,
        MessageId: messageId,
        MessageNote: messageNote,
        TagList: tagList
    }
    let response = await axios.post("ComfortMonitor/MessageUpdateNoteAndTags", vmObj).then(res => { return res.data });
    return response;
}

export const MessageGetTagsForMeeting = async (vmId) => {
    let response = await axios.get("ComfortMonitor/MessageGetTagsForMeeting/" + vmId).then(res => { return res.data });
    return response;
}

export const MessageGetTagsForGroup = async (vmId, messageGroupId) => {
    let vmObj = {
        VmeetingId: 0,
        MessageGroupId: messageGroupId
    };

    let response = await axios.post("ComfortMonitor/MessageGetTagsForGroup", vmObj).then(res => { return res.data });
    return response;
}

export const MessageAddNewAdminMessage = async (vmId, userId, messageText) => {
    let vmObj = {
        VmeetingId: 0,
        UserId: 0,
        MessageText: messageText
    }
    let response = await axios.post("ComfortMonitor/MessageAddNewAdminMessage", vmObj).then(res => { return res.data });
    return response;
}
