import React, { Fragment, useState, useContext } from 'react';
import { InlineLoader } from '../../SecondaryComponents';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEyeSlash, faEye } from '@fortawesome/free-solid-svg-icons';
import { MessageGroupDataBlock } from './MessageGroupDataBlock';
import { Form } from 'react-bootstrap';
import ChatContext from '../../context/ChatContext';
import { NewMessageModal } from './NewMessageModal';
import { InboxName, InboxPermission, NoCmInboxName } from './Constants';
import AdminContext from '../../context/AdminContext';

export function InboxGeneric(props) {
    const [showHidden, setShowHidden] = useState(false);
    const { groups, loading, currentInbox, summary, activeInbox } = props;
    const { comfortTagList } = useContext(ChatContext);
    const { switches, isAuthorized } = useContext(AdminContext);
    const [filter, setFilter] = useState(null);
    let data = groups?.filter(a => a.currentInbox === currentInbox);

    const handleClientDropdownChange = (e) => {
        setFilter(e.target.value);
    }

    return (
        <Fragment>
            <div className="chat-heading d-flex justify-content-between">
                <div className="text-uppercase d-flex align-items-center">

                    {switches.enableComfortMonitor ? InboxName[currentInbox] : NoCmInboxName[currentInbox] }
                </div>
                {currentInbox < 3 &&
                    <div>
                        <Form.Select aria-label="Select client from list" className="form-control" onChange={handleClientDropdownChange}  >
                            <option value="0">All tags</option>
                            {
                                (comfortTagList?.length) ?
                                    comfortTagList.map(cl =>
                                        <option key={cl.messageTagId} value={cl.messageTagId}>{cl.tagText}</option>)
                                    : null

                            }
                        </Form.Select>
                    </div>
                }
                {currentInbox === 1 &&
                    <button className="btn border btn-sm" onClick={() => setShowHidden(!showHidden)} title={`${showHidden ? "Hide": "Show"} hidden items`}> <FontAwesomeIcon icon={showHidden ? faEyeSlash : faEye} /></button>
                }
                {currentInbox === 2 && !summary && isAuthorized(InboxPermission[2], 'Write') &&
                    <NewMessageModal />
                }
            </div>

            {loading ?
                <div className="chat-box"><InlineLoader /></div>
                :
                <MessageGroupDataBlock
                    data={data}
                    showHidden={showHidden}
                    currentInbox={currentInbox}
                    filter={filter}
                    summary={summary}
                    activeInbox={activeInbox}
                />
            }
        </Fragment>
    )
}

