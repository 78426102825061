import React, { Component } from 'react';
import 'react-circular-progressbar/dist/styles.css';
import axios from 'axios';
import { Loader } from './SecondaryComponents';
import AdminContext from './context/AdminContext';

//*** Closed Resolution  ***//
export class ClosedPreferentialVote extends Component {
    constructor(props) {
        super(props);

        this.state = {
            showCandidates: false,
            candidateIdArray: [],
            resultBlock: null,
            uniquevoter: [],
            csvString: '',
            votingFor: this.props.votingFor ? this.props.votingFor : "candidate"
        };
    };

    componentDidMount() {
        let candidateIdArray = this.props.contest.options.map(a => a.voteOptionId).sort((a, b) => a.voteOptionId - b.voteOptionId);
        this.setState({
            candidateIdArray: candidateIdArray
        })
    }

    componentWillUnmount() {
        // fix Warning: Can't perform a React state update on an unmounted component
        this.setState = (state, callback) => {
            return;
        };
    }

    componentDidUpdate(prevProps) {
        if (this.props.contest.contestID !== prevProps.contest.contestID) {
            let candidateIdArray = this.props.contest.options.map(a => a.voteOptionId).sort((a, b) => a.voteOptionId - b.voteOptionId);
            this.setState({
                candidateIdArray: candidateIdArray,
                csvString: '',
                showCandidates: false,
                resultBlock: null,
                uniquevoter: [],
                votingFor: this.props.votingFor ? this.props.votingFor : "candidate"
            })
        }
    }

    getPreferentialVoteData = async () => {

        let contestID = this.props.contest.contestID
        if (parseInt(contestID) > 0 && this.context.userId > 0 && this.context.meetingId > 0) {
            const idata = { VMeetingId: this.context.meetingId, ContestID: parseInt(contestID), UserID: this.context.userId };
            await axios.post("VMGetContestPreferenceVoteData", idata).then(async response => {
                const data = response.data;
                if (data != null && data !== "") {
                    let uniquevoter = [...new Set(data.map(item => item.voterID))];
                    await this.setState({
                        resultBlock: data,
                        uniquevoter: uniquevoter
                    });
                    await this.generateResultTable();
                }
            });
        }
    }

    generateResultTable = () => {
        let dataArray = []


        let titleRow = []
        this.state.candidateIdArray.forEach(candidateId => {
            let candidateData = this.props.contest.options.find(a => a.voteOptionId === candidateId);
            titleRow.push("\"" + candidateData.voteOption + "\"");
        })
        titleRow.push("\"$mult\"");
        titleRow.push("\"$bpn\"");
        dataArray.push(titleRow);

        this.state.uniquevoter.forEach(voterId => {
            let voterRow = []
            let weighting = this.state.resultBlock.find(a => a.voterID === voterId).weighted

            this.state.candidateIdArray.forEach(candidateId => {
                let voteData = this.state.resultBlock.find(a => a.contestCandidateID === candidateId && a.voterID === voterId);
                let preference = voteData ? voteData.candidatePreference : 0
                voterRow.push(preference);
            })
            voterRow.push(weighting);
            voterRow.push(voterId);
            dataArray.push(voterRow);
        })

        var csvString = '';
        dataArray.forEach(function (row) {
            csvString += row.join(',');
            csvString += "\r\n";
        });

        this.setState({
            csvString: csvString
        })
        
    }

    downloadButton = () => {
        let csvString = this.state.csvString;
        var blob = new Blob([csvString], { type: 'data:text/csv;charset=utf-8' });
        var url = URL.createObjectURL(blob);
        return <a className="btn btn-dark" href={url} download={this.props.contest.contestName + ' Vote Data.csp'}>Download result csp file</a>
    }


    render() {
        let downloadButton = this.downloadButton();
        return (
            <div id="closeResDashboardb" className="carouselPadding">
                <p>
                    The contest is now closed.
                </p>
                <div>
                    <div className="mb-2">
                        <button className="btn btn-dark" onClick={() => this.getPreferentialVoteData()}>Fetch result data</button> {this.state.resultBlock && this.state.csvString.length > 1 && downloadButton}
                    </div>
                    {this.state.showCandidates ?
                        <AddPreferenceVoteResult contest={this.props.contest} votingFor={this.state.votingFor} populateResolutionData={this.props.populateResolutionData} />
                        :
                        <button className="btn btn-dark" onClick={() => this.setState({ showCandidates: true })}>Select elected candidates</button>
                    }
                </div>
            </div>
        )
    }
}

ClosedPreferentialVote.contextType = AdminContext;

export class AddPreferenceVoteResult extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            electedCandidates: [],
            votingFor: this.props.votingFor ? this.props.votingFor : "candidate"
        };
    };

    componentDidMount() {

    }

    componentWillUnmount() {
        // fix Warning: Can't perform a React state update on an unmounted component
        this.setState = (state, callback) => {
            return;
        };
    }

    componentDidUpdate(prevProps) {
        if (this.props.contest.contestID !== prevProps.contest.contestID) {
            this.setState({
                electedCandidates: []
            })
        }
    }

    isChecked = (candidateId) => {
        const index = this.state.electedCandidates.indexOf(candidateId);
        if (index > -1) {
            return true;
        } else {
            return false;
        }
    }

    selectItem = (candidateId) => {
        let candidateArray = this.state.electedCandidates;
        let len = candidateArray.length;
        const index = candidateArray.indexOf(candidateId);
        if (index > -1) {
            candidateArray.splice(index, 1);
        } else if (this.props.contest.contestSeats === 1) {
            candidateArray = [candidateId]
        } else if (this.props.contest.contestSeats > 1 && len < this.props.contest.contestSeats) {
            candidateArray.push(candidateId)
        }
        this.setState({
            electedCandidates: candidateArray
        })
    }

    submitForm = (e) => {
        e.preventDefault();
        let contest = this.props.contest;
        let contestID = contest.contestID;
        let voteString = this.state.electedCandidates;

        //new functionality required    
        let voteData = []
        voteString.forEach(item => {
            let voteItem = {
                CandidateId: parseInt(item),
                Preference: 1
            }
            voteData.push(voteItem);
        })

        var ballotsubmit = {
            UserID: this.context.userId,
            VMID: this.context.meetingId,
            ContestID: contestID,
            VoteValue: voteData
        }

        axios.post("VMAddPreferenceResult", ballotsubmit).then(response => {
            this.props.populateResolutionData();
        })
    }

    render() {

        return (
            <div id="closeResDashboardb" className="carouselPadding">
                <form onSubmit={this.submitForm}>
                    <fieldset>
                        <legend>
                            Select elected {this.props.votingFor}s
                        </legend>
                        <p>
                            ({this.props.contest.contestSeats} to elect, {this.state.electedCandidates.length} selected)
                        </p>
                        {this.props.contest.options.map(option =>
                            <div className="d-flex justify-content-between align-items-center mb-2 pvVoteSelectBox" key={option.voteValue}>
                                <label htmlFor={"option" + option.voteValue}>{option.voteOption}</label>
                                <input className="form-check-input mt-0" type="checkbox" checked={this.isChecked(option.voteValue)} onChange={() => this.selectItem(option.voteValue)} id={"option" + option.voteValue} />
                            </div>
                        )}
                    </fieldset>
                    <div className="d-flex justify-content-end mb-2">
                        <button className="btn btn-primary" disabled={this.state.electedCandidates.length !== this.props.contest.contestSeats} type="submit">Finalise</button>
                    </div>
                </form>
            </div>
        )
    }

}

AddPreferenceVoteResult.contextType = AdminContext;

export class FinalisedPreferentialVote extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            resultBlock: {},
            votingFor: this.props.votingFor ? this.props.votingFor : "candidate"
        };
    };

    componentDidMount() {
        this.GetCloseVoteResult();
    }

    componentWillUnmount() {
        // fix Warning: Can't perform a React state update on an unmounted component
        this.setState = (state, callback) => {
            return;
        };
    }

    componentDidUpdate(prevProps) {
        if (this.props.resolutionId !== prevProps.resolutionId) {
            this.setState({
                electedCandidates: {},
                resultBlock: {},
                loading: true
            })
            this.GetCloseVoteResult();
        }
    }

    GetCloseVoteResult = () => {
        let contestID = this.props.resolutionId
        if (parseInt(contestID) > 0 && this.context.userId > 0 && this.context.meetingId > 0) {
            const idata = { VMeetingId: this.context.meetingId, ContestID: parseInt(contestID), UserID: this.context.userId };
            const response = axios.post("VMGetVoteResultFinalisedResult", idata).then(response => {
                const data = response.data;
                if (data != null && data !== "") {
                    this.setState({
                        resultBlock: data
                    });
                    let electedCandidates = data.filter(a => a.voteResult > 0);
                    this.setState({
                        electedCandidates: electedCandidates,
                        loading: false
                    })
                }
            });
        }
    }

    dataDisplay = () => {
        var response = this.state.electedCandidates.sort((a, b) => a.candidateForename.localeCompare(b.candidateForename)).sort((a, b) => a.candidateSurname.localeCompare(b.candidateSurname)).map(result =>
            <div key={result.votingValue} >
                {result.candidateSurname.toUpperCase()}{result.candidateSurname.length > 0 && result.candidateForename.length > 0 ? "," : ""} {result.candidateForename}
            </div>
        )
        return response;
    }

    render() {
        let pluralise = this.state.electedCandidates && this.state.electedCandidates.length !== 1;

        return (
            <div className="m-3">
                The following {this.props.votingFor}{pluralise ? 's have' : ' has'} been elected:
                <div className="mx-3">
                    {this.state.loading ?
                        <Loader />
                        :
                        this.dataDisplay()
                    }
                </div>
            </div>
        )
    }
}

FinalisedPreferentialVote.contextType = AdminContext;