import React, { useState, useEffect, useContext, Fragment } from 'react';
import axios from 'axios';
import { NotificationManager } from 'react-notifications';
import { ResCard } from './ResCard'
import AdminContext from '../../context/AdminContext';

import {
    DndContext,
    rectIntersection,
    KeyboardSensor,
    PointerSensor,
    useSensor,
    useSensors
} from '@dnd-kit/core';
import {
    arrayMove,
    SortableContext,
    sortableKeyboardCoordinates,
    verticalListSortingStrategy,
    useSortable
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';



const ResOptions = props => {
    const AdmnContext = useContext(AdminContext);
    const [inputList, setInputList] = useState((props.options).sort(function (a, b) { return a.voteDisplayOrder - b.voteDisplayOrder }));
    const [sortable, setSortable] = useState(false);
    const { isAuthorized } = AdmnContext



    useEffect(() => {
        let list = [];
        if (inputList.length > 0) {
            setInputList(inputList.sort(function (a, b) { return a.voteDisplayOrder - b.voteDisplayOrder }));
            inputList.map((i) => {
                if (i !== undefined) {
                    list.push({ id: i.voteOptionId, voteOption: i.voteOption, voteDisplayOrder: i.voteDisplayOrder, voteValue: i.voteValue, voteResult: i.voteResult })
                }
            });
        }
    }, []);


    const handleAddButton = (e) => {
        e.preventDefault();
        let newVoteDisplayOrder = 0;
        let newVoteValue = 0;
        if (inputList && inputList.length > 0) {
            let maxVoteDisplayOrder = inputList.reduce((p, c) => p.voteDisplayOrder > c.voteDisplayOrder ? p : c);
            newVoteDisplayOrder = parseInt(maxVoteDisplayOrder.voteDisplayOrder) + 1;
            let maxVoteValue = inputList.reduce((p, c) => p.voteValue > c.voteValue ? p : c);
            newVoteValue = parseInt(maxVoteValue.voteValue) + 1;
        }


        const newValue = [{ voteOptionId: -1, voteDisplayOrder: newVoteDisplayOrder, voteValue: newVoteValue, voteOption: '', voteResult: 0 }];
        setInputList(inputList.concat(newValue))
        let list = inputList.concat(newValue);
        props.Changed(list);


    }

    const handleRemoveButton = (e, index, voteOptionId) => {
        if (voteOptionId === -1) {
            let list = [...inputList];
            list.splice(index, 1);
            setInputList(list);
            props.Changed(list);
        }
        else {
            let list = [...inputList];
            //api call
            const deleteContestOption = {
                ContestResolutionVotingOptionId: list[index].voteOptionId,
                VMeetingId: AdmnContext.meetingId,
                UserId: AdmnContext.userId
            };
            const options = {
                url: 'VMDeleteMeetingContestOption',
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                data: deleteContestOption
            };
            axios(options)
                .then(response => {
                    if (response.data.statusDescription === 'Success') {
                        NotificationManager.success("Contest option deleted", "Delete contest option", 2000);
                    } else {
                        NotificationManager.error("Contest option delete failed", "Delete contest option", 2000);
                    }
                })

            list.splice(index, 1);
            setInputList(list);
        }
    }

    const onSortEnd = (event) => {
        const { active, over } = event;
        const oldIndex = inputList.findIndex(x => x.voteValue === active.id);
        const newIndex = inputList.findIndex(x => x.voteValue === over.id);

        var newList = arrayMove(inputList, oldIndex, newIndex);
        newList.map((item, i) => {
            let itemToEdit = item;
            itemToEdit['voteDisplayOrder'] = i + 1;
            return itemToEdit;
        });
        setInputList(newList)
        props.Changed(newList);
    };

    function updateFromChild(index, newData) {
        var list = inputList.map((cc, i) => i === index ? newData : cc);
        setInputList(list);
        props.Changed(list);
    }

    if (sortable) {
        return (
            <Fragment>
                <div className="d-flex justify-content-between">
                    <div>Item options</div>
                    <button className="btn btn-sm btn-secondary" type="button" onClick={() => setSortable(!sortable)}>Enable edit</button>
                </div>
                <ul className="VoteOptions">
                    {inputList?.length > 0 &&
                        <ul className="VoteOptions nodots">
                            <SortableOptionsList
                                items={inputList }
                                onSortEnd={onSortEnd}
                                updateFromChild={updateFromChild}
                                handleRemoveButton={handleRemoveButton }
                            />

                        </ul>
                    }
                    <button disabled={!isAuthorized('VoteItems','Write')} className="btn btn-dark" onClick={(e) => handleAddButton(e)}>Add Additional Item Option</button>
                </ul>
            </Fragment>
        );
    } else {
        return (
            <Fragment>
                <div className="d-flex justify-content-between">
                    <div>Item options</div>
                    {!props.disabled &&
                        <button className="btn btn-sm btn-secondary" type="button" onClick={() => setSortable(!sortable)}>Enable sort</button>
                    }
                </div>
                <ul className="VoteOptions">
                    {inputList?.length > 0 &&
                        inputList.map((x,i) => {
                            return <li key={i} className="candidateList" >
                                <ResCard Index={i}
                                    Data={x}
                                    Update={updateFromChild}
                                    DeleteResOption={handleRemoveButton}
                                    OptionCount={props.OptionCount}
                                    Sortable={sortable}
                                    disabled={props.disabled}
                                />
                            </li>
                        })
                    }
                    {!props.disabled &&
                        <button disabled={!isAuthorized('VoteItems', 'Write')} className="btn btn-dark" onClick={(e) => handleAddButton(e)}>Add Additional Item Option</button>
                    }
                </ul>
            </Fragment>
        );
    }

}
export default ResOptions;

const SortableOptionsList = ({ items, ...props }) => {
    const sensors = useSensors(
        useSensor(PointerSensor),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates,
        })
    );

    return (
        <DndContext
            sensors={sensors}
            //collisionDetection={closestCenter}
            collisionDetection={rectIntersection}
            onDragEnd={handleDragEnd}
        >
            <SortableContext
                items={items}
                strategy={verticalListSortingStrategy}
            >            
                {items.map((item, i) => {
                    item.id = item.voteValue
                    return (
                        <SortableOptions key={item.voteValue} id={item.voteValue}>
                            <li className="candidateList" >
                                <ResCard Index={i}
                                    Data={item}
                                    Update={props.updateFromChild}
                                    DeleteResOption={props.handleRemoveButton}
                                    OptionCount={props.OptionCount}
                                    Sortable={true}
                                />
                            </li>
                        </SortableOptions>
                    )
                })}
            </SortableContext>
        </DndContext>
    );

    function handleDragEnd(event) {
        props.onSortEnd(event);
    }

}


const SortableOptions = (props) => {
    const {
        attributes,
        listeners,
        setNodeRef,
        transform,
        transition,
    } = useSortable({ id: props.id });

    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
    };

    return (
        <div ref={setNodeRef} style={style}>
            <div className="d-flex align-items-center mb-2">
                <div className="dragHandle mx-2"  {...attributes} {...listeners}>||</div>
                <div className="flex-fill">
                    {props.children}
                </div>
            </div>
        </div>
    );
}