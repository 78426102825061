import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import { Button } from 'react-bootstrap';
import React, { useContext, useState, useEffect, useRef } from 'react';
import { nextSortOrder } from './utils';
import { SurveyAdminContext } from './SurveyAdminContext';

export function OptionList({ id, disabled }) {

    const { sdata, setSdata } = useContext(SurveyAdminContext)

    // s.id to s.SortOrder
    const qst = sdata.Survey.Question.filter((s) => s.SortOrder === id)[0]

    const [options, setOptions] = useState(qst.Options ? qst.Options : [])

    function updateOptions(newOptions, id) {
        // s.id to s.SortOrder
        let qst = sdata.Survey.Question.filter((s) => s.SortOrder === id)[0]
        qst.Options = newOptions

        // s.id to s.SortOrder
        let questions = sdata.Survey.Question.map((s) => (s.SortOrder === id ? qst : s))
        let updated = { ...sdata.Survey, Question: [...questions] }
        setSdata({ Survey: updated })
    }

    useEffect(() => { updateOptions(options, id) }, [options])

    const addOption = option => {
        let repeated = false

        options.forEach(e => {
            if (e.OptionTitle === option.OptionTitle) {
                repeated = true
            }
        })

        if (!option.OptionTitle || /^\s*$/.test(option.OptionTitle)) {
            alert("Please input a valid option. Options cannot be blank.")
            return
        }

        if (repeated) {
            alert("Please input a valid option. Options cannot be repeated.")
            return
        }

        let sortOrder = nextSortOrder(options)
        let sortedOption = {
            OptionTitle: option.OptionTitle,
            SortOrder: sortOrder,
            id: sortOrder * -1  // option.id
        }
        const newOptions = [sortedOption, ...options]
        setOptions(newOptions)
    }

    const updateOption = (optionId, newValue) => {
        let repeated = false

        options.forEach(e => {
            if (e.OptionTitle === newValue.OptionTitle) {
                repeated = true
            }
        })

        if (repeated) {
            alert("Please input a valid option. Options cannot be repeated.")
            return
        }

        if (!newValue.OptionTitle || /^\s*$/.test(newValue.OptionTitle)) {
            return
        }
        setOptions(prev => prev.map((item) => (item.id === optionId ? newValue : item)))
    }

    const removeOption = id => {
        const removedArr = [...options].filter(option => option.id !== id)
        setOptions(removedArr)
    }

    const completeOption = id => {
        let updatedOptions = options.map(option => {
            if (option.id === id) {
                option.isComplete = !option.isComplete
            }
            return option
        })
        setOptions(updatedOptions)
    }

    function OptionForm(props) {
        const [optionTitle, setOptionTitle] = useState(props.edit ? props.edit.value : '')

        const inputRef = useRef(null)

        const handleChange = evt => {
            setOptionTitle(evt.target.value)
        }

        const handleSubmit = evt => {
            evt.preventDefault()

            let item = options.find(item => item.id === props.id)
            let sortOrder = item ? item.SortOrder : null

            props.onSubmit({
                id: props.id,
                OptionTitle: optionTitle,
                SortOrder: sortOrder
            })
            setOptionTitle('')
        }

        return (
            <Form onSubmit={handleSubmit} as="div">
                {props.edit ? (
                    <React.Fragment>
                        <InputGroup className="form-text">
                            <Form.Control
                                value={optionTitle}
                                onChange={handleChange}
                                name='text'
                                ref={inputRef} />
                            <Button variant='outline-primary' onClick={handleSubmit}>Update</Button>
                        </InputGroup>
                    </React.Fragment>
                ) : (
                    <React.Fragment>
                        <InputGroup className="form-text">
                            <Form.Control
                                placeholder="Add option"
                                aria-label="Add option"
                                value={optionTitle}
                                onChange={handleChange}
                                ref={inputRef}
                                disabled={disabled || !qst.Editable}
                                required={options.length < 2} />
                            <Button variant="outline-primary" onClick={handleSubmit} disabled={disabled || !qst.Editable} >Add option</Button>
                        </InputGroup>
                    </React.Fragment>
                )}
            </Form>
        )
    }

    const Option = ({ option, removeOption, updateOption }) => {
        const [edit, setEdit] = useState({
            id: null,
            value: ''
        })

        const submitUpdate = value => {
            updateOption(edit.id, value)
            setEdit({
                id: null,
                value: ''
            })
        }

        if (edit.id) {
            return <OptionForm edit={edit} onSubmit={submitUpdate} id={edit.id} />
        }

        return (
            <React.Fragment>
                <InputGroup key={option.id} className='form-text'>
                    <Form.Control defaultValue={option.OptionTitle} type="text" readOnly data-testid={option.OptionTitle} disabled={disabled || !qst.Editable} />
                    <Button variant='outline-primary' onClick={() => removeOption(option.id)} disabled={disabled || !qst.Editable}>Delete</Button>
                    <Button variant='outline-primary' onClick={() => setEdit({ id: option.id, value: option.OptionTitle })} disabled={disabled || !qst.Editable}>Edit</Button>
                </InputGroup>
            </React.Fragment>)
    }

    return (
        <React.Fragment>
            {options.sort((a, b) => a.SortOrder - b.SortOrder).map((option) => (
                <Option
                    key={option.id}
                    option={option}
                    completeOption={completeOption}
                    removeOption={removeOption}
                    updateOption={updateOption} />))}
            <OptionForm onSubmit={addOption} />
        </React.Fragment>
    )
}