import React from 'react';
import { Container, Col, Form, FormGroup, Button } from 'react-bootstrap';
import axios from 'axios';
import Select from 'react-select';
import SimpleReactValidator from 'simple-react-validator';
import { NotificationManager } from 'react-notifications';

export class AttendeesContact extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            UserId: parseInt(sessionStorage.getItem('UserId')),
            MessageBody: '',
            Subject: '',
            EmailAddress: props.emailAddress,
            AttendeeName: props.attendeeName,
            errormsg: '',
            selectedTemplate: null
        };
        this.onChange = this.onChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.validator = new SimpleReactValidator({ autoForceUpdate: this, element: (message, className) => <div className="text-danger">{message}</div> });
    }
    componentDidMount() {

    }

    onChange(e) {
        this.setState({ [e.target.name]: e.target.value });
    }

    maxLengthCheck = (object) => {
        if (object.target.value.length > object.target.maxLength) {
            object.target.value = object.target.value.slice(0, object.target.maxLength)
        }
        this.setState({ MessageBody: object.target.value });
        if (object.target.value === "") {
            this.setState({ errormsg: "" });
        }
    }

    /*handleChange(e) {
        this.setState({ SelectedTemplate: e.target.value });
        e.preventDefault();
    }*/
    handleChange = selectedTemplate => {
        this.setState({ selectedTemplate });
    };

    onSubmit(e) {
        e.preventDefault();
        if (this.state.MessageBody === "") {
            this.setState({ errormsg: "Message is required field" });
        }
        else {
            this.setState({ errormsg: "" });
        }
        if (!this.validator.allValid() || this.state.MessageBody === "") {
            this.validator.showMessages();
            return;
        }
        else {
            
            const attendeeDetail = {
                UserId: this.state.UserId,
                AttendeeName: this.state.AttendeeName,
                SelectedTemplate: this.state.selectedTemplate.value.toString(),
                EmailAddress: this.state.EmailAddress,
                Subject: this.state.Subject,
                MessageBody: this.state.MessageBody,
            };
            const options = {
                url: 'api/users/ContactAttendees/',
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                data: attendeeDetail
            };

            axios(options)
                .then(response => {
                    if (response.data.status === 'Success') {
                        NotificationManager.success("Contact email has sent successfully", "Contact Attendee", 4000);
                        
                    } else {
                        NotificationManager.error("Sorry, there was a problem in sending email", "Contact Attendee", 4000);
                    }
                })
        }
    }
    render() {
        this.templateList = [
            { value: 1, label: "Contact Attendee Template" }
        ];
        this.validator.purgeFields();
        return (

            <Container>
                <Form onSubmit={this.onSubmit}>
                    <Col>
                        <FormGroup row>
                            <Col>
                                <Select
                                    value={this.state.selectedTemplate}
                                    onChange={this.handleChange}
                                    options={this.templateList}
                                />
                                {this.validator.message('TemplateList', this.state.selectedTemplate, 'required')}
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Col>
                                <input type="text" name="EmailAddress" className="form-control input-lg" disabled={true} onChange={this.onChange} value={this.state.EmailAddress} placeholder="To:" onBlur={() => this.validator.showMessageFor('EmailAddress')} />
                                {this.validator.message('EmailAddress', this.state.EmailAddress, 'required|email')}
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Col>
                                <input type="text" name="Subject" className="form-control input-lg" onChange={this.onChange} value={this.state.Subject} placeholder="Subject" onBlur={() => this.validator.showMessageFor('Subject')} />
                                {this.validator.message('Subject', this.state.Subject, 'required|max:100')}
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Col>
                                <textarea ref={this.messBoxRef}
                                    maxLength="1000"
                                    onInput={this.maxLengthCheck.bind(this)}
                                    onChange={this.maxLengthCheck.bind(this)}
                                    className="form-control type_msg"
                                    placeholder="Email body"
                                    value={this.state.MessageBody} />
                                {this.state.errormsg && this.state.MessageBody === "" ?
                                    <div className="errorMsg">
                                        {this.state.errormsg}
                                    </div> : null
                                }
                            </Col>
                        </FormGroup>
                    </Col>
                    <Col>
                        <FormGroup row>
                            <Col>
                                <Button disabled={!this.props?.isAuthorized} className="btn btn-success float-right" type="submit" >Send</Button>{' '}
                            </Col>
                        </FormGroup>
                    </Col>
                </Form>
            </Container>
        );

    }
}
export default AttendeesContact;