import React, { Component, Fragment, useEffect, useState } from 'react';
import 'react-circular-progressbar/dist/styles.css';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSort } from '@fortawesome/free-solid-svg-icons'
import { Loader } from './SecondaryComponents';
import { useContext } from 'react';
import AdminContext from './context/AdminContext';
import { getAggregateDataAPI, getByValue } from './SingleResult';

//*** Closed Resolution  ***//
export class ClosedXvote extends Component {
    constructor(props) {
        super(props);

        this.state = {
            resultBlock: [],
            resolutionId: 0,
            isLoading: true,
            chartData: [],
            graphSize: 50,
            columnSorted: 1,
            votingFor: this.props.votingFor ? this.props.votingFor : "candidate"
        };
        this.VmeetingId = parseInt(sessionStorage.getItem('VMId'));
        this.userId = parseInt(sessionStorage.getItem('UserId'));

        this.GetCloseVoteResult = this.GetCloseVoteResult.bind(this);
        this.toggleButton = this.toggleButton.bind(this);

    };

    componentDidMount() {
        this.GetCloseVoteResult(this.props.resolutionId);
    }

    componentWillUnmount() {
        // fix Warning: Can't perform a React state update on an unmounted component
        this.setState = (state, callback) => {
            return;
        };
    }

    componentDidUpdate(prevProps) {
        if (prevProps.resolutionId !== this.props.resolutionId)
            this.GetCloseVoteResult(this.props.resolutionId);
    }

    GetCloseVoteResult(contestID) {
        if (parseInt(contestID) > 0 && this.userId > 0 && this.VmeetingId > 0) {
            const idata = { VMeetingId: this.VmeetingId, ContestID: parseInt(contestID), UserID: this.userId };
            const response = axios.post("VMGetVoteResultCountedResult", idata).then(response => {
                const data = response.data;
                if (data != null && data !== "") {
                    this.setState({
                        resultBlock: data,
                        resolutionId: this.props.resolutionId,
                        isLoading: false
                    });
                }
            });
        }
    }



    toggleButton = (column2Sort) => {
        if (column2Sort !== this.state.columnSorted) {
            let rb = this.state.resultBlock.map((e) => { return (e) });

            if (column2Sort === 2) {
                rb.sort((a, b) => a.voteResult < b.voteResult ? 1 : -1);
            } else if (column2Sort === 3) {
                rb.sort((a, b) => a.weightedResult < b.weightedResult ? 1 : -1);
            } else {
                rb.sort((a, b) => a.displayOrder > b.displayOrder ? 1 : -1);
            }

            this.setState({
                columnSorted: column2Sort,
                resultBlock: rb
            })
        }
    }


    render() {
        return (
            <div  className="carouselPadding">
                {!this.state.isLoading ?
                    <div className="row" key={this.state.resolutionId}>
                        <div className="col">
                            <XvoteResultGrid voteAdmin={this.props.voteAdmin} contestId={this.props.resolutionId} result={this.state.resultBlock} votingFor={this.props.votingFor} publishTypeId={this.props.publishTypeId} />
                        </div>
                    </div>
                    : <Loader />}
            </div>
        )
    }
}

export class FinalisedXvote extends Component {
    constructor(props) {
        super(props);

        this.state = {
            resultBlock: [],
            resolutionId: 0,
            isLoading: true,
            chartData: [],
            graphSize: 50,
            votingFor: this.props.votingFor ? this.props.votingFor : "candidate"
        };
        this.VmeetingId = parseInt(sessionStorage.getItem('VMId'));
        this.userId = parseInt(sessionStorage.getItem('UserId'));

        this.GetCloseVoteResult = this.GetCloseVoteResult.bind(this);
    };

    componentDidMount() {
        this.GetCloseVoteResult(this.props.resolutionId);
    }

    componentWillUnmount() {
        // fix Warning: Can't perform a React state update on an unmounted component
        this.setState = (state, callback) => {
            return;
        };
    }

    componentDidUpdate(prevProps) {
        if (prevProps.resolutionId !== this.props.resolutionId)
            this.GetCloseVoteResult(this.props.resolutionId);
    }

    GetCloseVoteResult(contestID) {
        if (parseInt(contestID) > 0 && this.userId > 0 && this.VmeetingId > 0) {
            const idata = { VMeetingId: this.VmeetingId, ContestID: parseInt(contestID), UserID: this.userId };
            const response = axios.post("VMGetVoteResultFinalisedResult", idata).then(response => {
                const data = response.data;
                if (data != null && data !== "") {
                    this.setState({
                        resultBlock: data,
                        resolutionId: this.props.resolutionId,
                        isLoading: false
                    });
                }
            });
        }
    }


    render() {
        let result = this.state.resultBlock;
        return (
            <div  className="carouselPadding">
                {!this.state.isLoading ?
                    <div className="row" key={this.state.resolutionId}>
                        <div className="col">
                            <XvoteResultGrid finalised voteAdmin={this.props.voteAdmin} contestId={this.props.resolutionId} result={this.state.resultBlock} votingFor={this.props.votingFor} publishTypeId={this.props.publishTypeId} />
                        </div>
                    </div>
                    : <Loader />}
            </div>
        )
    }
}


function XvoteResultGrid(props) {
    const [columnSorted, setColumnSorted] = useState(1);
    const [result, setResult] = useState();
    const [aggregateData, setAggregateData] = useState();
    const [loadingAggregateData, setLoadingAggregateData] = useState(!props.finalised);
    const { switches, meetingId } = useContext(AdminContext);
    const isHybrid = switches.hybrid && props.voteAdmin

    useEffect(() => {
        setResult(props.result);
        if (isHybrid && !props.finalised) {
            getAggregateData();
        }
    }, [props.result])

    useEffect(() => {
        if (aggregateData) {
            setLoadingAggregateData(false)
        }
    }, [aggregateData])

    const getAggregateData = async () => {
        let aggDataApi = await getAggregateDataAPI(meetingId, props.contestId);
        setAggregateData(aggDataApi);
    }

    const toggleButton = (column2Sort) => {
        if (column2Sort !== columnSorted) {
            let rb = result.map((e) => { return (e) });

            if (column2Sort === 2) {
                rb.sort((a, b) => a.voteResult < b.voteResult ? 1 : -1);
            } else if (column2Sort === 3) {
                rb.sort((a, b) => a.weightedResult < b.weightedResult ? 1 : -1);
            } else {
                rb.sort((a, b) => a.displayOrder > b.displayOrder ? 1 : -1);
            }
            setColumnSorted(column2Sort);
            setResult(rb);
        }
    }
    if (result) {
        return (
            <table>
                <tbody>
                    <tr>
                        <th>{props.votingFor[0].toUpperCase() + props.votingFor.slice(1)}
                            Name&nbsp;
                            <span onClick={() => toggleButton(1)}>
                                <span className={columnSorted === 1 ? "xVoteSorted" : ""}>
                                    <FontAwesomeIcon icon={faSort} />
                                </span>
                            </span>
                        </th>
                        {props.publishTypeId !== 2 && isHybrid && !props.finalised &&
                            <Fragment>
                                <th>
                                    joinIN
                                </th>
                                <th>
                                    Aggregate
                                </th>
                            </Fragment>
                        }
                        {props.publishTypeId !== 2 &&
                            <th>
                                Total&nbsp;
                                <span onClick={() => toggleButton(2)}>
                                    <span className={columnSorted === 2 ? "xVoteSorted" : ""}>
                                        <FontAwesomeIcon icon={faSort} />
                                    </span>
                                </span>
                            </th>
                        }

                        {props.publishTypeId !== 1 &&
                            <th>
                                Vote Weight&nbsp;
                                <span onClick={() => toggleButton(3)}>
                                    <span className={columnSorted === 3 ? "xVoteSorted" : ""}>
                                        <FontAwesomeIcon icon={faSort} />
                                    </span>
                                </span>
                            </th>
                        }
                    </tr>
                    {
                        result.map(option => {
                            let aggValue;
                            if (isHybrid) {
                                aggValue = getByValue(aggregateData, option.votingValue);
                            }
                            return (
                                <tr key={props.resolutionId + '_' + option.votingOption} className="candidateResultRow">
                                    <td>{option.votingOption}</td>

                                    {props.publishTypeId !== 2 && isHybrid && !props.finalised &&
                                        <Fragment>
                                            <td>{!loadingAggregateData ? option.voteResult - (aggValue?.voteResult ?? 0) : '--'}</td>
                                            <td>{aggValue?.voteResult ?? 0}</td>
                                        </Fragment>
                                    }
                                    {props.publishTypeId !== 2 &&
                                        <td>{option.voteResult}</td>
                                    }

                                    {props.publishTypeId !== 1 &&
                                        <td>{option.weightedResult}</td>
                                    }
                                </tr>
                            )
                        })
                    }
                </tbody>
            </table>
        )
    } else return <Fragment />
}

