import React, { useContext, Fragment, useState, useEffect } from 'react';
import AdminContext from '../../context/AdminContext';
import { DragDropContext } from 'react-beautiful-dnd';
import { InboxGeneric } from './InboxGeneric';
import { connection } from '../../SignalRController';
import { GetMessageGroupsForComfortInbox, MessageGroupForward, MessageGroupReorder, MessageMoveToOtherGroup, MessageReorder } from './ApiFunctions';
import ChatContext from '../../context/ChatContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ButtonIconSelector, InboxPermission, InboxName } from './Constants';
import { NotificationManager } from 'react-notifications';

export function ComfortManagement(props) {
    const { isAuthorized, meetingId, userId, switches } = useContext(AdminContext);
    const { GetComfortTagList, cmInboxSelector: inboxDisplay, setCmInboxSelector: setInboxDisplay } = useContext(ChatContext);
    const [groups, setGroups] = useState();
    const [loading, setLoading] = useState(true);
    const [inboxes] = useState(4);


    const FetchData = async () => {
        let data = await GetMessageGroupsForComfortInbox(meetingId, 0);
        SetGroupsSorted(data);
        setLoading(false);
    }

    const SetGroupsSorted = (groups) => {
        let data = [...groups];
        data = data.sort((a, b) => {
            //-1 is a first, 1 is b first
            if (a.currentInbox === b.currentInbox) {
                return a.displayOrder > b.displayOrder ? 1 : -1;
            } else {
                return a.currentInbox > b.currentInbox ? 1 : -1;
            }
        });
        setGroups(data);
    }

    useEffect(() => {
        //For each inbox you have permission for, set display to true
        
        let _inboxDisplay = [...inboxDisplay];
        if (!_inboxDisplay.some(a => a)) {
            for (let i = 1; i <= inboxes; i++) {
                _inboxDisplay[i] = (isAuthorized(InboxPermission[i], 'Read') && (i !== 3 || switches.enableComfortMonitor))
            }
        }
        setInboxDisplay(_inboxDisplay);
        FetchData();
        GetComfortTagList();
        return () => { };
    }, [])

    useEffect(() => {
        //Setup connection trigger for SR
        if (connection) {
            connection.on("srHubAdm2Adm_ComfortUpdate", () => {
                if (!loading) {
                    FetchData();
                }
            });
            connection.on("srHubAdm2Adm_ComfortTagUpdate", () => {
                GetComfortTagList();
            });
        }
        //Cleanup connection event listener
        return () => {
            connection.off("srHubAdm2Adm_ComfortUpdate");
            connection.off("srHubAdm2Adm_ComfortTagUpdate");
        };
    }, [loading, connection])

    const onDragEnd = result => {
        const { destination, source, draggableId, type } = result;
        if (!destination) {
            //null destination, cancelled or invalid end point
            return;
        }
        if (
            destination.droppableId === source.droppableId &&
            destination.index === source.index
        ) {
            return;
        }
        //check write permissions for inbox the message is currently in
        let messageGroupInboxId = parseInt(draggableId.split(":")[3]);
        if (!isAuthorized(InboxPermission[messageGroupInboxId], 'Write')) {
            return;
        }

        if (type === "Group") {
            onDragEndGroup(result);
        }
        if (type === "Message") {
            onDragEndMessage(result);
        }

    }

    const onDragEndGroup = result => {
        const { destination, source, draggableId } = result;
        let messageGroupId = parseInt(draggableId.split(":")[2]);

        if (destination.droppableId !== source.droppableId) {
            MoveMessageGroup(messageGroupId, parseInt(destination.droppableId), destination.index);
        }

        if (destination.droppableId === source.droppableId &&
            destination.index !== source.index
        ) {
            ReorderMessageGroup(messageGroupId, parseInt(destination.droppableId), source.index, destination.index);
        }
    }



    const onDragEndMessage = async (result) => {
        const { destination, source, draggableId } = result;
        let targetGroupId = parseInt(destination.droppableId.split(":")[1]);
        let messageId = parseInt(draggableId.split(":")[2]);

        if (destination.droppableId !== source.droppableId) {
            let response = await MessageMoveToOtherGroup(meetingId, userId, messageId, targetGroupId, destination.index);
            if (response.statusID !== 1) {
                //trigger notification perhaps, maybe nothing, SR should handle the data update
                NotificationManager.error("Sorry, there was a problem updating the messages.", "Move messages", 4000);
            }
        }
        if (destination.droppableId === source.droppableId && destination.index !== source.index) {
            let response = await MessageReorder(meetingId, userId, targetGroupId, messageId, source.index, destination.index);
            if (response.statusID !== 1) {
                //trigger notification perhaps, maybe nothing, SR should handle the data update
                NotificationManager.error("Sorry, there was a problem updating the messages.", "Reorder messages", 4000);
            }
        }

    }


    const MoveMessageGroup = async (messageGroupId, targetInbox, displayOrder) => {
        let obj = groups.find(a => a.messageGroupId === messageGroupId);
        if (obj.currentInbox !== targetInbox) {
            let newObj = [...groups];
            let indx = newObj.findIndex(obj => obj.messageGroupId === parseInt(messageGroupId));

            newObj.forEach((group, index) => {
                if (group.currentInbox === targetInbox && group.displayOrder >= displayOrder) {
                    newObj[index].displayOrder = group.displayOrder + 1;
                }
            })
            newObj[indx].currentInbox = parseInt(targetInbox);
            newObj[indx].displayOrder = displayOrder;

            SetGroupsSorted(newObj);

            let response = await MessageGroupForward(meetingId, userId, messageGroupId, targetInbox, displayOrder);

            if (response.statusID !== 1) {
                //trigger notification perhaps, maybe nothing, SR should handle the data update
                NotificationManager.error("Sorry, there was a problem updating the inboxes.", "Move group", 4000);
            }
        }
    }

    const ReorderMessageGroup = async (messageGroupId, targetInbox, oldDisplayOrder, newDisplayOrder) => {
        let newObj = [...groups];
        let indx = newObj.findIndex(obj => obj.messageGroupId === parseInt(messageGroupId));
        if (newDisplayOrder < oldDisplayOrder) {
            newObj.forEach((group, index) => {
                if (group.currentInbox === targetInbox && group.displayOrder >= newDisplayOrder && group.displayOrder < oldDisplayOrder) {
                    newObj[index].displayOrder = group.displayOrder + 1;
                }
            })
        } else {
            newObj.forEach((group, index) => {
                if (group.currentInbox === targetInbox && group.displayOrder > oldDisplayOrder && group.displayOrder <= newDisplayOrder) {
                    newObj[index].displayOrder = group.displayOrder - 1;
                }
            })
        }
        newObj[indx].displayOrder = newDisplayOrder;
        SetGroupsSorted(newObj);
        let response = await MessageGroupReorder(meetingId, userId, messageGroupId, targetInbox, oldDisplayOrder, newDisplayOrder);
        if (response.statusID !== 1) {
            //trigger notification perhaps, maybe nothing, SR should handle the data update
            NotificationManager.error("Sorry, there was a problem updating the inboxes.", "Reorder groups", 4000);
        }
    }



    let inboxSetup = [];
    let inboxCount = inboxDisplay.filter(Boolean).length;
    for (let i = 1; i <= inboxes; i++) {
        if (isAuthorized(InboxPermission[i], 'Read')
            && inboxDisplay[i]
        ) {
            inboxSetup.push(<div className={`col-lg-${12 / inboxCount}`} key={"inboxId" + i}>
                <InboxGeneric groups={groups} loading={loading} currentInbox={i} />
            </div>);
        }
    }


    return (
        <Fragment>
            <DragDropContext onDragEnd={onDragEnd}>
                <Fragment>
                    <div className="d-flex">
                        <div className="row flex-fill">
                            {inboxSetup}
                        </div>
                        <div className="">
                            <InboxDisplaySelector inboxes={inboxes} inboxDisplay={inboxDisplay} setInboxDisplay={setInboxDisplay} />
                        </div>
                    </div>
                </Fragment>
            </DragDropContext>
        </Fragment>
    );
}



function InboxDisplaySelector(props) {
    const { isAuthorized, switches } = useContext(AdminContext);
    const { inboxes, inboxDisplay, setInboxDisplay } = props;

    const ChangeDisplayState = (inboxId) => {
        let _inboxDisplay = [...inboxDisplay];
        _inboxDisplay[inboxId] = !_inboxDisplay[inboxId]
        setInboxDisplay(_inboxDisplay);
    }
    const buttonSetup = [];
    for (let i = 1; i <= inboxes; i++) {
        if (isAuthorized(InboxPermission[i], 'Read')
            && (i!==3 || switches.enableComfortMonitor)
        ) {
            let setButton = <div key={i} >
                <button
                    title={`${inboxDisplay[i] ? "Hide" : "Show"} ${InboxName[i]}`}
                    className={`w-100 mb-1 btn ${inboxDisplay[i] ? "btn-success" : "btn-warning"}`}
                    onClick={() => ChangeDisplayState(i)}
                >
                    <FontAwesomeIcon icon={ButtonIconSelector[i]} />
                </button>
            </div>;
            buttonSetup.push(setButton);
        }
    }

    return (
        <div>
            {buttonSetup}
        </div>
    )
}