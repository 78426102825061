import React from 'react';
import { Button } from 'reactstrap';

export class FileUpload extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            fileName: ''
        };
        this.inputFileRef = React.createRef();
        this.handleFileLoaded = this.handleFileLoaded.bind(this);
        this.selectFile = this.selectFile.bind(this);
    }


    handleFileLoaded(file, reader) {
        this.setState({
            fileName: file.name
        });

        this.props.onUpload({
            file: file,
            fileDataUrl: reader.result
        });
    }

    handleFileChange(e) {

        e.preventDefault();

        //if no file has been uploaded
        if (!e.target.files) return;
        if (e.target.files.length <= 0) return;

        const file = e.target.files[0];
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = () => {
            let handleFileLoaded = this.handleFileLoaded(file, reader);
            file.onload = function () {
                return handleFileLoaded
            }
        }
    }

    selectFile() {
        this.inputFileRef.current.click();
    }

    render() {
        const { id, isDisabled, accept } = this.props;
        return (
            <React.Fragment>
                <input id={id}
                    type="file"
                    ref={this.inputFileRef}
                    onChange={(e) => this.handleFileChange(e)}
                    accept={accept}
                    disabled={isDisabled}
                    style={{ display: "none" }} />
                <Button disabled={isDisabled} className="btn btn-primary" onClick={() => this.selectFile()} >Select File</Button>
            </React.Fragment>);
    }
}
FileUpload.defaultProps = {
    id: "fileUpload",
    text: "...",
    isDisabled: false,
    accept: ".pdf"
};

export default FileUpload;