import axios from 'axios';
import { Fragment, useContext } from 'react';
import AdminContext from '../../context/AdminContext';
import swal from 'sweetalert';
import { NotificationManager } from 'react-notifications';

export default function RemoveAllPermissions(props) {
    const { isGlobalAuthorized } = useContext(AdminContext);

    const areYouSure = () => {
        swal({
            title: "Remove All Permissions",
            text: "Are you sure you wish to remove all permissions from this user? This will make life difficult for all concerned.",
            icon: "warning",
            buttons: true,
            dangerMode: true
        }).then((value) => {
            if (value) { removeAllPermissionsAPI(props.userId) }
        })
    }

    if (isGlobalAuthorized('UserAdmin', 'Write')) {
        return (
            <button className="btn btn-danger btn-sm" onClick={areYouSure}>
                Remove all permissions
            </button>
        )
    } else return <Fragment />;
}


async function removeAllPermissionsAPI(targetuserId) {
    const axoptions = {
        url: 'api/UserAdminRemoveAllPermissions',
        method: 'POST',
        data: {
            TargetUserId: targetuserId
        }
    };

    let returnValue;
    await axios(axoptions)
        .then(response => {
            returnValue = response;
            return (response);
        }).catch((error) => {
            returnValue = "error"
            return ('error');
        })
    if (returnValue.status === 200 && returnValue.data?.statusID) {
        switch (returnValue.data.statusID) {
            case 1:
                NotificationManager.success("Permissions removed successfully", "Remove permissions", 4000);
                break;
            case 2:
                NotificationManager.info("User has no permissions to remove", "Remove permissions", 4000);
                break;
            case 3:
                NotificationManager.error("An error occurred, please try again", "Remove permissions", 4000);
                break;
            case 4:
                NotificationManager.error("Permission denied", "Remove permissions", 4000);
                break;
            default:
                NotificationManager.error("An error occurred, please try again", "Remove permissions", 4000);
        }

    }

}