import React, { Fragment, useContext, useState, useRef, useEffect, useLayoutEffect } from 'react';
import { Modal } from 'react-bootstrap';
import OpenChatContext from './Common/OpenChatContext/OpenChatContext';
import { timeBetween } from './JsFunctions/TimeBetween';
import { NotificationManager } from 'react-notifications';
import EmojiPicker from './EmojiPicker';


export function EditPost(props) {
    const context = useContext(OpenChatContext);
    let post = props.post;
    const [newText, setNewText] = useState(post.PostContent);
    const [message, setMessage] = useState('');

    const runAfterUpdate = useRunAfterUpdate();
    const ref = useRef(null);
    const refEmojiPicker = useRef(null);
    const [isEmojiListVisible, setEmojiVisibility] = useState(false);
    const [disableSend, setDisableSend] = useState(false);

    async function submitEdit(e) {
        setDisableSend(true)
        setMessage('')
        let response = await context.OpenChatEditPost(e, post.PostId, newText);
        if (response === true) {
            NotificationManager.success("Post updated successfully", "Open Chat", 2000);
            setDisableSend(false);
            props.handleClose();
        } else if (response === "profanity") {
            setMessage("A profanity filter is enabled");
            setDisableSend(false);
        }
    }

    useEffect(() => {
        document.addEventListener('click', handleClickOutside);
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    });

    const handleClickOutside = (event) => {
        if (refEmojiPicker.current && !refEmojiPicker.current.contains(event.target) && !event.target.classList.contains('spanEmojiPicker')) {
            setEmojiVisibility(false);
        }
    };

    const handleEmojiClick = (emoji) => {
        const cursor = ref.current.selectionStart;
        const text = newText.slice(0, cursor) + (emoji.E).trim() + newText.slice(cursor);
        setNewText(text);
        runAfterUpdate(() => {
            ref.current.selectionStart = cursor + (emoji.E).trim().length;
            ref.current.selectionEnd = cursor + (emoji.E).trim().length;
            ref.current.focus();
        })
    }

    const showEmojis = () => {
        setEmojiVisibility(!isEmojiListVisible);
    }

    return (
        <Fragment>
            <Modal show={props.show} onHide={props.handleClose} className="openChatModal">
                <form autoComplete="off" onSubmit={(e) => submitEdit(e)}>
                    <Modal.Header closeButton>
                        <Modal.Title>Edit post</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="p-2">
                        <div className="">
                            <div className="d-flex small">
                                <div>
                                    <strong>
                                        {post.UserName} {post.AdminUserId > 0 ? ' (Admin)' : ''} {post.IsPostOwner && ' (You)'}&nbsp;
                                    </strong>
                                </div>
                                <div className="text-muted small">{timeBetween(post.CreatedDateTime, post.DateTimeNow)}</div>
                            </div>
                        </div>
                        <div className="openChatReply mb-3">
                            <div className="postContent p-2">
                                {post.PostContent}
                            </div>
                        </div>
                        <div className="border border-secondary rounded">
                            <EmojiPicker
                                refEmojiPicker={refEmojiPicker}
                                isEmojiListVisible={isEmojiListVisible}
                                handleEmojiClick={handleEmojiClick}
                            />
                            <div className="form-floating border-0">
                                <input type="text"
                                    ref={ref}
                                    className="form-control"
                                    value={newText}
                                    onChange={(e) => { setNewText(e.target.value); setMessage("") }}
                                    placeholder="Enter the new text"
                                    id="floatingTextarea"
                                    maxLength="500"
                                    required></input>
                                <label htmlFor="floatingTextarea">Enter the new text</label>
                            </div>
                        </div>

                    </Modal.Body>
                    <Modal.Footer>
                        <div className="d-flex w-100">
                            <button type="button" className="spanEmojiPicker emoji-icon btn btn-sm p-0" onClick={() => showEmojis()}>{message.length ? '😟' : '😀'}</button>
                            <div className="flex-grow-1 text-danger">{message}</div>
                            <button type="submit" className="btn btn-sm btn-primary me-1" disabled={disableSend}>
                                Submit
                            </button>
                            <button className="btn btn-sm btn-secondary" onClick={props.handleClose}>
                                Cancel
                            </button>
                        </div>
                    </Modal.Footer>
                </form>
            </Modal>
        </Fragment>
    )
}

function useRunAfterUpdate() {
    const afterPaintRef = React.useRef(null);
    useLayoutEffect(() => {
        if (afterPaintRef.current) {
            afterPaintRef.current();
            afterPaintRef.current = null;
        }
    });
    return fn => (afterPaintRef.current = fn);
}