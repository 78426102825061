import React, { Component } from 'react';
import SignalRController from './SignalRController';
import axios from 'axios';

export class ForcedLogout extends Component {
    constructor(props) {
        super(props)
        this.state = {
            value: '',
            message: ''
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange(event) {
        this.setState({
            value: event.target.value,
            message: ''
        });
    }

    handleSubmit(event) {
        event.preventDefault();
        const options = {
            url: 'api/ForceLogout',
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            data: this.state.value
        };
        axios(options).then(() => {
            this.setState({
                value: '',
                message: "Forced logout initiated"
            });
        })
    }

    componentDidMount() {
    }

    componentWillUnmount() {
    }

    render() {
        return (
            <div className="form-group my-3">
                <div className="chat-heading-am">Force Attendee Logout</div>                
                WARNING USE WITH CAUTION!!
                <hr />
                <label htmlFor="forceLogoutMessage">
                    Forced logout message to ALL attendees:
                </label>
                <div>
                    <input className="form-control" id="forceLogoutMessage" type="text" placeholder='Enter your message for forced logout here' value={this.state.value} onChange={this.handleChange} />
                </div>
                <div>
                    <button className="btn btn-danger" disabled={(!this.props.isAuthorized || this.state.value === '') ? true : false} onClick={this.handleSubmit}>Force</button>
                </div>
                <div>
                    {this.state.message}
                </div>

            </div>
        )
    }
}

export default ForcedLogout