import React, { useContext } from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import axios from 'axios';
import './chat.css';
import DateTimeManager from './../Common/DateTimeManager';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShare, faPaperPlane, faFlag, faComments, faHandPaper, faGavel, faQuestionCircle } from '@fortawesome/free-solid-svg-icons'
import ScrollToBottom from 'react-scroll-to-bottom';
import SendToPopup from './SendToPopup'
import ChatContext from './../context/ChatContext';
import { RemoveFromComfortSystem } from './ComfortMonitor/ApiFunctions';
import { InboxName, NoCmInboxName } from './ComfortMonitor/Constants';
import AdminContext from '../context/AdminContext';

export function ChatResponsePopup(props) {
    const _adminContext = useContext(AdminContext);
    return(
        <ChatResponsePopupWithSwitches adminContext={_adminContext} {...props} />
    )
}

export class ChatResponsePopupWithSwitches extends React.Component {
    constructor(props) {
        super(props);
        this.getConversation();
        this.state = {
            messageArray: [],
            messageTags: [],
            message: null,
            chatId: this.props.chat,
            chatForward: null,
            inputMessage: '',
            sendToPopup: false,
            disableSend: false
        }
        this.switches = this.props.adminContext.switches;
        this.sendMessage = this.sendMessage.bind(this);
        this.popupSendToInbox = this.popupSendToInbox.bind(this);
        this.removeChatFromInbox = this.removeChatFromInbox.bind(this);
    }

    componentDidUpdate(prevProps) {
        if (this.props.chat.attendeeId !== prevProps.chat.attendeeId) {
            this.getConversation();
        }
    }

    componentWillUnmount() {
        // fix Warning: Can't perform a React state update on an unmounted component
        this.setState = (state, callback) => {
            return;
        };
    }

    async getConversation() {
        let user = this.props.chat.attendeeId;
        let vmID = this.props.VMeetingId;
        let inbox = this.props.inbox;
        if (user && vmID) {
            await axios.get('api/GetUserConversation', { params: { vMeetingId: vmID, UserId: user, Inbox: inbox } }).then(response => {
                this.setState({
                    messageArray: response.data.filter((chat) => chat.adminResponse !== 2 && chat.messageId >= this.props.requestMessageId),
                    messageTags: response.data.filter((chat) => chat.adminResponse === 2 && chat.messageId >= this.props.requestMessageId)
                });
            })
        }
    }


    async popupSendToInbox(chat) {
        if (this.state.sendToPopup === true) await this.getConversation();
        this.setState({
            sendToPopup: !this.state.sendToPopup,
            chat: chat
        })
    }

    async removeChatFromInbox(chat, inbox) {
        axios.get(`api/RemoveChatFromInbox`, { params: { VmId: this.props.VMeetingId, chatId: chat.messageId, attendeeId: chat.attendeeId, inbox: inbox, oneOrMany: 0 } }).then(res => {
            this.setState({ inputMessage: '' });
        })
    }

    async removeMessageFromCM(messageId) {
        const { meetingId, user } = this.context;
        let response = await RemoveFromComfortSystem(meetingId, user, messageId);
        if (response.statusID === 2) {
            console.log("action denied")
        } else if (response.statusID === 1) {
            let array = [...this.state.messageArray]
            let index = array.findIndex(msg => msg.messageId === messageId);
            array[index].messageGroupId = 0;
            this.setState({
                messageArray: array
            })
        }
    }

    enterPressed(event) {
        let code = event.keyCode || event.which;
        if (code === 13 && !this.state.disableSend) { //13 is the enter keycode
            this.sendMessage();
        }
    }

    maxLengthCheck = (object) => {
        if (object.target.value.length > object.target.maxLength) {
            object.target.value = object.target.value.slice(0, object.target.maxLength)
        }
        this.setState({ inputMessage: object.target.value });
    }

    customValidation() {
        if (this.state.inputMessage === "")
            setTimeout(() => this.setState({
                commentError: 'Message is required.'
            }), 200);

        else
            setTimeout(() => this.setState({
                commentError: ''
            }), 200);
    }

    setMessageCmLocationText = (currentTriageGroupInbox) => {
        if (this.switches.enableComfortMonitor) {
            return InboxName[currentTriageGroupInbox];
        } else {
            return NoCmInboxName[currentTriageGroupInbox];
        }

    }

    setMessageIcon = (messageTypeId) => {
        let icon;
        let classType;
        switch (messageTypeId) {
            case 1:
                icon = faComments;
                classType = "genChat";
                break;
            case 2:
                icon = faHandPaper;
                classType = "speakLive";
                break;
            case 3:
                icon = faGavel;
                classType = "pointOrder";
                break;
            case 4:
                icon = faQuestionCircle;
                classType = "techSupport";
                break;
            default:
                break;

        }
        return (
            <div className={classType}><FontAwesomeIcon icon={icon} /></div>
        )
    }


    async sendMessage(link) {
        let messageDirectionModifier = 1;
        let comment;
        let joinLink = this.switches.enableCommonLink ? this.switches.commonLink : this.props.linkAddress;
        this.setState({
            disableSend: true,
            inputMessage: this.state.inputMessage.trim()
        })
        if (!link) {
            comment = this.state.inputMessage.trim();
        } else {
            comment = "This is your link to speak. <a href='" + joinLink.trim() + "' target='_blank'>Please click here</a> to join the live event.";
            messageDirectionModifier = 2;
        }


        try {
            this.customValidation();

            if (comment.length > 0) {
                let vmID = parseInt(sessionStorage.getItem('VMId'));

                let chatResponse = {
                    AdminId: 1,
                    VMeetingId: vmID,
                    QuestionText: comment,
                    SubmittedById: this.state.messageArray.find((chat) => chat.messageDirection === 0).attendeeId,
                    SubmittedByName: 'notused',
                    messageDirection: messageDirectionModifier
                };

                if (comment !== '') {
                    await axios.post(`api/SubmitAdminMessage`, chatResponse).then(res => {
                        this.setState({
                            inputMessage: ''
                        });
                        this.getConversation();
                    });
                }
            }

        }

        catch (err) {
            console.log("Error: Saving chat", err.message);
        }
        setTimeout(() => {
            this.setState({
                disableSend: false
            })
        }, 250)

    }

    chatMessageOptions = (chat) => {
        let presInbox = this.props.PresenterInbox;
        let modInbox = this.props.ModeratorInbox;
        let techInbox = this.props.TechInbox;
        let isPresInbox = !!presInbox?.find((inbox) => inbox.messageId === chat.messageId);
        let isModInbox = !!modInbox?.find((inbox) => inbox.messageId === chat.messageId);
        let isTechInbox = !!techInbox?.find((inbox) => inbox.messageId === chat.messageId);
        return (
            <div className="Options">
                {
                    this.props.isForwardAuthorized && !(isPresInbox || isModInbox || isTechInbox || this.props.noforward || !!chat.messageGroupId) &&
                    <button disabled={!this.props.isForwardAuthorized} className="btn msgforward" onClick={() => this.popupSendToInbox(chat)} ><FontAwesomeIcon className="msgforward" icon={faShare} /></button>
                }
                {
                    isPresInbox &&
                    <span className="msginbox">Presenter
                        {this.props.isForwardAuthorized &&
                            <button
                                disabled={!this.props.isForwardAuthorized}
                                className="removeChat btn"
                                onClick={() => this.removeChatFromInbox(chat, 4)}
                                title="Return to inbox">
                                x</button>
                        }
                    </span>
                }
                {
                    isModInbox &&
                    <span className="msginbox">Moderator
                        {this.props.isForwardAuthorized &&
                            <button className="removeChat btn"
                                onClick={() => this.removeChatFromInbox(chat, 2)}
                                title="Return to inbox">x
                            </button>
                        }
                    </span>
                }
                {
                    isTechInbox &&
                    <span className="msginbox">Tech Support
                        {this.props.isForwardAuthorized &&
                            <button className="removeChat btn"
                                disabled={!this.props.isAuthorized}
                                onClick={() => this.removeChatFromInbox(chat, 8)} title="Return to inbox">x
                            </button>
                        }
                    </span>
                }
                {
                    !!chat.messageGroupId &&
                    <span className="msginbox">{this.setMessageCmLocationText(chat.currentTriageGroupInbox)}
                            {this.props.isForwardAuthorized && chat.currentTriageGroupInbox < 3 &&
                            <button className="removeChat btn"
                                disabled={!this.props.isAuthorized}
                                    onClick={() => this.removeMessageFromCM(chat.messageId)} title="Return to inbox">x
                            </button>
                        }
                    </span>
                }
            </div>
        )
    }

    chatMessage = (chat) => {
        if (chat.messageDirection === 0) {
            return (
                <div className="msg-container-attendee" key={"msgs" + chat.messageId}>
                    <div className="DateTime">
                        {new DateTimeManager(chat.messageDateTime).getTimeDefaultFormat()}
                        {!!chat.answered && <span className="answeredFlag"><FontAwesomeIcon title="Answered in meeting" icon={faFlag} /></span>}
                        {!!chat.passedBack && <span className="ignoredFlag"><FontAwesomeIcon title="Passed back by presenter" icon={faFlag} /></span>}
                    </div>
                    <div className="MessageFull"> {chat.messageTypeId > 1 && <div className="requestType">{this.setMessageIcon(chat.messageTypeId)} {chat.messageTypeDescription}</div>}
                        {chat.messageText}</div>
                    {this.chatMessageOptions(chat)}
                    {chat.messageNotes &&
                        <div className="Response">
                            {chat.messageNotes}
                        </div>
                    }
                </div>
            )
        }
        else {
            return (
                <div className="msg-container-admin" key={"msgs" + chat.messageId}>
                    <div className="Options"> {chat.adminName}</div>
                    <div className="MessageFull"> {chat.messageText}</div>
                    <div className="DateTime"> {new DateTimeManager(chat.messageDateTime).getTimeDefaultFormat()}</div>
                </div>)
        }
    }


    render() {
        let vmID = parseInt(sessionStorage.getItem('VMId'));
        return (
            <React.Fragment>
                {this.state.sendToPopup &&
                    <SendToPopup
                        VMeetingId={vmID}
                        chat={this.state.chat}
                        toggle={this.popupSendToInbox}
                        isAuthorized={this.props.isAuthorized}
                        isForwardAuthorized={this.props.isForwardAuthorized}
                        authFunc={this.props.authFunc}

                    />}
                <Modal isOpen={true} className="messaging-modal-style" toggle={this.props.toggle} onClosed={this.props.toggle} fade={true}>
                    <ModalHeader className="messaging-modal-header">
                        <span className="msgTitle">Messaging with: {this.state.messageArray[0]?.attendeeName ?? null}</span>
                        <button className="closeBox btn" onClick={this.props.close} title="Close">&times;</button>
                    </ModalHeader>
                    <ModalBody className="messaging-modal-body">
                        <ScrollToBottom className="msg-container-window">
                            {this.state.messageArray?.map((chat) => this.chatMessage(chat))}
                        </ScrollToBottom>
                    </ModalBody>

                    <ModalFooter className="messaging-modal-footer">
                        <div className="input-group">
                            <textarea ref={this.messBoxRef}
                                maxLength="500"
                                onInput={this.maxLengthCheck.bind(this)}
                                onChange={this.maxLengthCheck.bind(this)}
                                onKeyUp={this.enterPressed.bind(this)}
                                className="form-control type_msg"
                                placeholder="Respond to the attendee"
                                value={this.state.inputMessage}
                                disabled={!this.props.isAuthorized}
                            />

                            <div className="input-group-append">
                                <button onClick={() => this.sendMessage(false)} title="Send message" disabled={!this.props.isAuthorized || this.state.disableSend} className="btn input-group-text send_btn">{!this.state.disableSend ? <FontAwesomeIcon icon={faPaperPlane} /> : <div className="groupChatloaderholder"><div className="loader" /></div>}</button>
                            </div>
                        </div>
                        {((this.switches.enableCommonLink && this.switches.commonLink) || this.props.linkAddress) && this.props.activeWindow === "Requests" && this.props.chat.messageTypeId == 2 &&
                            <div className="speaklivelink">
                                <span className="msgTitle">Send speak live access link</span>
                                <label className="sr-only" htmlFor="speaklivelink">Call link</label>
                                <div className="input-group">
                                    <textarea
                                        id="speaklivelink"
                                        className="form-control type_msg"
                                        value={this.switches.enableCommonLink? this.switches.commonLink: this.props.linkAddress}
                                        disabled
                                    />
                                    <div className="input-group-append">
                                        <button onClick={() => this.sendMessage(true)} title="Send link" disabled={!this.props.isAuthorized || this.state.disableSend} className="btn input-group-text send_btn">{!this.state.disableSend ? <FontAwesomeIcon icon={faPaperPlane} /> : <div className="groupChatloaderholder"><div className="loader" /></div>}</button>
                                    </div>
                                </div>
                            </div>
                        }
                    </ModalFooter>
                </Modal>
            </React.Fragment>
        );
    }
}
ChatResponsePopupWithSwitches.contextType = ChatContext;
