import React, { Component, Fragment } from 'react';
import { Row, Col } from 'reactstrap';
import AdminContext from './context/AdminContext';


export class PresenterInboxMonitor extends Component {
    static contextType = AdminContext;
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            requestData: [],
            dataFound: false,
            requestCount: 0,
            readyCount: 0,
            presenterInboxCount: 0
        };
    }

    componentDidMount() {
        this.populateData();
    }

    componentDidUpdate(prevProps) {
        if (this.props !== prevProps) {
            this.populateData();
        }
    }

    componentWillUnmount() {
        this.setState = (state, callback) => {
            return;
        };
    }

    populateData = async () => {
        var requestCount;
        var readyCount;
        if (this.props.requestData) {
            requestCount = this.props.requestData.filter(request => !request.complete && !request.ready).length;
            let requests = this.props.requestData.filter(request => request.ready && !request.complete).sort((a, b) => a.readyAt > b.readyAt ? 1 : -1);
            readyCount = requests.length;

            this.setState({
                dataFound: this.props.dataFound,
                requestData: requests,
                loading: this.props.loading,
                requestCount: requestCount,
                readyCount: readyCount
            })
        }

        if (this.props.presenterInboxItems) {
            this.setState({
                presenterInboxCount: this.props.presenterInboxItems.length
            })
        }

    }

    render() {

        if (!this.state.loading) {
            return (
                <Fragment>
                    <div className="px-2 my-1">
                        <Row className="text-center">
                            {/*<div className="attendeeSummaryBoxPres col  mx-1">
                                <div className="summaryBoxText">Presenter Questions</div>
                                <div>
                                    <h3 className="hMargin">{this.state.presenterInboxCount}</h3>
                                </div>
                            </div>*/}
                            {(this.context.switches.seenAndHeard || this.context.switches.inRoomSeenAndHeard) &&
                                <Fragment>
                                    <div className="attendeeSummaryBoxPres col  mx-1">
                                        <div className="summaryBoxText">Requests pending</div>
                                        <div >
                                            <h3 className="hMargin">{this.state.requestCount}</h3>

                                        </div>
                                    </div>
                                    <div className="attendeeSummaryBoxPres col  mx-1">
                                        <div className="summaryBoxText">Requests to Action</div>
                                        <div>
                                            <h3 className="hMargin">{this.state.readyCount}</h3>

                                        </div>
                                    </div>
                                </Fragment>
                            }
                        </Row>
                    </div>
                </Fragment>
            )
        }

        else return (<div>Loading...</div>);
    }
}