import React, { useState, useContext, useEffect } from "react";
import Form from 'react-bootstrap/Form';
import { DefineTitle } from "./SurveyTitle";
import { AddQuestion } from "./AddQuestion";
import { Button } from 'react-bootstrap';
import { nextQuestionId, nextSortOrder } from "./utils";
import { SurveyAdminContext } from "./SurveyAdminContext";
import { InstanceContext } from "./InstanceContext";
import axios from "axios";
import { NotificationManager } from "react-notifications";

export default function AdminContainer({ editMode, setEditMode }) {

    const { sdata, setSdata } = useContext(SurveyAdminContext)

    const { instanceData, setInstanceData } = useContext(InstanceContext)

    const [validated, setValidated] = useState(false)

    const [disabled, setDisabled] = useState(!instanceData.hasSurvey ? false : !editMode)

    useEffect(() => {
        setDisabled(!instanceData.hasSurvey ? false : !editMode)
    }, [editMode])


    const fetchData = async () => {
        if (sdata.Survey.Question !== undefined && Array.isArray(sdata.Survey.Question) && sdata.Survey.Question.length > 0) {
            const updatedQuestions = await sdata.Survey.Question.map((question) => {
                return {
                    ...question,
                    Editable: true
                }
            })
            setSdata({
                ...sdata,
                Survey: {
                    ...sdata.Survey,
                    Question: updatedQuestions
                }
            })
        }

        if (instanceData.hasSurvey && sdata.Survey.Question !== undefined && Array.isArray(sdata.Survey.Question) && sdata.Survey.Question.length > 0) {
            const msurveyId = {
                surveyId: instanceData.surveyId
            }

            let updatedQuestions = []

            axios.post('api/GetQuestionsWithResponses', msurveyId)
                .then((response) => {
                    let newi = JSON.parse(response.data.jsonresponse)

                    if (newi[0].Responses !== 0) {
                        updatedQuestions = sdata.Survey.Question.map((question) => {
                            let hasResponse = newi.some(item => item.QuestionId === question.id)
                            return {
                                ...question,
                                Editable: !hasResponse
                            }
                        })
                    }
                })
                .then(() => {
                    if (updatedQuestions.length > 0) {
                        setSdata({
                            ...sdata,
                            Survey: {
                                ...sdata.Survey,
                                Question: updatedQuestions
                            }
                        })
                    }
                })
                .catch(function (error) { console.log(error); })
        }
    }

    useEffect(() => {
        fetchData()
    }, [sdata.Survey.id])

    function addQuestion() {
        let sortOrder = nextSortOrder(sdata.Survey.Question)
        let qid = nextQuestionId(sdata.Survey.Question)
        let newQuestion = {
            "id": qid,
            "Mandatory": false,
            "QuestionTitle": "",
            "QuestionType": "",
            "SortOrder": sortOrder,
            "Response": "",
            "Options": [],
            "Editable": true
        }
        let questions = sdata.Survey.Question
        questions = (questions || []).concat(newQuestion)

        let updated = { ...sdata.Survey, Question: [...questions] }
        setSdata({ Survey: updated })
    }

    function getQuestionTypeId(type) {
        switch (type) {
            case "Short answer":
                return (1)
            case "Long answer":
                return (2)
            case "Single choice":
                return (3)
            case "Checkbox":
                return (4)
            case "Linear scale":
                return (5)
            case "Linear radio":
                return (6)
            case "Ranking":
                return (7)
            case "Drag and drop ranking":
                return (8)
            default: return
        }
    }

    const handleSubmit = (evt) => {
        const form = evt.currentTarget
        if (form.checkValidity()) {
            evt.preventDefault()
            setDisabled(true)

            saveSurvey(sdata.Survey.Question, sdata.Survey)
            NotificationManager.success("The survey saved successfully.", "Survey", 2000);
        }
        else {
            evt.preventDefault()
            setValidated(true)
        }
    }

    const saveSurvey = async (questions, survey) => {
        setEditMode(false)
        let surveyData = {
            "VMeetingId": instanceData.instanceId,
            "UserId": instanceData.userId,
            "SurveyId": survey.id,
            "SurveyTitle": survey.SurveyTitle,
            "SurveyDescription": survey.SurveyDescription,
            "AcceptingResponses": survey.AcceptingResponses,
            "AcceptingResponsesMessage": survey.AcceptingResponsesMessage,
            "isTemplate": false
        }

        let surveyQuestionsAndOptions = []

        if (sdata.Survey.Question !== undefined && Array.isArray(sdata.Survey.Question) && sdata.Survey.Question.length > 0) {
            surveyQuestionsAndOptions = questions.map((question) => {
                return {
                    "SurveyId": survey.id,
                    "QuestionId": question.id,
                    "QuestionTitle": question.QuestionTitle,
                    "Mandatory": question.Mandatory,
                    "Validated": !question.Mandatory,
                    "QuestionTypeId": getQuestionTypeId(question.QuestionType),
                    "SortOrder": question.SortOrder,
                    "Minimum": question.Minimum,
                    "Maximum": question.Maximum,
                    "MaximumLength": question.MaximumLength,
                    "DateCreated": new Date(),
                    "Options": JSON.stringify(question.Options)
                }
            })
        }

        let fromTemp = false

        if (sdata.Survey.id > -1 && instanceData.surveyId <= -1 && !instanceData.hasSurvey) {
            fromTemp = true
        }

        let mergedData = {
            ...surveyData,
            surveyQuestions: surveyQuestionsAndOptions,
            fromTemplate: fromTemp,
            instanceId: instanceData.instanceId,
            appName: instanceData.appName
        }

        axios.post('api/SurveyOperations', mergedData)
            .then(response => {
                const SurveySettingModel = {
                    surveyId: response.data.surveyId
                }

                axios.post('api/GetSurveySettings', SurveySettingModel)
                    .then(response => {
                        setSdata({ "Survey": JSON.parse(response.data.jsonresponse)[0] })
                    })
                    .catch(function (error) { console.log(error); })
            })
            .catch(function (error) { console.log(error); })

        setInstanceData(prevInstanceData => ({
            ...prevInstanceData,
            hasSurvey: true
        }))
    }

    return (
        <React.Fragment>
            <Form noValidate validated={validated} onSubmit={handleSubmit}>
                {!instanceData.hasSurvey || sdata.Survey.SurveyTitle ?
                    <DefineTitle id={sdata.Survey.id} disabled={disabled} /> : ""}

                {sdata.Survey.Question ?
                    sdata.Survey.Question
                        .sort((a, b) => a.SortOrder - b.SortOrder)
                        .map((q) => {
                        // id={q.id} to id={q.SortOrder}
                        return (
                            <AddQuestion id={q.SortOrder} key={q.id} disabled={disabled} />
                        )
                    })
                    : ""}
                <Button className="button-margin" variant="outline-secondary" onClick={() => addQuestion()} disabled={disabled}>Add question</Button>
                <br></br>
                <Button className="button-margin" variant="outline-primary" type="submit" disabled={disabled}>
                    {instanceData.hasSurvey ? "Update survey" : "Save survey"}
                </Button>
            </Form>
        </React.Fragment>
    )
}