import React, { Component, Fragment, useEffect, useState } from 'react';
import axios from 'axios';
import { Row, Col } from 'reactstrap';
import { exportTableData } from './Table2CSV';
import 'font-awesome/css/font-awesome.min.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSort } from '@fortawesome/free-solid-svg-icons';
import AdminContext from './context/AdminContext';


//Report for candidate votes
export class ContestReport extends Component {
    constructor(props) {
        super(props);

        this.state = {
            resultCount: [],
            contestData: [],
            loading: true,
            badger: false
        };
        this.VMId = this.props.VmeetingId;
    }
    componentDidMount() {
        this.populateContestData();

    }

    populateContestData = () => {
        axios.get('VMMeetingContests?VMId=' + this.VMId)
            .then(response => {
                const data = response.data;
                if (data !== null && data !== "") {
                    this.setState({
                        fetchData: data
                    });
                    var sorteddata = data.sort((a, b) => a.displayOrder > b.displayOrder ? 1 : -1)
                    var contestData = sorteddata.filter(cont => cont.contestStatusId >= 5 && (cont.contestTypeId === 2 || cont.contestTypeId === 3) && cont.isEnabled === true)
                    this.setState({
                        contestData: contestData,
                        loading: false
                    });
                }
            }).catch(error => {
                console.log("Get contests failed");
            });
    }


    render() {
        const { contestData = [] } = this.state;
        const { isAuthorized } = this.context;

        if (this.state.loading) {
            return (
                <Row><Col><p>Loading candidate contests...</p></Col></Row>
            )
        }
        else if (contestData.length < 1) {
            return (
                <Fragment>
                    <h2 className="chat-heading-am">CANDIDATE RESULTS BREAKDOWN</h2>
                    <Row><Col><p>No candidate results currently available, only contests that are closed or published will be displayed here.</p></Col></Row>
                </Fragment>
            )
        }

        return (
            <Fragment>
                <h2 className="chat-heading-am">CANDIDATE RESULTS BREAKDOWN</h2>
                {isAuthorized('DataExport', 'Read') &&
                    <Row className="row justify-content-start">
                        <Col sm="11"></Col>
                        <Col className="text-right">
                            <button
                                id="export"
                                onClick={() => exportTableData('#candidatevotes', 'candidatevotes.csv')}
                                className="btn btn-primary">
                                <i className="fa fa-file-text-o" aria-hidden="true" title="Export to CSV"></i>
                            </button>
                        </Col>
                    </Row>
                }
                <Row className="ls">
                    <Col>
                        <table id="candidatevotes" className="postReportsTable candidateResults" size="sm" >
                            {contestData.map(cont => {
                                return (
                                    <CandidateDisplay cont={cont} key={cont.contestId} />
                                )
                            })}
                        </table>
                    </Col>
                </Row>
            </Fragment>
        )
    }
}
export default ContestReport;
ContestReport.contextType = AdminContext;

function CandidateDisplay(props) {
    const [cont, setCont] = useState({})
    const [options, setOptions] = useState({})
    const [columnSorted, setColumnSorted] = useState(1)
    let votingFor = cont.votingFor;

    useEffect(() => {
        setCont(props.cont)
    }, [props.cont])

    useEffect(() => {
        if (cont.options) {
            if (cont.contestTypeId === 3) {
                let temp = [...cont.options]
                setOptions(temp.filter(option => option.voteResult > 0));
            } else {
                let temp = [...cont.options]
                setOptions(temp.sort((a, b) => a.voteDisplayOrder > b.voteDisplayOrder ? 1 : -1));
            }
        }
    }, [cont])

    const sortData = (sortBy) => {
        if (sortBy === 1) {
            let temp = [...options]
            setOptions(temp.sort((a, b) => a.voteDisplayOrder > b.voteDisplayOrder ? 1 : -1));
        } else if (sortBy === 2) {
            let temp = [...options]
            setOptions(temp.sort((a, b) => a.voteResult > b.voteResult ? -1 : 1));
        } else if (sortBy === 3) {
            let temp = [...options]
            setOptions(temp.sort((a, b) => a.weightedResult > b.weightedResult ? -1 : 1));
        }
        setColumnSorted(sortBy)
    }

    if (!options.length) {
        return (<Fragment>
            <tbody>
                <tr>
                    <td>
                        Results pending
                    </td>
                </tr>
            </tbody>
        </Fragment>)
    }

    return (
        <tbody>
            <tr><th colSpan={cont.publishTypeId == 3 ? "3" : "2"}>{cont.contestName}</th></tr>
            <tr>
                <th colSpan={cont.contestTypeId === 2 ? "1" : "2"} style={{ minWidth: '50%' }}>
                    {cont.contestTypeId === 1 && "Option"}
                    {cont.contestTypeId === 3 && "Elected "}
                    {cont.contestTypeId > 1 && votingFor[0].toUpperCase() + votingFor.slice(1) + (options.length !== 1 ? "s" : "")}
                    {cont.contestTypeId === 2 &&
                        <span onClick={() => sortData(1)}>
                            <span className={columnSorted === 1 ? "xVoteSorted" : ""}>
                                &nbsp;<FontAwesomeIcon icon={faSort} title="Sort by candidate order" />
                            </span>
                        </span>
                    }
                </th>
                {cont.contestTypeId === 2 &&
                    <Fragment>
                        {cont.publishTypeId !== 2 &&
                            <th style={{ width: '150px' }} >
                                TOTAL
                                <span onClick={() => sortData(2)}>
                                    <span className={columnSorted === 2 ? "xVoteSorted" : ""}>
                                        &nbsp;<FontAwesomeIcon icon={faSort} title="Sort by vote count" />
                                    </span>
                                </span>
                            </th>
                        }
                        {cont.publishTypeId !== 1 &&
                            <th style={{ width: '150px' }} >
                                WEIGHT
                                <span onClick={() => sortData(3)}>
                                    <span className={columnSorted === 3 ? "xVoteSorted" : ""}>
                                        &nbsp;<FontAwesomeIcon icon={faSort} title="Sort by vote weight" />
                                    </span>
                                </span>
                            </th>
                        }
                    </Fragment>
                }
            </tr>
            {cont.contestTypeId === 2 && options.map(option => {
                return (
                    <Fragment key={option.voteOptionId}>
                        <tr>
                            <td>{option.voteOption}</td>
                            {cont.publishTypeId !== 2 &&
                                <td>{option.voteResult}</td>
                            }
                            {cont.publishTypeId !== 1 &&
                                <td>{option.weightedResult}</td>
                            }
                        </tr>
                    </Fragment>
                )
            })}

            {cont.contestTypeId === 3 && options.sort((a, b) => a.candidateForename.localeCompare(b.candidateForename)).sort((a, b) => a.candidateSurname.localeCompare(b.candidateSurname)).map(option => {
                return (
                    <Fragment key={option.voteOptionId}>
                        <tr>
                            <td colSpan="2">
                                {option.candidateSurname.toUpperCase()}{option.candidateSurname.length > 0 && option.candidateForename.length > 0 ? "," : ""} {option.candidateForename}
                            </td>
                        </tr>
                    </Fragment>
                )
            })}
        </tbody>
    )
}

