import React from 'react';
import OpenChatStats from './OpenChatStats';
import { OpenChatContextProvider } from './Common/OpenChatContext/OpenChatContextProvider';


export default function OpenChatStatsContainer() {
    return (
        <OpenChatContextProvider>
            <OpenChatStats presenter/>
        </OpenChatContextProvider>
    )
}
