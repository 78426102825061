import React, { Component } from 'react';
import ChatContext from './ChatContext';
import axios from 'axios';
import AdminContext from './AdminContext';
import { MessageGetTagsForMeeting } from '../chat/ComfortMonitor/ApiFunctions';


export class ChatContextProvider extends Component {
    constructor(props) {
        super(props);
        this.state = {
            chats: [],
            user: '',
            role: '',
            loading: false,
            comparisonVmId: 0,
            comfortTagList: [],
            cmInboxSelector: [false, false, false, false],
            cv2InboxSelector : [true,true,true]
        };
        this.VmeetingId = parseInt(sessionStorage.getItem('VMId'));
        this.getChats = this.getChats.bind(this)
        this.GetChatInboxItems = this.GetChatInboxItems.bind(this)
    }

    setCmInboxSelector = (newValue) => {
        this.setState({
            cmInboxSelector: newValue
        })
    }

    setCv2InboxSelector = (newValue) => {
        this.setState({
            cv2InboxSelector: newValue
        })
    }

    hasChatPermission() {
        //InboxPrim.Read, InboxMod.Read, InboxTech.Read, Requests.Read, InboxPres.Read, PresInbox.Read, AdminChat.Read
        const { isAuthorized } = this.context;
        return (
            isAuthorized('InboxPrim', 'Read')
            || isAuthorized('InboxMod', 'Read')
            || isAuthorized('InboxTech', 'Read')
            || isAuthorized('Requests', 'Read')
            || isAuthorized('InboxPres', 'Read')
            || isAuthorized('PresInbox', 'Read')
            || isAuthorized('AdminChat', 'Read')
        )
    }

    componentDidUpdate() {
        if ((parseInt(sessionStorage.getItem('VMId')) !== this.state.comparisonVmId) && this.hasChatPermission()) {
            this.setState({
                comparisonVmId: parseInt(sessionStorage.getItem('VMId'))
            })
            this.getChats();
        }
    }


    GetComfortTagList = async () => {
        //get the tag list
        let data = await MessageGetTagsForMeeting(this.VmeetingId);
        this.setState({ comfortTagList: data });
    }

    GetChatInboxItems(chats, inboxes, inbox) {

        if (inboxes == null || chats == null) return null;

        // Filter for this inbox and reverse sort order so last message is used to display
        let thisinbox = []

        if (inbox === 4 || inbox === 16)
            thisinbox = inboxes.filter((box) => box.inboxType === inbox).sort((a, b) => a.sortOrder > b.sortOrder ? 1 : -1);
        else
            thisinbox = inboxes.filter((box) => box.inboxType === inbox).sort((a, b) => a.messageId < b.messageId ? 1 : -1);

        let items = [];
        thisinbox.forEach((inboxItem) => {
            let tmp = chats.findIndex(chat => chat.messageId === inboxItem.messageId);
            items.push(chats[tmp]);
        });

        const uniqueUsers = [];
        const retMessages = [];
        const unreadCount = [];

        // If not Presenter inbox group by user instead
        if (inbox !== 4 && inbox !== 16) {
            items.forEach((item) => {
                if (uniqueUsers.indexOf(item.attendeeId) === -1) {
                    uniqueUsers.push(item.attendeeId);
                    retMessages.push(item);
                }
            })

            uniqueUsers.forEach((user) => {
                let unreadinbs = thisinbox.filter((inboxUnreads) => inboxUnreads.messageRead === 0 && user === inboxUnreads.attendeeId);
                unreadCount.push({
                    user: user,
                    unread: unreadinbs.length //countunread
                });
            })

            return {
                allMessages: items,
                lastMessage: retMessages,
                unread: unreadCount
            };
        }

        return items;
    }

    getChats = async () => {
        const VmeetingId = await parseInt(sessionStorage.getItem('VMId'));
        const isAuthorized = this.context.isAuthorized;

        if (isAuthorized("InboxPrim", "Read")
            || isAuthorized("InboxMod", "Read")
            || isAuthorized("InboxTech", "Read")
            || isAuthorized("Requests", "Read")
            || isAuthorized("InboxPres", "Read")
            || isAuthorized("PresInbox", "Read")
            || isAuthorized("AdminChat", "Read")) {
            try {
                const { data } = await axios.get('api/GetMessages', { params: { vmId: VmeetingId } });
                const adminInbox = this.GetChatInboxItems(data.messages, data.inboxes, 1);
                const moderatorInbox = this.GetChatInboxItems(data.messages, data.inboxes, 2);
                const presenterInbox = this.GetChatInboxItems(data.messages, data.inboxes, 4);
                const techInbox = this.GetChatInboxItems(data.messages, data.inboxes, 8);
                const groupInbox = this.GetChatInboxItems(data.messages, data.inboxes, 16);

                this.setState({
                    adminInboxItems: adminInbox.allMessages,
                    adminInboxLatest: adminInbox.lastMessage,
                    adminInboxUnread: adminInbox.unread,
                    moderatorInboxItems: moderatorInbox.allMessages,
                    moderatorInboxLatest: moderatorInbox.lastMessage,
                    moderatorInboxUnread: moderatorInbox.unread,
                    presenterInboxItems: presenterInbox,
                    techInboxItems: techInbox.allMessages,
                    techInboxLatest: techInbox.lastMessage,
                    techInboxUnread: techInbox.unread,
                    groupInboxItems: groupInbox,

                    chats: data,
                    Loading: false
                })
                return data;

            } catch (err) {
                console.log("ERROR - Couldn't retrieve chats");
            }
        }
        return null;
    }

    render() {
        return (
            <ChatContext.Provider value={{
                getChats: this.getChats,
                chats: this.state.chats,
                user: this.context.userId,
                meetingId: this.context.meetingId,
                name: this.state.name,
                loading: this.state.loading,
                adminInboxItems: this.state.adminInboxItems,
                adminInboxLatest: this.state.adminInboxLatest,
                adminInboxUnread: this.state.adminInboxUnread,
                moderatorInboxItems: this.state.moderatorInboxItems,
                moderatorInboxLatest: this.state.moderatorInboxLatest,
                moderatorInboxUnread: this.state.moderatorInboxUnread,
                presenterInboxItems: this.state.presenterInboxItems,
                techInboxItems: this.state.techInboxItems,
                techInboxLatest: this.state.techInboxLatest,
                techInboxUnread: this.state.techInboxUnread,
                groupInboxItems: this.state.groupInboxItems,
                isAuthorized: this.context.isAuthorized,
                comfortTagList: this.state.comfortTagList,
                GetComfortTagList: this.GetComfortTagList,
                cmInboxSelector: this.state.cmInboxSelector,
                setCmInboxSelector: this.setCmInboxSelector,
                cv2InboxSelector: this.state.cv2InboxSelector,
                setCv2InboxSelector: this.setCv2InboxSelector
            }} >
                {this.props.children}
            </ChatContext.Provider>
        )
    }
}

ChatContextProvider.contextType = AdminContext;