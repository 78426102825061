import React, { useContext } from 'react';
import { OpenChatContextProvider } from './Common/OpenChatContext/OpenChatContextProvider';
import { OpenChatParent } from './Common/OpenChatParent';
import OpenChatModeration from './OpenChatModeration';
import OpenChatStats from './OpenChatStats';
import AdminContext from '../context/AdminContext'

export function AdminOpenChatParent(props) {
    const context = useContext(AdminContext);
    const { isAuthorized } = context;
    return (
        <OpenChatContextProvider>
            <div className="w-100 mw-100 adminOpenChatParent">
                {
                    isAuthorized('OpenChat', 'Read') &&
                    <div className="h-100">
                        <OpenChatParent />
                    </div>
                }
                {
                    isAuthorized('OpenChatMod', 'Read') &&
                    <div >
                        <div className="h-100 small">
                            <OpenChatModeration />
                        </div>
                    </div>
                }
                {
                    isAuthorized('OpenChatStats', 'Read') &&
                    <div>
                        <div className="h-100 small">
                            <OpenChatStats />
                        </div>
                    </div>
                }
            </div>
        </OpenChatContextProvider>
    )
}