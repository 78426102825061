import React, { Component } from 'react';
import SeenAndHeardContext from './SeenAndHeardContext';
import axios from 'axios';



export class SeenAndHeardContextProvider extends Component {
    constructor(props) {
        super(props);
        this.state = {
            seenAndHeardList: [],
            changes: false,
            loading: true,
            message: null
        };
        this.VmeetingId = parseInt(sessionStorage.getItem('VMId'));
        this.UserId = parseInt(sessionStorage.getItem('UserId'));
    }

    //Get existing data
    populateSetupData = async () => {
        this.setState({
            loading: true
        })
        var request = {
            VMId: this.VmeetingId,
            UserID: this.UserId
        }
        await axios.post("api/VMGetMeetingQuestionSetup", request).then(response => {
            
            var data = response.data;
            if (data.length > 0) {
                this.setState({
                    seenAndHeardList: response.data
                })
            } 
        }).finally(response => {
            this.setState({
                loading: false,
                changes: false
            })
        })
    }

    //Add new item to list, generate blank item
    addNewItem = () => {
        var list = this.state.seenAndHeardList;
        list.push({
            id: -1,
            longDescription: null,
            questionTypeId: "",
            shortDescription: null,
            enabled: true
        })
        this.setState({
            seenAndHeardList: list,
            changes: true
        })
    }

    //Remove item from list
    removeItem = (index) => {       
        var list = this.state.seenAndHeardList;
        if (list[index].id === -1) {
            list.splice(index, 1);
        } else {
            list[index].enabled = false;
        }
        
        //won't add API call to remove item, just disable for now. ID likely to be foreign key to attendee message

        this.setState({
            seenAndHeardList: list,
            changes: true
        })

    }


    //Handle changes in dropdown and text fields
    changeField = (tar, val, i) => {
        var list = this.state.seenAndHeardList;
        list[i][tar] = val
        this.setState({
            seenAndHeardList: list,
            changes: true
        })
    }


    //Submit changes
    submitSetup = async (e) => {
        e.preventDefault();
        if (!this.state.loading) {
            this.setState({
                message: null,
                loading: true
            })
            var list = this.state.seenAndHeardList;
            var request = [];

            //Generate c# acceptable variable for submission
            list.forEach(item => {
                let apiItem = {
                    UserId: this.UserId,
                    VmeetingId: this.VmeetingId,
                    Id: item.id,
                    QuestionTypeId: parseInt(item.questionTypeId),
                    ShortDescription: item.shortDescription,
                    LongDescription: item.longDescription,
                    Enabled: item.enabled
                }
                request.push(apiItem)
            })

            await axios.post("api/VMUpdateMeetingQuestionSetup", request).then(response => {
                if (response.data.length > 0) {
                    this.setState({
                        seenAndHeardList: response.data,
                        message: 'Update Successful',
                        loading: false,
                        changes: false
                    })
                } else {
                    this.setState({
                        message: 'An error occurred',
                        loading: false
                    })
                }
            })
        }
    }

    render() {
        return (
            <SeenAndHeardContext.Provider value={{                
                fullState: this.state,
                loading: this.state.loading,
                changes: this.state.changes,
                message: this.state.message,
                seenAndHeardList: this.state.seenAndHeardList,

                populateSeenAndHeard: this.populateSetupData,
                addNewItem: this.addNewItem,
                removeItem: this.removeItem,
                changeField: this.changeField,
                submitSetup: this.submitSetup

            }} >
                {this.props.children}
            </SeenAndHeardContext.Provider>
        )
    }
}

