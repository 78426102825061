import React, { Component, Fragment } from 'react';
import { SingleResult } from './SingleResult';
import { CircularProgressbarWithChildren, buildStyles } from 'react-circular-progressbar';
//import { ProgressIndicator, ProgressStep } from 'react-rainbow-components';
import StatusLozenge from './Contest/StatusLozenge';
import axios from 'axios';
import { faCheckCircle, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AdminContext from './context/AdminContext';
import { InlineLoader } from './SecondaryComponents';


//const stepNames = ['new', 'display', 'open', 'closed', 'published']

//*** Closed Resolution  ***//
export class ClosedResolution extends Component {
    constructor(props) {
        super(props);

        this.state = {
            resultBlock: [],
            resolutionId: 0,
            isLoading: true
        };
        this.VmeetingId = parseInt(sessionStorage.getItem('VMId'));
        this.userId = parseInt(sessionStorage.getItem('UserId'));

    };



    componentDidMount() {
        this.GetCloseVoteResult(this.props.resolutionId);
    }

    componentWillUnmount() {
        // fix Warning: Can't perform a React state update on an unmounted component
        this.setState = (state, callback) => {
            return;
        };
    }

    componentDidUpdate(prevProps) {
        if (prevProps.resolutionId !== this.props.resolutionId) {
            this.setState({ isLoading: true })
            this.GetCloseVoteResult(this.props.resolutionId);
        }
    }

    GetCloseVoteResult = (contestID) => {
        if (parseInt(contestID) > 0 && this.userId > 0 && this.VmeetingId > 0) {
            const idata = { VMeetingId: this.VmeetingId, ContestID: parseInt(contestID), UserID: this.userId };
            axios.post("VMGetVoteResultCountedResult", idata).then(response => {
                const data = response.data;
                if (data != null && data !== "") {
                    this.setState({
                        resultBlock: data,
                        resolutionId: this.props.resolutionId,
                        isLoading: false
                    });
                }
            });
        }
    }

    render() {
        return (
            <div id="closeResDashboardb" className="chat-box-am">
                {!this.state.isLoading ?
                    <div>
                        <SingleResult voteAdmin={this.props.voteAdmin} contestId={this.props.resolutionId} resultBlock={this.state.resultBlock} publishTypeId={this.props.publishTypeId} carouselTrigger={this.state.resolutionId} />
                    </div>
                    : <InlineLoader />
                }
            </div>
        )
    }
}

//*** Finalise Resolution  ***//
export class FinalisedResolution extends Component {
    constructor(props) {
        super(props);

        this.state = {
            resultBlock: [],
            resolutionId: 0,
            isLoading: true,
            isDeclared: false
        };
        this.VmeetingId = parseInt(sessionStorage.getItem('VMId'));
        this.userId = parseInt(sessionStorage.getItem('UserId'));
    };



    componentDidMount() {
        this.GetCloseVoteResult(this.props.resolutionId);
    }

    componentWillUnmount() {
        // fix Warning: Can't perform a React state update on an unmounted component
        this.setState = (state, callback) => {
            return;
        };
    }


    componentDidUpdate(prevProps) {
        if (prevProps.resolutionId !== this.props.resolutionId) {
            this.setState({ isLoading: true })
            this.GetCloseVoteResult(this.props.resolutionId);
        }
    }

    GetCloseVoteResult = (contestID) => {
        if (parseInt(contestID) > 0 && this.userId > 0 && this.VmeetingId > 0) {
            const idata = { VMeetingId: this.VmeetingId, ContestID: parseInt(contestID), UserID: this.userId };
            axios.post("VMGetVoteResultFinalisedResult", idata).then(response => {
                const data = response.data;
                if (data != null && data !== "") {
                    this.setState({
                        resultBlock: data,
                        resolutionId: this.props.resolutionId,
                        isLoading: false,
                        isDeclared: data[0].inRoomOutcomeId !== 0 ? true : false
                    });
                }
            });
        }
    }

    render() {
        return (
            <div id="closeResDashboardb" className="chat-box-am">
                {!this.state.isLoading ?
                    <div>
                        <SingleResult resultBlock={this.state.resultBlock} publishTypeId={this.props.publishTypeId} carouselTrigger={this.state.resolutionId} />
                        {this.state.isDeclared &&
                            <Fragment>
                                <span>In room result:&nbsp;</span><strong>{this.state.resultBlock[0].inRoomOutcome}</strong>
                            </Fragment>
                        }
                        {this.props.enableShowOfHands && this.props.resolutionStatus === 7 && <PublishedShowOfHands publishTypeId={this.props.publishTypeId} resolutionId={this.props.resolutionId} />}
                    </div>
                    : <InlineLoader />
                }
            </div>
        )
    }
}

//*** Published Show of Hands  ***//
export class PublishedShowOfHands extends Component {
    constructor(props) {
        super(props);

        this.state = {
            resultBlock: [],
            resolutionId: 0,
            isLoading: true,
            isDeclared: false
        };
        this.VmeetingId = parseInt(sessionStorage.getItem('VMId'));
        this.userId = parseInt(sessionStorage.getItem('UserId'));
    };



    componentDidMount() {
        this.GetCloseVoteResult(this.props.resolutionId);
    }

    componentWillUnmount() {
        // fix Warning: Can't perform a React state update on an unmounted component
        this.setState = (state, callback) => {
            return;
        };
    }

    componentDidUpdate(prevProps) {
        if (prevProps.resolutionId !== this.props.resolutionId) {
            this.setState({ isLoading: true })
            this.GetCloseVoteResult(this.props.resolutionId);
        }
    }

    GetCloseVoteResult = (contestID) => {
        if (parseInt(contestID) > 0 && this.userId > 0 && this.VmeetingId > 0) {
            const idata = { VMeetingId: this.VmeetingId, ContestID: parseInt(contestID), UserID: this.userId };
            const response = axios.post("VMGetVoteResultPublishedResult", idata).then(response => {
                const data = response.data;
                if (data != null && data !== "") {
                    this.setState({
                        resultBlock: data,
                        resolutionId: this.props.resolutionId,
                        isLoading: false
                    });
                }
            });
        }
    }

    render() {
        return (
            <div>
                {!this.state.isLoading ?
                    <span>Published result:&nbsp;<strong>{this.state.resultBlock[0].inRoomOutcome}</strong> </span>
                    : <InlineLoader />
                }
            </div>
        )
    }
}


//*** Open Resolution  ***//
export class OpenResolution extends Component {
    constructor(props) {
        super(props)
        this.state = {

        }
    }


    render() {

        let liveCount = this.props.liveCount;
        if (!liveCount) return (<div><InlineLoader /></div>);

        let maxValue = liveCount.proxyRemoteCount + liveCount.voterRemoteCount + liveCount.voterInRoomCount + liveCount.proxyInRoomCount;
        let value = maxValue - (liveCount.proxyRemoteRemaining + liveCount.voterRemoteRemaining + liveCount.voterInRoomRemaining + liveCount.proxyInRoomRemaining);
        let pcnt = value > 0 && maxValue > 0 ? (value / maxValue) * 100 : 0;
        return (
            <div>
                {liveCount &&
                    <div className="PvotingSummary">

                        <div className="cloudGrid h-150">
                            <div className="col">
                                <table className="table table-bordered">
                                    <thead className="thead-light">
                                        <tr>
                                            <th scope="col"></th>
                                            <th scope="col">Remote</th>
                                            <th scope="col">In Room</th>
                                            <th scope="col">Total</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>Attendee Voters Available</td>
                                            <td>{liveCount.voterRemoteCount}</td>
                                            <td>{liveCount.voterInRoomCount}</td>
                                            <td>{liveCount.voterRemoteCount + liveCount.voterInRoomCount}</td>
                                        </tr>
                                        {this.context.switches.hasPreMeetingData && this.context.switches.preMeetingDataType === 2 &&
                                            <tr>
                                                <td>Proxy Voters Available</td>
                                                <td>{liveCount.proxyRemoteCount}</td>
                                                <td>{liveCount.proxyInRoomCount}</td>
                                                <td>{liveCount.proxyRemoteCount + liveCount.proxyInRoomCount}</td>
                                            </tr>
                                        }
                                    </tbody>
                                    <thead>
                                        <tr>
                                            <th>Voters Remaining to Cast</th>
                                            <th>{liveCount.proxyRemoteRemaining + liveCount.voterRemoteRemaining}</th>
                                            <th>{liveCount.proxyInRoomRemaining + liveCount.voterInRoomRemaining}</th>
                                            <th>{liveCount.proxyRemoteRemaining + liveCount.voterRemoteRemaining + liveCount.proxyInRoomRemaining + liveCount.voterInRoomRemaining}</th>
                                        </tr>
                                    </thead>

                                </table>
                            </div>
                        </div>

                        <div className="circleprogress">
                            <CircularProgressbarWithChildren className="circleprogess" value={pcnt}
                                background
                                backgroundPadding={6}
                                styles={buildStyles({
                                    backgroundColor: "rgba(62,152,199,0.9)",
                                    textColor: "rgba(255,255,255,1)",
                                    pathColor: "#fff",
                                    trailColor: "transparent"
                                })}>
                                {
                                    <div style={{ fontSize: 12, marginTop: -5, color: "#fff" }}>
                                        <strong>{Math.round(pcnt)}%<br /></strong> Voted
                                    </div>
                                }
                            </CircularProgressbarWithChildren>
                        </div>

                    </div>

                }
            </div>
        );
    }
}
OpenResolution.contextType = AdminContext;

export class ActiveCounted extends Component {
    constructor(props) {
        super(props);

        this.state = {
            resultBlock: [],
            contestId: 0,
            isLoading: true
        };
        this.VmeetingId = parseInt(sessionStorage.getItem('VMId'));
        this.userId = parseInt(sessionStorage.getItem('UserId'));
        this.getVotes = setInterval(() => this.GetVoteResult(this.props.contestId), 2000)
    };



    componentDidMount() {
        this.GetVoteResult(this.props.contestId);
    }

    componentWillUnmount() {
        clearInterval(this.getVotes);
        // fix Warning: Can't perform a React state update on an unmounted component
        this.setState = (state, callback) => {
            return;
        };
    }

    componentDidUpdate(prevProps) {
        if (prevProps.resolutionId !== this.props.resolutionId) {
            this.setState({ isLoading: true })
            this.GetVoteResult(this.props.resolutionId);
        }
    }

    GetVoteResult = (contestID) => {
        if (parseInt(contestID) > 0 && this.userId > 0 && this.VmeetingId > 0) {
            const idata = { VMeetingId: this.VmeetingId, ContestID: parseInt(contestID), UserID: this.userId };
            const response = axios.post("VMGetVoteResultCountedResult", idata).then(response => {
                const data = response.data;
                if (data != null && data !== "") {
                    this.setState({
                        resultBlock: data,
                        resolutionId: this.props.contestId,
                        isLoading: false
                    });
                }
            });
        }
    }

    render() {
        return (
            <div id="closeResDashboardb" className="chat-box-am">
                {!this.state.isLoading ?
                    <div>
                        <SingleResult contestId={this.props.resolutionId} resultBlock={this.state.resultBlock} publishTypeId={this.props.publishTypeId} carouselTrigger={this.state.contestId} />
                    </div>
                    : <InlineLoader />
                }
            </div>
        )
    }
}

export class ShowOfHands extends Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: true,
            options: {},
            selectedItem: '',
            submitting: false,
            submitted: false
        }

        this.VmeetingId = parseInt(sessionStorage.getItem('VMId'));
        this.UserId = parseInt(sessionStorage.getItem('UserId'));
    }

    componentDidMount() {
        this.props.validation(false);
        this.getOutcomeList();
    }

    getOutcomeList = async () => {
        await axios.get("VMGetOutcomeList").then(response => {
            const resdata = response.data;
            if (resdata.length > 1) {
                this.setState({
                    options: resdata
                })
            }
            return;
        })
        var ContestData = {
            UserId: this.UserId,
            VmeetingId: this.VmeetingId,
            ContestId: this.props.contestId,
        }
        await axios.post("VMGetContestOutcome", ContestData).then(response => {
            const resdata = response.data;
            if (resdata.declareInRoomOutcomeId > 0) {
                this.setState({
                    selectedItem: resdata.declareInRoomOutcomeId,
                    submitted: true
                })
                this.props.validation(true);
            }
            return;
        })
        this.setState({
            loading: false
        })
    }

    onChange = async (event) => {
        this.setState({
            submitting: true
        })
        const target = event.target;
        const value = target.value;
        if (value > 0) {
            this.setState({
                selectedItem: parseInt(value),
            });

            var publishData = {
                UserId: this.UserId,
                VmeetingId: this.VmeetingId,
                ContestId: this.props.contestId,
                DeclareInRoomOutcomeId: parseInt(value)
            }
            if (publishData.UserId > 0 && publishData.VmeetingId > 0 && publishData.ContestId > 0 && publishData.DeclareInRoomOutcomeId > 0) {
                await axios.post("VMUpdateInRoomOutcome", publishData).then(response => {
                    if (response.data.statusID === 1) {
                        this.setState({
                            submitting: false,
                            submitted: true
                        })
                        this.props.validation(true);
                    }
                    else {
                        this.setState({
                            submitting: false,
                            message: 'Error updating result'
                        })
                        this.props.validation(false);
                    }
                    return;
                })
            }
        }
        else {
            this.setState({
                selectedItem: '',
                submitting: false,
                submitted: false
            })
            this.props.validation(false);
        }

    }

    render() {
        if (this.state.loading) {
            return (
                <InlineLoader />)
        }
        return (
            <Fragment>
                <label htmlFor="selectedItem">Outcome in room:</label>
                {this.props.status === 5 && !this.state.submitted && <div className="warning"><FontAwesomeIcon icon={faExclamationTriangle} color="red" />  Please select in room outcome before finalising the contest</div>}
                {(this.props.status === 3 || this.props.status === 4) && !this.context.switches.hybrid && !this.state.submitted && <div className="warning"><FontAwesomeIcon icon={faExclamationTriangle} color="red" />  Please select in room outcome before closing the contest</div>}
                <div className="row">
                    <div className="col-md-6">
                        <select
                            style={{
                                border: this.props.status === 5 && !this.state.submitted && '2px solid red',
                                width: '100%'
                            }}
                            id="selectedItem"
                            name="selectedItem"
                            value={this.state.selectedItem}
                            onChange={(e) => this.onChange(e)}
                            required
                        >
                            <Fragment>
                                <option value=''> Select Outcome </option>
                                {this.state.options.map(option => (
                                    <option key={option.declareInRoomOutcomeId} value={option.declareInRoomOutcomeId}>
                                        {option.declaredInRoomOutcome}
                                    </option>
                                ))}
                            </Fragment>
                        </select>
                    </div>
                    <div className="col-2">{this.state.submitting ? <div className="loaderholder"><div className="loader" /></div> : this.state.submitted && <FontAwesomeIcon icon={faCheckCircle} color="green" />}</div>
                </div>
            </Fragment>
        )
    }



}
ShowOfHands.contextType = AdminContext;

//*** Heading Resolution  ***//
export class ResolutionHeading extends Component {
    constructor(props) {
        super(props)
        this.state = {
            checkbox: false
        }
    }

    selectContest = (e, contestID, statusId) => {
        e.stopPropagation();
        this.setState({
            checkbox: !this.state.checkbox
        })
        this.props.multiSelectContest(e, contestID, statusId)
    }

    //let pvProtection = this.props.multiSelectContestsActive && res.contestTypeId === 3 && this.context.switches.hybrid;
    //let pvCloseProtection = this.props.multiSelectContestsActive && this.checkMultiSelectForPreferenceVote() && !this.context.switches.hybrid;
    //((this.props.multiSelectStatus === 3 || this.props.multiSelectStatus === 4) && pvCloseProtection || this.props.multiSelectStatus === 5 && pvProtection)

    render() {
        let res = this.props.resolution;
        let pvProtection = this.props.multiSelectContestsActive && res.contestTypeId === 3 && this.context.switches.hybrid;
        let pvCloseProtection = this.props.multiSelectContestsActive && res.contestTypeId === 3 && !this.context.switches.hybrid;
        let pvHighlight = ((this.props.multiSelectStatus === 3 || this.props.multiSelectStatus === 4) && pvCloseProtection || this.props.multiSelectStatus === 5 && pvProtection)
        return (
            <Fragment>
                <div className={"my-1 fw-bolder"} key={res.pageControlResolutionID}>
                    <div className="row w-100 mx-0 align-items-center">
                        <div className="col ResSelectList TitleText">
                            {res.resolutionText}
                            {(res.contestTypeId === 1 || res.contestTypeId === 2) && res.publishTypeId === 1 && !res.enableShowOfHands && ' (Standard Count)'}
                            {(res.contestTypeId === 1 || res.contestTypeId === 2) && res.publishTypeId === 2 && !res.enableShowOfHands && ' (Weighted Count)'}
                            {(res.contestTypeId === 1 || res.contestTypeId === 2) && res.publishTypeId === 3 && !res.enableShowOfHands && ' (Standard and Weighted Counts)'}
                            {res.contestTypeId === 1 && res.enableShowOfHands && ' (Show of hands)'}
                            {res.enableRealTimeResults && ' (RTR)'}
                        </div>
                        <div className="col-auto">
                            <StatusLozenge status={res.status} />
                        </div>

                        {this.props.multiSelectContestsActive && !(res.enableShowOfHands && res.contestTypeId === 1) &&
                            <div className={`col-auto ResSelectList MultiSelectBox d-flex align-items-center ${pvHighlight && !!this.props.selectedContests[res.pageControlResolutionID] ? "pvHighlight" : ""}  `}>
                                <label htmlFor={"multiselect" + res.pageControlResolutionID}> Multiselect for {res.resolutionText} </label>
                                <input
                                    disabled={this.props.multiSelectStatus === null || this.props.multiSelectStatus === res.contestStatusId ? false : true}
                                    type="checkbox"
                                    title={this.props.multiSelectStatus === null || this.props.multiSelectStatus === res.contestStatusId ? "Select Contest" : "Selected contests must have the same status"}
                                    name={"multiselect" + res.pageControlResolutionID}
                                    id={"multiselect" + res.pageControlResolutionID}
                                    checked={!!this.props.selectedContests[res.pageControlResolutionID]}
                                    onChange={(e) => this.selectContest(e, res.pageControlResolutionID.toString(), res.contestStatusId)}
                                />
                            </div>}
                    </div>
                </div>
                {/*<ContestProgress status={res.status} />*/}
            </Fragment>);
    }
}
ResolutionHeading.contextType = AdminContext
export default ClosedResolution