import React, { Fragment, useContext, useRef, useState, useEffect, useLayoutEffect } from 'react';
import { NewPostInterface } from './NewPost';
import OpenChatContext from './OpenChatContext/OpenChatContext';
import { OpenChatContent } from './OpenChatPost';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDoubleDown } from '@fortawesome/free-solid-svg-icons';
import AdminContext from '../../context/AdminContext';


//The display component for the entire chat
export function OpenChatParent(props) {
    const uContext = useContext(AdminContext);
    if (!uContext.switches.length > 0) {
        return (
            <ChatStructure isAuthorized={uContext.isAuthorized('OpenChat', 'Write')} />
        )
    } else {
        return <Fragment />
    }

}


function ChatStructure(props) {
    const context = useContext(OpenChatContext);
    const [atBottom, setAtBottom] = useState(true);
    const [atTop, setAtTop] = useState(true);
    const scrolled = useRef(false);

    const endOfPosts = useRef(null);
    const startOfPosts = useRef(null);
    const postContainer = useRef(null);

    function scrollToBottom() {
        endOfPosts.current.scrollIntoView();
        scrolled.current = false;
    }

    let postListLength = context.contextState.Posts.length;
    const previousValues = useRef({ atBottom, postListLength });

    function CheckScrollToBottom() {
        postListLength = context.contextState.Posts.length;

        if (
            (previousValues.current.atBottom &&
            previousValues.current.postListLength !== context.contextState.Posts.length) ||
            !scrolled.current
        ) {
            //if new posts have come in while you're at the bottom, scroll to bottom
            previousValues.current = { atBottom, postListLength }
            scrollToBottom();
        } else if (
            !previousValues.current.atBottom &&
            previousValues.current.postListLength !== context.contextState.Posts.length
        ) {
            //If new posts have come in while you weren't at the bottom, display more posts button
            previousValues.current = { atBottom, postListLength }
            //add "more posts" button
        } else {
            //Else just update ref
            previousValues.current = { atBottom, postListLength }
        }
    }

    useEffect(() => {
        CheckScrollToBottom();
    }, [atBottom, context.contextState.Posts.length]);


    //https://stackoverflow.com/questions/70397346/intersection-observer-with-react-js
    //check watched elements are visible on screen, if not set state to invisible
    const observer = new IntersectionObserver(
        (entries) => {
            for (let entry of entries) {
                if (entry.isIntersecting) {
                    // update the active state to the visible section
                    if (entry.target.id === "endOfPosts") {
                        setAtBottom(true);
                        scrolled.current = false;
                    } else if (entry.target.id === "startOfPosts") {
                        setAtTop(true);
                    }
                } else {
                    if (entry.target.id === "endOfPosts") {
                        setAtBottom(false);
                    } else if (entry.target.id === "startOfPosts") {
                        setAtTop(false);
                    }
                }
            }
        },
        {
            // root property defaults to the browser viewport

            // intersection ratio (90% of section must be visibile)
            threshold: 0.7
        }
    );

    useEffect(() => {
        if (atTop && !atBottom) {
            let target = document.getElementById("chatPostId" + context.minVisiblePost);
            context.OpenChatGetAllPosts().then(() => {
                target.scrollIntoView();
            })
        }
    }, [atTop])


    useLayoutEffect(() => {
        observer.observe(endOfPosts.current);
        observer.observe(startOfPosts.current);
        if (atTop && atBottom) {
            context.OpenChatGetAllPosts().then(() => {
                scrollToBottom();
            })
        }
        // cleanup function
        return () => {
            observer.unobserve(endOfPosts.current);
            observer.unobserve(startOfPosts.current);
            setAtBottom({});
            setAtTop({}); 
        };
    }, []);

    useEffect(() => {
        if (!scrolled.current) {
            scrollToBottom();
        }
    }, [context.contextState])

    const onScrollEvent = () => {
        if (atBottom && scrolled.current) {
            scrolled.current = false;
        } else if (!atBottom && !scrolled.current) {
            scrolled.current = true;
        }
    }

    return (
        <div className="mh-100 h-100 m-0">
            <div className="openChatParent d-flex flex-column ">
                <div className="chat-heading d-flex">
                    <div className="flex-grow-1">
                        OPEN CHAT
                    </div>
                </div>
                <div onScroll={() => onScrollEvent()} className="flex-grow-1 overflow-auto" ref={postContainer}>
                    <div id="startOfPosts" className="mt-1" ref={startOfPosts} />
                    <OpenChatContent isAuthorized={props.isAuthorized} />
                    <div id="endOfPosts" className="mb-3" ref={endOfPosts} />
                    {!atBottom && !context.replyTarget > 0 && <button className="btn btn-sm scrollToBottomButton py-1" title="Scroll to bottom" aria-label="scroll to bottom" onClick={() => scrollToBottom()}><FontAwesomeIcon icon={faAngleDoubleDown} /></button>}
                </div>
                <div className="flex-grow-0">
                    <NewPostInterface scrollToBottom={scrollToBottom} isAuthorized={props.isAuthorized} />
                </div>
            </div>
        </div >
    )
}