export function nextQuestionId(questions) {
    if (typeof (questions) === "object" && questions.length > 0) {
        return Math.max(...questions.map(o => o.id)) + 1
    }
    else {
        return 1
    }
}

export function nextOptionId(options) {
    if (typeof (options) === "object" && options.length > 0) {
        return Math.max(...options.map(o => o.id)) + 1
    }
    else {
        return 1
    }
}

export function nextSortOrder(options) {
    if (typeof (options) === "object" && options.length > 0) {
        return Math.max(...options.map(o => o.SortOrder)) + 1
    }
    else {
        return 1
    }
}

export function updatedSortOrder(questions) {
    if (typeof (options) === "object") {
        return questions.sort((a, b) => (a.SortOrder > b.SortOrder) ? 1 : -1)
    }
}
