import React, { Fragment, useState, useContext } from 'react';
import { Modal } from 'react-bootstrap';
import OpenChatContext from './Common/OpenChatContext/OpenChatContext';
import { timeBetween } from './JsFunctions/TimeBetween';
import { NotificationManager } from 'react-notifications';


export function ModeratorFlag(props) {
    const [selected, setSelected] = useState(-1);
    const [messageToAttendee, setMessageToAttendee] = useState('');    
    const [message, setMessage] = useState(); //response message
    const context = useContext(OpenChatContext);
    //let options = context.contextState.FlagOptions;
    let post = props.post;

    async function submitFlag() {
        if (selected === 2 && messageToAttendee.trim().length > 10 && messageToAttendee.trim().length <= 150 || selected < 2) {
            let submit = await context.ResolveFlag(post.PostId, post.AttendeeUserId, selected, messageToAttendee.trim());
            if (submit === "Success") {
                await setMessage("Flag resolved successfully");
                NotificationManager.success("Flag resolved successfully", "Open Chat", 2000);
                if (selected === 2) {
                    context.setUpdateMuted(true);
                }
                props.handleClose();
            } else {
                setMessage("An error occurred");
            }
        } else {
            setMessage("Please enter a message to the attendee between 10 and 150 characters.");
        }
    }

    return (
        <Fragment>
            <Modal show={props.show} onHide={props.handleClose} className="openChatModal">
                <Fragment>
                    <Modal.Header closeButton>
                        <Modal.Title>Resolve moderator flag</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="p-2">
                        <div className="">
                            <div className="d-flex small">
                                <div>
                                    <strong>
                                        {post.UserName} {post.AdminUserId > 0 ? ' (Admin)' : ''} {post.IsPostOwner && ' (You)'}&nbsp;
                                    </strong>
                                </div>
                                <div className="text-muted small">{timeBetween(post.CreatedDateTime, post.DateTimeNow)}</div>
                            </div>
                        </div>
                        <div className="openChatReply mb-3">
                            <div className="postContent p-2">
                                {post.PostContent}
                            </div>
                        </div>

                        <fieldset>
                            <legend>
                                Please select your resolution from the options below
                            </legend>
                            <div className="form-check">

                                <label className="form-check-label d-block" >
                                    <input
                                        className="form-check-input"
                                        type='radio'
                                        value={0}
                                        name="flagForModerator"
                                        checked={0 === selected}
                                        onChange={() => setSelected(0)}
                                    />
                                    Reject flag
                                </label>
                                <label className="form-check-label d-block"  >
                                    <input
                                        className="form-check-input"
                                        type='radio'
                                        value={1}
                                        name="flagForModerator"
                                        checked={1 === selected}
                                        onChange={() => setSelected(1)}
                                    />
                                    Delete message
                                </label>
                                <label className="form-check-label d-block" >
                                    <input
                                        className="form-check-input"
                                        type='radio'
                                        value={2}
                                        name="flagForModerator"
                                        checked={2 === selected}
                                        onChange={() => setSelected(2)}
                                        disabled={post.AdminUserId > 0}
                                    />
                                    Delete message and mute attendee
                                </label>

                            </div>
                            {selected === 2 &&
                            <div className="form-floating mt-3">
                                <textarea
                                    className="form-control"
                                    value={messageToAttendee}
                                    onChange={(e)=>setMessageToAttendee(e.target.value) }
                                    placeholder="Enter the mute reason to be displayed to the attendee"
                                    id="floatingTextarea"
                                    required></textarea>
                                <label htmlFor="floatingTextarea">Enter the mute reason to be displayed to the attendee</label>
                            </div>
                            }
                        </fieldset>
                    </Modal.Body>
                    <Modal.Footer>
                        <div>{message}</div>
                        <button disabled={selected < 0} className="btn btn-sm btn-primary" onClick={() => submitFlag()}>
                            Submit
                        </button>
                        <button className="btn btn-sm btn-secondary" onClick={props.handleClose}>
                            Cancel
                        </button>
                    </Modal.Footer>
                </Fragment>
            </Modal>
        </Fragment>
    );
}


//To delete message without using a flag
export function ModeratorDelete(props) {
    const [selected, setSelected] = useState(-1);
    const [messageToAttendee, setMessageToAttendee] = useState('');    
    const [message, setMessage] = useState(); //response message
    const context = useContext(OpenChatContext);
    let post = props.post;

    async function submitFlag() {
        if (selected === 2 && messageToAttendee.trim().length > 10 && messageToAttendee.trim().length <= 150 || selected < 2) {
            //let submit = await context.ResolveFlag(post.PostId, post.AttendeeUserId, selected, messageToAttendee.trim);
            let submit = await context.OpenChatDeletePost(post.PostId, post.AttendeeUserId, selected, messageToAttendee.trim());
            if (submit === "Success") {
                NotificationManager.success("Message deleted successfully", "Open Chat", 2000);
                await setMessage("Message deleted successfully");
                if (selected === 2) {
                    context.setUpdateMuted(true);
                }
                props.handleClose();
            } else {
                setMessage("An error occurred");
            }
        } else {
            setMessage("Please enter a reason between 10 and 150 characters.");
        }
    }

    return (
        <Fragment>
            <Modal show={props.show} onHide={props.handleClose} className="openChatModal">
                <Fragment>
                    <Modal.Header closeButton>
                        <Modal.Title>Delete Message</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="p-2">
                        <div className="">
                            <div className="d-flex small">
                                <div>
                                    <strong>
                                        {post.UserName} {post.AdminUserId > 0 ? ' (Admin)' : ''} {post.IsPostOwner && ' (You)'}&nbsp;
                                    </strong>
                                </div>
                                <div className="text-muted small">{timeBetween(post.CreatedDateTime, post.DateTimeNow)}</div>
                            </div>
                        </div>
                        <div className="openChatReply mb-3">
                            <div className="postContent p-2">
                                {post.PostContent}
                            </div>
                        </div>

                        <fieldset>
                            <legend>
                                Please select action from the options below
                            </legend>
                            <div className="form-check">
                                <label className="form-check-label d-block"  >
                                    <input
                                        className="form-check-input"
                                        type='radio'
                                        value={1}
                                        name="flagForModerator"
                                        checked={1 === selected}
                                        onChange={() => setSelected(1)}
                                    />
                                    Delete message
                                </label>
                                <label className="form-check-label d-block" >
                                    <input
                                        className="form-check-input"
                                        type='radio'
                                        value={2}
                                        name="flagForModerator"
                                        checked={2 === selected}
                                        onChange={() => setSelected(2)}
                                        disabled={post.AdminUserId > 0}
                                    />
                                    Delete message and mute attendee
                                </label>
                            </div>
                            {selected === 2 &&
                                <div className="form-floating mt-3">
                                    <textarea
                                        className="form-control"
                                        value={messageToAttendee}
                                        onChange={(e) => setMessageToAttendee(e.target.value)}
                                        placeholder="Enter reason, this will be displayed to the attendee if muted"
                                        id="floatingTextarea"
                                        required></textarea>
                                    <label htmlFor="floatingTextarea">Enter reason, this will be displayed to the attendee if muted</label>
                                </div>
                            }
                        </fieldset>
                    </Modal.Body>
                    <Modal.Footer>
                        <div>{message}</div>
                        <button disabled={selected < 0} className="btn btn-sm btn-primary" onClick={() => submitFlag()}>
                            Submit
                        </button>
                        <button className="btn btn-sm btn-secondary" onClick={props.handleClose}>
                            Cancel
                        </button>
                    </Modal.Footer>
                </Fragment>
            </Modal>
        </Fragment>
    );
}