import React, { useState, useRef, useEffect, useContext } from 'react';
import './QuickPollAdmin.css';
import axios from 'axios';
import { Container, Col, FormGroup, Label, Button } from 'reactstrap';
import SimpleReactValidator from 'simple-react-validator';
import { NotificationManager } from 'react-notifications';

export default function QuickPollAddNew(props) {
    const [pollDescription, setPollDescription] = useState('');
    const [templates, setTemplates] = useState(0);
    const [templateId, setTemplateId] = useState(0);
    const description = useRef(null);
    const validator = new SimpleReactValidator({ autoForceUpdate: this, element: (message, className) => <div className="text-danger">{message}</div> });
    const [showButton, setShowButton] = useState(true);
    
    useEffect(() => {
        getTemplates();
        description.current.focus();
        return (() => {
        })
    }, []);

    function onChange(e) {
        if (e.target.value.length <= 100)
            setPollDescription(e.target.value);
    }

    function getTemplates() {
        axios.post(`VMGetQuickPollTemplates`, {
            VMId: props.vMeetingId,
        }).then(res => {
            var data = res.data;
            setTemplates(data);
            if (typeof (props.refresh) == 'function') props.refresh();
        })
    }

    function onAddQuickPoll() {
        setShowButton(false);
        axios.post(`VMAddQuickPoll`, {
            vMeetingId: props.vMeetingId,
            UserId: 1,
            Description: pollDescription,
            TemplateId: parseInt(templateId)
        }).then(res => {
            var data = res.data;
            if (data) {
                NotificationManager.success("Poll created successfully", "Create Poll", 2000);
                setPollDescription('');
            }
            if (typeof (props.refresh) === 'function') props.refresh();
        }).catch(error => {
            NotificationManager.error("Poll creation failed successfully", "Create Poll", 2000);
        })
        
        setTimeout(function () {
            setShowButton(true);
        }, 250);

    }

    function handleClientDropdownChange(e) {
        setTemplateId(e.target.value);
        e.preventDefault();
    }
    const options = [1, 2, 3, 4, 5]
    return (

        <div>
            <Container>
                <div>
                    <FormGroup row>
                        <Col >
                            <Label for="Description">Poll name (minimum of 5 and maximum of 100 characters)</Label>
                            <input ref={description} className="form-control" type="text" name="pollTitle" style={{ width: '100%' }} onChange={onChange} value={pollDescription} placeholder="Quick Poll title" onBlur={() => validator.showMessageFor('Description')} />
                            {validator.message('pollDescription', pollDescription, 'required|max:100')}
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Col>
                            <Label for="ResText">Template</Label>
                            <select className="form-control" onChange={handleClientDropdownChange}  >
                                <option value="-1">Select Template</option>
                                {
                                    (templates && templates.length > 0) ?
                                        templates.map(cl =>
                                            <option key={cl.templateId} value={cl.templateId}>{cl.description.trim()}&nbsp;({options.map((i) => { return (cl['option' + i]) ? i === 1 ? cl['option' + i].trim() : ', ' + cl['option' + i].trim() : '' })}) </option>)

                                        : null
                                };
                            </select>
                        </Col>
                    </FormGroup>
                </div>
                <FormGroup row>
                    <Col sm={5}>
                        
                        {showButton
                            ? <Button
                                className={(pollDescription.trim().length < 5 || parseInt(templateId) < 1) ? "disablePollButton" : "btn btn-dark btn-success"}
                                onClick={() => onAddQuickPoll()}
                                disabled={(!props?.authorize) || (pollDescription.trim().length < 5 || parseInt(templateId) < 1)} >Create Poll</Button>
                            : <Button className="disablePollButton" disabled><div className="loaderholder"><div className="loader"></div></div></Button>
                        }
                    </Col>
                </FormGroup>
            </Container>
        </div>
    )

}

