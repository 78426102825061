import React, { useState, useContext } from "react";
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import { Form } from "react-bootstrap";
import { SurveyAdminContext } from "./SurveyAdminContext";

export function DefineTitle({ id, disabled }) {

    const { sdata, setSdata } = useContext(SurveyAdminContext)

    const survey = sdata.Survey

    const [title, setTitle] = useState(survey && survey.SurveyTitle ? survey.SurveyTitle : "")
    const [description, setDescription] = useState(survey && survey.SurveyDescription ? survey.SurveyDescription : "")

    function setBlur() {
        title ? survey.SurveyTitle = title : survey.SurveyTitle = " "
        description ? survey.SurveyDescription = description : survey.SurveyDescription = " "

        setSdata({ Survey: survey })
    }

    return (
        <React.Fragment>
            <FloatingLabel label="Survey title" className="mb-3">
                <Form.Control
                    type="text"
                    name={id}
                    value={title}
                    onChange={(evt) => setTitle(evt.target.value)}
                    onBlur={() => setBlur()}
                    required
                    disabled={disabled}
                    data-testid={id} />
            </FloatingLabel>
            <FloatingLabel htmlFor={id * 2} label="Survey description" className="mb-3">
                <Form.Control
                    as="textarea"
                    rows={3}
                    name={id * 2}
                    value={description}
                    onChange={(evt) => setDescription(evt.target.value)}
                    onBlur={() => setBlur()}
                    disabled={disabled}
                    data-testid={id * 2} />
            </FloatingLabel>
        </React.Fragment>)
}

export function SurveyTitle({ name, description }) {
    return <React.Fragment>
        <h1>{name}</h1>
        <p>{description}</p>
    </React.Fragment>
}