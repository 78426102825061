import React, { Component, Fragment, useContext } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import axios from 'axios';
import swal from 'sweetalert';
import SignalRController from './SignalRController';
import { ResolutionHeading, ClosedResolution, OpenResolution, FinalisedResolution, ShowOfHands, ActiveCounted, PublishedShowOfHands } from './ResolutionBlocks.js';
import { ClosedXvote, FinalisedXvote } from './XvoteBlocks.js';
import { ClosedPreferentialVote, FinalisedPreferentialVote } from './PreferentialVoteBlocks';
import ProxyPollCard from './ProxyPollCard';
import QuickPollAdmin from './QuickPolls/QuickPollAdmin'
import { Container } from 'react-bootstrap';
import Dropdown from 'react-bootstrap/Dropdown';
import PublishShowOfHands from './PublishShowOfHands';
import AdminContext from './context/AdminContext';
import AggregateResult from './AggregateResult';
import { VoteLoader } from './SecondaryComponents';
import DisplayQuorumData from './Quorum/QuorumData';
import { NotificationManager } from 'react-notifications';

export class VoteAdministration extends Component {
    static displayName = VoteAdministration.name;

    constructor(props) {
        super(props);
        this.VmeetingId = parseInt(sessionStorage.getItem('VMId'));
        this.userId = parseInt(sessionStorage.getItem('UserId'));

        this.state = { // These all need checking to see they are still required
            resolutions: [],
            resText: '',
            contestID: 0,
            errors: {},
            successMsg: '',
            liveCount: null,
            status: 0,
            resultBlock: [],
            loading: true,
            tabOrder: 0,
            subTabOrder: 0,
            //btnDisabled: [],
            requestFinished: true,
            chairProxyVoteAlert: false,
            multiSelectContestsActive: false,
            selectedContests: {},
            multiSelectStatus: null,
            buttonGroupDisable: false,
            role: '',
            inRoomValidation: false

        };
        this.contestString = '';
        this.resultsPolling = null;
    }

    componentDidMount = () => {
        this.populateResolutionData();
        SignalRController.addCallbackAdm2Adm_VoteUpdate(this.populateResolutionData);
        //SignalRController.ConnectionStart();
        this.GetVoteResult();
    }

    componentWillUnmount() {
        SignalRController.removeCallbackAdm2Adm_VoteUpdate(this.populateResolutionData);
        clearInterval(this.resultsPolling);
        this.setState = (state, callback) => {
            return;
        };
    }

    populateResolutionData = () => {
        if (this.VmeetingId && this.state.requestFinished === true) {
            this.setState({
                requestFinished: false
            });

            var anyResOpen = null;

            axios.get('VMMeetingContestNoRes?VMId=' + this.VmeetingId)
                .then(response => {
                    const data = response.data;

                    if (data != null && data !== "") {
                        this.setState({ resolutions: data });
                        // Add button options & icons states for each resolution 
                        //fix old references so I don't have to rebuild everything.
                        data.map((resolution) => (
                            resolution.resolutionText = resolution.contestName,
                            resolution.status = resolution.contestStatusId,
                            resolution.pageControlResolutionID = resolution.contestId,
                            resolution.contestID = resolution.contestId
                        ));


                        anyResOpen = data.filter(x => (x.status === 3));

                        // Start timers if there is a resolution open
                        //No don't, we only want the timer if the selected contest is open - AM
                        if (anyResOpen.length > 0 && !this.resultsPolling) {
                            //this.resultsPolling = setInterval(this.GetVoteResult, 2000);
                        }

                        if (anyResOpen.length === 0 && this.resultsPolling) {
                            clearInterval(this.resultsPolling);
                        }
                        this.setState({ requestFinished: true });
                    }
                }).catch(error => {
                    this.setState({ requestFinished: true });
                });
            this.getChairProxyVote()
        }
        return;
    }

    getChairProxyVote = () => {
        axios.get('VMHasChairVoted?VMId=' + this.VmeetingId)
            .then(response => {
                this.setState({
                    chairProxyVoteAlert: !response.data.chairVoted
                })
            });
    }

    ShowResolution = (contestID) => {
        let resl = this.state.resolutions.filter(res => res.contestId === contestID);
        if (resl.length > 0) {
            this.setState({
                resText: resl[0].resolutionText,
                contestID: resl[0].contestID,
                status: resl[0].status,
                resultBlock: []
            });
            if (resl[0].status === 3 || resl[0].status === 4) {
                this.SetVoteResultTimer();
            } else if (this.resultsPolling) {
                clearInterval(this.resultsPolling);
            }

        } else {
            this.setState({
                resText: '',
                contestID: 0,
                status: 0,
                resultBlock: []
            });
        }
    }

    updateResolution = async (resUpdateReason) => {
        //single contest update
        var errors = {};
        var Status = 0;
        var successMsg = '';
        let selectedResolutionTypeId = this.state.resolutions.filter(res => res.contestId === this.state.contestID)[0].contestTypeId;

        this.setState({
            successMsg: '',
            errors: errors,
            buttonGroupDisable: true
        });
        if (this.state.contestID <= 0) {
            errors["Resolution"] = "*Please select a contest";
            this.setState({ errors: errors });
        }

        Status = resUpdateReason === "Display" ? 2 :
            resUpdateReason === "Open" ? 3 :
                resUpdateReason === "Pause" ? 4 :
                    resUpdateReason === "Close" ? 5 :
                        resUpdateReason === "Finalise" ? 6 :
                            resUpdateReason === "Publish" ? 7 : 0;

        successMsg = resUpdateReason === "Display" ? "Contest displayed successfully" :
            resUpdateReason === "Open" ? "Contest opened successfully" :
                resUpdateReason === "Pause" ? "Contest paused successfully":
                    resUpdateReason === "Close" ? "Contest closed successfully" :
                        resUpdateReason === "Publish" ? "Contest published successfully" : "";

        if (Status > 0) {
            if (parseInt(this.state.contestID) > 0 && this.userId > 0 && this.VmeetingId > 0) {
                const idata = { ContestID: this.state.contestID, Status: Status, ResolText: this.state.resText, UserID: this.userId, VMID: this.VmeetingId };
                await axios.post("VMUpdateResolution", idata).then(async response => {
                    const resdata = response;
                    if (resdata && resdata.data.length > 0 && resdata.data[0].contestID > 0) {
                        if (Status === 1 || Status === 2 || Status === 4 || (Status === 5 && (this.context.switches.hybrid || selectedResolutionTypeId === 3))) {
                            this.setState({
                                status: Status
                            });
                            NotificationManager.success(successMsg, "Vote Administration", 2000);
                            this.populateResolutionData();
                            setTimeout(() => {
                                this.setState({
                                    buttonGroupDisable: false
                                });
                            }, 250);
                        }

                        if (Status === 3 || Status === 4) {
                            const idata = { ContestID: parseInt(this.state.contestID), VMID: this.VmeetingId, UserID: this.userId };
                            await axios.post("VMOpenResolutionVoteResult", idata).then(response => {
                                const data = response.data;
                                if (data != null && data !== "") {
                                    this.setState({
                                        status: Status,
                                        liveCount: data
                                    });
                                    Status === 3 && NotificationManager.success(successMsg, "Vote Administration", 2000);
                                    // If voting open set a timer to refresh
                                    if (Status === 3) {
                                        this.SetVoteResultTimer();
                                    } else if (this.resultsPolling) {
                                        clearInterval(this.resultsPolling);
                                    }
                                    this.populateResolutionData();
                                    setTimeout(() => {
                                        this.setState({
                                            buttonGroupDisable: false
                                        });
                                    }, 250);
                                }
                                else
                                    errors["Resolution"] = "Error: Unable to open contest"
                            });
                        }

                        if (Status === 6 || (Status === 5 && !this.context.switches.hybrid && selectedResolutionTypeId !== 3)) {
                            const idata = { ContestId: parseInt(this.state.contestID), VMeetingId: this.VmeetingId, UserId: this.userId };
                            await axios.post("VMFinaliseContestResult", idata).then(response => {
                                const data = response.data;
                                if (data != null && data !== "") {
                                    this.setState({
                                        status: Status
                                    });
                                    NotificationManager.success("Vote Finalised", "Vote Administration", 2000);
                                    setTimeout(() => {
                                        this.setState({
                                            buttonGroupDisable: false
                                        });
                                    }, 250);
                                }
                                else {
                                    errors["Resolution"] = "Error: Unable to finalise vote result"
                                }

                                this.populateResolutionData();
                            });
                        }


                        if (Status === 7) {
                            const idata = { ContestID: parseInt(this.state.contestID), VMID: this.VmeetingId, UserID: this.userId };
                            await axios.post("VMPublishResolutionVoteResult", idata).then(response => {
                                const data = response.data;
                                if (data != null && data !== "") {
                                    this.setState({
                                        status: Status
                                    });
                                    NotificationManager.success(successMsg, "Vote Administration", 2000);
                                    setTimeout(() => {
                                        this.setState({
                                            buttonGroupDisable: false
                                        });
                                    }, 250);
                                }
                                else
                                    errors["Resolution"] = "Error: Unable to publish vote result"
                                this.populateResolutionData();
                            });
                        }
                    }
                });
            }
        }
        else {
            errors["Resolution"] = "*Current contest is " + this.state.resText + " with " + this.ResolutionStatus(this.state.status) + " status";
            this.setState({
                errors: errors
            });
            setTimeout(() => {
                this.setState({
                    buttonGroupDisable: false
                });
            }, 250);
        }


    }

    updateContestList = async (contestUpdateIntent) => {
        //multiple contest update
        var errors = {};
        var Status = 0;
        var successMsg = '';

        this.setState({
            successMsg: '',
            errors: errors,
            buttonGroupDisable: true
        });
        if (this.contestString === null || this.contestString === '') {
            errors["Resolution"] = "*Please select at least one contest";
            this.setState({ errors: errors });
        }

        switch (contestUpdateIntent) {
            case "Display":
                Status = 2;
                successMsg = "Contests displayed successfully"
                break;
            case "Open":
                Status = 3;
                successMsg = "Contests opened successfully"
                break;
            case "Pause":
                Status = 4;
                successMsg = "Contests paused successfully"
                break;
            case "Close":
                Status = 5;
                successMsg = "Contests closed successfully"
                break;
            case "Finalise":
                Status = 6;
                successMsg = "Contests finalised successfully"
                break;
            case "Publish":
                Status = 7;
                successMsg = "Contests published successfully"
                break;
            default:
                Status = 0;
        }

        if (Status > 0) {
            if (parseInt(this.state.contestID) > 0 && this.userId > 0 && this.VmeetingId > 0) {
                const idata = { ContestList: this.contestString, Status: Status, UserID: this.userId, VMID: this.VmeetingId };
                await axios.post("VMUpdateContestList", idata).then(async response => {
                    const resdata = response;
                    if (resdata && resdata.data.length > 0 && resdata.data[0].contestID > 0) {

                        if (Status === 1 || Status === 2 || Status === 4 || (Status === 5 && this.context.switches.hybrid)) {

                            this.setState({
                                status: Status
                            });
                            NotificationManager.success(successMsg, "Vote Administration", 2000);
                            this.populateResolutionData();
                            setTimeout(() => {
                                this.setState({
                                    buttonGroupDisable: false
                                });
                            }, 250);
                        }

                        if (Status === 3) {  //Phil W CHECK THIS IS CORRECT
                            const idata = { ContestID: parseInt(this.state.contestID), VMID: this.VmeetingId, UserID: this.userId };
                            await axios.post("VMOpenResolutionVoteResult", idata).then(response => {
                                const data = response.data;
                                if (data != null && data !== "") {
                                    this.setState({
                                        status: Status,
                                        liveCount: data
                                    });
                                    this.SetVoteResultTimer();
                                    NotificationManager.success(successMsg, "Vote Administration", 2000);
                                    this.populateResolutionData();
                                    setTimeout(() => {
                                        this.setState({
                                            buttonGroupDisable: false
                                        });
                                    }, 250);
                                }
                                else
                                    errors["Resolution"] = "Error: Unable to open contest"
                            });
                        }


                        if (Status === 6 || (Status === 5 && !this.context.switches.hybrid)) {
                            //finalise list function VMFinaliseContestResultList
                            const idata = { ContestList: this.contestString, VMID: this.VmeetingId, UserID: this.userId };
                            await axios.post("VMFinaliseContestResultList", idata).then(response => {
                                const data = response.data;
                                if (data != null && data !== "") {
                                    this.setState({
                                        status: Status
                                    });
                                    NotificationManager.success(successMsg, "Vote Administration", 2000);
                                    setTimeout(() => {
                                        this.setState({
                                            buttonGroupDisable: false
                                        });
                                    }, 250);
                                }
                                else
                                    errors["Resolution"] = "Error: Unable to publish vote result"
                                this.populateResolutionData();
                            });

                        }


                        if (Status === 7) {
                            const idata = { ContestList: this.contestString, VMID: this.VmeetingId, UserID: this.userId };
                            await axios.post("VMPublishContestResultList", idata).then(response => {
                                const data = response.data;
                                if (data != null && data !== "") {
                                    this.setState({
                                        status: Status
                                    });
                                    NotificationManager.success(successMsg, "Vote Administration", 2000);
                                    setTimeout(() => {
                                        this.setState({
                                            buttonGroupDisable: false
                                        });
                                    }, 250);
                                }
                                else
                                    errors["Resolution"] = "Error: Unable to publish vote result"
                                this.populateResolutionData();
                            });
                        }
                    }
                });
            }
            this.setState({
                multiSelectStatus: Status === 5 && !this.context.switches.hybrid ? 6 : Status
            })
        }
        else {
            errors["Resolution"] = "*Current contest is " + this.state.resText + " with " + this.ResolutionStatus(this.state.status) + " status";
            this.setState({ errors: errors });
            setTimeout(() => {
                this.setState({
                    buttonGroupDisable: false
                });
            }, 250);
        }

    }

    resetResolution = (value) => {
        let errors = {};
        this.setState({
            successMsg: '',
            errors: errors
        });


        if (this.state.contestID <= 0) {
            errors["Resolution"] = "*Please select item";
            this.setState({
                errors: errors,
                buttonGroupDisable: false
            });
        }
        else if (!value) {
            errors["Resolution"] = "*Please enter a reason for resetting the item";
            this.setState({
                errors: errors,
                buttonGroupDisable: false
            });
            return;
        }
        if (parseInt(this.state.contestID) > 0 && this.userId > 0 && this.VmeetingId > 0) {
            const idata = { ContestID: this.state.contestID, VMID: this.VmeetingId, UserID: this.userId, ResetReslReason: value };

            axios.post("VMResetResolution", idata).then(response => {
                const resdata = response;

                if (resdata && resdata.data.length > 0 && resdata.data[0].contestID > 0) {
                    this.populateResolutionData();
                    this.setState({
                        //resolutions: resdata.data,
                        status: 1,
                        resText: '',
                        contestID: 0
                    });
                    NotificationManager.success('Item reset successfully', "Vote Administration", 2000);
                    setTimeout(() => {
                        this.setState({
                            buttonGroupDisable: false
                        });
                    }, 250);
                }
            });
        }

    }

    ResolutionStatus = (status) => {
        const statusDesc = [
            "pending",
            "display",
            "open",
            "paused",
            "closed",
            "finalised",
            "published"
        ];
        if (status >= 0 && status <= 7) return statusDesc[status];
        else return "ERROR"
    }

    SetVoteResultTimer = () => {
        clearInterval(this.resultsPolling);
        this.GetVoteResult();
        this.resultsPolling = setInterval(this.GetVoteResult, 2000);
    }

    GetVoteResult = () => {
        if (parseInt(this.state.contestID) > 0 && this.userId > 0 && this.VmeetingId > 0) {
            const idata = { VMID: this.VmeetingId, ContestID: parseInt(this.state.contestID), UserID: this.userId };

            axios.post("VMOpenResolutionVoteResult", idata).then(response => {
                const data = response.data;
                if (data != null && data !== "")
                    this.setState({
                        liveCount: data
                    });
            });
        }
    }

    tabChange = (e) => {
        if (e === 0) {
            this.getChairProxyVote();
        }
        if (e === 1) {
            this.setState({
                successMsg: '',
                tabOrder: 1,
                errors: [],
                proxyPollCard: [],
            })
        }
    }

    contestTypeChange = (index) => {
        // Switched between onRequest and Continous so find a contest to focus on
        let continousMatch = index === 0 ? false : true;

        // Does the page we are on match the contest we are focussed on? If so exit
        var contest = this.state.resolutions.filter(res => res.contestId === this.state.contestID && res.isContinuous === continousMatch);
        if (contest.length === 1) {
            return;
        }

        // Ok, so we have switched to a new tab - first see if a contest is open and if so focus on that
        contest = this.state.resolutions.filter(res => res.isContinuous === continousMatch && res.contestStatusId === 3);
        if (contest.length > 0) {
            this.setState({
                contestID: contest[0].contestID
            })
            if (contest[0].contestStatusId === 3) {
                this.SetVoteResultTimer();
            } else if (this.resultsPolling) {
                clearInterval(this.resultsPolling);
            }
            return;
        }

        // Ok, so nothing open so just pick the first one if there is one
        contest = this.state.resolutions.filter(res => res.isContinuous === continousMatch);
        if (contest.length > 0) {
            this.setState({
                contestID: contest[0].contestID
            })
            return;
        }
        //What if there isn't a contest in this set? Reset to 0 as it's breaking as is
        else {
            this.setState({
                resText: '',
                contestID: 0,
                status: 0,
                resultBlock: []
            });
        }

    }

    multiSelectContest = (e, contestID, statusId) => {
        e.stopPropagation() //prevents the click from acting on the x and the div causing a double trigger
        this.setState({
            voteLimitReached: false
        })
        const value = !!!this.state.selectedContests[contestID];
        let oldstate = this.state.selectedContests;
        oldstate[contestID] = value

        this.contestString = Object.keys(oldstate).filter(key => oldstate[key] === true).toString();
        if (this.contestString === '') {
            this.setState({
                selectedContests: oldstate,
                multiSelectStatus: null
            })
        } else {
            this.setState({
                selectedContests: oldstate,
                multiSelectStatus: statusId
            })
        }
    }

    selectAllButton = () => {
        let isHybrid = this.context.switches.hybrid;
        return (
            <Dropdown>
                <Dropdown.Toggle variant="success" id="dropdown-basic">
                    Select all...
                </Dropdown.Toggle>

                <Dropdown.Menu>
                    <Dropdown.Item role="button" onClick={() => this.selectAllContinuousContests(1)}>New questions</Dropdown.Item>
                    <Dropdown.Item role="button" onClick={() => this.selectAllContinuousContests(3)}>Open questions</Dropdown.Item>
                    <Dropdown.Item role="button" onClick={() => this.selectAllContinuousContests(4)}>Paused questions</Dropdown.Item>
                    <Dropdown.Item role="button" onClick={() => this.selectAllContinuousContests(isHybrid ? 5 : 6)}>Closed questions</Dropdown.Item>
                    {isHybrid &&
                        <Dropdown.Item role="button" onClick={() => this.selectAllContinuousContests(6)}>Finalised questions</Dropdown.Item>
                    }
                </Dropdown.Menu>
            </Dropdown>
        )
    }

    selectAllContinuousContests = (multiSelectStatus) => {
        this.setState({
            multiSelectContestsActive: true,
            multiSelectStatus: null,
            selectedContests: {}
        })
        this.contestString = ''
        let contestList = this.state.resolutions.filter(res => res.isEnabled && res.contestTypeId === 1 && res.isContinuous && res.contestStatusId === multiSelectStatus)
        let selectedContests = {}
        if (contestList.length > 0) {
            contestList.forEach(res => {
                selectedContests[res.contestId] = true;
                this.contestString = Object.keys(selectedContests).filter(key => selectedContests[key] === true).toString();
            })

            let contest = this.state.resolutions.filter(res => res.isContinuous && res.contestStatusId === multiSelectStatus);
            if (contest.length > 0) {
                this.setState({
                    contestID: contest[0].contestID
                })
                if (multiSelectStatus === 3) {
                    this.SetVoteResultTimer();
                } else if (this.resultsPolling) {
                    clearInterval(this.resultsPolling);
                }
            }

            this.setState({
                multiSelectStatus
            })
        }
        this.setState({
            selectedContests
        })

    }

    inRoomValidation = (value) => {
        this.setState({
            inRoomValidation: value
        })
    }

    displayRightPanel = () => {
        //Common right panel display and control elements, excluding primary control buttons as differ on-request/continuous
        let liveCount = this.state.liveCount;

        let selectedResolution = this.state.resolutions.filter(res => res.contestId === this.state.contestID);
        if (selectedResolution.length > 0)
            selectedResolution = selectedResolution[0];
        let buttonGroupDisable = this.state.buttonGroupDisable;

        return <Fragment>
            <div className="error-message">{this.state.errors.Resolution ? this.state.errors.Resolution : ''}</div>
            <div >
                <div className="errorMsg">{this.state.errors.ResolutionText}</div>
            </div>
            {this.state.contestID === selectedResolution.contestID ? <ResolutionHeading resolution={selectedResolution} /> : "Please select a vote item on the left"}

            {this.state.contestID === selectedResolution.contestID &&
                <Tabs>
                    <TabList>
                        <Tab>Item admin</Tab>
                        {this.context.isAuthorized('VoteAdmin', 'Write')
                            && this.state.contestID === selectedResolution.contestID
                            && selectedResolution.status >= 3
                            && selectedResolution.status <= 5
                            && selectedResolution.contestTypeId !== 3
                            && this.context.switches.hybrid &&
                            <Tab>Add aggregate data</Tab>
                        }
                        {this.context.isGlobalAuthorized('VoteAdmin', 'Write') &&
                            <Tab>Active users</Tab>
                        }
                    </TabList>
                    <TabPanel>
                        {((this.state.contestID > 0 && selectedResolution.status === 3) || (this.state.contestID > 0 && selectedResolution.status === 4)) &&
                            <div id="openResDashboardb">
                                <OpenResolution resolution={selectedResolution} liveCount={liveCount} />
                                {(selectedResolution.enableShowOfHands || selectedResolution.enableRealTimeResults) &&
                                    <ActiveCounted voteAdmin contestId={selectedResolution.contestID} publishTypeId={selectedResolution.publishTypeId} />
                                }
                                {selectedResolution.enableShowOfHands && <ShowOfHands validation={this.inRoomValidation} status={selectedResolution.status} contestId={selectedResolution.contestID} />}
                            </div>
                        }

                        {this.state.contestID > 0 && selectedResolution.status === 5 && selectedResolution.contestTypeId === 1 &&
                            <div id="closeResDashboardb">
                                <ClosedResolution voteAdmin resolutionId={this.state.contestID} publishTypeId={selectedResolution.publishTypeId} />
                                {selectedResolution.enableShowOfHands && <ShowOfHands validation={this.inRoomValidation} status={selectedResolution.status} contestId={selectedResolution.contestID} />}
                            </div>
                        }
                        {this.state.contestID > 0 && selectedResolution.status > 5 && selectedResolution.contestTypeId === 1 &&
                            <div id="closeResDashboardb">
                                <FinalisedResolution voteAdmin resolutionStatus={selectedResolution.status} enableShowOfHands={selectedResolution.enableShowOfHands} publishTypeId={selectedResolution.publishTypeId} resolutionId={selectedResolution.contestID} />
                                {selectedResolution.status >= 5 && selectedResolution.status < 7 && selectedResolution.enableShowOfHands &&
                                    <PublishShowOfHands contestId={this.state.contestID} populateResolutionData={this.populateResolutionData} />
                                }
                            </div>
                        }
                        {this.state.contestID > 0 && selectedResolution.status === 5 && selectedResolution.contestTypeId === 2 &&
                            <div id="closeResDashboardb">
                                <ClosedXvote
                                    voteAdmin
                                    votingFor={selectedResolution.votingFor}
                                    resolutionId={this.state.contestID}
                                    publishTypeId={selectedResolution.publishTypeId}
                                />
                            </div>
                        }
                        {this.state.contestID > 0 && selectedResolution.status > 5 && selectedResolution.contestTypeId === 2 &&
                            <div id="closeResDashboardb">
                                <FinalisedXvote
                                    voteAdmin
                                    votingFor={selectedResolution.votingFor}
                                    resolutionId={this.state.contestID}
                                    publishTypeId={selectedResolution.publishTypeId}
                                />
                            </div>
                        }

                        {this.state.contestID > 0 && selectedResolution.status === 5 && selectedResolution.contestTypeId === 3 &&
                            <div id="closeResDashboardb">
                                <ClosedPreferentialVote
                                    contest={selectedResolution}
                                    votingFor={selectedResolution.votingFor}
                                    resolutionId={this.state.contestID}
                                    populateResolutionData={this.populateResolutionData} />
                            </div>
                        }

                        {this.state.contestID > 0 && selectedResolution.status > 5 && selectedResolution.contestTypeId === 3 &&
                            <div id="closeResDashboardb">
                                <FinalisedPreferentialVote votingFor={selectedResolution.votingFor} contest={selectedResolution} resolutionId={this.state.contestID} />
                            </div>
                        }
                    </TabPanel>
                    {this.context.isAuthorized('VoteAdmin', 'Write')
                        && this.state.contestID === selectedResolution.contestID
                        && selectedResolution.status >= 3
                        && selectedResolution.status <= 5
                        && selectedResolution.contestTypeId !== 3
                        && this.context.switches.hybrid &&
                        <TabPanel>
                            <AggregateResult data={selectedResolution} />
                        </TabPanel>
                    }
                    {this.context.isGlobalAuthorized('VoteAdmin', 'Write') &&
                        <TabPanel>
                            <DisplayQuorumData contestId={selectedResolution.contestID} />
                        </TabPanel>
                    }
                </Tabs>
            }
            <div className="row">
                <hr />
            </div>
            {buttonGroupDisable && <VoteLoader />}
        </Fragment>
    }

    multiContestControls = () => {
        //multi select contest controls
        let selectedResolution = this.state.resolutions.filter(res => res.contestId === this.state.contestID);
        if (selectedResolution.length > 0)
            selectedResolution = selectedResolution[0];

        let buttonGroupDisable = this.state.buttonGroupDisable || !this.contestString?.length > 0;

        let pvProtection = this.state.multiSelectContestsActive && this.checkMultiSelectForPreferenceVote() && this.context.switches.hybrid;
        let pvCloseProtection = this.state.multiSelectContestsActive && this.checkMultiSelectForPreferenceVote() && !this.context.switches.hybrid;
        let continuousCount = this.state.resolutions.filter(res => res.isContinuous && res.isEnabled).length;
        let multiSelectedCount = () => {
            let count = 0;
            if (Object.keys(this.state.selectedContests).length > 0) {
                for (const [key, value] of Object.entries(this.state.selectedContests)) {
                    if (value) count += 1
                }
            }
            return count;
        }


        if (this.state.multiSelectContestsActive) {
            return <div className={`MultiContestBox ${!!this.state.multiSelectContestsActive ? 'buttonsActive' : 'buttonsInActive'}`}>
                <h3>Multi-item Controls ({multiSelectedCount()}/{continuousCount})</h3>
                <div className="row btnPadding">
                    <div className="voteControlButton active">
                        <button
                            disabled={
                                !buttonGroupDisable
                                    && this.state.multiSelectStatus
                                    && (this.state.multiSelectStatus < 3
                                        || this.state.multiSelectStatus === 5
                                        || (!this.context.switches.hybrid
                                            && selectedResolution.status === 6)
                                    )
                                    ? false : true}
                            className="btn btnOpen" id="btnOpen"
                            onClick={() => this.updateContestList("Open")} >
                            {this.state.multiSelectStatus === 5 || (!this.context.switches.hybrid && selectedResolution.status === 6) ? 'Re-open' : 'Open'}
                        </button>
                    </div>
                    <div className="voteControlButton active">
                        <button
                            disabled={!buttonGroupDisable && this.state.multiSelectStatus && (this.state.multiSelectStatus === 3 || this.state.multiSelectStatus === 4) ? false : true}
                            className="btn btnOpen" id="btnOpen"
                            onClick={() => this.state.multiSelectStatus === 3 ? this.updateContestList("Pause") : this.updateContestList("Open")} >
                            {this.state.multiSelectStatus === 4 ? 'Resume' : 'Pause'}
                        </button>
                    </div>
                    <div className="voteControlButton active">
                        <button
                            disabled={!buttonGroupDisable && !pvCloseProtection && (this.state.multiSelectStatus === 3 || this.state.multiSelectStatus === 4) ? false : true}
                            className="btn btnClose" id="btnClose"
                            onClick={() => this.updateContestList("Close")} >
                            Close
                        </button>
                    </div>
                    <div className={`voteControlButton ${this.context.switches.hybrid ? 'active' : 'inactive d-none'}`}>
                        <button
                            disabled={!buttonGroupDisable && !pvProtection && this.state.multiSelectStatus === 5 ? false : true}
                            className="btn btnClose" id="btnClose"
                            onClick={() => this.updateContestList("Finalise")} >
                            Finalise
                        </button>
                    </div>
                    <div className="voteControlButton active">
                        <button
                            disabled={!buttonGroupDisable && this.state.multiSelectStatus === 6 ? false : true}
                            className="btn btnPublish" id="btnPublish"
                            onClick={() => this.updateContestList("Publish")} >
                            Publish
                        </button>
                    </div>
                </div>
                {(((this.state.multiSelectStatus === 3 || this.state.multiSelectStatus === 4) && pvCloseProtection) || (this.state.multiSelectStatus === 5 && pvProtection)) && <div className="alert alert-warning">Preferential votes must be finalised individually</div>}
            </div>
        }
    }

    checkMultiSelectForPreferenceVote = () => {
        let selectedContests = this.state.selectedContests;
        let contestList = this.state.resolutions;
        var response = false;

        if (selectedContests && contestList.length) {
            for (const [key, value] of Object.entries(selectedContests)) {
                if (value) {
                    let search = contestList.find(contest => contest.contestID === parseInt(key))
                    if (search) {
                        if (search.contestTypeId === 3) {
                            response = true
                        }
                    }

                }
            }
        }

        return response;
    }



    render() {

        let selectedResolution = this.state.resolutions.filter(res => res.contestId === this.state.contestID);
        if (selectedResolution.length > 0)
            selectedResolution = selectedResolution[0];

        let openResolution = this.state.resolutions.filter(res => res.isContinuous === false && (res.status === 3 || res.status === 4));
        var openResolutionLock = false;
        if (openResolution.length > 0) {
            openResolutionLock = true;
        }

        let displayedResolution = this.state.resolutions.filter(res => res.status === 2);
        var displayedResolutionLock = false;
        if (displayedResolution.length > 0) {
            displayedResolutionLock = true;
        }

        let continuousCount = this.state.resolutions.filter(res => res.isContinuous && res.isEnabled).length;
        let continuousOpenCount = this.state.resolutions.filter(res => res.isContinuous === true && (res.status === 3 || res.status === 4)).length;
        let requestCount = this.state.resolutions.filter(res => res.isContinuous === false && res.isEnabled).length;
        let requestOpenCount = this.state.resolutions.filter(res => res.isContinuous === false && (res.status === 3 || res.status === 4)).length;

        let buttonGroupDisable = this.state.buttonGroupDisable;

        let rightPanel = this.displayRightPanel();
        let multiContestControls = this.multiContestControls();
        const { isAuthorized } = this.context;
        return (
            <div >
                <Tabs defaultIndex={this.state.tabOrder} onSelect={this.tabChange} >
                    <TabList>
                        {isAuthorized('VoteAdmin', 'Read') && <Tab>Vote Administration</Tab>}
                        {isAuthorized('QuickPoll', 'Read') && <Tab>Quick Poll Administration</Tab>}
                        {isAuthorized('Premeeting', 'Read') && <Tab disabled={this.context.switches.hasPreMeetingData ? false : true}>Pre-meeting Instructions</Tab>}
                    </TabList>

                    {
                        isAuthorized('VoteAdmin', 'Read') &&
                        <TabPanel  >
                            <Tabs defaultIndex={this.state.subTabOrder} onSelect={index => this.contestTypeChange(index)} >
                                {this.state.chairProxyVoteAlert && <Fragment>
                                    <div className="ChairProxyVoteAlert"> {this.context.switches.hasPreMeetingData ?
                                        'Chair Proxy Votes have not been cast.' :
                                        'Premeeting data is disabled but premeeting data exists in the table'}
                                    </div>
                                </Fragment>
                                }
                                <h2 className="chat-heading-am">Items</h2>
                                <TabList>
                                    <Tab>On Request Voting ({requestOpenCount}/{requestCount})</Tab>
                                    <Tab disabled={!(continuousCount && continuousCount !== 0)}>Continuous Voting ({continuousOpenCount}/{continuousCount})</Tab>
                                </TabList>
                                <TabPanel>
                                    <Container fluid>
                                        <div className="row">
                                            <div name="Resolution tab" className='col-5' id='resListColumn'>
                                                {this.state.resolutions.filter(a => !a.isContinuous).map((res) => {
                                                    if (res.isEnabled) {
                                                        return (
                                                            <div
                                                                tabIndex="0"
                                                                className={res.contestId === this.state.contestID ? "resolutionBox active" : "resolutionBox"}
                                                                id={res.contestId}
                                                                key={res.contestId}
                                                                onClick={() => this.ShowResolution(res.contestId)}
                                                            >
                                                                <ResolutionHeading resolution={res} />
                                                            </div>
                                                        )
                                                    }
                                                    else
                                                        return null;
                                                }
                                                )}
                                            </div>

                                            <div name="Resolution tab" className='col-7'>
                                                <div className="ResolutionBox">

                                                    {rightPanel}

                                                    <div className="row">
                                                        {selectedResolution &&
                                                            <VoteControls
                                                                inRoomValidation={this.state.inRoomValidation}
                                                                selectedResolution={selectedResolution}
                                                                buttonGroupDisable={buttonGroupDisable}
                                                                updateResolution={this.updateResolution}
                                                                resetResolution={this.resetResolution}
                                                                openResolutionLock={openResolutionLock}
                                                                displayedResolutionLock={displayedResolutionLock}
                                                            />
                                                        }
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </Container>
                                    <div className="successMsg">{this.state.successMsg}</div>

                                </TabPanel>

                                <TabPanel >
                                    <Container fluid>
                                        <div className="row">
                                            <div name="Resolution tab" className='col-5' id='resListColumn'>
                                                <div className="ResolutionListButtonHolder d-flex">
                                                    {this.selectAllButton()}
                                                    <button
                                                        className="btn btn-primary ms-auto"
                                                        onClick={() => this.setState({
                                                            multiSelectContestsActive: !this.state.multiSelectContestsActive,
                                                            multiSelectStatus: null,
                                                            selectedContests: {}
                                                        })}
                                                    >{this.state.multiSelectContestsActive ? "Dis" : "En"}able multiselect</button>

                                                </div>
                                                {this.state.resolutions.filter(a => a.isContinuous).map((res) => {

                                                    if (res.isEnabled) {
                                                        return (
                                                            <div tabIndex="0" className={res.contestId === this.state.contestID ? "resolutionBox active" : "resolutionBox"} id={res.contestId} key={res.contestId}
                                                                onClick={() => this.ShowResolution(res.contestId)}
                                                            >
                                                                <ResolutionHeading
                                                                    resolution={res}
                                                                    multiSelectContestsActive={this.state.multiSelectContestsActive}
                                                                    multiSelectContest={this.multiSelectContest}
                                                                    selectedContests={this.state.selectedContests}
                                                                    multiSelectStatus={this.state.multiSelectStatus} />
                                                            </div>
                                                        )
                                                    }
                                                    else
                                                        return null;
                                                }
                                                )}
                                            </div>

                                            <div name="Resolution tab" className='col-7'>
                                                <div className="ResolutionBox">
                                                    {multiContestControls}
                                                    {rightPanel}
                                                    <div className={`row ${this.state.multiSelectContestsActive && !!this.state.multiSelectStatus ? 'buttonsInActive' : 'buttonsActive'}`}>
                                                        {selectedResolution &&
                                                            <VoteControls
                                                                inRoomValidation={this.state.inRoomValidation}
                                                                selectedResolution={selectedResolution}
                                                                buttonGroupDisable={buttonGroupDisable}
                                                                updateResolution={this.updateResolution}
                                                                resetResolution={this.resetResolution}
                                                            />
                                                        }
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </Container>
                                    <div className="successMsg">{this.state.successMsg}</div>
                                </TabPanel>
                            </Tabs>
                        </TabPanel >
                    }
                    {
                        isAuthorized('QuickPoll', 'Read') &&
                        <TabPanel >
                            <QuickPollAdmin authorize={isAuthorized('QuickPoll', 'Write')} />
                        </TabPanel >
                    }
                    {
                        isAuthorized('Premeeting', 'Read') &&
                        <TabPanel >
                            <ProxyPollCard authorize={isAuthorized('Premeeting', 'Write')} />
                        </TabPanel >
                    }


                </Tabs>
            </div>
        );
    }
}



VoteAdministration.contextType = AdminContext;

function VoteControls(props) {
    //single contest vote control buttons, extracted as duplicated for indentical functionality.
    //props requires: selected resolution, button group disable, function updateResolution, function resetResolution, inRoomValidation
    var context = useContext(AdminContext);
    var status = props.selectedResolution.status;
    var type = props.selectedResolution.contestTypeId
    var displayLock = !props.selectedResolution.isContinuous && status !== 2 && props.displayedResolutionLock;
    var openLock = !props.selectedResolution.isContinuous && status !== 2 && props.openResolutionLock;
    var activeContestLock = displayLock || openLock;
    const authorize = context.isAuthorized('VoteAdmin', 'Write');

    const isVisible = (buttonStatusId) => {
        //Display: 2, Open: 3, Pause: 4, Close: 5, Finalise: 6, Publish: 7
        var response = false;
        if (
            (buttonStatusId === 1 && status && context.isAuthorized('VoteReset', 'Read')) ||
            (buttonStatusId === 2 && status < 3) ||
            (buttonStatusId === 3 && (status < 6 || (!context.switches.hybrid && status === 6))) ||
            (buttonStatusId === 4 && (status > 2 && status < 5)) ||
            (buttonStatusId === 5 && (status > 2 && status < 6)) ||
            (buttonStatusId === 6 && (context.switches.hybrid && status > 4 && status < 7)) ||
            (buttonStatusId === 7 && (status > 4 && status < 7 && !props.selectedResolution.enableShowOfHands))
        ) { response = true; }
        return response;
    }

    const isDisabled = (buttonStatusId) => {
        //Reset: 1, Display: 2, Open: 3, Pause: 4, Close: 5, Finalise: 6, Publish: 7
        var response = true;
        if (props.buttonGroupDisable || !authorize) {
            response = true;
        } else if (buttonStatusId === 1 && context.isAuthorized('VoteReset', 'Write') && status && status !== 3 && status !== 4) {
             response = false;
        } else if (
            (buttonStatusId === 2 && (status === 1 && !activeContestLock)) ||
            (buttonStatusId === 3 && !activeContestLock && (status < 3 || status === 5 || (!context.switches.hybrid && status === 6))) ||
            (buttonStatusId === 4 && (status === 3 || status === 4)) ||
            (buttonStatusId === 5 && (status === 3 || status === 4)) ||
            (buttonStatusId === 6 && status === 5 && type !== 3 && (props.selectedResolution.enableShowOfHands ? props.selectedResolution.enableShowOfHands === props.inRoomValidation : true)) ||
            (buttonStatusId === 7 && (!props.selectedResolution.enableShowOfHands && status === 6)
            )) { response = false; }
        return response;
    }

    return (
        <Fragment>
            <div className="row col-lg btnPadding">
                {!props.selectedResolution.isContinuous &&
                    <div className={`col-lg-auto voteControlButton ${isVisible(2) ? 'active' : 'inactive d-none'}`}><button disabled={isDisabled(2)} className="btn btnDisplay" id="btnDisplay" onClick={() => props.updateResolution("Display")} >Display</button></div>
                }
                <div className={`col-lg-auto voteControlButton ${isVisible(3) ? 'active' : 'inactive d-none'}`}><button disabled={isDisabled(3)} className="btn btnOpen" id="btnOpen" onClick={() => props.updateResolution("Open")}>{status === 5 || (!context.switches.hybrid && status === 6) ? 'Re-open' : 'Open'}</button></div>
                <div className={`col-lg-auto voteControlButton ${isVisible(4) ? 'active' : 'inactive d-none'}`}><button disabled={isDisabled(4)} className="btn btnOpen" onClick={() => status === 3 ? props.updateResolution("Pause") : props.updateResolution("Open")}>{status === 4 ? 'Resume' : 'Pause'}</button></div>
                <div className={`col-lg-auto voteControlButton ${isVisible(5) ? 'active' : 'inactive d-none'}`}><button disabled={isDisabled(5)} className="btn btnClose" id="btnClose" onClick={() => props.updateResolution("Close")}>Close</button></div>
                <div className={`col-lg-auto voteControlButton ${isVisible(6) ? 'active' : 'inactive d-none'}`}><button disabled={isDisabled(6)} className="btn btnClose" onClick={() => props.updateResolution("Finalise")}>Finalise</button></div>
                <div className={`col-lg-auto voteControlButton ${isVisible(7) ? 'active' : 'inactive d-none'}`}><button disabled={isDisabled(7)} className="btn btnPublish" id="btnPublish" onClick={() => props.updateResolution("Publish")}>Publish</button></div>
            </div>
            <div className={`voteControlButton ${isVisible(1) ? 'active col-lg-3' : 'inactive d-none'}`} ><button disabled={isDisabled(1)} className="btn btnReset" id="btnReset" onClick={() => swal("Please enter reason for reset here:", { content: "input", }).then((value) => { props.resetResolution(value) })}>Reset</button></div>
        </Fragment>
    );
}