import React, { Component } from 'react';
import axios from 'axios';
import { NotificationManager } from 'react-notifications';
export class Broadcast extends Component {
    constructor(props) {
        super(props)
        this.state = {
            value: '',
            message: ''
        };

        this.handleChange = this.handleChange.bind(this);
        this.broadcastMessage = this.broadcastMessage.bind(this);
    }

    handleChange(event) {
        this.setState({
            value: event.target.value,
            message: ''
        });
    }

    broadcastMessage() {
        const message = this.state.value;
        const options = {
            url: 'Adm2HubBroadcast',
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            data: JSON.stringify(message)
        };
        axios(options).catch(function (error) {
            NotificationManager.error("Error trying to contact server", "BroadCast Message " + error.message, 4000);
            clearInterval(this.state.countTimer);
        })

        this.setState({
            value: '',
            message: "Message Sent"
        });
        //event.preventDefault();
    }

    componentDidMount() {
    }

    componentWillUnmount() {
    }

    render() {
        return (
            <div className="form-group">
                <label htmlFor="broadcastMessage">
                    Message to broadcast to ALL attendees:
                </label>
                <div>
                    <input className="form-control" id="broadcastMessage" placeholder='Enter your message to broadcast here' type="text" value={this.state.value} onChange={this.handleChange} />
                </div>
                <div>
                    <button className="btn btn-primary" disabled={(!this.props.isAuthorized || this.state.value === '') ? true : false} type="submit" onClick={this.broadcastMessage} value="Broadcast">BROADCAST</button>
                </div>
                <div>
                    {this.state.message}
                </div>
            </div>
        )
    }
}

export default Broadcast