import React, { useState, useContext } from 'react';
import UserPermissionContext from '../UserPermissionContext';
import { NotificationManager } from 'react-notifications';

const AdminAddUser = () => {
    //State
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [isSubmitting, setisSubmitting] = useState(false);
    //Context
    const Context = useContext(UserPermissionContext);

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (isValidInputs()===false) {
            NotificationManager.error("Please enter valid details", "Add User", 4000);
            return;
        }
        setisSubmitting(true);
        let request = {
            Name: name,
            EmailAddress: email?.replace(/\s+/g, "")
        }
        const response = await Context.InviteUser(request);
        const { statusID } = response.data;
        setisSubmitting(false);
        setName("");
        setEmail("");
        if (statusID === 1 || statusID === 2) {
            const selectedUser = {
                Id: response.data.id,
                ForeName: response.data.foreName,
                EmailAddress: response.data.emailAddress,
                LockoutEnabled: response.data?.lockoutEnabled
            }
            Context.setUser(selectedUser);
            displayNotification(statusID);
        }
        else {
            NotificationManager.error("Sorry, Something went wrong", "Add User", 4000);
        }
    }


    const displayNotification = status => {
        if (status === 1)
            NotificationManager.success("User permissions saved", "Add User", 2000);
        if (status === 2)
            NotificationManager.success("User already exists", "Add User", 4000);
    }

    const isValidInputs = () => {
        let isFormValid = true;
        if (name?.trim() === '' || email?.trim() === '') {
            isFormValid = false;
        }
        return isFormValid;
    }

    return (
        <form autoComplete="off" onSubmit={handleSubmit} autoSave="off">
            <div className="pb-3">
                <label htmlFor="Name" className="form-label">Name</label>
                <input
                    id="Name"
                    name="Name"
                    className="form-control"
                    value={name}
                    required
                    onChange={(e) => setName(e.target.value)}
                />
            </div>
            <div className="pb-3">
                <label htmlFor="Email" className="form-label">Email</label>
                <input
                    type="email"
                    id="Email"
                    name="Email"
                    className="form-control"
                    value={email}
                    required
                    onChange={(e) => setEmail(e.target.value?.trim())}
                />
            </div>
            <button
                type="submit"
                className="btn btn-primary"
                disabled={isSubmitting}
                title="Add user"
            >
                {
                    isSubmitting ?
                        <span className="spinner-border spinner-border-sm" role="sss" aria-hidden="true"></span>
                        :
                        "Add user"
                }
            </button>
        </form>
    )
}
export default AdminAddUser;