import React, { useContext } from 'react';
import { AdminContext } from '../context/AdminContext';


export default function StatusLozenge(props) {
    const { switches } = useContext(AdminContext);

    const values = () => {
        switch (props.status) {
            case 1:
                return ["New", "bg-dark"];
            case 2:
                return ["Displayed", "bg-primary"];
            case 3:
                return ["Open", "bg-success"];
            case 4:
                return ["Paused", "bg-warning text-dark"];
            case 5:
                return ["Closed", "bg-danger"];
            case 6:
                if (switches.hybrid) {
                    return ["Finalised", "bg-secondary"];
                } else {
                    return ["Closed", "bg-danger"];
                }
            case 7:
                return ["Published", "bg-light text-dark"];
            default:
                return ["Error", "bg-dark"];
        }
    }

    return (
        <div>
            {props.status > 1 &&
                <div className={`p-1 badge ${values()[1]}`}>
                    {values()[0]}
                </div>
            }
        </div>
    )
}