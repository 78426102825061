import React, { Component } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { Container } from 'react-bootstrap';
import Switches from './Switches'
import '../Picker.less';
import Contests from '../Contest/Contests';
import Documents from './Documents';
import { Constituencies } from './Constituencies';
import AdminContext from '../context/AdminContext';
import { ContestContextProvider } from '../Contest/Context/ContestContextProvider';
import { ContentAndStyling } from './ContentAndStyling';

export class EventSetup extends Component {

    constructor(props) {
        super(props);
        this.userId = parseInt(sessionStorage.getItem('UserId'));
        this.VmeetingId = parseInt(sessionStorage.getItem('VMId'));
        this.state = {

        };
    }

    componentDidMount() {

    }

    componentWillUnmount() {
        // fix Warning: Can't perform a React state update on an unmounted component
        this.setState = (state, callback) => {
            return;
        };
    }

    render() {
        const { enableConstituencies, enableVoting } = this.context.switches;
        let showConstituencies = enableConstituencies && enableVoting;
        const { isAuthorized } = this.context;
        return (
            <div>
                <div>
                    <Tabs>
                        <TabList>
                            {isAuthorized('Switches', 'Read') && <Tab>Meeting Configuration</Tab>}
                            {isAuthorized('Styling', 'Read') && <Tab>Content and Styling</Tab>}
                            {isAuthorized('Documents', 'Read') && <Tab>Documents</Tab>}
                            {isAuthorized('VoteItems', 'Read') && <Tab>Vote Items</Tab>}
                            {isAuthorized('VoteConst', 'Read') && showConstituencies && <Tab>Constituencies</Tab>}
                        </TabList>

                        {// Meeting Configuration Tab
                            isAuthorized('Switches', 'Read') &&
                            <TabPanel >
                                <Container fluid>
                                    <Switches
                                        VmeetingId={this.VmeetingId}
                                        UserId={this.userId}
                                    />
                                </Container>
                            </TabPanel>}


                        {// Content and Styling Tab
                            isAuthorized('Styling', 'Read') &&
                            <TabPanel >
                                <ContentAndStyling />
                            </TabPanel>
                        }

                        {// Documents Tab
                            isAuthorized('Documents', 'Read') &&
                            <TabPanel>
                                <Container fluid>
                                    <Documents VmeetingId={this.VmeetingId} UserID={this.userId} />
                                </Container>
                            </TabPanel>
                        }

                        {// Contests Tab
                            isAuthorized('VoteItems', 'Read') &&
                            <TabPanel>
                                <Container fluid>
                                    <div id="contestPage">
                                        <ContestContextProvider>
                                            <Contests isAuthorized={isAuthorized('VoteItems', 'Write')} />
                                        </ContestContextProvider>
                                    </div>
                                </Container>
                            </TabPanel>
                        }

                        { // Constituencies Tab
                            (isAuthorized('VoteConst', 'Read') && showConstituencies) &&
                            <TabPanel>
                                <Container fluid>
                                    <div id="constituenciesPage">
                                        <Constituencies
                                            meetingid={sessionStorage.getItem('VMId')}
                                            isAuthorized={isAuthorized('VoteConst', 'Write')}
                                        />
                                    </div>
                                </Container>
                            </TabPanel>
                        }

                    </Tabs>
                </div>


            </div>
        );
    }

}
EventSetup.contextType = AdminContext;