import React, { useContext, useState, useEffect } from 'react';
import { faEllipsisH } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Dropdown } from 'react-bootstrap';
import UserPermissionContext from '../UserPermissionContext';
import swal from 'sweetalert';

export const userActions = {
    PERMISSION: 'Permissions',
    ADDACCESS:'AddAccess',
    DELETE: 'Delete'
}

const AccessList = ({ isLoading, accessList, sectionId, handleClientSelection, handleActionClick }) => {

    //Context//
    const Context = useContext(UserPermissionContext);
    const { selectedUser, defaultClient :selectedClient} = Context;

    //Component state//
    const [activePermission, setActivePermission] = useState(0);


    useEffect(() => {
        setDefaultClient();
    }, [accessList, isLoading])

    /**
     * Event handler to handle cliekt selection
     * */
    const handleClick = (accessItem) => {
        //handle client selection only//
        if (sectionId === 1) {
            Context.setSelectedClient({ value: accessItem.Id, name: accessItem.Name });
            setActivePermission(accessItem.Id);
            handleClientSelection(accessItem.Id)
        }
    }

    /**
     * function will pick first client and client's meetings
     * */
    const setDefaultClient = () => {
        if (sectionId === 1 && accessList?.length > 0) {
            let isSelectedClientExist = accessList.find(x => x.Id == selectedClient?.value);
            const { Id } = accessList[0];          
            const defaultClient = isSelectedClientExist !== undefined ? isSelectedClientExist.Id : Id;
            setActivePermission(defaultClient);
            handleClientSelection(defaultClient);
        }
    }

    /***
     * Event handler to perform user actions.
     * If clicked on permissions, it will navigate to 
     * appropriate permissions tab.
     * If Clicked on Delete, it will delete users permission 
     * from appropriate level
     ***/
    const handleAction = (accessItem, action) => {
        //Navigates user to appropriate permissions tab
        handleActionClick(sectionId, accessItem, action)
    }


    const confirmRemove = (accessItem, action) => {
        swal({
            title: "Remove Permission",
            text: "Are you sure you wish to remove this permission?",
            icon: "warning",
            buttons: true,
            dangerMode: true
        }).then((value) => {
            if (value) {
                handleActionClick(sectionId, accessItem, action)
            }
        })
    }

    
    const renderAddActions = (accessItem) => {
        return (
            <>
                <Dropdown.Item
                    href=""
                    onClick={() => handleAction(accessItem, userActions.ADDACCESS)}
                >
                    Add {sectionId === 1 ? `Client` : `Meeting`} Permissions
                </Dropdown.Item>
            </>
        );
    }

    const renderEditActions = (accessItem) => {
        return (
            <>
                <Dropdown.Item
                    href=""
                    onClick={() => handleAction(accessItem, userActions.PERMISSION)}
                    data-testid={`Edit${sectionId === 1 ? 'Client' : 'Meeting'}Permissions`}
                    >
                    Edit {sectionId === 1 ? `Client` : `Meeting`} Permissions
                </Dropdown.Item>
                <Dropdown.Item
                    href=""
                    className="text-danger"
                    onClick={() => confirmRemove(accessItem, userActions.DELETE)}>
                    Remove {sectionId === 1 ? `Client` : `Meeting`} Permissions
                </Dropdown.Item>
            </>
        );
    }

    /**
     * Component's render functions
     * */
    const renderActions = (accessItem) => {
        return (
            <Dropdown>
                <Dropdown.Toggle variant="transparent" id="dropdown-basic"  data-testid='actiondropdown'>
                    <FontAwesomeIcon
                        icon={faEllipsisH}
                    />
                </Dropdown.Toggle>
                <Dropdown.Menu>
                    {accessItem.Rank ?
                        renderEditActions(accessItem) :
                        renderAddActions(accessItem)}
                </Dropdown.Menu>
            </Dropdown>
        )
    }

    const renderLoader = () => {
        return (
            <div className="d-flex justify-content-center">
                <div className="spinner-border text-primary" role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>
            </div>
        )
    }


    const renderPermissionBadge = (accessItem) => {
        if (sectionId === 1) {
            return accessItem?.Rank ?
                <div className="d-flex flex-column justify-content-center" >
                    <span title={`${selectedUser.ForeName} has client level permission`} className="badge clientBadge">Client</span>
                </div> : null
        }
        if (sectionId === 2) {
            return accessItem?.CustomRank ?
                <div className="d-flex flex-column justify-content-center" >
                    <span title={`${selectedUser.ForeName} has meeting specific permissions`} className="badge bg-warning text-dark">Meeting</span>
                </div> : <div className="d-flex flex-column justify-content-center" >
                    <span title={`${selectedUser.ForeName} inherits permissions to this meeting`} className="badge inheritedBadge">Inherited</span>
                </div>
        }
    }

    const renderClients = () => {
        if (isLoading) {
            return (
                renderLoader()
            )
        }
        return accessList.map(accessItem => {
            return (
                <li className={`list-group-item`} key={accessItem.Id}
                    style={{
                        backgroundColor:
                            (sectionId === 1) && (activePermission === accessItem.Id) ? '#b9e2f5' : ''
                    }}
                >
                    <div className={`d-flex justify-content-between permissionAction`}

                    >
                        <div>
                            <label className={`${sectionId === 1 ? `btn` : ``}`} onClick={() => handleClick(accessItem)}>
                                <p className="h6 text-start">{accessItem.Name}</p>
                            </label>
                        </div>

                        <div className="d-flex justify-content-between align-items-center">
                            {renderPermissionBadge(accessItem)}
                            {
                                //(adminContext.isAuthorized('UserAdmin', 'Write')) ?
                                    renderActions(accessItem)
                                    
                            }
                        </div>
                    </div>
                </li>
            )
        })
    }
    /**
     * Component's render functions end
     * */

    return (
        renderClients()
    )
}
export default AccessList;

