import React, { useContext, useState, useEffect } from 'react';
import { Draggable } from 'react-beautiful-dnd';
import AdminContext from '../../context/AdminContext';
import { InlineLoader } from '../../SecondaryComponents';
import { GetMessageDetailsForComfort, MessageRemoveFromGroup } from './ApiFunctions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { connection } from '../../SignalRController';
import { Tag } from './Tag';
import { TagEditor } from './TagEditor';
import { MessageIconSelector, InboxPermission } from './Constants';


export function Message(props) {
    const { meetingId, userId, isAuthorized, switches } = useContext(AdminContext);
    const { message, messageCount, groupInbox, currentInbox, SetReady, index, summary } = props;
    const [messageDetails, setMessageDetails] = useState();
    const [loading, setLoading] = useState(true);
    const [editing, setEditing] = useState(false);

    const FetchData = async () => {
        let data = await GetMessageDetailsForComfort(meetingId, message.messageId);
        if (data.tagListJson) {
            data.tags = JSON.parse(data?.tagListJson)
        }
        setMessageDetails(data);
        setLoading(false);

        if (data.messageTypeId === 2) {
            SetReady(index, data.ready? true : false);
        } else {
            SetReady(index, true);
        }

    }

    const Remove = async () => {
        await MessageRemoveFromGroup(meetingId, userId, message.messageId);
    }

    useEffect(() => {
        if (!loading) {
            if (messageDetails.messageTypeId === 2) {
                SetReady(index, messageDetails.ready ? true : false);
            } else {
                SetReady(index, true);
            }
        }
    }, [messageCount])

    useEffect(() => {
        //Setup connection trigger for SR
        connection.on("srHubAdm2Adm_ComfortUpdateMessage:" + message.messageId, () => {
            if (!loading) {
                FetchData();
            }
        });
        //Cleanup connection event listener
        return () => { connection.off("srHubAdm2Adm_ComfortUpdateMessage:" + message.messageId) };
    }, [loading])

    useEffect(() => {
        FetchData();
        // fix Warning: Can't perform a React state update on an unmounted component
        return () => { };
    }, [])

    const BeginEdit = () => {
        setEditing(true);
    }

    const SenderName = () => {
        if (messageDetails?.attendeeId === -1) {
            return "Admin Message";
        } else {
            return messageDetails?.userName
        }
    }

    const InitialExpand = () => {
        if (messageDetails?.messageNotes?.length > 0 && summary) {
            //we always want notes expanded in the summary if there is a note
            return true;
        }
        //Otherwise we want single messages that aren't in the CM itself expanded unless they're admin notes that wouldn't have another note attached
        return (messageCount === 1 && currentInbox < 3
            && messageDetails?.attendeeId !== -1)
    }

    const AddHighlight = () => {
        return messageDetails?.messageNotes?.length > 0 || (messageDetails?.tags?.length > 0 && !summary)
    }
    const dragDisabled = currentInbox > 2
        || (!switches.enableComfortMonitor && currentInbox > 1)
        || summary
        || !isAuthorized(InboxPermission[groupInbox], 'Write');        

    return (
        <Draggable
            draggableId={"msg:" + message.messageGroupId + ":" + message.messageId + ":" + groupInbox + ":" + currentInbox}
            isDragDisabled={dragDisabled}
            index={index}
            type="Message">
            {(provided, snapshot) => (
                <div className={`border rounded mt-1 bg-white ${snapshot.isDragging ? 'dragging' : ''}`}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    ref={provided.innerRef}
                >

                    {loading ? <InlineLoader />
                        :
                        <div className="d-flex align-items-start">                            
                            <div className={`${messageDetails?.messageTypeId === 2 && !messageDetails?.complete ? 'rounded bg-brand-tertiary text-light p-1' : ''}`}>
                                <FontAwesomeIcon icon={MessageIconSelector(messageDetails?.messageTypeId, (messageDetails?.ready && !messageDetails?.complete))} />
                            </div>
                            <details className="flex-fill ms-1" open={InitialExpand()}>
                                <summary>
                                    <strong>{SenderName()}</strong>
                                    <br />
                                    {messageDetails?.messageText}</summary>
                                <div className={` rounded p-1 m-1 ${AddHighlight() ? "border bg-highlight" : ""}`}>
                                    {editing ?
                                        <TagEditor
                                            message={message}
                                            groupInbox={groupInbox}
                                            currentInbox={currentInbox}
                                            setEditing={setEditing}
                                            messageDetails={messageDetails} />
                                        :
                                        <div className="d-flex">
                                            <div className="flex-fill">
                                                {messageDetails?.messageNotes?.length > 0
                                                    && <div>{messageDetails.messageNotes ?? ""}</div>
                                                }
                                                {!summary &&
                                                    <div className="d-flex flex-wrap tag-manager rounded">
                                                        {messageDetails?.tags?.length > 0 && messageDetails?.tags?.map(tag =>
                                                            <Tag key={tag.messageTagId} tag={tag} />
                                                        )}
                                                    </div>
                                                }
                                            </div>
                                            {currentInbox < 3 && !summary && isAuthorized(InboxPermission[groupInbox], 'Write') && 
                                                <div>
                                                    <button className="btn btn-sm p-0" onClick={BeginEdit} title="Edit note and tags"><FontAwesomeIcon icon={faEdit} /></button>
                                                </div>
                                            }
                                        </div>
                                    }
                                </div>
                            </details>
                            <div>
                                {messageCount > 1 && currentInbox < 3 && !summary && isAuthorized(InboxPermission[groupInbox], 'Write') && 
                                    <div>
                                        <button onClick={Remove} title="Remove from group" className="btn btn-sm Close py-1">
                                            &times;
                                        </button>
                                    </div>
                                }
                            </div>
                        </div>
                    }
                </div>
            )}
        </Draggable>
    )
}
