import React, { Component } from 'react';
import axios from 'axios';
import { Container, Col, Form } from 'react-bootstrap';
import SimpleReactValidator from 'simple-react-validator';
import { NotificationManager } from 'react-notifications';
import Select from 'react-select';
import ResOptions from './ResolutionOptions/ResOptionsSort';

export default class ResolutionSetup extends Component {
    constructor(props) {
        super(props);

        this.publishTypes = [
            { value: 1, label: "Standard" },
            { value: 2, label: "Weighted" },
            { value: 3, label: "Both" }
        ];

        this.state = {
            ContestId: this.props.id ? this.props.id : 0,
            ContestName: this.props.name,
            ResText: this.props.atext ? this.props.atext : '',
            options: this.props.options,  //[{ VoteOption: '', VoteDisplayOrder: 0, VoteValue: 0, VoteOptionId: 0 }],
            VMeetingId: this.props.meeting,
            errorMessage: "",
            publishtypeid: this.props.publishtypeid,
            enableweightedcount: this.props.enableweightedcount,
            selectedPublishType: this.publishTypes.filter((x) => x.value === this.props.publishtypeid)[0],
            showButton: true,
            notificationExists: false,
            display: this.props.display ? this.props.display : 0
        };


        this.userId = parseInt(sessionStorage.getItem('UserId'));
        this.validator = new SimpleReactValidator({ autoForceUpdate: this, element: (message, className) => <div className="text-danger">{message}</div> });
        this.submitted = false;
    }

    onSubmit = (e) => {
        e.preventDefault();
        this.setState({
            showButton: false,
            ContestName: this.state.ContestName?.trim()
        })
        var success = false;
        var voteOption = this.state.options.map((vo) => vo.voteOption);
        var voteValues = this.state.options.map((item)=> parseInt(item.voteValue) );
        var voteValueIsDuplicate = voteValues.some((item, idx) => {
            return voteValues.indexOf(item) != idx
        });
        if (voteValueIsDuplicate) {
            this.setState({
                errorMessage: 'A vote value is duplicated.'
            })
        }
        var emptyVoteOption = voteOption.includes('');
        if (emptyVoteOption || voteOption[0] === undefined) {
            emptyVoteOption = true;
            this.setState({
                errorMessage: 'Please enter a valid vote option.'
            })
        }

        if (!this.validator.allValid() || emptyVoteOption === true || this.dupVoteOption(voteOption) === true || voteValueIsDuplicate === true) {
            this.validator.showMessages();
        }
        else {
            this.setState({
                errorMessage: ''
            })

            let objops = this.state.options;
            const formData = new FormData();
            formData.append("VmeetingId", this.props.meeting);
            formData.append("UserID", this.userId);
            formData.append("ContestId", this.state.ContestId);
            formData.append("ContestName", this.state.ContestName.trim());
            formData.append("AdditionalResText", this.state.ResText);
            formData.append("StatusId", 1);
            formData.append("TypeID", this.props.type);
            formData.append("IsActive", false);
            formData.append("DisplayOrder", this.state.display);
            formData.append("Options", JSON.stringify(objops));
            formData.append("ActionType", this.props.actionType);
            formData.append("PublishTypeId", this.state.selectedPublishType === undefined ? 1 : parseInt(this.state.selectedPublishType.value));

            const headers1 = { "Content-Type": "multipart/form-data" };

            if (this.submitted === false) {
                this.submitted = true;

                axios.post('VMSaveContest', formData, { headers: headers1 }).then(response => {
                    if (response.data.statusDescription === 'Success') {
                        NotificationManager.success(this.props.actionType === "edit" ? "Item updated successfully" : "Item created successfully", "Vote Item", 2000);
                        success = true;
                        this.props.onHide(true);
                    } else if (response.data.statusID === -2) {
                        NotificationManager.error("Sorry, a duplicate item exists", "Vote Item", 2000);
                    } else {
                        NotificationManager.error(this.props.actionType === "edit" ? "Sorry, there was a problem updating the item" : "Sorry, there was a problem creating the item", "Vote Item", 2000);
                    }
                }).catch((error) => console.log(error.response));
            }
        }
        var parentThis = this;
        if (!success) {
            setTimeout(function () {
                parentThis.submitted = false;
                parentThis.setState({
                    showButton: true
                })
            }, 250);
        }

    }


    handlePublishTypeChange = selectedPublishType => {
        this.setState({ selectedPublishType });
    };

    onChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    }

    handleInputChange = (optsList) => {
        this.setState({ options: optsList });
    }

    dupVoteOption = (voteOption) => {
        for (var i = 0; i < voteOption.length; i++) {
            for (var j = 0; j < voteOption.length; j++) {
                if (i !== j && voteOption[i].trim().toLowerCase() === voteOption[j].trim().toLowerCase()) {
                    this.setState({
                        errorMessage: 'A vote option is duplicated.'
                    })
                    return true;
                }
            }
        }
        return false;
    }


    render() {
        this.validator.purgeFields();
        return (

            <div>

                <Container>
                    <form onSubmit={this.onSubmit}>
                        <div className="modalContestContent">
                            <div className="modalContestBody">
                                <div>
                                    <Form.Group className="mb-3">
                                        <Form.Label htmlFor="ContestName">Item name</Form.Label>
                                        <input className="form-control" type="text" name="ContestName" style={{ width: '100%' }} onChange={this.onChange} value={this.state.ContestName} placeholder="Item name / question" onBlur={() => this.validator.showMessageFor('ContestName')} />
                                        {this.validator.message('ContestName', this.state.ContestName, 'required|max:1000')}
                                    </Form.Group>
                                    {
                                        this.state.enableweightedcount === "true" ?
                                            <Form.Group className="mb-3">
                                                <Form.Label htmlFor="PublishTypes">Publish Types</Form.Label>
                                                <Select
                                                    name="PublishTypes"
                                                    value={this.state.selectedPublishType}
                                                    onChange={this.handlePublishTypeChange}
                                                    options={this.publishTypes}
                                                />
                                                {this.validator.message('PublishTypes', this.state.selectedPublishType, 'required')}

                                            </Form.Group>
                                            : null
                                    }
                                    <Form.Group className="mb-3">
                                        <Form.Label htmlFor="ResText">Additional text</Form.Label>
                                        <textarea className="form-control" name="ResText" style={{ width: '100%' }} onChange={this.onChange} value={this.state.ResText} placeholder="Additional text" rows={3} />
                                    </Form.Group>
                                </div>

                                <div className="mb-3">
                                    <Form.Group>
                                        <ResOptions Changed={this.handleInputChange} options={this.state.options} aType={"NEW"} OptionCount={this.state.options.length} />
                                    </Form.Group>
                                </div>
                            </div>
                            <div className="modalUpdateFooter">
                                <Form.Group className="row">
                                    <Col xs="9">
                                        <div className="error-message">{this.state.errorMessage && <span>{this.state.errorMessage}</span>}</div>
                                    </Col>
                                    <Col xs="3" className="modalUpdateFooterSubmit">
                                        {this.state.showButton
                                            ?
                                            <button
                                                className="btn btn-dark"
                                                type="submit"
                                                disabled={!this.props.isAuthorized}
                                                color="success">{this.props.actionType === "new" ? "Create" : "Update"} and close</button>
                                            : <button className="btn btn-dark" disabled><div className="loaderholder"><div className="loader"></div></div></button>
                                        }
                                    </Col>
                                </Form.Group>
                            </div>
                        </div>
                    </form>

                </Container>
            </div>
        );

    }

}