import React, { useContext, useEffect, useRef, useState } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import axios from 'axios';
import './chat.css';
import DateTimeManager from './../Common/DateTimeManager';
import { ForwardToComfortSystem } from './ComfortMonitor/ForwardToComfortSystem';
import AdminContext from '../context/AdminContext';
import ChatContext from '../context/ChatContext';
import { Tag } from './ComfortMonitor/Tag';
import { AutoComplete } from './ComfortMonitor/AutoComplete';

export class SendToPopup extends React.Component {
    constructor(props) {

        super(props);

        this.state = {
            modal: true,
            Comment: this.props.chat?.messageNotes,
            activeChat: this.props.chat,
            commentError: '',
            messageSent: '',
            tags: []
        };

        this.onClosed = this.onClosed.bind(this);
        this.close = this.close.bind(this);
        this.SendToInboxOnClick = this.SendToInboxOnClick.bind(this);
        this.SendOnClick = this.SendOnClick.bind(this);
        this.commentUpdate = this.commentUpdate.bind(this);
    }

    onClosed() {
        this.props.onClosed && this.props.onClosed();
        this.props.closeMethod && this.props.closeMethod();
    }

    close() {
        this.props.toggle();
    }

    commentUpdate = (object) => {
        if (object.target.value.length > object.target.maxLength) {
            object.target.value = object.target.value.slice(0, object.target.maxLength)
        }
        this.setState({ Comment: object.target.value });
    }

    async SendToInboxOnClick(inbox) {
        this.state.Comment !== '' && await this.SendOnClick(this.props.chat);
        axios.get(`api/AddChatToInbox`, { params: { vmId: this.props.VMeetingId, chatId: this.props.chat.messageId, inbox: inbox } }).then(res => {
            this.close();
        })
    }

    async SendOnClick() {
        let chatResponse = {
            VMeetingId: this.props.VMeetingId,
            MessageId: this.props.chat.messageId,
            SubmittedById: this.props.chat.attendeeId,
            QuestionText: this.state.Comment,
            AdminId: 999
        };
        let dataChatResponse;
        if (this.state.Comment !== '') {
            axios.post(`api/UpdateMessageNotes`, chatResponse).then(res => {
                dataChatResponse = res.data;
                if (dataChatResponse !== null && dataChatResponse !== "") {
                    this.setState({
                        adminChatResponses: dataChatResponse
                    });
                }
            }).catch(err => {
                console.log("error", "sending chat response");
            });
        }
    }

    RemoveTag = tagId => {
        let nat = [...this.state.tags];
        nat = nat.filter(tag => {
            return tag.messageTagId !== tagId;
        });
        this.setState({
            tags: nat
        })
    }

    AddTag = (tagId, tagText) => {
        let nat = [...this.state.tags];
        nat.push({ "messageTagId": tagId, "tagText": tagText });
        this.setState({
            tags: nat
        })
    }


    render() {
        let chat = this.props.chat;
        return (
            <React.Fragment>
                <Modal isOpen={this.state.modal} className="custom-modal-style" toggle={this.props.toggle} onClosed={this.onClosed}>
                    <ModalHeader className="messaging-modal-header ">
                        <div className="chat-heading d-flex justify-content-between">
                            <div>Send to chat box</div>
                            <button className="btn" onClick={() => { this.close() }}>&times;</button>
                        </div>
                    </ModalHeader>
                    <ModalBody>
                        <span>
                            <div className="chat-box-pres">
                                <div key={chat.messageId + '_' + chat.responseId} className="grid-container-pres" >
                                    <div className="Status" onClick={this.props.respond ? () => this.props.respond(chat) : null}>{chat.messageId}</div>
                                    <div className="Title-Bar" onClick={this.props.respond ? () => this.props.respond(chat) : null}>
                                        {chat.attendeeName ? chat.attendeeName : <span>User ID: {chat.attendeeId}</span>}
                                    </div>
                                    <div className="MessageForward" onClick={this.props.respond ? () => this.props.respond(chat) : null}>{chat.messageText}</div>
                                    <div className="Time" onClick={this.props.respond ? () => this.props.respond(chat) : null}> {new DateTimeManager(chat.messageDateTime).getTimeDefaultFormat()} </div>
                                </div>
                            </div>
                        </span>
                    </ModalBody>
                    <div className="Pchat-tiles">
                        <div className="row no-gutters">
                            <div className="input-group">
                                <label htmlFor="comment" className="sr-only">Add a note when forwarding</label>
                                <textarea id="comment" name="comment" maxLength="500" onInput={this.commentUpdate} className="form-control type_msg" onChange={this.commentUpdate} placeholder="Type a note to add to this message" value={this.state.Comment}></textarea>
                            </div>
                        </div>
                        <ForwardingTagEditor tags={this.state.tags} addTag={this.AddTag} removeTag={this.RemoveTag} />

                    </div>
                    <ModalFooter className="messaging-modal-footer">
                        <div className="row no-gutters">
                            {
                                this.props.isAuthorized
                                &&
                                <div><small>Send To </small>
                                    <button
                                        className="btn btn-sm btn-virtual-meeting"
                                        onClick={() => { this.SendToInboxOnClick(2); }}>
                                        Moderator
                                    </button>
                                    <button
                                        className="btn btn-sm btn-virtual-meeting"
                                        onClick={() => { this.SendToInboxOnClick(8); }}>
                                        Tech Support
                                    </button>
                                    <ForwardToComfortSystem targetCmInbox={1} messageId={this.props.chat.messageId} close={this.close} note={this.state.Comment} tags={this.state.tags} />
                                    {!this.context.switches.enableComfortMonitor &&
                                        <ForwardToComfortSystem targetCmInbox={2} messageId={this.props.chat.messageId} close={this.close} note={this.state.Comment} tags={this.state.tags} />
                                    }
                                </div>

                            }
                        </div>
                    </ModalFooter>
                </Modal>
            </React.Fragment>);
    }
}

SendToPopup.defaultProps = {
    onClosed: (e) => { }
}

SendToPopup.contextType = AdminContext;

export default SendToPopup;



function ForwardingTagEditor(props) {
    const { comfortTagList, GetComfortTagList } = useContext(ChatContext);
    const { tags, addTag, removeTag } = props;
    const [newTag, setNewTag] = useState();
    const focusRef = useRef();


    useEffect(() => {
        if (comfortTagList.length === 0) {
            GetComfortTagList();
        }
    }, [])

    const AutoAddTag = value => {
        //Add tag while typing
        let newValue;
        let hasTag = tags.find(a => a.tagText.toLowerCase() === value.toLowerCase().trim());
        if (hasTag && value.length !== value.trim().length && value.trim().length > 2) {
            newValue = "";
        } else
            if (value.length !== value.trim().length && value.trim().length > 2) {
                //Now we need to pull from the parent list of existing tags
                let existingTag;
                if (comfortTagList) {
                    existingTag = comfortTagList.find(a => a.tagText.toLowerCase() === value.toLowerCase().trim());
                }
                if (existingTag) {
                    addTag(existingTag.messageTagId, existingTag.tagText);
                }
                else {
                    let min = Math.min(...tags.map(item => item.messageTagId), 0);
                    addTag(min - 1, value.trim());
                }
                newValue = "";
            } else {
                newValue = value.trim();
            }
        setNewTag(newValue);
    }

    const AddTagById = tagId => {
        let checkTag = tags.find(a => a.messageTagId === tagId);
        if (checkTag) {
            return;
        } else {
            let existingTag = comfortTagList.find(a => a.messageTagId === tagId);
            addTag(existingTag.messageTagId, existingTag.tagText);
            setNewTag("");
        }
        focusRef.current.focus();
    }

    const RemoveTagWithFocusSet = tagId => {
        removeTag(tagId);
        focusRef.current.focus();
    }


    return (
        <div className="d-flex flex-wrap tag-manager rounded m-1">
            {tags?.map(tag =>
                <Tag key={tag.messageTagId} tag={tag} edit RemoveTag={RemoveTagWithFocusSet} />
            )}
            <div className="autocomplete-parent flex-fill">
                <label
                    htmlFor={"tags:"}
                    className="sr-only"
                >Add tags</label>
                <input type="text"
                    ref={focusRef}
                    id={"tags:"}
                    placeholder="Add tags"
                    size="20" //Set this to adjust the minimum size of the input field
                    maxLength="15"
                    className="tag-form-control w-100"
                    value={newTag}
                    autoComplete="off"
                    onChange={e => AutoAddTag(e.target.value)}
                />
                <AutoComplete value={newTag} handleSuggestionClick={AddTagById} />
            </div>
        </div>
    )
}
