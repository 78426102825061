import React, { Component, Fragment } from 'react';
import { Row, Col } from 'reactstrap';
import PresenterResolutions from './PresenterResolutions';
import SignalRController from './SignalRController'
import authService from './api-authorization/AuthorizeService';
import ChatContext from './context/ChatContext';
import AdminContext from './context/AdminContext';
import { MeetingAttendance } from './MeetingAttendance';
import QuickPollPresenter from './QuickPolls/QuickPollPresenter';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { PresenterInboxMonitor } from './PresenterInboxMonitor';
import axios from 'axios';
import OpenChatStatsContainer from './OpenChat/OpenChatStatsContainer';
import { ComfortPresenter } from './chat/ComfortMonitor/ComfortPresenter';


export class Summary extends Component {
    constructor(props) {
        super(props)
        this.srCallbackAdm2Adm_MessageUpdate = this.srCallbackAdm2Adm_MessageUpdate.bind(this);
        this.srCallbackAdm2Adm_VoteUpdate = this.srCallbackAdm2Adm_VoteUpdate.bind(this);
        this.populateState = this.populateState.bind(this);

        this.state = {
            emailupdate: false,
            votingupdate: false,
            loading: true,
            requestData: [],
            dataFound: false
        }

        this.populateState();
        this.populateTimeout = null;
    }

    VmeetingId = () => {
        return parseInt(sessionStorage.getItem('VMId'));
    }

    UserId = () => {
        return parseInt(sessionStorage.getItem('UserId'));
    }

    populateData = async () => {
        let request = {
            VMId: this.VmeetingId(),
            UserID: this.UserId()
        }
        if (this.context.isAuthorized('PresInbox', 'Read') || this.context.isAuthorized('PresGI', 'Read')) {
            await axios.post("api/VMGetMeetingQuestionList", request).then(response => {
                if (response.data.length > 0) {
                    let requests = response.data;
                    this.setState({
                        dataFound: true,
                        requestData: requests,
                        loading: false
                    })
                } else {
                    this.setState({
                        dataFound: false,
                        loading: false
                    })
                }

            })
        }

    }

    async populateState() {
        const [user] = await Promise.all([authService.getUser()])
        this.setState({
            user: user && user.sub,
            role: user && user.role
        });

    }

    srCallbackAdm2Adm_MessageUpdate(message) {
        this.setState({ emailupdate: !this.state.emailupdate });
    }

    srCallbackAdm2Adm_VoteUpdate() {
        this.setState({ votingupdate: !this.state.votingupdate });
    }

    componentDidMount() {
        SignalRController.addCallbackAdm2Adm_MessageUpdate(this.srCallbackAdm2Adm_MessageUpdate);
        SignalRController.addCallbackAdm2Adm_VoteUpdate(this.srCallbackAdm2Adm_VoteUpdate);
        this.populateData();
        this.populateTimeout = setInterval(() => { this.populateData(); }, 7000);
    }

    componentWillUnmount() {
        clearInterval(this.populateTimeout);
        SignalRController.removeCallbackAdm2Adm_MessageUpdate(this.srCallbackAdm2Adm_MessageUpdate);
        SignalRController.removeCallbackAdm2Adm_VoteUpdate(this.srCallbackAdm2Adm_VoteUpdate);
        this.setState = (state, callback) => {
            return;
        };
    }

    render() {
        return (
            <AdminContext.Consumer>
                {AdminContext => (
                    <Fragment>
                        <div className="w-100 px-1">
                            <Tabs>
                                <TabList>

                                    {
                                        (AdminContext.isAuthorized('VoteSummary', 'Read')
                                            || AdminContext.isAuthorized('PresAttendance', 'Read')
                                            || AdminContext.isAuthorized('QuickPoll', 'Read')
                                        ) && <Tab>Voting Summary</Tab>
                                    }

                                    {
                                        (AdminContext.isAuthorized('PresInbox', 'Read') || AdminContext.isAuthorized('PresGI', 'Read'))
                                        && <Tab>Presenter Questions</Tab>
                                    }

                                    {
                                        AdminContext.isAuthorized('PresOpenChat', 'Read') &&
                                        <Tab disabled={!(AdminContext.switches.openChat || AdminContext.switches.inRoomOpenChat)}>Open Chat</Tab>
                                    }

                                </TabList>

                                {
                                    (AdminContext.isAuthorized('VoteSummary', 'Read')
                                        || AdminContext.isAuthorized('PresAttendance', 'Read')
                                        || AdminContext.isAuthorized('QuickPoll', 'Read')
                                    ) &&
                                    <TabPanel>
                                        <Row>
                                            {AdminContext.isAuthorized('PresAttendance', 'Read') &&
                                                <Col md="12" xl="6" className="addPadding">
                                                    <MeetingAttendance postMeeting={false} showGuests={true} showTitle={false} />
                                                </Col>
                                            }
                                            {AdminContext.isAuthorized('PresInbox', 'Read') &&
                                                <Col md="12" xl="6" className="addPadding">
                                                    <PresenterInboxMonitor requestData={this.state.requestData} loading={this.state.loading} dataFound={this.state.dataFound} presenterInboxItems={this.context.presenterInboxItems} />
                                                </Col>
                                            }
                                        </Row>
                                        <Row>
                                            <Col className="addPadding">
                                                {AdminContext.isAuthorized('QuickPoll', 'Read') && <div className="scrollQuickPoll"> <QuickPollPresenter /> </div>}
                                                {AdminContext.isAuthorized('VoteSummary', 'Read') && <PresenterResolutions key="1" VmeetingId={this.VmeetingId()} UserId={this.state.userId} role={this.state.role} vote={this.state.votingupdate} />}
                                            </Col>
                                        </Row>
                                    </TabPanel>
                                }

                                {
                                    (AdminContext.isAuthorized('PresInbox', 'Read') || AdminContext.isAuthorized('PresGI', 'Read')) &&
                                    <TabPanel>
                                        <Row>
                                            <ComfortPresenter summary/>
                                        </Row>
                                    </TabPanel>
                                }
                                {
                                    AdminContext.isAuthorized('PresOpenChat', 'Read') &&
                                    <TabPanel>
                                        <Row>
                                            <OpenChatStatsContainer key="1" VmeetingId={this.VmeetingId()} ToggleActionButtons={false} user={this.context.user} role={this.context.role} email={this.state.emailupdate} />
                                        </Row>
                                    </TabPanel>
                                }

                            </Tabs>
                        </div>
                    </Fragment>
                )}
            </AdminContext.Consumer>
        )
    }
}
export default Summary;
Summary.contextType = ChatContext;



