import React, { Component } from 'react';
import axios from 'axios';
import { AdminAttendance } from './AdminAttendance';
import AdminContext from './context/AdminContext';

export default class AdminAttendanceReport extends Component {
    constructor(props) {
        super(props);
        this.loggedInCurrent = false;

        this.state = {
            loginsummary: []
            , loading: true
        };
        this.getReport = this.getReport.bind(this);
    }

    componentDidMount() {
        this.getReport();

    }
    componentWillUnmount() {
        // fix Warning: Can't perform a React state update on an unmounted component
        this.setState = (state, callback) => {
            return;
        };
    }
    getReport() {
        let VMId= this.context.meetingId;

        axios.get('GetMeetingAdminUsers', { params: { VMId: VMId} })
            .then(response => {
                let ls = response.data;
                this.setState({
                    loginsummary: ls,
                    loading: false
                });
            }).catch(function (error) { console.log(error); })
    }

    render() {
        const { loginsummary = [] } = this.state;
        return (
            <div>
                {!this.state.loading ?
                    <div className="Reports">
                        <div className="chat-heading-am">ADMIN ATTENDANCE</div>
                        {this.state.loginsummary && loginsummary.length > 0 ?
                            <AdminAttendance AdminList={loginsummary} />
                            :
                            <div>No data found for this meeting.</div>
                        }
                    </div>
                    :
                    <div className="dashHeading">Loading....</div>
                }
            </div>
        )
    }
}

AdminAttendanceReport.contextType = AdminContext;
