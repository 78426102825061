import { Fragment } from "react";
import { useNavigate } from "react-router";

export const BackButton = (props) => {
    const navigate = useNavigate();
    return (
        <Fragment>
            <button className="btn btn-secondary btn-sm me-1" onClick={() => navigate(-1)}>Back</button>
        </Fragment>
    );
};