const UserTypes = {
    GLOBAL_USER: 'global',
    CLIENT_USER: 'client',
    MEETING_USER: 'meeting'
}

const AccessTypes = {
    READ_ACCESS: 'ReadAccess',
    WRITE_ACCESS: 'WriteAccess',
    GRANT_ACCESS: 'GrantAccess'
}

const Permissions = {
    ACCESS_PERMISSION: 'Access'
}

export {
    UserTypes,
    AccessTypes,
    Permissions
}