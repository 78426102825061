import React, { Component, Fragment } from 'react';
import SignalRController from './SignalRController';
import axios from 'axios';

export class RefreshBrowser extends Component {
    constructor(props) {
        super(props)
        this.state = {
            value: '',
            message: ''
        };
    }

    handleChange = (event) => {
        this.setState({
            value: event.target.value,
            message: ''
        });
    }

    handleSubmit = (event) => {
        //SignalRController.InvokeAdm2Att_RefreshBrowser(this.state.value);
        //this.setState({
        //    value: '',
        //    message: "Forced attendee browser refresh initiated"
        //});
        //event.preventDefault();

        event.preventDefault();
        const options = {
            url: 'api/RefreshBrowser',
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            data: this.state.value
        };
        axios(options).then(() => {
            this.setState({
                value: '',
                message: "Forced attendee browser refresh initiated"
            });
        })


    }

    softRefresh = (event) => {
        //SignalRController.InvokeAdm2Att_SoftRefresh(this.state.value);
        //this.setState({
        //    value: '',
        //    message: "Attendee meeting update initiated"
        //});
        //event.preventDefault();

        event.preventDefault();
        const options = {
            url: 'api/SoftRefreshBrowser',
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            data: this.state.value
        };
        axios(options).then(() => {
            this.setState({
                value: '',
                message: "Attendee meeting update initiated"
            });
        })
    }

    componentDidMount() {
    }

    componentWillUnmount() {
    }

    render() {
        return (
            <Fragment>
                <div className="form-group my-3">
                    <div className="chat-heading-am">Refresh Attendee Interface</div>  
                    <p>Soft refresh triggers a new fetch of the meeting setup data in the attendee client. Hard refresh triggers browser refresh, equivalent to pressing reload or F5. </p>
                    <p>Always use soft refresh if possible.</p>
                    <label htmlFor="refreshMessage">
                        Browser refresh reason:
                    </label>
                    <input className="form-control" id="refreshMessage" type="text" placeholder='Enter your message for refresh here' value={this.state.value} onChange={this.handleChange} />
                    <div className="my-3">
                        <button
                            className="btn btn-primary me-3"
                            disabled={(!this.props.isAuthorized || this.state.value.trim() === '') ? true : false}
                            onClick={this.softRefresh}>
                            Soft Refresh
                        </button>
                        <button
                            className="btn btn-danger"
                            disabled={(!this.props.isAuthorized || this.state.value.trim() === '') ? true : false}
                            onClick={this.handleSubmit}>
                            Hard Refresh
                        </button>
                    </div>
                    {this.state.message}
                </div>
            </Fragment>
        )
    }
}

export default RefreshBrowser