import React, {  useState, useEffect, useRef } from "react";
import { useMsal } from "@azure/msal-react";
import { auditLogin } from "../context/ApiFunctions";
import createActivityDetector from "../Common/ActivityDetector";
import { Fragment } from "react";
import { Modal } from 'react-bootstrap';
import { useCountDownTimer } from "../Common/CountDown";

function useIdle() {
    const [isIdle, setIsIdle] = useState(false)
    const activityDetector = useRef(null);


    useEffect(() => {
        if (!activityDetector.current) {
            activityDetector.current = createActivityDetector({ timeToIdle: 3000000 })
        }
        activityDetector.current.on('idle', () => setIsIdle(true));
        activityDetector.current.on('active', () => setIsIdle(false));
        return () => activityDetector.current.stop();
    }, [])

    return isIdle
}


async function handleLogout(instance) {
    auditLogin('Logout').then(() => {
        sessionStorage.removeItem('VMId'); 
        //instance.logoutPopup({
        instance.logoutRedirect({        
            postLogoutRedirectUri: "/",
            //mainWindowRedirectUri: "/" // redirects the top level app after logout
        }).catch(e => {
            console.error(e);
        });
    })
}

/**
 * Renders a button which, when selected, will open a popup for logout
 */
export const SignOutButton = () => {
    const { instance } = useMsal();
    const isIdle = useIdle();
    return (
        <Fragment>
            <WarningModal showModal={isIdle} />
            <button className="ml-auto btn btn-danger" onClick={() => handleLogout(instance)}>Logout</button>
        </Fragment>
    );
}


function WarningModal(props) {
    const { instance } = useMsal();
    const lockOutTimeOut = useRef(null);    
    const waitTime = 600000
    const timer = useCountDownTimer(waitTime, props.showModal);
    useEffect(() => {
        if (props.showModal) {
            lockOutTimeOut.current = setTimeout(() => handleLogout(instance), waitTime)
        } else {
            clearTimeout(lockOutTimeOut.current);
        }
        return () => clearTimeout(lockOutTimeOut.current);
    }, [props.showModal])
    
    return (
        <Modal show={props.showModal}  centered>
            <Modal.Header>
                <Modal.Title>You have been inactive for a while</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="mx-2 my-2">
                    <p>Do you want to stay logged in?</p>
                    {props.showModal && <p>You will be logged out in {timer}</p>}
                </div>
            </Modal.Body>
            <Modal.Footer>
                <button className="btn btn-primary"> Stay logged in </button>
                <button className="btn btn-danger" onClick={() => handleLogout(instance)}> Logout </button>
            </Modal.Footer>
        </Modal >)
}