import React, { useState, useContext, useEffect } from 'react';
import AdminContext from '../../context/AdminContext';
import UserPermissionContext from '../UserPermissionContext';
import swal from 'sweetalert';
import { UserTypes } from './UserAdminTypes';

const GlobalPermission = ({ handlePermissionAction }) => {
    //Contexts//
    const { isGlobalAuthorized } = useContext(AdminContext);
    const { userAccessPermission, selectedUser, ...Context } = useContext(UserPermissionContext);

    //Compoenent state//
    const [isGlobalUser, setIsGlobalUser] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isSaving, SetIsSaving] = useState(false);

    let userParams = {
        Id: selectedUser.Id,
        PermissionLevel: UserTypes.GLOBAL_USER,
        ClientId: null,
        MeetingId: null,
    };

    useEffect(() => {
        GetSelectedUserPermissions();
    }, [])


    const handleGlobalPermissionClick = async () => {
        if (!isGlobalUser) {
            await confirmSave();
        }
        else {
            handlePermissionAction(userParams, 3);
        } 
    }


    const GetSelectedUserPermissions = async () => {
        setIsLoading(true);
        const response = await Context.getUserPermissions(selectedUser.Id, null, null);
        const permissions = JSON.parse(response?.data.templatePermissionJson);
        const userAdminPermission = permissions.reduce((prev, nex) => prev.concat(nex.FeatureList), []).find(x => x.ShortName == 'Access');
        setIsGlobalUser(userAdminPermission?.WriteAccess);
        setIsLoading(false);
    }


    const SaveAccessPermission = async () => {
        SetIsSaving(true);
        const status = await Context.AddUserAccessPermission(userParams);
        SetIsSaving(false);
    }

    const getButtonText = () => {
        return `${isGlobalUser ? '' : 'Add'} Global Permissions`
    }

    const confirmSave = async () => {
        swal({
            title: "Add Global Permissions",
            text: "Are you sure you wish to add global permission?",
            icon: "warning",
            buttons: true,
            dangerMode: true
        }).then(async (value) => {
            if (value) {
                await SaveAccessPermission();
                handlePermissionAction(userParams, 3);
            }
        })
    }

    return (
        isGlobalAuthorized('UserAdmin', 'Write') && !isLoading ?
            <div className="d-flex flex-column">
                <button
                    className="mt-auto btn btn-primary btn-sm"
                    onClick={handleGlobalPermissionClick}
                    disabled={isSaving}
                >
                    {
                        isSaving ?
                            <span className="spinner-border spinner-border-sm" role="sss" aria-hidden="true"></span>
                            :
                            getButtonText()
                    }
                </button>
            </div>
            : null
    )
}

export default GlobalPermission;
