import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';
import React, { useState } from "react";
import ValidateOptions from "./ValidateOptions";

export function SingleChoice({ question = [],
    handleChange = () => { },
    disabled = false,
    handleSave = () => { } }) {

    const [choice, setChoice] = useState("")

    let errorMessage = ValidateOptions(question, handleChange, disabled)

    function onChange(evt) {
        setChoice(evt.target.value)
        let qst = { ...question, Selection: evt.target.name }

        if (question.Mandatory) {
            qst = { ...question, Validated: true, Selection: evt.target.name }
        }
        handleChange(qst)
    }

    function onBlur(evt) {
        // evt.target.name will save the optionid to Selection
        let qst = { ...question, Selection: evt.target.name }
        handleSave(qst)
    }

    return (
        errorMessage === "" ? <Form.Group className='form-div'>
            <Form.Label>{question.QuestionTitle}{question.Mandatory ? "*" : ""}</Form.Label>
            {question.Options.map((option) => (
                <Form.Check
                    key={option.id}
                    type="radio"
                    id={option.id}
                    name={option.id}
                    data-testid={option.id}
                    onChange={(evt) => onChange(evt)}
                    onBlur={(evt) => onBlur(evt)}
                    label={option.OptionTitle}
                    value={option.OptionTitle}
                    checked={choice === option.OptionTitle}
                    required={!question.Validated}
                    disabled={disabled}
                    feedback="Please select an option."
                    feedbackType="invalid" />
            ))}
        </Form.Group> : <Alert variant="danger" className='alert-margin'>{errorMessage}</Alert>
    )
}