import React, { useContext, useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import UserPermissionContext from './UserPermissionContext';
import { NotificationManager } from 'react-notifications';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import './UserPermissions.css';
import PermissionList from './PermissionList';
import UserDetails from './UserDetails/UserDetails';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Dropdown from 'react-bootstrap/Dropdown';
import { UserTypes, Permissions } from './UserDetails/UserAdminTypes';

const AddPermissions = ({ showModal, handleModalClose, selectedMeeting,...props }) => {

    //Component state//
    const [userPermissions, setUserPermission] = useState([]);
    const [isPermissionUpdated, setPermissionUpdate] = useState();
    const [isLoading, setIsloading] = useState(false);
    const [activeTab, setActiveTab] = useState(0);


    //Context//
    const Context = useContext(UserPermissionContext);
    const { userAdminPermissions, permissions, userDefaultPermissions, selectedUserMeeting, defaultClient, selectedUser } = Context;

    useEffect(() => {
        setUserPermission(userDefaultPermissions);
        //getDefaultIndex();
    }, [userDefaultPermissions]);


    /**
     * Event handler for Permission template change dropdown
     * @param {any} e
     */
    const handleChange = async (e, permissionLevel) => {
        setIsloading(true);
        const templatePermissions = await Context.getTemplatePermissions(e.target.value);
        applyTemplatePermission(templatePermissions, permissionLevel);
        setIsloading(false);
    }

    /**
     * Event handler for Permission template change dropdown
     * @param {any} e
     */
    const handleTemplateClick = async (templateId, permissionLevel) => {
        setIsloading(true);
        const templatePermissions = await Context.getTemplatePermissions(templateId);
        applyTemplatePermission(templatePermissions, permissionLevel);
        setIsloading(false);
    }


    /**
     * Update the state based on check checked for feature.
     * @param {any} featureGroupIndex
     * @param {any} featureIndex
     * @param {any} permissionKey
     * @param {any} e
     */
    const handlePermissionClick = (featureGroupIndex, featureIndex, permissionKey, e,permissionLevel) => {
        let permissions = userPermissions;
        if (permissionKey === 'ReadAccess') {
            //If read access is removed, remove write access also//
            if (!e.target.checked) {
                permissions[featureGroupIndex].FeatureList[featureIndex]['WriteAccess'] = e.target.checked;
                permissions[featureGroupIndex].FeatureList[featureIndex][permissionKey] = e.target.checked;
            }
            permissions[featureGroupIndex].FeatureList[featureIndex][permissionKey] = e.target.checked;
        }
       
        if (permissionKey === 'RevokeAccess' && e.target.checked) {
            permissions[featureGroupIndex].FeatureList[featureIndex]['FeatureRank'] = permissionLevel;
            permissions[featureGroupIndex].FeatureList[featureIndex]['VMeetingId'] = permissionLevel === UserTypes.MEETING_USER ? selectedUserMeeting.value : '';
            permissions[featureGroupIndex].FeatureList[featureIndex]['ClientId'] = permissionLevel === UserTypes.CLIENT_USER ? defaultClient.value : '';
            permissions[featureGroupIndex].FeatureList[featureIndex][permissionKey] = e.target.checked;
        }

        //If write is given then give read also//
        if (permissionKey === 'WriteAccess') {
            permissions[featureGroupIndex].FeatureList[featureIndex][permissionKey] = e.target.checked;
            if (e.target.checked)
                permissions[featureGroupIndex].FeatureList[featureIndex]['ReadAccess'] = e.target.checked;
        }

        if (permissionKey === 'GrantAccess') {
            permissions[featureGroupIndex].FeatureList[featureIndex][permissionKey] = e.target.checked;
        }

        setUserPermission(permissions);
        //Just to rerender the component when key gets updated//
        setPermissionUpdate(!isPermissionUpdated);
    }

    /**
     * Based on selected template, get configured template permisions and 
     * update the UI
     * @param {any} templatePermissions
     */
    const applyTemplatePermission = (templatePermissions, permissionLevel) => {
        handleClearAll(permissionLevel);
        const updatedPermissions = userPermissions;
        updatedPermissions.forEach((permission) => {
            permission.FeatureList.forEach((feature, index) => {
                if (!isFeatureDisabled(feature, permissionLevel)) {
                    const templateFeature = getTemplateFeature(templatePermissions, feature.FeatureId);
                    permission.FeatureList[index].ReadAccess = templateFeature != undefined ? templateFeature.ReadAccess : permission.FeatureList[index].ReadAccess;
                    permission.FeatureList[index].WriteAccess = templateFeature != undefined ? templateFeature.WriteAccess : permission.FeatureList[index].WriteAccess;
                    permission.FeatureList[index].GrantAccess = templateFeature != undefined ? templateFeature.GrantAccess : permission.FeatureList[index].GrantAccess;

                }
            })
        });

        setUserPermission(updatedPermissions);
        setPermissionUpdate(!isPermissionUpdated);
    }


    /**
     * 
     * @param {any} templatePermissions
     * @param {any} featureId
     */
    const getTemplateFeature = (templatePermissions, featureId) => {
        return templatePermissions.reduce((prev, nex) => prev.concat(nex.FeatureList), []).find(x => x.FeatureId == featureId);
    }

    /**
     * Event handler to handle submit form
     * @param {any} e
     */
    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsloading(true);
        let userMeetingPermissions = [];
        userPermissions.forEach((permissionGroup) => {
            permissionGroup.FeatureList.forEach((feature) => {
                let featureObj = {
                    Rank: feature.FeatureRank,
                    FeatureId: feature.FeatureId,
                    Read: feature.ReadAccess,
                    Write: feature.WriteAccess,
                    Grant: feature.GrantAccess,
                    Revoke: feature.RevokeAccess,
                    UserId: selectedUser.Id,
                    VMeetingId: feature.FeatureRank == UserTypes.MEETING_USER ? selectedUserMeeting?.value : null,
                    ClientId: feature.FeatureRank == UserTypes.CLIENT_USER ? defaultClient?.value : null
                }
                userMeetingPermissions = [...userMeetingPermissions, featureObj]
            })
        });
        const saveStatus = await Context.SaveMeetingPermissions(userMeetingPermissions);
        if (saveStatus) {
            NotificationManager.success("User permissions saved", "Permissions", 2000);
            setActiveTab(0);
        }
        else {
            NotificationManager.error("Sorry, there was a problem in saving user permissions", "Permissions", 4000);
        }
        setPermissionUpdate(!isPermissionUpdated);
        setIsloading(false);
    }

    /**
     * If user has set of permission then only user can access this feature
     * @param {any} feature
     */
    const isFeatureDisabled = (feature, permissionLevel) => {
        if (
            userAdminPermissions.UserId !== selectedUser.Id &&
            userAdminPermissions.ReadAccess &&
            userAdminPermissions.WriteAccess && 
            feature?.FeatureRank === permissionLevel) {
            let featurePermission = permissions.reduce((prev, nex) => prev.concat(nex.FeatureList), []).find(x => x.FeatureId == feature.FeatureId);
            if (permissionLevel === UserTypes.GLOBAL_USER && featurePermission.FeatureRank !== UserTypes.GLOBAL_USER) {
                return true;
            }
            return !featurePermission?.GrantAccess;
        }
        return true;
    }

    /**
     * To revoke feature permissions to other user, logged in user must have 
     * revoke user admin access
     * */
    const isRevokePermissionDisabled = (feature) => {
        const { userRevokePermissions } = Context;
        let featurePermission = permissions.reduce((prev, nex) => prev.concat(nex.FeatureList), []).find(x => x.FeatureId == feature.FeatureId);
        return !((userRevokePermissions.ReadAccess && userRevokePermissions.WriteAccess)
            && (featurePermission?.GrantAccess)
            && (userAdminPermissions.UserId !== selectedUser.Id)
        )
    }

    /**
     * event handler for tab selection
     * @param {any} index
     */
    const handleTabSelect = async index => {
        setActiveTab(index)
        //Get user permissions based on selected tab.//
        let userParams = {
            Id: selectedUser.Id,
            Rank: '',
            ClientId: null,
            MeetingId:null
        };
        if (index == 1 ) {
            //Get meeting permission from main screen for selected user//

            userParams.MeetingId = selectedUserMeeting.value;
            userParams.PermissionLevel = UserTypes.MEETING_USER;
            await Context.GetReqestedPermissions(userParams);
        }
        if (index == 2) {
            //Get client permission from main screen for selected user//
            userParams.ClientId = props.selectedClient.value;
            userParams.PermissionLevel = UserTypes.CLIENT_USER;
            await Context.GetReqestedPermissions(userParams)
        }
        if (index == 3) {
            //Get global permissions for selected user//
            userParams.PermissionLevel = UserTypes.GLOBAL_USER;
            await Context.GetReqestedPermissions(userParams)
        }
    }

    const handlePermissionAction = async (userParams, index) => {
        setActiveTab(index);
        await Context.GetReqestedPermissions(userParams)
    }

    const handleAllPermissionClick = (templateGroup, event, permissionLevel) => {
        const accessKey = event.target.name;
        const isChecked = event.target.checked;
        const updatedPermissions = userPermissions;
        const featureGroupPermissions = userPermissions.find(featureGroup => featureGroup.FeatureGroupId == templateGroup.FeatureGroupId);
        featureGroupPermissions?.FeatureList.forEach(feature => {
            if (!isFeatureDisabled(feature, permissionLevel)) {
                
                feature[accessKey] = isChecked;
                if (accessKey == "WriteAccess" && isChecked) {
                    feature["ReadAccess"] = isChecked;
                }
                if (accessKey == "ReadAccess" && !isChecked) {
                    feature["WriteAccess"] = isChecked;
                }
            }
        })

        const updateIndex = userPermissions.findIndex(x => x.FeatureGroupId == templateGroup.FeatureGroupId);
        updatedPermissions[updateIndex] = featureGroupPermissions;

        setUserPermission(updatedPermissions);
        //Just to rerender the component when key gets updated//
        setPermissionUpdate(!isPermissionUpdated);
    }

    const handleClearAll = (permissionLevel) => {
        const updatedPermissions = userPermissions;
        updatedPermissions.forEach((permission) => {
            permission.FeatureList.forEach((feature, index) => {
                if (!isFeatureDisabled(feature, permissionLevel)) {
                    if (feature.ShortName !== Permissions.ACCESS_PERMISSION) {
                        permission.FeatureList[index].ReadAccess = false;
                        permission.FeatureList[index].WriteAccess = false;
                        permission.FeatureList[index].GrantAccess = false;
                    }
                }
            })
        });

        setUserPermission(updatedPermissions);
        setPermissionUpdate(!isPermissionUpdated);
    }

    /**
     * Render function to render template options
     * */
    const renderTemplateOptions = () => {
        const { templates } = Context;
        let permissionLevel = '';
        if (activeTab === 1)
            permissionLevel = UserTypes.MEETING_USER;
        if (activeTab === 2)
            permissionLevel = UserTypes.CLIENT_USER;
        if (activeTab === 3)
            permissionLevel = UserTypes.GLOBAL_USER;
        return templates.map((template, index) => {
            return (
                <Dropdown.Item
                    key={template.TemplateId}
                    href=""
                    onClick={() => handleTemplateClick(template.TemplateId, permissionLevel)}>
                    {template.Name}
                </Dropdown.Item>
            )
        })
    };

    const getSelctedUserName = () => {
        let user = Context.userList.find(x => x.Id == selectedUser.Id) || selectedUser;
        if (user) {
            return (
                <strong>
                    {`${user.ForeName} ${user.LastName || ''}`}
                </strong>
            )
        }
    }

    return (
        <Modal show={showModal}
            onHide={() => {
                setActiveTab(0)
                handleModalClose();
            }}
            size="xl"
            scrollable={true}
            backdrop="static"
            keyboard={false}
            //{/*fullscreen={true}*/}
            className="addPermissionModal userAdminModal"
        >
            <Modal.Header closeButton>
                <Modal.Title>User Administration - <strong>{getSelctedUserName()}</strong></Modal.Title>
            </Modal.Header>
            <Modal.Body className="p-2">
                {
                    activeTab === 0 ? null :
                        <button
                            className="btn btn-primary position-absolute me-1 mt-1 end-0"
                            onClick={() => { setActiveTab(0) } }
                        >Back</button>
                }
               
                <Tabs selectedIndex={activeTab} onSelect={handleTabSelect} >
                    <TabList>
                        <Tab>User Details</Tab>
                        <Tab>Meeting Permissions</Tab>

                        {
                            //clinet level
                            ((userAdminPermissions.FeatureRank === UserTypes.GLOBAL_USER || userAdminPermissions.FeatureRank === UserTypes.CLIENT_USER)
                                && userAdminPermissions.WriteAccess) ?
                                <Tab>Client Permissions</Tab>
                                : null
                        }

                        {
                            //Global level
                            (userAdminPermissions.FeatureRank === UserTypes.GLOBAL_USER && userAdminPermissions.WriteAccess) ?
                                <Tab>Global Permissions</Tab>
                                : null

                        }
                    </TabList>
                    <TabPanel>
                        <UserDetails
                            selectedUser={selectedUser}
                            handlePermissionAction={handlePermissionAction}
                        />
                    </TabPanel>
                    <TabPanel>
                        {
                            //Meeting Level
                            <PermissionList
                                handlePermissionClick={handlePermissionClick}
                                isFeatureDisabled={isFeatureDisabled}
                                isRevokePermissionDisabled={isRevokePermissionDisabled}
                                handleChange={handleChange}
                                permissionLevel={UserTypes.MEETING_USER}
                                handleAllPermissionClick={handleAllPermissionClick}
                                handleClearAll={handleClearAll}
                            />
                        }
                    </TabPanel>
                    {
                        //Client level
                        ((userAdminPermissions.FeatureRank === UserTypes.GLOBAL_USER || userAdminPermissions.FeatureRank === UserTypes.CLIENT_USER)
                            && userAdminPermissions.WriteAccess) ?

                            <TabPanel>
                                <PermissionList
                                    handlePermissionClick={handlePermissionClick}
                                    isFeatureDisabled={isFeatureDisabled}
                                    isRevokePermissionDisabled={isRevokePermissionDisabled}
                                    handleChange={handleChange}
                                    permissionLevel={UserTypes.CLIENT_USER}
                                    handleAllPermissionClick={handleAllPermissionClick}
                                    handleClearAll={handleClearAll}
                                />
                            </TabPanel>
                            :
                            <></>
                    }
                    {
                        //Global Level
                        (userAdminPermissions.FeatureRank === UserTypes.GLOBAL_USER && userAdminPermissions.WriteAccess) ?
                            <TabPanel>
                                <PermissionList
                                    handlePermissionClick={handlePermissionClick}
                                    isFeatureDisabled={isFeatureDisabled}
                                    isRevokePermissionDisabled={isRevokePermissionDisabled}
                                    handleChange={handleChange}
                                    permissionLevel={UserTypes.GLOBAL_USER}
                                    handleAllPermissionClick={handleAllPermissionClick}
                                    handleClearAll={handleClearAll}
                                />
                            </TabPanel>
                            :
                            <></>
                    }

                </Tabs>
            </Modal.Body>
            <Modal.Footer>
                <button
                    className="btn btn-secondary btn-sm me-auto d-none"
                    type="button"
                    hidden={activeTab===0}
                    onClick={(e) => {
                        e.preventDefault();
                        setActiveTab(0)
                    }} >
                    Cancel
                </button>

                <DropdownButton
                    id="dropdown-basic-button"
                    size="sm"
                    title="Permission Template"
                    hidden={activeTab === 0}
                    drop={"up"}
                >
                    {renderTemplateOptions()}
                </DropdownButton>

                <button
                    type="submit"
                    disabled={(userAdminPermissions.UserId === selectedUser.Id) || (isLoading)}
                    className="btn btn-primary btn-sm"
                    onClick={(e) => handleSubmit(e)}
                    hidden={activeTab === 0}
                >
                    {isLoading ? <span className="spinner-border spinner-border-sm" role="sss" aria-hidden="true"></span> : "Save and Close"}
                </button>
            </Modal.Footer>
        </Modal>
    )
}
export default AddPermissions;