import React, { useState } from 'react';
import { Card, Form } from 'react-bootstrap';
import ListGroup from 'react-bootstrap/ListGroup';
import Alert from 'react-bootstrap/Alert';
import ValidateOptions from "./ValidateOptions";

export function DraggableRanking({ question = [],
    handleChange = () => { },
    disabled = false,
    handleSave = () => { } }) {

    const optionsArray = []
    const [ranking, setRanking] = useState(question)
    const [dragItem, setDragItem] = useState()
    const [list, setList] = useState(question.Options.map((option, i) => (optionsArray[i] = option.OptionTitle)))

    let errorMessage = ValidateOptions(question, handleChange, disabled)

    const handleDragStart = (index) => {
        setDragItem(index)
    }

    const handleDragEnter = (evt, index) => {
        const newList = [...list]
        const item = newList[dragItem]

        evt.target.style.backgroundColor = "#e2e3e5"
        newList.splice(dragItem, 1)
        newList.splice(index, 0, item)
        setDragItem(index)
        setList(newList)
    }

    const handleDragLeave = (evt) => {
        evt.target.style.backgroundColor = "#ffffff"
    }

    const handleDrop = (evt) => {
        evt.target.style.backgroundColor = "#ffffff"

        let localOptions = ranking.Options.map((option) => (
            { ...option, Selection: list.indexOf(option.OptionTitle) + 1 }
        ))

        let qst = { ...ranking, Options: [...localOptions] }

        setRanking(qst)
        handleChange(qst)
        handleSave(qst)
    }

    return (
        errorMessage === "" ?
            <Form.Group className='form-div'>
                <Form.Label>{question.QuestionTitle}{question.Mandatory ? "*" : ""}</Form.Label>
                <Card>
                    <ListGroup variant="flush" numbered>
                        {list &&
                            list.map((item, index) => (
                                <ListGroup.Item
                                    className='dnd'
                                    draggable
                                    key={index}
                                    onDragStart={() => handleDragStart(index)}
                                    onDragEnter={(evt) => handleDragEnter(evt, index)}
                                    onDragLeave={(evt) => handleDragLeave(evt)}
                                    onDrop={(evt) => handleDrop(evt)}
                                    onDragOver={(evt) => evt.preventDefault()}
                                    disabled={disabled}>
                                    {item}
                                </ListGroup.Item>
                            ))}
                    </ListGroup>
                    <Card.Footer className="text-muted">Rank the items where 1 is the best.</Card.Footer>
                </Card>
            </Form.Group>
            : <Alert variant="danger" className='alert-margin'>{errorMessage}</Alert>
    )
}