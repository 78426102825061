import React, { Component } from 'react';
import AdminContext from '../../context/AdminContext';
import ContestContext from './ContestContext';
import axios from 'axios';
import { arrayMoveImmutable as arrayMove } from 'array-move';
import { NotificationManager } from 'react-notifications';


export class ContestContextProvider extends Component {
    constructor(props) {
        super(props);
        this.state = {           
            loading: true,
            message: null,
            voteOptions: []
        };
    }

    SortCandidateList = (actionType, odata) => {
        const list = [];
        if (actionType === "new") {
            this.setState({
                voteOptions: []
            })
        } else if (odata.length > 0) {
            var iList = odata.sort(function (a, b) { return a.voteDisplayOrder > b.voteDisplayOrder ? 1 : -1 });
            iList.forEach((i) => {
                if (i !== undefined) {
                    //is this used anywhere? It doesn't appear to be
                    list.push({ id: i.voteOptionId, candidateSurname: i.candidateSurname, candidateForename: i.candidateForename, candidateStatement: i.candidateStatement, candidatePhoto: i.candidatePhoto })
                }
            });
            this.setState({
                voteOptions: iList
            })
        }
    }

    UpdateCandidateSort = (oldIndex, newIndex) => {
        //Drag and drop sorting
        var newList = arrayMove(this.state.voteOptions, oldIndex, newIndex);
        newList.map((item, i) => {
            let itemToEdit = item;
            itemToEdit['voteDisplayOrder'] = i;
            return itemToEdit;
        });
        this.setState({
            voteOptions: newList
        })
    }

    UpdateCandidate = (index, newData) => {
        //Update candidate from card
        var list = this.state.voteOptions.map((cc, i) => i === index ? newData : cc);
        this.setState({
            voteOptions: list
        })
    }

    AddCandidate = (e, actionType) => {
        e.preventDefault();
        //Add new candidate to list
        if (actionType === "new") {
            const newValue = { candidateForename: '', candidateSurname: '', candidateStatement: '', candidatePhoto: '', candidatePhotoString: '', voteDisplayOrder: 0, voteOptionId: -1 };
            this.setState({
                voteOptions: this.state.voteOptions.concat(newValue)
            })
        }
        else {
            const maxVoteDisplayOrder = this.state.voteOptions.reduce((p, c) => p.voteDisplayOrder > c.voteDisplayOrder ? p : c);
            const newValue = [{ voteOptionId: -1, voteDisplayOrder: parseInt(maxVoteDisplayOrder.voteDisplayOrder) + 1, candidateForename: '', candidateSurname: '', candidateStatement: '', candidatePhoto: '', voteResult: 0 }];
            //setInputList(inputList.concat(newValue))
            this.setState({
                voteOptions: this.state.voteOptions.concat(newValue)
            })
        }
    }

    DeleteCandidate = (index, voteOptionId) => {
        //Remove candidate from list
        if (voteOptionId === -1) { //check if candidate already exists in database
            var list = [...this.state.voteOptions];
            list.splice(index, 1);
            this.setState({
                voteOptions: list
            })
        }
        else {
            var list = [...this.state.voteOptions];
            //api call
            const deleteContestOption = {
                ContestCandidateId: list[index].voteOptionId,
                VMeetingId: this.context.meetingId,
                UserId: this.context.userId
            };
            const options = {
                url: 'VMDeleteMeetingContestCandidate',
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                data: deleteContestOption
            };
            axios(options)
                .then(response => {
                    if (response.data.statusDescription === 'Success') {
                        NotificationManager.success("Candidate deleted", "Delete Candidate", 2000);
                    } else {
                        NotificationManager.error("Candidate delete failed", "Delete Candidate", 2000);
                    }
                })

            list.splice(index, 1);
            this.setState({
                voteOptions: list
            })

        }
    }

    ClearVoteOptions = () => {
        this.setState({ voteOptions: [] })
    }

    render() {
        return (
            <ContestContext.Provider value={{
                //data
                fullState: this.state,
                loading: this.state.loading,               
                message: this.state.message,
                meetingId: this.context.meetingId,
                userId: this.context.userId,
                switches: this.context.switches,
                voteOptions: this.state.voteOptions,


                //functions
                SortCandidateList: this.SortCandidateList,
                UpdateCandidateSort: this.UpdateCandidateSort,
                UpdateCandidate: this.UpdateCandidate,
                AddCandidate: this.AddCandidate,
                DeleteCandidate: this.DeleteCandidate,
                ClearVoteOptions: this.ClearVoteOptions

            }} >
                {this.props.children}
            </ContestContext.Provider>
        )
    }
}

ContestContextProvider.contextType = AdminContext;
