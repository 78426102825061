import React, { Fragment, useContext, useEffect, useState } from 'react';
import { NotificationManager } from 'react-notifications';
import UserPermissionContext from './UserPermissionContext';
import './UserPermissions.css';
import PropTypes from 'prop-types';
import { UserTypes,AccessTypes } from './UserDetails/UserAdminTypes';

const PermissionList = ({ handlePermissionClick, isFeatureDisabled, isRevokePermissionDisabled, handleChange, ...props }) => {

    //Component state//
    const [userPermissions, setUserPermission] = useState([]);
    const [userMeetings, setUserMeetings] = useState([]);
    const [selectedClient, setClient] = useState({ name: '', value: 0 });
    const [isSaving, setIsSaving] = useState(false);
    const [defaultMeeting, setMeeting] = useState({ name: "", value: 0 });

    //Context//
    const Context = useContext(UserPermissionContext);
    const { permissions, userDefaultPermissions, userAccessPermission, selectedUserMeeting, defaultClient, isLoading, clients, selectedUser } = Context;

    //component hooks//
    useEffect(() => {
        setUserPermission(userDefaultPermissions);
        getMeetings();
    }, [userDefaultPermissions, permissions, userAccessPermission]);





    //Component functions//
    /**
     * event handler for client selection change
     * @param {any} e
     */
    const handleClientChange = async (e) => {
        const { permissionLevel } = props;
        const userSelectedClient = { name: e.target.options[e.target.selectedIndex].text, value: e.target.value };
        setClient(userSelectedClient);
        Context.setSelectedClient(userSelectedClient);
        //Get meetings from client at meeting level
        if (permissionLevel === UserTypes.MEETING_USER) {
            let client = clients.find(client => client.ClientID == e.target.value);
            //If rank is global then no need to make an api call because app already knows
            //all the clients and client meetings
            if (client?.Rank == UserTypes.GLOBAL_USER) {
                const { Meetings } = client || [];
                setUserMeetings(Meetings);
            }
            else {
                const meetingsResponse = await Context.getUserMeetings(e.target.value);
                setUserMeetings(meetingsResponse);
            }
        }
        if (permissionLevel === UserTypes.CLIENT_USER) {
            //Get user's permission at client level//
            let userParams = {
                Id: selectedUser.Id,
                PermissionLevel: UserTypes.CLIENT_USER,
                ClientId: e.target.value,
                MeetingId: null
            };
            await Context.GetReqestedPermissions(userParams)
        }
    }


    const getMeetings = async () => {
        if (defaultClient?.value) {
            let client = clients.find(client => client.ClientID == defaultClient.value);
            if (client?.Rank == UserTypes.GLOBAL_USER) {
                const { Meetings } = client || [];
                setUserMeetings(Meetings);
            }
            else {
                const meetingsResponse = await Context.getUserMeetings(defaultClient.value);
                setUserMeetings(meetingsResponse);
            }
        }
    }

    /**
   *  event handler for meeting selection change.
   *  Added this event handler on this compoenent(Parent) because we are using same compoenent
   *  to handle permissions at any level. 
   * @param {any} e
   */
    const handleMeetingChange = async (id, value) => {
        const selectedMeeting = { value: id, name: value };
        setMeeting(selectedMeeting);
        let userParams = {
            Id: selectedUser.Id,
            PermissionLevel: 'Meeting',
            ClientId: null,
            MeetingId: id
        };
        Context.setUserMeeting(selectedMeeting);
        await Context.GetReqestedPermissions(userParams);
    }

    const isAllPermissionsChecked = (featureGroupPermissions, accessKey) => {
        let accessibleFeatures = featureGroupPermissions?.FeatureList.filter(feature => !isFeatureDisabled(feature, props.permissionLevel));
        return accessibleFeatures.every(feature => feature[accessKey]);
    }


    /**
     * Event handler for adding access permission for user
     * */
    const handleAddAccessPermission = async () => {
        const { permissionLevel } = props;

        if (permissionLevel == UserTypes.MEETING_USER && selectedUserMeeting.value == 0) {
            NotificationManager.error("Please select the meeting", "Permissions", 4000);
            return;
        }

        if (permissionLevel == UserTypes.CLIENT_USER && defaultClient.value == 0) {
            NotificationManager.error("Please select the client", "Permissions", 4000);
            return;
        }
        await saveAccessPermission();
    }

    const saveAccessPermission = async () => {
        const userParams = {
            permissionLevel: props.permissionLevel,
            meetingId: props.permissionLevel === UserTypes.MEETING_USER ? selectedUserMeeting.value : null,
            clientId: props.permissionLevel === UserTypes.CLIENT_USER ? defaultClient.value : null,
            id: selectedUser.Id
        }
        setIsSaving(true);
        const status = await Context.AddUserAccessPermission(userParams);
        if (status?.errorMessage === '') {
            await Context.GetReqestedPermissions(userParams);
        }
        else {
            NotificationManager.error(status?.errorMessage, "Permissions", 4000);
        }
        setIsSaving(false);
    }


    const GetMeetingName = () => {
        return (
            props.permissionLevel == UserTypes.MEETING_USER ?
                selectedUserMeeting?.name
                : null
        )
    }


    /**
     * Render function to render template options
     * */
    const renderTemplateOptions = () => {
        const { templates } = Context;
        return templates.map((template, index) => {
            return (
                <option key={template.TemplateId} value={template.TemplateId}>{template.Name}</option>
            )
        })
    }

    /**
     * Render user accessible meetings from selected client
     * */
    const renderUserMeetings = () => {
        if (userMeetings != undefined && userMeetings.length > 0) {
            return userMeetings.map((meeting, index) => {
                return (
                    <option key={meeting.VMeetingID} value={meeting.VMeetingID}>{meeting.MeetingName} </option>
                )
            })
        }
        return null;
    }

    /**
     * Render user accessible clients
     * */
    const renderUserClients = () => {
        if (clients.length > 0) {
            return clients.map((client, index) => {
                return (
                    <option key={client.ClientID} value={client.ClientID}>{client.ClientName} </option>
                )
            })
        }
        return null;
    }

    /**
     * render function for meeting and user status
     * */
    const renderUserMeetingStatus = () => {
        return (
            <>
                <label className="fw-bold">{`Permissions`} </label>
                <label className="ms-1">You are editing the permissions for  <strong>{`${selectedUser.ForeName} ${selectedUser.LastName || ''} `}</strong>
                    {props.permissionLevel != 'Global' && `for  ${props.permissionLevel} `}
                    <strong>
                        {
                            props.permissionLevel == UserTypes.CLIENT_USER ?
                                defaultClient?.name
                                :
                                GetMeetingName()
                        }
                    </strong>
                </label>
            </>
        )
    }


    /**
     * Function to render warning.
     * */
    const renderWarningMessage = () => {
        if (((props.permissionLevel === UserTypes.MEETING_USER && selectedUserMeeting?.value == null)
            || (props.permissionLevel === UserTypes.CLIENT_USER && defaultClient?.value == null))) {
            return (
                <div className="alert alert-warning" role="alert">
                    <label>{`Please select the ${props.permissionLevel}`}</label>
                </div>
            )
        }
        if (!userAccessPermission.WriteAccess) {
            return (
                <div className="alert alert-warning" role="alert">
                    <label>
                        {`${selectedUser.ForeName} ${selectedUser.LastName || ''} does not have ${props.permissionLevel} permission. Give ${selectedUser.ForeName} ${selectedUser.LastName || ''} access permission at ${props.permissionLevel} level `}
                    </label>
                    <button
                        type="button"
                        className="btn btn-primary ms-1"
                        title="Access Permission"
                        onClick={handleAddAccessPermission}
                        disabled={isSaving}
                    >Access Permission</button>
                </div>
            )
        }
    }

    const isClientOrMeetingSelected = () => {
        return (
            (props.permissionLevel === UserTypes.MEETING_USER && selectedUserMeeting?.value !== null)
            || (props.permissionLevel === UserTypes.CLIENT_USER && defaultClient?.value !== null)
            || props.permissionLevel === UserTypes.GLOBAL_USER
        )

    }

    const renderClearAll = () => {
        return (
            <div className="col-md-2 col-2 d-flex justify-content-start pb-1 ">
                <button
                    type="button"
                    className="btn btn-primary"
                    title="Clear All"
                    onClick={() => { props.handleClearAll(props.permissionLevel) }}
                >Clear All</button>
            </div>
        )
    }

    /**
     * Function will render feature permission table
     * 
     * */
    const renderFeatures = () => {
        return (
            <div className="container-fluid permissionList"> 
                {renderClearAll()}
                <div className="row mb-4">
                    {userPermissions.map((templateGroup, templateGroupIndex) => {
                        return (

                            <div key={templateGroupIndex} className="col-lg-6 col-md-12 mb-2">
                                <div className="switchBox">
                                    <div className="row permissionHeading">
                                        <div className="col-md-4  col-4">
                                            <div className="switchBoxHeading">
                                                <h5>{templateGroup.GroupName}</h5>
                                            </div>
                                        </div>
                                        <div className="col-md-2  col-2 d-flex justify-content-center align-items-center">
                                            <span className="permissionType readPermission pe-2">Read</span>
                                            <input
                                                type="checkbox"
                                                title="Check/uncheck all permissions"
                                                className="form-check-input-reverse"
                                                onChange={(event) => props.handleAllPermissionClick(templateGroup, event, props.permissionLevel)}
                                                name={AccessTypes.READ_ACCESS}
                                                checked={isAllPermissionsChecked(templateGroup, AccessTypes.READ_ACCESS)}
                                            />
                                        </div>
                                        <div className="col-md-2  col-2 d-flex justify-content-center align-items-center">
                                            <span className="permissionType writePermission pe-2">Write</span>
                                            <input
                                                type="checkbox"
                                                title="Check/uncheck all permissions"
                                                className="form-check-input-reverse"
                                                onChange={(event) => props.handleAllPermissionClick(templateGroup, event, props.permissionLevel)}
                                                name={AccessTypes.WRITE_ACCESS}
                                                checked={isAllPermissionsChecked(templateGroup, AccessTypes.WRITE_ACCESS)}
                                            />
                                        </div>
                                        <div className="col-md-2  col-2 d-flex justify-content-center align-items-center">
                                            <span className="permissionType grantPermission pe-2">Grant</span>
                                            <input
                                                type="checkbox"
                                                title="Check/uncheck all permissions"
                                                className="form-check-input-reverse"
                                                onChange={(event) => props.handleAllPermissionClick(templateGroup, event, props.permissionLevel)}
                                                name={AccessTypes.GRANT_ACCESS}
                                                checked={isAllPermissionsChecked(templateGroup, AccessTypes.GRANT_ACCESS)}
                                            />
                                        </div>
                                        <div className="col-md-2  col-2 d-flex justify-content-center align-items-center">
                                            <span className="permissionType revokePermission">Revoke</span>
                                        </div>
                                    </div>
                                    <div className="row d-flex align-items-center mb-2 rowLine featureList">
                                        {templateGroup.FeatureList.map((feature, index) => {
                                            return (
                                                <Fragment key={feature.FeatureId}>
                                                    <div className="col-md-4 col-4 text-wrap ">{feature.Feature}</div>
                                                    <div key={feature + index} className="col-md-2  col-2 d-flex justify-content-center">
                                                        <input
                                                            className="form-check-input-reverse"
                                                            type="checkbox"
                                                            value={feature.ReadAccess}
                                                            checked={feature.ReadAccess}
                                                            onChange={(e) => handlePermissionClick(templateGroupIndex, index, 'ReadAccess', e)}
                                                            disabled={isFeatureDisabled(feature, props.permissionLevel)}
                                                            title={feature.Feature + " Read Access"}
                                                            placeholder="Read Access"
                                                        />
                                                    </div>
                                                    <div className="col-md-2 col-2 d-flex justify-content-center">
                                                        <input
                                                            className="form-check-input-reverse"
                                                            type="checkbox"
                                                            value={feature.WriteAccess}
                                                            checked={feature.WriteAccess}
                                                            onChange={(e) => handlePermissionClick(templateGroupIndex, index, 'WriteAccess', e)}
                                                            disabled={isFeatureDisabled(feature, props.permissionLevel)}
                                                            title={feature.Feature + " Write Access"}
                                                            placeholder="Write Access"
                                                        />
                                                    </div>
                                                    <div className="col-md-2 col-2 d-flex justify-content-center">
                                                        <input
                                                            className="form-check-input-reverse"
                                                            type="checkbox"
                                                            value={feature.GrantAccess}
                                                            checked={feature.GrantAccess}
                                                            onChange={(e) => handlePermissionClick(templateGroupIndex, index, 'GrantAccess', e)}
                                                            disabled={isFeatureDisabled(feature, props.permissionLevel)}
                                                            title={feature.Feature + " Grant Access"}
                                                            placeholder="Grant Access"
                                                        />
                                                    </div>
                                                    <div className="col-md-2 col-2 d-flex justify-content-center">
                                                        <input
                                                            className={`form-check-input-reverse ${feature.FeatureRank === props.permissionLevel ? 'd-none' : ''}`}
                                                            type="checkbox"
                                                            value={feature.RevokeAccess}
                                                            checked={feature.RevokeAccess}
                                                            onChange={(e) => handlePermissionClick(templateGroupIndex, index, 'RevokeAccess', e, props.permissionLevel)}
                                                            disabled={feature.FeatureRank === props.permissionLevel || isRevokePermissionDisabled(feature)}
                                                            title={feature.Feature + " Revoke Access"}
                                                            placeholder="Revoke Access"
                                                        />
                                                    </div>
                                                </Fragment>

                                            )
                                        })}
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>
        )
    }


    /**
     * Render client list
     * */
    const renderClientList = () => {
        return (
            <select
                className="form-select"
                onChange={handleClientChange}
                value={defaultClient?.value}
            >
                <option value="0" >Select Client</option>
                {renderUserClients()}
            </select>
        )
    }

    /**
     * Render meeting list
     * */
    const renderMeetingList = () => {
        return (
            <select
                className="form-select"
                aria-label="Default select example"
                onChange={(e) => handleMeetingChange(e.target.value, e.target[e.target.selectedIndex].text)}
                value={selectedUserMeeting?.value}
            >
                <option value="0">Select Meeting</option>
                {renderUserMeetings()}
            </select>
        )
    }

    /**
     * If permissions afe fetched, function will display them
     * */
    const LoadPermissions = () => {
        return (userAccessPermission.WriteAccess && isClientOrMeetingSelected())
            ?
            renderFeatures()
            : <></>
    }

    const shouldRenderClients = () => {
        return (
            (!Context.isSingleClientUser)
            &&
            (props.permissionLevel === UserTypes.MEETING_USER || props.permissionLevel === UserTypes.CLIENT_USER)
        )
    }

    return (
        <div className="container-fluid border shadow-sm p-2">
            <div className="d-flex flex-row justify-content-between p-2">
                <div className={`col-3" ${(Context.isSingleClientUser && Context.isSingleMeetingUser) ? 'd-none' : ''}`}>
                    <div className="d-flex flex-column justify-content-between" >
                        <div className="d-none">
                            {
                                shouldRenderClients() ?
                                    renderClientList()
                                    : null
                            }
                        </div>

                        <div className="d-none pt-2">
                            {
                                (!Context.isSingleMeetingUser) && (props.permissionLevel === UserTypes.MEETING_USER) ?
                                    renderMeetingList()
                                    : null
                            }
                        </div>
                    </div>
                </div>

                <div className="col-3 d-none">
                    <select
                        onChange={e => handleChange(e, props.permissionLevel)}
                        className="form-select"
                        defaultValue={0}>
                        <option value="0">Permission Template</option>
                        {renderTemplateOptions()}
                    </select>
                </div>
            </div>
            <div className="d-flex flex-row justify-content-between p-2">

                <div className="col-12">
                    {
                        (userAccessPermission.WriteAccess && isClientOrMeetingSelected())
                            ?
                            renderUserMeetingStatus()
                            : renderWarningMessage()
                    }

                </div>
            </div>
            <div className="row p-2 d-flex justify-content-center ">
                {
                    isLoading ?
                        <div className="spinner-border text-primary" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </div>
                        :
                        LoadPermissions()
                }
            </div>
        </div>
    )

};

PermissionList.propTypes = {
    permissionLevel: PropTypes.string
};

export default PermissionList;