import { InstanceContext } from "./InstanceContext";
import React, { useEffect, useState } from "react";
import axios from "axios";

export function InstanceContextProvider({ children }) {

    const [instanceData, setInstanceData] = useState({
        instanceId: sessionStorage.getItem('VMId'),
        userId: sessionStorage.getItem('UserId'),
        appName: "CES JoinIn",
        hasSurvey: false,
        surveyId: -1
    })

    useEffect(() => {
        axios.post('api/CheckInstanceForSurvey', instanceData)
            .then(response => {
                setInstanceData({
                    ...instanceData,
                    hasSurvey: response.data.hasSurvey,
                    surveyId: response.data.surveyId
                })
            })
            .catch(function (error) { console.log(error); })
    }, [instanceData.surveyId])

    return (<InstanceContext.Provider value={{ instanceData, setInstanceData }}>
        {children}
    </InstanceContext.Provider>
    )
}