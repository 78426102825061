import React, { Component, Fragment } from 'react';
import { NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import authService from './AuthorizeService';
import axios from 'axios';
import SignalRController from '../SignalRController';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUsers, faSignal, faExclamationTriangle, faPeopleArrows, faUsersCog } from '@fortawesome/free-solid-svg-icons'
import { Modal } from 'react-bootstrap';
import DOMPurify from 'dompurify';
import { SignInButton } from './SignInButton';
import { SignOutButton } from './SignOutButton';
import AdminContext from '../context/AdminContext';
import { MeetingDetails } from '../meeting/MeetingDetails';


export class LoginMenu extends Component {
    constructor(props) {
        super(props);

        this.state = {
            userName: null,
            givenName: null,
            userID: null,
            selectValue: "",
            listofTitles: [],
            meetingDetails: {},
            VMId: '',
            roleName: '',
            meetings: '',
            refresh: false,
            signalRConnectedState: false,
            attendeeCount: 0,
            attendeeCountTimer: null,
            attendeeLockoutStatus: false,
            attendeeLockoutMessage: '',
            modalShow: false
        };
        //this.handleDropdownChange = this.handleDropdownChange.bind(this);
        this.connectionState = this.connectionState.bind(this);
        this.CountCallback = this.CountCallback.bind(this);
        this._unmounting = false;
        this.getLockoutStatus = null;
    }

    componentDidMount() {
        this._subscription = authService.subscribe(() => this.populateState());
        this.populateState();
        this.populateLockoutStatus();
        this.getLockoutStatus = setInterval(() => this.populateLockoutStatus(), 15000);
    }


    populateLockoutStatus = () => {
        if (this.props.isAuthenticated) {
            //excludes the axios interceptor
            const uninterceptedAxiosInstance = axios.create();
            uninterceptedAxiosInstance.get('api/GetLockout').then(response => {
                this.setState({
                    attendeeLockoutStatus: response.data.lockoutStatus,
                    attendeeLockoutMessage: response.data.displayMessage
                })
            })
        }
    }


    getEnvironment = () => {
        if (this.context.isAuthorized('AzureCapacity', 'Read') && sessionStorage.getItem('VMId') > 0) {
            axios.get('GetEnvironment')
                .then(response => {
                    console.log("Environment=", response.data);
                }).catch(function (error) {
                    console.log('error getting environment');
                });
        }
    }

    componentDidUpdate() {
        if (this.state.attendeeCountTimer && this.props.isAuthenticated) {
            //console.log("setting up attendee count timer")
        }
    }

    componentWillUnmount() {
        this._unmounting = true;
        if (this._subscription) authService.unsubscribe(this._subscription);
        SignalRController.removeCallbackHub2Adm_Attendance(this.CountCallback);
        SignalRController.removeCallback_SignalRStateChange(this.connectionState);
        clearInterval(this.getLockoutStatus);
        this.setState = (state, callback) => {
            return;
        };
    }

    CountCallback(nPingcount) {
        //console.log("back here",nPingcount)
        this.setState({ attendeeCount: nPingcount });
    }


    connectionState(message) {
        //console.log("menuSignalRstate")
        this.setState({
            signalRConnectedState: !this.state.signalRConnectedState
        });
        return;
    }

    async populateState() {
        const isAuthenticated = this.props.isAuthenticated;
        if (isAuthenticated && this.context.ready) {
            if (sessionStorage.getItem('VMId') != null) {
                this.setState({
                    clientId: sessionStorage.getItem('ClientId'),
                    VMId: sessionStorage.getItem('VMId'),
                })
            }

            SignalRController.addCallback_SignalRStateChange(this.connectionState);
            SignalRController.addCallbackHub2Adm_Attendance(this.CountCallback);
            SignalRController.ConnectionSetup(await this.context.fetchControllerToken());
            this.getEnvironment();

        }
    }

    toggleModal = () => {
        this.setState({
            modalShow: !this.state.modalShow
        })
    }

    sanitiseMessage = (inputvalue) => {
        let clean = DOMPurify.sanitize(inputvalue);
        clean = clean.replace(/\.(?=\S)/g, ". ");
        return { __html: clean };
    }

    render() {
        //console.log("loginmenu isAuthenticated", isAuthenticated)
        if (this.props.isAuthenticated) {
            return this.authenticatedView();
        } else {
            return this.anonymousView();
        }
    }

    authenticatedView() {
        var VMId = parseInt(sessionStorage.getItem('VMId'));
        //console.log("login menu vmID", VMId);
        const { meetingDetails } = this.state;

        if (isNaN(VMId) && this.state.VMId) {
            // We have lost the session data probably due to token refresh so repopulate it
            // console.log("repopulating local storage with",this.state)
            sessionStorage.setItem('VMId', this.state.VMId);
            sessionStorage.setItem('JobNo', this.state.jobNo);
            sessionStorage.setItem('ClientId', this.state.clientId);
            sessionStorage.setItem('UserId', this.state.userID);
            sessionStorage.setItem('BallotName', this.state.ballotName);
            VMId = this.state.VMId;
        }

        if (VMId > 0) {
            var sigState = "SigR " + SignalRController.connectionState() + " fa layers fa-fw";
            return (
                <Fragment>
                    <div className="titleBarWarnings">
                        {this.state.attendeeLockoutStatus &&
                            <span className="testWarning px-0" >
                                <button
                                    onClick={this.toggleModal}
                                    title="Click to display attendee message"
                                    className="testWarning btn py-0 my-0 mx-0 border-0">
                                    <FontAwesomeIcon
                                        icon={faExclamationTriangle}
                                        title={this.state.attendeeLockoutMessage} />
                                    Attendee Lockout
                                </button>
                            </span>
                        }
                        {this.context.switches.includeTestMetrics &&
                            <span className="testWarning" title="WARNING: Results may include TEST Users - disable via Setup" >
                                <FontAwesomeIcon
                                    icon={faExclamationTriangle}
                                    title="WARNING: Results may include TEST Users - disable via Setup" />
                                Test Users
                            </span>
                        }
                    </div>
                    <span className="attendeeCount" title="Attendee Count">
                        <FontAwesomeIcon icon={faUsers} size="lg" title="Attendee Count" />&nbsp;
                        {this.state.attendeeCount}
                    </span>
                    <span
                        className={sigState}
                        title="Signal state">
                        <FontAwesomeIcon
                            icon={faSignal}
                            size="lg"
                            title="Signal state" />
                    </span>
                    <NavItem>
                        <MeetingDetails />
                    </NavItem>
                    <NavItem>
                        <NavLink tag={Link} to='/meeting'
                            title="Select a meeting"
                            style={{ color: '#fff', textDecoration: 'none' }}
                            activestyle={{ color: 'red', textDecoration: 'none' }}>
                            <FontAwesomeIcon icon={faPeopleArrows} />
                        </NavLink>
                    </NavItem>

                    {this.context.isAuthorized('UserAdmin', 'Read') &&
                        <NavItem>
                            <NavLink tag={Link} to='/UserAdministration' title="User Management" style={{ color: '#fff', textDecoration: 'none' }} activestyle={{ color: 'red', textDecoration: 'none' }}><FontAwesomeIcon icon={faUsersCog} /></NavLink>
                        </NavItem>
                    }

                    <SignOutButton />

                    <LockoutModal modalShow={this.state.modalShow} toggleModal={this.toggleModal} message={this.sanitiseMessage(this.state.attendeeLockoutMessage)} />

                </Fragment>

            )
        }
        else {
            return (
                <Fragment>
                    {(window.location.pathname.toLowerCase() === '/useradministration') && <NavItem>
                        <NavLink tag={Link} to='/meeting'
                            title="Select a meeting"
                            style={{ color: '#fff', textDecoration: 'none' }}
                            activestyle={{ color: 'red', textDecoration: 'none' }}>
                            <FontAwesomeIcon icon={faPeopleArrows} />
                        </NavLink>
                    </NavItem>
                    }

                        <SignOutButton />
                </Fragment>
            )
        }
    }

    anonymousView() {
        return (<Fragment>
            <SignInButton />
        </Fragment>);
    }
}

LoginMenu.contextType = AdminContext;

function LockoutModal(props) {
    return (
        <Modal show={props.modalShow} onHide={() => props.toggleModal()} centered>
            <Modal.Header closeButton>
                <Modal.Title>Lockout message displayed to attendees</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="mx-2 my-2">
                    <div dangerouslySetInnerHTML={props.message} />
                </div>
            </Modal.Body>
            <Modal.Footer>
                <button className="btn btn-primary" onClick={() => props.toggleModal()}> Close </button>
            </Modal.Footer>
        </Modal >)
}


