import React, { useContext, useState } from 'react';
import { Fragment } from 'react';


import { CandidateCard } from './CandidateCard'
import ContestContext from '../Context/ContestContext';

import {
    DndContext,
    rectIntersection,
    KeyboardSensor,
    PointerSensor,
    useSensor,
    useSensors
} from '@dnd-kit/core';
import {
    arrayMove,
    SortableContext,
    sortableKeyboardCoordinates,
    verticalListSortingStrategy,
    useSortable
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';


export function CandidateSort(props) {
    const context = useContext(ContestContext);
    const [sortable, setSortable] = useState(false)


    const onSortEnd = (event) => {
        const { active, over } = event;
        const oldIndex = props.optionReferences.indexOf(active.id);
        const newIndex = props.optionReferences.indexOf(over.id);
        //Drag and drop sorting
        context.UpdateCandidateSort(oldIndex, newIndex);
    };
    if (sortable) {
        return (
            <Fragment>
                <div className="d-flex justify-content-between">
                    <h4>{props.VotingFor[0].toUpperCase() + props.VotingFor.slice(1)}s</h4>
                    <button className="btn btn-sm btn-secondary" type="button" onClick={() => setSortable(!sortable)}>Enable edit</button>
                </div>
                {props.optionReferences?.length > 0 &&
                    <ul className="VoteOptions nodots">
                        <SortableCandidateList onSortEnd={onSortEnd} items={props.optionReferences} {...props} sortable={sortable} />
                    </ul>
                }
            </Fragment >
        );
    } else {
        return (
            <Fragment>
                <div className="d-flex justify-content-between">
                    <h4>{props.VotingFor[0].toUpperCase() + props.VotingFor.slice(1)}s</h4>
                    {!props.disabled &&
                        <button className="btn btn-sm btn-secondary" type="button" onClick={() => setSortable(!sortable)}>Enable sort</button>
                    }
                </div>
                {
                    props.optionReferences.map((i) => {
                        return (
                            <li key={i} className="candidateList" >
                                <CandidateCard Index={i}
                                    ShowPhoto={props.ShowPhoto}
                                    ShowStatement={props.ShowStatement}
                                    sortable={sortable}
                                    disabled={props.disabled}
                                />
                            </li>
                        )
                    })
                }
            </Fragment>
        );
    }
}

const SortableCandidateList = ({ items, ...props }) => {
    const sensors = useSensors(
        useSensor(PointerSensor),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates,
        })
    );

    return (
        <DndContext
            sensors={sensors}
            //collisionDetection={closestCenter}
            collisionDetection={rectIntersection}
            onDragEnd={handleDragEnd}
        >
            <SortableContext
                items={items}
                strategy={verticalListSortingStrategy}
            >
                {items.map((item, i) => {
                    
                    return (
                        <SortableCandidates key={item} id={item}>
                            <li className="candidateList" >
                                <CandidateCard Index={i}
                                    ShowPhoto={props.ShowPhoto}
                                    ShowStatement={props.ShowStatement}
                                    sortable={props.sortable}
                                    disabled={props.disabled}
                                />
                            </li>
                        </SortableCandidates>
                    )
                })}
            </SortableContext>
        </DndContext>
    );

    function handleDragEnd(event) {
        props.onSortEnd(event);
    }

}


const SortableCandidates = (props) => {
    const {
        attributes,
        listeners,
        setNodeRef,
        transform,
        transition,
    } = useSortable({ id: props.id });

    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
    };

    return (
        <div ref={setNodeRef} style={style}>
            <div className="d-flex align-items-center mb-2">
                <div className="dragHandle mx-2"  {...attributes} {...listeners}>||</div>
                <div className="flex-fill">
                    {props.children}
                </div>
            </div>
        </div>
    );
}