import { Fragment, useState } from "react";
import { DisableAccount } from "./DisableAccount";
import GlobalPermission from "./GlobalPermission";
import NameEmail from "./NameEmail";
import RemoveAllPermissions from "./RemoveAllPermissions";

export default function AdminUpdateUserDetails({ selectedUser,...props }) {
    const [enableUpdate, setEnableUpdate] = useState(false);

    const toggleEnableUpdate = () => {
        setEnableUpdate(!enableUpdate)
    }

    return (
        <Fragment>
            <NameEmail enableUpdate={enableUpdate} toggleEnableUpdate={toggleEnableUpdate} />
            <div className="d-flex justify-content-between p-1">
                <button className="btn btn-primary btn-sm" disabled={enableUpdate} title="Enable user details" onClick={() => setEnableUpdate(true)}>Edit user details</button>
                <DisableAccount selectedUser={selectedUser} />
                <RemoveAllPermissions userId={selectedUser.Id} />
            </div>
            <GlobalPermission {...props} />
        </Fragment>
    )
}