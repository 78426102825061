import React, { Component } from 'react';
import axios from 'axios';
import { Row, Col } from 'reactstrap';
import { CSVLink } from "react-csv";
import { MeetingAttendance } from './MeetingAttendance';
import AdminContext from './context/AdminContext';
import { MembersList } from './MembersList';

export default class PostMeetingReport extends Component {
    constructor(props) {
        super(props);
        this.loggedInCurrent = false;
        this.VmeetingId = parseInt(sessionStorage.getItem('VMId'));
        this.state = {
            loginsummary: []
            , totalAttendee: 0
            , totalInRoom: 0
            , totalRemote: 0
            , loading: true
        };
        this.getReport = this.getReport.bind(this);

    }

    componentDidMount() {
        this.getReport();

    }
    componentWillUnmount() {
        // fix Warning: Can't perform a React state update on an unmounted component
        this.setState = (state, callback) => {
            return;
        };
    }

    getData = (loginsummary) => {
        let mb = [];

        if (!this.context.switches.hybrid) {

            loginsummary.map((mbi, i) => mb.push({
                index: i + 1,
                memberType: mbi.memberType,
                total: mbi.total,
                inRoom_sys: mbi.inRoom_sys,
                remote: mbi.remote
            }));
        } else { mb = loginsummary; }

        return mb;
    }

    getHeaders = () => {
        let headers = [];
        if (this.context.switches.hybrid == true) {
            headers = [
                { label: "Member Type", key: "memberType" },
                { label: "Total", key: "total" },
                { label: "InRoom", key: "inRoom" },
                { label: "InRoom_sys", key: "inRoom_sys" },
                { label: "Remote", key: "remote" }
            ];
        }
        else {

            headers = [
                { label: "Member Type", key: "memberType" },
                { label: "Total", key: "total" },
                { label: "InRoom_sys", key: "inRoom_sys" },
                { label: "Remote", key: "remote" }
            ];
        }
        return headers;
    }

    getReport() {
        axios.get('VMGetMeetingReportBreakdown', { params: { VMId: this.VmeetingId, loggedInCurrent: false, isHybrid: this.context.switches.hybrid } })
            .then(response => {
                let ls = [];
                ls = this.getData(response.data.loginSummary);

                this.setState({
                    //memberdata: mb,
                    loginsummary: ls,
                    totalAttendee: 0, //response.data.totalAttendee,  //RG set this 0 for now as i think this is legacy and no longer needed for V3
                    totalInRoom: 0,//response.data.totalInRoom,
                    totalRemote: 0,//response.data.totalRemote,
                    loading: false
                });
            }).catch(function (error) { console.log('error'); })
    }

    tabChange(e) {
        if (e === 1) {

        }
    }

    render() {
        const { loginsummary = [] } = this.state;
        const { authorized } = this.props;
        const { isAuthorized } = this.context;
        return (
            <div>
                {!this.state.loading ?
                    <div className="Reports">

                        {this.state.loginsummary && loginsummary.length > 0 ?
                            <div>
                                <div className="chat-heading-am">TOTAL ATTENDANCE</div><br />
                                {isAuthorized('DataExport', 'Read') &&
                                    <Row>
                                        <Col className="text-right" am="12">
                                            <CSVLink
                                                data={loginsummary}
                                                filename={"CESJoinInLoginSummary.csv"}
                                                headers={this.getHeaders()}
                                                className={`btn btn-primary`}>
                                                <i className="fa fa-file-text-o" aria-hidden="true" title="Export to CSV"></i>
                                            </CSVLink>
                                        </Col>
                                    </Row>
                                }

                                <Row>
                                    <Col sm="12">
                                        <MeetingAttendance postMeeting={true} showGuests={true} showTitle={false} />
                                    </Col>
                                </Row>

                                <hr />
                                <div className="chat-heading-am">LIST ATTENDEES</div><br />
                                <Row>
                                    <Col sm="12">
                                        <MembersList authorized={authorized} isloggedincurrent={this.loggedInCurrent} vmid={this.VmeetingId} />
                                    </Col>
                                </Row>
                                <hr />
                            </div>
                            :
                            <div>No data found for this meeting.</div>
                        }
                    </div>
                    :
                    <div className="dashHeading">Loading....</div>
                }
            </div>
        )
    }
}

PostMeetingReport.contextType = AdminContext;
