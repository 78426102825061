import React, { useEffect, useState, useContext, useRef } from 'react';
import AdminContext from '../../context/AdminContext';
import { InlineLoader } from '../../SecondaryComponents';
import { GetMessageGroupsForComfortInbox, GetMessagesForComfortGroup, GetMessageDetailsForComfort } from './ApiFunctions';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tag } from './Tag';
import { MessageIconSelector } from './Constants';
import { connection } from '../../SignalRController';
import { eventEmitter } from '../../context/AdminContextProvider';


export function ComfortMonitor(props) {
    const { meetingId } = useContext(AdminContext);
    const [groups, setGroups] = useState();
    const [loading, setLoading] = useState(true);

    const FetchData = async () => {
        let data = await GetMessageGroupsForComfortInbox(meetingId, 3);
        data = data.filter(a => a.currentInbox === 3);
        data = data.sort((a, b) => {
            //-1 is a first, 1 is b first
            if (a.currentInbox === b.currentInbox) {
                return a.displayOrder > b.displayOrder ? 1 : -1;
            } else {
                return a.currentInbox > b.currentInbox ? 1 : -1;
            }
        });
        setGroups(data);
        setLoading(false);
        eventEmitter.emit("CmUpdateActivity");
    }

    useEffect(() => {
        setLoading(true);
        FetchData();
    }, [])

    useEffect(() => {
        //Setup connection trigger for SR
        if (connection) {
            connection.on("srHubAdm2Adm_ComfortUpdate", () => {
                FetchData();
            });
        }

        //Cleanup connection event listener
        return () => {
            connection.off("srHubAdm2Adm_ComfortUpdate");
        };
    }, [connection])

    if (loading) {
        return <InlineLoader />
    }

    return (
        groups.map((group => <ComfortDisplayGroup group={group} key={group.messageGroupId} />))
    )
}

function ComfortDisplayGroup(props) {
    const { group } = props;
    const { meetingId } = useContext(AdminContext);
    const [loading, setLoading] = useState(true);
    const [messages, setMessages] = useState();
    const wordCount = useRef([]);
    const commentWordCount = useRef([]);
    const commentBool = useRef([]);
    const [totalCharCount, setTotalCharCount] = useState(0);
    const [fontSize, setFontSize] = useState("1vw");

    const FetchData = async () => {
        let data = await GetMessagesForComfortGroup(meetingId, group.messageGroupId)
        data = data.sort((a, b) => a.displayOrder > b.displayOrder ? 1 : -1);
        setMessages(data);
        setLoading(false);
    }

    const setWordCountFromChild = (index, count, commentCount) => {
        wordCount.current[index] = count;
        commentWordCount.current[index] = commentCount;
        commentBool.current[index] = commentCount > 0;
        calcTotalCharCount();
    }

    const calcTotalCharCount = () => {
        let localtotalCharCount = wordCount.current.reduce((sum, num) => sum + num, 0)
            + commentWordCount.current.reduce((sum, num) => sum + num, 0);
        setTotalCharCount(localtotalCharCount);
    }

    const chooseFontSize = (charCount) => {
        let baseSize = (1000 / (0.7 * (charCount + 150))) + 0.5;
        return baseSize;
    }

    //This calculates the number of characters in a line from any given vw font size;
    const lineCount = (fontSize) => (173 / (fontSize * 0.8)) - 7;

    useEffect(() => {
        //count of title rows in all messages
        let titleCount = commentBool.current.filter(Boolean)?.length + messages?.length;
        //base font size not allowing for titles
        let baseSize = chooseFontSize(totalCharCount);
        //add a number of characters for title rows based on estimated size of row for that font size
        //173/(A1*0.8) -7
        let _totalCharCount = totalCharCount + (lineCount(baseSize) * titleCount);
        //get a final font size allowing for titles
        let finalSize = chooseFontSize(_totalCharCount);

        let _lineCount = lineCount(finalSize);
        for (let i = 0; i < messages?.length; i++) {
            if (wordCount.current[i] < _lineCount) {
                wordCount.current[i] = _lineCount
            }
        }
        for (let i = 0; i < messages?.length; i++) {
            if (commentWordCount.current[i] > 0 && commentWordCount.current[i] < _lineCount) {
                commentWordCount.current[i] = _lineCount
            }
        }

        calcTotalCharCount();


        let output = finalSize + "vw";
        setFontSize(output);
    }, [totalCharCount])


    useEffect(() => {
        FetchData();
        // fix Warning: Can't perform a React state update on an unmounted component
        //Setup connection trigger for SR
        connection.on("srHubAdm2Adm_ComfortUpdateGroup:" + group.messageGroupId, () => {
            FetchData();
        });

        //Cleanup connection event listener
        return () => {
            connection.off("srHubAdm2Adm_ComfortUpdateGroup:" + group.messageGroupId);
        };
    }, [])

    if (loading) {
        return <InlineLoader />
    }

    return (
        <div className="w-100 bg-white comfort-wrapper py-1">
            {messages.map((message, index) => <ComfortDisplayMessage
                message={message}
                key={message.messageId}
                index={index}
                fontSize={fontSize}
                setWordCount={setWordCountFromChild} />)}
        </div >
    )
}

function ComfortDisplayMessage(props) {
    const { message, fontSize, index, setWordCount } = props;
    const { meetingId } = useContext(AdminContext);
    const [messageDetails, setMessageDetails] = useState();
    const [loading, setLoading] = useState(true);

    const FetchData = async () => {
        let data = await GetMessageDetailsForComfort(meetingId, message.messageId);
        if (data.tagListJson) {
            data.tags = JSON.parse(data?.tagListJson)
        }
        setMessageDetails(data);
        setLoading(false);
        setWordCount(index, data.messageText.length, data.messageNotes.length);
    }

    useEffect(() => {
        FetchData();
    }, [])


    if (loading) {
        return <InlineLoader />
    }

    return (
        <div style={{ fontSize: fontSize }} className="mb-2">
            <div className="border bg-light rounded p-2 bigger me-2">
                <div className="d-flex w-100 ">
                    <div className={`${messageDetails?.messageTypeId === 2 ? 'rounded bg-brand-tertiary text-light p-1':''}`}>
                        <FontAwesomeIcon icon={MessageIconSelector(messageDetails?.messageTypeId, messageDetails?.ready)} />
                    </div>
                    <div className="px-2 flex-fill">
                        <div className="d-flex align-items-center">
                            <div className="me-2">
                                {messageDetails.attendeeId > 0 ?
                                    <strong>{messageDetails?.userName}</strong>
                                    :
                                    <strong>Administrator</strong>
                                }
                            </div>
                            <div className="flex-fill d-flex flex-wrap tag-manager rounded justify-content-end">
                                {messageDetails?.tags?.length > 0 && messageDetails?.tags?.map(tag =>
                                    <Tag key={tag.messageTagId} tag={tag} />
                                )
                                }
                            </div>
                        </div>
                        <div>{messageDetails?.messageText}</div>
                    </div>
                </div>
                {messageDetails?.messageNotes?.length > 0 && <div className="cm-message-notes-padding" />}
            </div>
            {
                messageDetails?.messageNotes?.length > 0
                && <div className="p-2 rounded border cm-message-notes ms-4 ps-4 bg-highlight">
                    <div><em>Note:</em></div>
                    <div><em>{messageDetails.messageNotes ?? ""}</em></div>
                </div>
            }
        </div>
    )
}