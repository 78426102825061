import React from 'react'

const Button =(props)=>{
    const {children,format,active, ...rest} = props
    return(
        <button className={` btn btn-sm ${active ? 'btnActive' : ''}`} title={format}  {...rest}>
            {children}
        </button>
    )
}

export default Button;