import React, { Component } from 'react';
import { Container, Form, Modal } from 'react-bootstrap';
import SeenAndHeardItem from './SeenAndHeardItem';
import SeenAndHeardContext from './Context/SeenAndHeardContext';

export default class SeenAndHeard extends Component {
    constructor(props) {
        super(props)

        this.state = {
            loading: true,
            message:""
        };
    }

    
    //componentDidMount() {
    //    this.context.populateSeenAndHeard();
    //}


    componentDidUpdate(prevProps) {
        //Check that it's visible and previously not visible to populate
        if (this.props.show && this.props.show !== prevProps.show) {
            this.context.populateSeenAndHeard();
        }
    }

    componentWillUnmount() {
        this.setState = (state, callback) => {
            return;
        };
    }

    handleSubmit = async (e) => {
        this.setState({ message: "" })
        await this.context.submitSetup(e);
        await this.context.populateSeenAndHeard();
        this.setState({message: this.context.message})
    }


    render() {

        return (
            <Modal show={this.props.show} onHide={() => {
                this.props.close(this.context.changes);
                this.setState({
                    message:""
                })
            }} centered size="xl">
                <Modal.Header closeButton>
                    <Modal.Title>Seen and heard setup</Modal.Title>
                </Modal.Header>
                <Form onSubmit={this.handleSubmit}>
                    <Modal.Body>
                        {this.context.loading ? <div className="loaderholder"><div className="loader" /></div> :
                            <Container className="pt-3">
                                {this.context.seenAndHeardList.map((item, i) => {
                                    //this approach maintains the item in the index order to be able to remove new items without clashing with disabled items
                                    if (item.enabled) {
                                        return (
                                            <div key={"itemIndex" + i}>
                                                <SeenAndHeardItem index={i} item={item} isAuthorized={this.props.isAuthorized} />
                                            </div>
                                        )
                                    }
                                })}
                            </Container>
                        }

                        <button type="button" disabled={!this.props.isAuthorized} className="btn btn-primary mx-3" onClick={() => this.context.addNewItem()}>Add new item</button>
                    </Modal.Body>
                    <Modal.Footer>
                        {this.state.message && <p className="text-success">{this.state.message}</p>}
                        <button className="btn btn-secondary" type="button" onClick={() => {
                            this.props.close(this.context.changes);
                            this.setState({
                                message:""
                            })
                        }}>
                            Close
                        </button>
                        <button disabled={!this.props.isAuthorized || this.context.loading} className={`btn ${this.context.changes ? "btn-primary":"btn-secondary"} `} type="Submit">
                            Save Changes
                        </button>
                    </Modal.Footer>
                </Form>
            </Modal>
        );
    }
}


SeenAndHeard.contextType = SeenAndHeardContext;