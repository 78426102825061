import React, { Component, Fragment, useContext } from 'react';
import { Route, Routes } from 'react-router-dom'
import { Home } from './components/Home';
import { EventAdministration } from './components/MeetingSetup/EventAdministration';
import { EventSetup } from './components/MeetingSetup/EventSetup';
import { VoteAdministration } from './components/VoteAdministration';
import { Chat } from './components/chat/ChatV2';
import { ComfortMonitor } from './components/chat/ComfortMonitor/ComfortMonitor';
import { NotificationContainer } from 'react-notifications';
import AzureScale from './components/AzureScale';
import Report from './components/Report';
import { NavSide } from './components/NavSidebar';
import { NavMenu } from './components/NavMenu';
import { Container } from 'react-bootstrap';

import Summary from './components/Summary';
import { ChatContextProvider } from './components/context/ChatContextProvider';
import { Registration } from './components/Registration/Registration';
import { SelfRegistration } from './components/Registration/SelfRegistration';

import { Meeting } from './components/meeting/Meeting';
import { Logo } from './components/CivicaBrand';
import { GroupChat } from './components/chat/GroupChat';
import AdminContext from './components/context/AdminContext';
import UserAdministration from './components/UserAdministration';


import { VoteLoader } from './components/SecondaryComponents';
import SurveyAdminParent from './components/Survey/SurveyAdminParent';


const NotFound = () => <div> <h2>Not Found!</h2><p>The page you have requested has not been found, please check the address and try again.</p> </div>;
function NotAuthorised(props) {
    const { userData } = useContext(AdminContext);
    return (
        <Container><div> <h2>Not Authorised!</h2><p>You are not authorised to view this page.</p> {!!userData?.LockoutEnabled && <p>Your account has been disabled, please speak to your client advisor for assistance.</p>}</div></Container>
    )
};

export default class App extends Component {
    static displayName = App.name;
    static contextType = AdminContext;
    constructor(props) {
        super(props);
        this.state = {
            ready: false
        }
        console.log("Version 3.10.0");
    }

    componentDidMount() {
        //Authentication subscription set up
        this.getSettings();
    }

    componentWillUnmount() {
        // fix Warning: Can't perform a React state update on an unmounted component
        this.setState = (state, callback) => {
            return;
        };
    }

    getSettings = () => {
        this.context.populateswitchdata(false);
    }



    render() {
        const { isAuthorized, isAuthenticated } = this.context;
        const { ready, userId, meetingId } = this.context;
        let link = document.createElement("a");
        link.href = this.props.path;

        if (isAuthenticated && ready && userId && meetingId) return (
            <div className="appParent overflow-hidden" >
                <Routes>
                    <Route path='/selfregistration' element={isAuthorized('SelfRegistration', 'Read') ? <SelfRegistration/> : <NotAuthorised/>} />
                    <Route path='/registration' element={isAuthorized('Registration', 'Read') ? <Registration/> : <NotAuthorised/>} />
                    <Route path='/*' element={<RouterBlock isAuthenticated={isAuthenticated} />} />
                </Routes>
            </div>
        )
        //If meeting not set, any path should be userAdmin or meeting select
        else if (isAuthenticated && ready && userId && !meetingId) return (
            <Routes>
                <Route path='*' element={<MeetingSelectBlock />} />
            </Routes>
        )
        else if (isAuthenticated) {
            return (
                <Routes>
                    <Route path='*' element={<VoteLoaderBlock />} />
                </Routes>
            )
        } else {
            return (
                <Routes>
                    <Route path='*' element={<ElseBlock />} />
                </Routes>
            )
        }
    }
}
App.contextType = AdminContext;

function RouterBlock(props) {
    const { isUserHasPageAccess, isGlobalAuthorized, GetUserAccessiblePages, meetingId, CheckUserMeetingPermissions } = useContext(AdminContext);
    let pages = GetUserAccessiblePages();
    const HomeComponent = extGetHomePage(pages);
    return (
        <Fragment>
            <NotificationContainer />
            {meetingId > 0 && pages?.length > 1 && <NavSide isAuthenticated={props.isAuthenticated} />}

            <div className="main vh-100 overflow-auto">
                <NavMenu isAuthenticated={props.isAuthenticated} />
                <Container fluid>
                    <div className="row fillheight">
                        <div className="col-md-12 mainArea">
                            <ChatContextProvider>
                                { /* All children of the Routes must be a Route */}
                                <Routes>
                                    <Route path='/' element={<HomeComponent />} />
                                    <Route path='/home' element={<HomeComponent />} />
                                    <Route path='/meeting' element={<Meeting />} />
                                    <Route path='/eventadministration' element={isUserHasPageAccess('EventAdmin', 'Read') ? <EventAdministration /> : <NotAuthorised />} />
                                    <Route path='/eventsetup' element={isUserHasPageAccess('Setup', 'Read') ? <EventSetup /> : <NotAuthorised />} />
                                    <Route path='/voteadministration' element={isUserHasPageAccess('VoteAdmin', 'Read') ? <VoteAdministration /> : <NotAuthorised />} />
                                    <Route path='/report' element={isUserHasPageAccess('Report', 'Read') ? <Report /> : <NotAuthorised />} />
                                    <Route path='/summary' element={isUserHasPageAccess('PresSummary', 'Read') ? <Summary /> : <NotAuthorised />} />
                                    <Route path='/comfort' element={isUserHasPageAccess('ComfortMonitor', 'Read') ? <ComfortMonitor /> : <NotAuthorised />} />
                                    <Route path='/chat' element={isUserHasPageAccess('Moderators', 'Read') ? <Chat /> : <NotAuthorised />} />
                                    <Route path='/useradministration' element={isUserHasPageAccess('UserAdmin', 'Read') ? <UserAdministration /> : <NotAuthorised />} />
                                    <Route path='/azurescale' element={isGlobalAuthorized('AzureCapacity', 'Read') ? <AzureScale /> : <NotAuthorised />} />
                                    <Route path='/survey' element={isUserHasPageAccess('SurveyAdmin', 'Read') ? <SurveyAdminParent /> : <NotAuthorised />} />

                                    <Route path="*" element={<NotFound />} />
                                </Routes>
                                {meetingId &&
                                    CheckUserMeetingPermissions('AdminChat', 'Read') &&
                                    <GroupChat authorize={CheckUserMeetingPermissions('AdminChat', 'Write')} />
                                }
                            </ChatContextProvider>
                        </div>
                    </div>
                </Container>
            </div>
        </Fragment>
    )
}

function VoteLoaderBlock(props) {
    const { isAuthenticated } = useContext(AdminContext);
    return (
        <div className="main">
            <NavMenu isAuthenticated={isAuthenticated} />
            <VoteLoader />
        </div>
    )
}

function ElseBlock(props) {
    const { isAuthenticated } = useContext(AdminContext);
    return (
        <div className="main">
            <NavMenu isAuthenticated={isAuthenticated} />
            <Logo />
        </div>
    )
}

function MeetingSelectBlock(props) {
    const { isAuthenticated, isGlobalAuthorized } = useContext(AdminContext);
    return (
        <div className="main">
            <NotificationContainer />
            <NavMenu isAuthenticated={isAuthenticated} />
            <Routes>
                <Route path='/useradministration'
                    element={
                        isGlobalAuthorized('MetaUserAdmin', 'Read') ?
                            <UserAdministration /> :
                            <NotAuthorised />
                    }
                />
                <Route path="*" element={<Meeting />} />
            </Routes>
        </div>
    )
}

function extGetHomePage(pages) {
    let component;
    if (pages?.length > 1) {
        component = Home;
    } else if (pages?.length === 1) {
        switch (pages[0]) {
            case "Moderators":
                component = Chat;
                break;
            case "PresSummary":
                component = Summary;
                break;
            case "ComfortMonitor":
                component = ComfortMonitor;
                break;
            case "UserAdmin":
                component = UserAdministration;
                break;
            case "EventAdmin":
                component = EventAdministration;
                break;
            case "Setup":
                component = EventSetup;
                break;
            case "AttendReg":
                component = Registration;
                break;
            case "AttendSelfReg":
                component = SelfRegistration;
                break;
            case "Report":
                component = Report;
                break;
            case "VoteAdmin":
                component = VoteAdministration;
                break;
            case "SystemInfo":
                component = AzureScale;
                break;
            default:
                component = Home;
        }
    }
    return component;
}
