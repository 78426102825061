import { useEffect, useRef, useState } from "react";

export function useCountDownTimer(countDownTo, active) {
    // Set the date we're counting down to
    const timer = useRef(null);
    const [time, setTime] = useState(null)
    const [countDownDate, setCountDownDate] = useState(new Date().getTime() + countDownTo)

    useEffect(() => {
        if(active) {
            setCountDownDate(new Date().getTime() + countDownTo);
        } else if (timer.current) {
            clearInterval(timer.current);
            setTime(null)
        }

    }, [active])


    useEffect(() => {
        if (active) {
            // Update the count down every 1 second
            timer.current = setInterval(function () {

                // Get today's date and time
                let now = new Date().getTime();               

                // Find the distance between now and the count down date
                let distance = countDownDate - now;

                // Time calculations for days, hours, minutes and seconds
                let minutes = Math.floor(distance / (1000 * 60));
                let seconds = Math.floor((distance % (1000 * 60)) / 1000);

                if (distance >= 0) {
                    // Output the result in an element with id="demo"
                    setTime(minutes + "m " + seconds + "s ");
                } else if (distance < 0) {
                    // If the count down is over, write some text 
                    clearInterval(timer.current);
                    setTime("EXPIRED");
                }
            }, 1000);
        } else {
            clearInterval(timer.current);
        }
        return () => {
            clearInterval(timer.current)
        };
    }, [countDownDate])

    return time;
}