import axios from 'axios';

export const VMUpdateCommonLink = async (enableCommonLink, commonLink) => {
    let VMCommonLink = {
        UserId: 0,
        VmeetingId: 0,
        EnableCommonLink: enableCommonLink,
        CommonLink: commonLink
    }
    let response = await axios.post("api/VMUpdateCommonLink", VMCommonLink).then(res => { return res.data });
    return response;
}