import React from 'react';
import UserAdminSection from '../components/UserAdministration/index'
import UserPermissionContextProvider from './UserAdministration/UserPermissionContextProvider';

const UserAdministration = () => {
    return (
        <UserPermissionContextProvider>
            <UserAdminSection />
        </UserPermissionContextProvider>
    )
}

export default UserAdministration