import React, { Fragment, Component, useContext, useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import './chat.css';
import { ChatList } from './ChatList';
import SignalRController, { connection } from '../SignalRController.js';
import { Broadcast } from '../Broadcast';
import axios from 'axios';
import { ChatCard } from './ChatCard'
import ChatContext from './../context/ChatContext';
import AdminContext from '../context/AdminContext';
import { ChatResponsePopup } from './ChatResponsePopup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Requests } from './Requests';
import { AdminOpenChatParent } from '../OpenChat/AdminOpenChatParent';
import { ComfortManagement } from './ComfortMonitor/ComfortManagement';
import { ComfortPresenter } from './ComfortMonitor/ComfortPresenter';
import { ActiveInboxName, ActiveInboxPermission, ButtonIconSelector } from './ComfortMonitor/Constants';




export function Chat(props) {
    const { isAuthorized } = useContext(AdminContext);
    const { GetComfortTagList } = useContext(ChatContext);

    useEffect(() => {
        //Setup connection trigger for SR
        if (connection) {

            connection.on("srHubAdm2Adm_ComfortTagUpdate", () => {
                GetComfortTagList();
            });
        }
        //Cleanup connection event listener
        return () => {

            connection.off("srHubAdm2Adm_ComfortTagUpdate");
        };
    }, [connection])


    return (<ChatParent isAuthorized={isAuthorized} />)
}


class ChatParent extends Component {
    constructor(props) {
        super(props)

        this.VmeetingId = parseInt(sessionStorage.getItem('VMId'));

        this.state = {
            chats: [],
            chatClick: null,
            inboxClick: null,
            errors: {},
            chatResponsePopup: false,
            modal2IsOpen: false,
            Loading: true,
            unreadCount: [],
            requestMessageId: 1,
            linkAddress: null,
            noforward: null,
            activeWindow: ''
        }

        this.ignoreMessage = this.ignoreMessage.bind(this);
        this.answeredMessage = this.answeredMessage.bind(this);
        this.srCallbackRefreshMessages = this.srCallbackRefreshMessages.bind(this);
        this.toggleChatResponse = this.toggleChatResponse.bind(this);
        this.respond = this.respond.bind(this);
        this.updateChatResponse = this.updateChatResponse.bind(this);
        this.removeChatFromInbox = this.removeChatFromInbox.bind(this);
        this.refreshChat = this.refreshChat.bind(this);
    }

    updateChatResponse(adminChatResponses) {
        this.setState({ chatResponses: adminChatResponses });
    }

    srCallbackRefreshMessages = async () => {
        let tempChat = this.state.chatClick;
        await this.context.getChats();

        let chatData = this.context.chats;

        // If the messaging popup is active we also need to see if that needs refreshing
        // We can do this by checking the last message for the user and comparing to what was previously clicked
        if (tempChat && this.state.chatResponsePopup === true && chatData.messages.length > 0) {
            let newChat = chatData.messages.find((chat) => chat.attendeeId === tempChat.attendeeId && chat.receivedDateTime > tempChat.receivedDateTime);
            if (newChat && newChat.messages && newChat.chatId !== tempChat.chatId) {
                this.setState({ chatClick: newChat })
            }
        }

        // Note the return value for the Presenter and Group inboxes is different to all other inboxes
        // They just returns a list of messages, the other boxes return messages and unread count

        // If you add another inbox in here - you edit the data model in the WebAPI and add the inbox number to the roles allowed to see it


        this.setState({ Loading: false });
    }

    async componentDidMount() {
        let isAuthorized = this.props.isAuthorized;
        let permitted = (isAuthorized('InboxPrim', 'Read')
            || isAuthorized('InboxMod', 'Read')
            || isAuthorized('InboxPres', 'Read')
            || isAuthorized('InboxTech', 'Read')
            || isAuthorized('Requests', 'Read')
            || isAuthorized('InboxSearch', 'Read')
            || isAuthorized('InboxTriage', 'Read')
            || isAuthorized('InboxQueue', 'Read')
        );
        //Prevent get messages running if you don't have permission
        if (permitted) {
            SignalRController.addCallbackAdm2Adm_MessageUpdate(this.srCallbackRefreshMessages);
            this.srCallbackRefreshMessages();
        }
    }

    componentWillUnmount() {
        SignalRController.removeCallbackAdm2Adm_MessageUpdate(this.srCallbackRefreshMessages);
        // fix Warning: Can't perform a React state update on an unmounted component
        this.setState = (state, callback) => {
            return;
        };
    }

    respond(chat) {
        this.setState({
            modal2IsOpen: !this.state.modal2IsOpen,
            activeChat: chat
        });
    }

    async toggleChatResponse(chat, inbox, type = '') {
        // If toggling the chat off - then refresh the messages
        if (this.state.chatResponsePopup === true) {
            await this.srCallbackRefreshMessages();
            this.setState({
                requestMessageId: 1,
                linkAddress: null,
                activeWindow: type
            })
        }

        this.setState({
            chatResponsePopup: !this.state.chatResponsePopup,
            chatClick: chat,
            inboxClick: inbox,
            activeWindow: type
        });
    }

    toggleRequestResponse = async (chat, inbox, messageId, linkAddress, noforward, type = '') => {
        // If toggling the chat off - then refresh the messages
        if (this.state.chatResponsePopup === true) {
            await this.srCallbackRefreshMessages();
            this.setState({
                chatResponsePopup: !this.state.chatResponsePopup,
                requestMessageId: 1,
                linkAddress: null,
                noforward: null,
                activeWindow: type
            })
        } else {
            this.setState({
                chatResponsePopup: !this.state.chatResponsePopup,
                chatClick: chat,
                inboxClick: inbox,
                requestMessageId: messageId,
                linkAddress: linkAddress,
                noforward: noforward,
                activeWindow: type
            });
        }
    }

    closeChatResponse = async (chat, inbox) => {
        //using toggle was causing issues reported by testers
        if (this.state.chatResponsePopup === true)
            await this.srCallbackRefreshMessages();

        this.setState({
            chatResponsePopup: false,
            chatClick: chat,
            inboxClick: inbox,
            requestMessageId: 1,
            linkAddress: null,
            noforward: null
        });
    }


    // This is the Presenter Ignore option - remove it from the Presenter Inbox and put it back in the Admin Inbox
    async ignoreMessage(e, chat) {
        e.stopPropagation();

        // Add chat back into Moderator Admin Inbox
        await axios.get('api/AddChatToInbox', { params: { vmId: this.VmeetingId, chatId: chat.messageId, inbox: 1 } }).then(res => {
        })

        // Remove Chat from Presenter Inbox (4)
        await axios.get('api/RemoveChatFromInbox', { params: { VmId: this.VmeetingId, chatId: chat.messageId, attendeeId: chat.attendeeId, inbox: 4, oneOrMany: 0 } }).then(res => {
        })

        await axios.get('api/UpdateMessageFlags', { params: { VmId: this.VmeetingId, chatId: chat.messageId, attendeeId: chat.attendeeId, passedBack: 1, answered: chat.answered } }).then(res => {
        })

        this.refreshChat();
    }

    // This is the Presenter Answered Message option - Flag it send a message back to the user
    async answeredMessage(e, chat) {
        e.stopPropagation();

        let vmID = parseInt(sessionStorage.getItem('VMId'));
        let origMsg = chat.messageText.length > 100 ? chat.messageText.substring(0, 100) + '...' : chat.messageText;
        let chatResponse = {
            AdminId: 1, // not used
            VMeetingId: vmID,
            QuestionText: "Automated Message: This question '" + origMsg + "' has been answered in the meeting.",
            SubmittedById: chat.attendeeId,
            SubmittedByName: 'notused',
            messageDirection: 99  // Not Used
        };

        await axios.get('api/UpdateMessageFlags', { params: { VmId: this.VmeetingId, chatId: chat.messageId, attendeeId: chat.attendeeId, passedBack: chat.passedBack, answered: 1 } }).then(res => {
        })

        await axios.post('api/SubmitAdminMessage', chatResponse).then(res => {
            this.setState({
                inputMessage: ''
            });
        })

        this.refreshChat();
    }


    async removeChatFromInbox(chat, inbox) {
        axios.get('api/RemoveChatFromInbox', { params: { VmId: this.VmeetingId, chatId: chat.messageId, attendeeId: chat.attendeeId, inbox: inbox, oneOrMany: 1 } }).then(res => {
            this.setState({ inputMessage: '' });
        })

        this.refreshChat();
    }

    async refreshChat() {
        await this.context.getChats();
        let chatData = this.context.chats;
        this.setState({ chats: chatData, Loading: false });
    }
    /* 
     *  Pull out the latest messages from each user to only display one line per user in the inbox
     *  */

    /*
        case 1:
                'InboxPrim';
        case 2:
                'InboxMod';
        case 4:
                'InboxPres';
        case 8:
                'InboxTech';        
    */

    render() {
        if (this.context.loading === false) {
            let { adminInboxUnread, moderatorInboxItems, moderatorInboxLatest, presenterInboxItems, techInboxItems, techInboxLatest, techInboxUnread } = this.context;

            return (
                <AdminContext.Consumer>
                    {AdminContext => (
                        <Fragment>
                            {this.state.chatResponsePopup &&
                                <ChatResponsePopup
                                toggle={this.toggleChatResponse}
                                close={this.closeChatResponse}
                                chat={this.state.chatClick}
                                inbox={this.state.inboxClick}
                                VMeetingId={this.VmeetingId}
                                PresenterInbox={presenterInboxItems}
                                ModeratorInbox={moderatorInboxItems}
                                TechInbox={techInboxItems}
                                requestMessageId={this.state.requestMessageId}
                                linkAddress={this.state.linkAddress}
                                noforward={this.state.noforward}
                                isAuthorized={AdminContext.isAuthorized(this.state.activeWindow, 'Write')}
                                isForwardAuthorized={AdminContext.isAuthorized('InboxPrim', 'Write')}
                                authFunc={AdminContext.isAuthorized}
                                activeWindow={this.state.activeWindow}
                                />}
                            <Tabs defaultIndex={0} onSelect={this.tabChange} >

                                <TabList>
                                    {
                                        (
                                            AdminContext.isAuthorized('InboxPrim', 'Read')
                                            || AdminContext.isAuthorized('InboxMod', 'Read')
                                            || AdminContext.isAuthorized('InboxPres', 'Read')
                                        ) &&
                                        <Tab>Active</Tab>
                                    }
                                    {
                                        (
                                            AdminContext.isAuthorized('OpenChat', 'Read')
                                            || AdminContext.isAuthorized('OpenChatMod', 'Read')
                                            || AdminContext.isAuthorized('OpenChatStats', 'Read')
                                        ) &&
                                        <Tab disabled={!(AdminContext.switches.enableOpenChat || AdminContext.switches.inRoomOpenChat)}>Open Chat</Tab>
                                    }
                                    {

                                        (
                                            AdminContext.isAuthorized('InboxTriage', 'Read')
                                            || AdminContext.isAuthorized('InboxQueue', 'Read')
                                        ) &&
                                        <Tab>Triage</Tab>
                                    }

                                    {
                                        AdminContext.isAuthorized('Requests', 'Read') &&
                                        <Tab disabled={!(AdminContext.switches.seenAndHeard || AdminContext.switches.inRoomSeenAndHeard)}>Requests</Tab>
                                    }

                                    {//Search
                                        AdminContext.isAuthorized('InboxSearch', 'Read') &&
                                        <Tab>Search</Tab>
                                    }

                                    {//Broadcast
                                        AdminContext.isAuthorized('Broadcast', 'Read') &&
                                        <Tab>Broadcast</Tab>
                                    }
                                    {
                                        AdminContext.isAuthorized('InboxTech', 'Read') &&
                                        <Tab>Tech Support</Tab>
                                    }


                                </TabList>
                                {
                                    (
                                        AdminContext.isAuthorized('InboxPrim', 'Read')
                                        || AdminContext.isAuthorized('InboxMod', 'Read')
                                        || AdminContext.isAuthorized('InboxPres', 'Read')
                                    )
                                    &&
                                    <TabPanel eventkey="Active"  >
                                        <ActiveInboxes loading={this.state.Loading}
                                            toggleChatResponse={this.toggleChatResponse}
                                            removeChatFromInbox={this.removeChatFromInbox}
                                            ignoreMessage={this.ignoreMessage}
                                            answeredMessage={this.answeredMessage}
                                        />
                                    </TabPanel >
                                }
                                {
                                    // All Messages and Management panel - allows moderators and meeting admins to broadcast, refresh and kick attendee's
                                }

                                {//Open chat
                                    (
                                        AdminContext.isAuthorized('OpenChat', 'Read')
                                        || AdminContext.isAuthorized('OpenChatMod', 'Read')
                                        || AdminContext.isAuthorized('OpenChatStats', 'Read')
                                    ) &&
                                    <TabPanel>
                                        <AdminOpenChatParent />
                                    </TabPanel>
                                }
                                {//Comfort Monitor control
                                    (
                                        AdminContext.isAuthorized('InboxTriage', 'Read')
                                        || AdminContext.isAuthorized('InboxQueue', 'Read')
                                    ) &&
                                    <TabPanel>
                                        <ComfortManagement />
                                    </TabPanel>
                                }



                                {//Requests
                                    AdminContext.isAuthorized('Requests', 'Read') &&
                                    <TabPanel>
                                        <Requests
                                            unreadCount={adminInboxUnread}
                                            respond={this.toggleRequestResponse}
                                            inbox={1}
                                            isAuthorized={AdminContext.isAuthorized('Requests', 'Write')}
                                            type={'Requests'}
                                        />
                                    </TabPanel>
                                }

                                {//Search
                                    AdminContext.isAuthorized('InboxSearch', 'Read') &&

                                    <TabPanel eventkey="Search">
                                        <ChatList
                                            VmeetingId={this.VmeetingId}
                                            isAuthorized={AdminContext.isAuthorized('InboxSearch', 'Write')}
                                        />
                                    </TabPanel>
                                }

                                {//Broadcast
                                    AdminContext.isAuthorized('Broadcast', 'Read') &&
                                    <TabPanel eventkey="Manage"  >
                                        <Broadcast isAuthorized={AdminContext.isAuthorized('Broadcast', 'Write')} />
                                    </TabPanel>
                                }

                                {//Tech Support

                                    AdminContext.isAuthorized('InboxTech', 'Read') &&
                                    <TabPanel eventkey="TechSupport">
                                        <Row>
                                            <Col className="col-6">
                                                <div className="col-12">
                                                    <div className="chat-heading">TECH SUPPORT - INBOX</div>
                                                    <div className="chat-box">
                                                        {techInboxItems && techInboxItems.length > 0 &&
                                                            <ChatCard
                                                                chatItems={techInboxLatest}
                                                                respond={this.toggleChatResponse}
                                                                key="8"
                                                                removeChatFromInbox={this.removeChatFromInbox}
                                                                inbox={8}
                                                                unreadCount={techInboxUnread}
                                                                presenterInboxItems={presenterInboxItems}
                                                                moderatorInboxItems={moderatorInboxLatest}
                                                                isAuthorized={AdminContext.isAuthorized('InboxTech', 'Write')}
                                                                authFunc={AdminContext.isAuthorized}
                                                                type={'InboxTech'}
                                                            />
                                                        }
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                    </TabPanel>
                                }
                            </Tabs>
                        </Fragment>
                    )}
                </AdminContext.Consumer>
            )
        } else return (<div>Loading...</div>);
    }
}

ChatParent.contextType = ChatContext;


function ActiveInboxes(props) {
    let { adminInboxItems, adminInboxLatest, adminInboxUnread, presenterInboxItems, moderatorInboxLatest, techInboxItems, moderatorInboxUnread, techInboxLatest } = useContext(ChatContext);
    let { cv2InboxSelector: inboxDisplay, setCv2InboxSelector: setInboxDisplay } = useContext(ChatContext);
    let { user } = useContext(ChatContext);
    let { isAuthorized } = useContext(AdminContext);

    return (
        <div className="d-flex">
            <div className="row flex-fill">
                {
                    inboxDisplay[0] && isAuthorized('InboxPrim', 'Read') &&
                    <div className="col-lg">
                        <div className="chat-heading">INBOX</div>
                        <div className="chat-box">
                            {
                                !props.loading &&
                                adminInboxItems &&
                                <ChatCard
                                    chatItems={adminInboxLatest}
                                    respond={props.toggleChatResponse}
                                    key="0"
                                    removeChatFromInbox={props.removeChatFromInbox}
                                    inbox={1}
                                    userId={user}
                                    unreadCount={adminInboxUnread}
                                    adminInboxItems={adminInboxItems}
                                    presenterInboxItems={presenterInboxItems}
                                    moderatorInboxItems={moderatorInboxLatest}
                                    techInboxItems={techInboxItems}
                                    isAuthorized={isAuthorized('InboxPrim', 'Write')}
                                    authFunc={isAuthorized}
                                    type={'InboxPrim'}
                                />
                            }

                        </div>
                    </div>
                }
                {
                    inboxDisplay[1] && isAuthorized('InboxMod', 'Read') &&
                    <div className="col-lg">
                        <div className="h-100">
                            <div className="chat-heading">MODERATORS</div>
                            <div className="chat-box">
                                {
                                    !props.loading ?
                                        moderatorInboxLatest &&
                                        <ChatCard
                                            chatItems={moderatorInboxLatest}
                                            respond={props.toggleChatResponse}
                                            key="1"
                                            userId={user}
                                            unreadCount={moderatorInboxUnread}
                                            adminInboxItems={adminInboxItems}
                                            presenterInboxItems={presenterInboxItems}
                                            moderatorInboxItems={moderatorInboxLatest}
                                            techInboxItems={techInboxLatest}
                                            removeChatFromInbox={props.removeChatFromInbox}
                                            inbox={2}
                                            isAuthorized={isAuthorized('InboxMod', 'Write')}
                                            authFunc={isAuthorized}
                                            type={'InboxMod'}
                                        /> :
                                        <div className="dashHeading">Loading....</div>
                                }
                            </div>
                        </div>
                    </div>
                }
                {
                    inboxDisplay[2] && isAuthorized('InboxPres', 'Read') &&
                    <div className="col-lg">
                        <ComfortPresenter activeInbox />
                    </div>
                }
            </div>
            <div>
                <InboxDisplaySelector inboxDisplay={inboxDisplay} setInboxDisplay={setInboxDisplay} />
            </div>
        </div>

    )
}

function InboxDisplaySelector(props) {
    const { isAuthorized } = useContext(AdminContext);
    const { inboxDisplay, setInboxDisplay } = props;

    const ChangeDisplayState = (inboxId) => {
        let _inboxDisplay = [...inboxDisplay];
        _inboxDisplay[inboxId] = !_inboxDisplay[inboxId]
        setInboxDisplay(_inboxDisplay);
    }
    const buttonSetup = [];
    for (let i = 0; i <= 2; i++) {
        if (isAuthorized(ActiveInboxPermission[i], 'Read')) {
            let setButton = <div key={i} >
                <button
                    title={`${inboxDisplay[i] ? "Hide" : "Show"} ${ActiveInboxName[i]}`}
                    className={`w-100 mb-1 btn ${inboxDisplay[i] ? "btn-success" : "btn-warning"}`}
                    onClick={() => ChangeDisplayState(i)}
                >
                    <FontAwesomeIcon icon={ButtonIconSelector[i + 1]} />
                </button>
            </div>;
            buttonSetup.push(setButton);
        }
    }

    return (
        <div>
            {buttonSetup}
        </div>
    )
}