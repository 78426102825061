import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { OverlayTrigger, Tooltip, Popover } from 'react-bootstrap';

const popover = (helpText) => {
	return (
		<Popover id="popover-basic">
			<Popover.Header as="h3">Image restrictions</Popover.Header>
			<Popover.Body>
				{helpText}
			</Popover.Body>
		</Popover>
	)
};

export function HelpButton({ helpText }) {
	return (
		<OverlayTrigger
			placement="bottom"
			trigger={["hover", 'focus']}
			overlay={popover(helpText)}
		>
			<button className="btn btn-help px-2"><FontAwesomeIcon icon={faQuestionCircle} /> </button>
		</OverlayTrigger>
	);
}