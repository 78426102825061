import React, { Component, useContext } from 'react';

import { Container, Row, Col, Form, Button, FloatingLabel, AccordionContext, useAccordionButton, Accordion } from 'react-bootstrap';
import SimpleReactValidator from 'simple-react-validator';
import { CandidateSort } from './CandidateComponents/CandidateSort';
import ContestContext from './Context/ContestContext';
import Select from 'react-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinus, faPlus } from '@fortawesome/free-solid-svg-icons';



export default class CandidateVoteView extends Component {
    constructor(props) {
        super(props);

        this.publishTypes = [
            { value: 1, label: "Standard" },
            { value: 2, label: "Weighted" },
            { value: 3, label: "Both" }
        ];

        this.state = {
            ContestId: 0,
            ContestName: '',
            Seats: undefined,
            MaxVotes: undefined,
            ShowStatement: false,
            ShowPhoto: false,
            RandomiseOrder: false,
            ShowNumSeats: true,
            ShowMaxVotes: true,
            ShowCountSelected: true,
            VMeetingId: this.props.meeting,
            AdditionalText: '',
            VotingFor: 'candidate',
            options: [],
            errorMessage: "",
            votesErrorMessage: "",
            seatsErrorMessage: "",
            showButton: true,

            publishtypeid: this.props.publishtypeid,
            enableweightedcount: this.props.enableweightedcount,
            selectedPublishType: this.publishTypes.filter((x) => x.value === this.props.publishtypeid)[0]
        };
        this.submitted = false;
        this.validator = new SimpleReactValidator({ autoForceUpdate: this, element: (message, className) => <div className="text-danger">{message}</div> });
    }



    componentDidMount() {
        this.setState({
            contestData: this.props.contestdata,
            ContestId: this.props.id,
            ContestName: this.props.name,
            Seats: this.props.contestdata.contestSeats,
            MaxVotes: this.props.contestdata.maxVotes,
            ShowStatement: this.props.contestdata.showStatement,
            ShowPhoto: this.props.contestdata.showPhoto,
            RandomiseOrder: this.props.contestdata.randomiseCandidates,
            options: this.props.contestdata.options,
            AdditionalText: this.props.contestdata.additionalResolutionText,
            ShowNumSeats: this.props.contestdata.showNumSeats,
            ShowMaxVotes: this.props.contestdata.showMaxVotes,
            ShowCountSelected: this.props.contestdata.showCountSelected,
            VotingFor: this.props.contestdata.votingFor ? this.props.contestdata.votingFor : 'candidate'
        });
        this.context.SortCandidateList(this.props.actionType, this.props.contestdata.options);
    }


    onSubmit = (e) => {
        e.preventDefault();
        this.props.onHide(true);
    }



    render() {
        let votingFor = this.state.VotingFor.length > 3 ? this.state.VotingFor : 'candidate'
        return (

            <div>
                <Container fluid>
                    <form onSubmit={this.onSubmit}>
                        <div className="modalContestContent">
                            <div className="modalContestBody">
                                <div>
                                    <Form.Group>
                                        <Row>
                                            <Col>
                                                <div className="form-group my-3">
                                                    <FloatingLabel label="Item name">
                                                        <input disabled={true} className="form-control" type="text" name="ContestName" style={{ width: '100%' }} onChange={this.onChange} value={this.state.ContestName} placeholder="Item name" onBlur={() => this.validator.showMessageFor('ContestName')} />
                                                    </FloatingLabel>
                                                    {this.validator.message('ContestName', this.state.ContestName, 'required|max:1000', { messages: {} })}
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md="6">
                                                <div className="xVoteCheck ">
                                                    <div className="form-group mb-3">
                                                        <input disabled={true} type="checkbox" className="form-check-input" name="ShowPhoto" onChange={this.onChange} checked={this.state.ShowPhoto} />
                                                        <Form.Label htmlFor="ShowPhoto" className="form-check-label">Show Photo</Form.Label>
                                                    </div>
                                                    <div className="form-group mb-3">
                                                        <input disabled={true} type="checkbox" className="form-check-input" name="ShowStatement" onChange={this.onChange} checked={this.state.ShowStatement} />
                                                        <Form.Label htmlFor="ShowStatement" className="form-check-label">Show Statement</Form.Label>
                                                    </div>
                                                    <div className="form-group mb-3">
                                                        <input disabled={true} type="checkbox" className="form-check-input" name="RandomiseOrder" onChange={this.onChange} checked={this.state.RandomiseOrder} />
                                                        <Form.Label htmlFor="RandomiseOrder" className="form-check-label">Randomise Order</Form.Label>
                                                    </div>
                                                </div>
                                            </Col>
                                            <Col md="6">
                                                <div>
                                                    <div className="form-group mb-3" >
                                                        <FloatingLabel label="Number to elect (Defaults to 1)">
                                                            <input disabled={true} className="form-control" type="number" step="1" min="0" name="Seats" style={{ width: '100%' }} onChange={this.onChange} value={this.state.Seats} placeholder="Number to elect (Defaults to 1)" />
                                                        </FloatingLabel>
                                                        {this.state.seatsErrorMessage && <span className="error-message"> {this.state.seatsErrorMessage}</span>}
                                                    </div>
                                                    <div className="form-group mb-3">
                                                        <FloatingLabel label={`Maximum votes (Defaults to number ${parseInt(this.props.type) === 2 ? " to elect" : "of candidates"})`}>
                                                            <input disabled={true} className="form-control" type="number" step="1" min="0" name="MaxVotes" style={{ width: '100%' }} onChange={this.onChange} value={this.state.MaxVotes} placeholder={`Maximum votes (Defaults to number ${parseInt(this.props.type) === 2 ? " to elect" : "of candidates"})`} />
                                                        </FloatingLabel>
                                                        {this.state.votesErrorMessage && <span className="error-message">{this.state.votesErrorMessage}</span>}
                                                    </div>
                                                    {
                                                        this.state.enableweightedcount === "true" && this.props.type === 2 ?
                                                            <Form.Group className="mb-3">
                                                                <Form.Label htmlFor="PublishTypes">Publish Types</Form.Label>
                                                                <Select
                                                                    name="PublishTypes"
                                                                    value={this.state.selectedPublishType}
                                                                    options={this.publishTypes}
                                                                    isDisabled
                                                                />
                                                                {this.validator.message('PublishTypes', this.state.selectedPublishType, 'required')}

                                                            </Form.Group>
                                                            : null
                                                    }
                                                </div>
                                            </Col>
                                        </Row>
                                    </Form.Group>
                                </div>
                                <div className="mb-3">
                                    <Accordion>
                                        <div>
                                            <ContextAwareToggle eventKey="1" />
                                        </div>

                                        <Accordion.Collapse eventKey="1" >

                                            <div className="pt-2">
                                                <div className="form-group mb-3">
                                                    <FloatingLabel label="Additional text" >
                                                        <textarea disabled={true} className="form-control" rows="3" name="AdditionalText" style={{ width: '100%' }} onChange={this.onChange} value={this.state.AdditionalText} placeholder="Additional text" />
                                                    </FloatingLabel>
                                                    {this.state.votesErrorMessage && <span className="error-message">{this.state.votesErrorMessage}</span>}
                                                </div>
                                                <Row>
                                                    <Col md="6" className="xVoteCheck ">
                                                        <div className="form-group mb-3">
                                                            <input disabled={true} type="checkbox" className="form-check-input" name="ShowNumSeats" onChange={this.onChange} checked={this.state.ShowNumSeats} />
                                                            <Form.Label htmlFor="RandomiseOrder" className="form-check-label">Show Number of Seats</Form.Label>
                                                        </div>
                                                        <div className="form-group mb-3">
                                                            <input disabled={true} type="checkbox" className="form-check-input" name="ShowMaxVotes" onChange={this.onChange} checked={this.state.ShowMaxVotes} />
                                                            <Form.Label htmlFor="RandomiseOrder" className="form-check-label">Show Maximum Votes</Form.Label>
                                                        </div>
                                                        <div className="form-group mb-3">
                                                            <input disabled={true} type="checkbox" className="form-check-input" name="ShowCountSelected" onChange={this.onChange} checked={this.state.ShowCountSelected} />
                                                            <Form.Label htmlFor="RandomiseOrder" className="form-check-label">Show Count of Selected</Form.Label>
                                                        </div>
                                                    </Col>
                                                    <Col md="6">
                                                        <div className="form-group mb-3">
                                                            <FloatingLabel label="Voting for (Singular, defaults to 'candidate')" >
                                                                <input disabled={true} className="form-control" name="VotingFor" style={{ width: '100%' }} onChange={this.onChange} value={this.state.VotingFor} placeholder="Voting for (Singular, defaults to 'candidate')" />
                                                            </FloatingLabel>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </div>

                                        </Accordion.Collapse>
                                    </Accordion>
                                </div>


                                <div>

                                    <Form.Group>
                                        <CandidateSort
                                            Changed={this.handleInputChange}
                                            aType={this.props.actionType}
                                            ShowPhoto={this.state.ShowPhoto}
                                            ShowStatement={this.state.ShowStatement}
                                            CandidateCount={this.context.voteOptions.length}
                                            optionReferences={Object.keys(this.context.voteOptions)}
                                            VotingFor={votingFor}
                                            disabled={true}
                                        />
                                    </Form.Group>
                                </div>
                            </div>

                            <div className="modalUpdateFooter">
                                <div className="d-flex justify-content-between">

                                    <div className="error-message"></div>

                                    <div className="modalUpdateFooterSubmit">
                                        <Button className="btn btn-dark text-nowrap" type="submit" color="success">Close</Button>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </form>
                </Container>
            </div >
        );

    }

}

CandidateVoteView.contextType = ContestContext;


function ContextAwareToggle({ eventKey, callback }) {
    const { activeEventKey } = useContext(AccordionContext);

    const decoratedOnClick = useAccordionButton(
        eventKey,
        () => callback && callback(eventKey),
    );

    const isCurrentEventKey = activeEventKey === eventKey;

    const buttonOpen = () => {
        return (
            <div className="readMore">
                More options&nbsp;
                <FontAwesomeIcon icon={faPlus} />
            </div>
        )
    }
    const buttonClose = () => {
        return (
            <div className="readMore">
                Hide options&nbsp;
                <FontAwesomeIcon icon={faMinus} />
            </div>
        )
    }

    return (
        <button
            type="button"
            onClick={decoratedOnClick}
            className="btn btn-sm btn-primary"
            aria-label={isCurrentEventKey ? "Collapse to hide additional options" : "Expand for additonal options"}
        >
            {isCurrentEventKey ? buttonClose() : buttonOpen()}
        </button>
    );
}
