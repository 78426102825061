import axios from 'axios';

//Gets moderator flags
export async function OpenChatGetModeratorFlagOptions() {
    return false;
}

//Resolve flag on post
export async function OpenChatResolveFlag(targetPostId, attendeeId, action, vMeetingId, messageToAttendee) {
    let responder;
    const idata = {
        VMeetingId: vMeetingId,
        AttendeeUserId: attendeeId,
        PostId: targetPostId,
        Action: action,
        Message: messageToAttendee
    };
    await axios.post("api/OpenChatResolveFlag", idata).then(response => {
        if (response && response.data) {
            responder = response.data;
        }

    }).catch(error => {
        responder = "Error"
    });
    return responder;
}

//Delete post
export async function OpenChatAdminDeletePost(targetPostId, attendeeId, action, vMeetingId, messageToAttendee) {
    let responder;
    const idata = {
        VMeetingId: vMeetingId,
        AttendeeUserId: attendeeId,
        PostId: targetPostId,
        Action: action,
        Message: messageToAttendee
    };
    await axios.post("api/OpenChatAdminDeletePost", idata).then(response => {
        if (response && response.data) {
            responder = response.data;
        }

    }).catch(error => {
        responder = "Error"
    });
    return responder;
}

//UnDelete post
export async function OpenChatAdminUnDeletePost(targetPostId, vMeetingId) {
    let responder;
    const idata = {
        VMeetingId: vMeetingId,
        PostId: targetPostId
    };
    await axios.post("api/OpenChatAdminUnDeletePost", idata).then(response => {
        if (response && response.data) {
            responder = response.data;
        }

    }).catch(error => {
        responder = "Error"
    });
    return responder;
}


//Get Muted User List
export async function OpenChatGetMutedUserList(vMeetingId) {
    let responder;
    const idata = {
        vmid: vMeetingId
    }
    await axios.post("api/OpenChatGetMutedUsers", idata).then(response => {
        if (response && response.data) {
            responder = JSON.parse(response.data.openChatJson)
        }
    }).catch(error => {
        responder = "Error"
    });
    return responder;
}


//UnMute user
export async function OpenChatAdminUnMuteUser(vMeetingId, attendeeId) {
    let responder;
    const idata = {
        VMeetingId: vMeetingId,
        AttendeeUserId: attendeeId
    }
    await axios.post("api/OpenChatAdminUnMuteUser", idata).then(response => {
        if (response && response.data) {
            responder = response.data
        }
    }).catch(error => {
        responder = "Error"
    });
    return responder;
}
