import React, { useContext, useRef, useState } from 'react';
import AdminContext from '../../context/AdminContext'
import { MessageUpdateNoteAndTags } from './ApiFunctions';
import ChatContext from '../../context/ChatContext';
import { Tag } from './Tag';
import { AutoComplete } from './AutoComplete';

export function TagEditor(props) {
    const { message, groupInbox, currentInbox, setEditing, messageDetails } = props;
    const [noteAndTag, setNoteAndTag] = useState({
        note: messageDetails.messageNotes,
        tags: messageDetails.tags ?? [],
        newTag: ""
    });
    const { meetingId, userId } = useContext(AdminContext);
    const { comfortTagList } = useContext(ChatContext);
    const focusRef = useRef();

    const CancelEdit = () => {
        setEditing(false);
    }

    const SaveEdit = (e) => {
        e.preventDefault();
        CheckAndAddTag();
        MessageUpdateNoteAndTags(meetingId, userId, message.messageGroupId, message.messageId, noteAndTag.note, noteAndTag.tags);
        setEditing(false);
    }

    const SetNote = value => {
        let nat = { ...noteAndTag };
        nat.note = value;
        setNoteAndTag(nat);
    }

    const AutoAddTag = value => {
        //Add tag while typing
        let nat = { ...noteAndTag };
        let hasTag = nat.tags.find(a => a.tagText.toLowerCase() === value.toLowerCase().trim());
        if (hasTag && value.length !== value.trim().length && value.trim().length > 2) {
            nat.newTag = "";
        } else if (value.length !== value.trim().length && value.trim().length > 2) {
            //Now we need to pull from the parent list of existing tags
            let existingTag;
            if (comfortTagList) {
                existingTag = comfortTagList.find(a => a.tagText.toLowerCase() === value.toLowerCase().trim());
            }
            if (existingTag) {
                nat.tags.push({ "messageTagId": existingTag.messageTagId, "tagText": existingTag.tagText });
            }
            else {
                let min = Math.min(...nat.tags.map(item => item.messageTagId), 0);
                nat.tags.push({ "messageTagId": min - 1, "tagText": value.trim() });
            }
            nat.newTag = "";
        } else {
            nat.newTag = value.trim();
        }
        setNoteAndTag(nat);
    }

    const CheckAndAddTag = () => {
        //Add tag from field before save
        //Add tag while typing
        let nat = { ...noteAndTag };
        let hasTag = nat.tags.find(a => a.tagText.toLowerCase() === nat.newTag.toLowerCase().trim());
        if (nat.newTag.length > 2 && !hasTag) {
            //Now we need to pull from the parent list of existing tags
            let existingTag;
            if (comfortTagList) {
                existingTag = comfortTagList.find(a => a.tagText.toLowerCase() === nat.newTag.toLowerCase());
            }
            if (existingTag) {
                nat.tags.push({ "messageTagId": existingTag.messageTagId, "tagText": existingTag.tagText });
            }
            else {
                let min = Math.min(...nat.tags.map(item => item.messageTagId), 0);
                nat.tags.push({ "messageTagId": min - 1, "tagText": nat.newTag });
            }
            nat.newTag = "";
            setNoteAndTag(nat);
        }
    }

    const AddTagById = tagId => {
        let nat = { ...noteAndTag };
        let checkTag = nat.tags.find(a => a.messageTagId === tagId);
        if (checkTag) {
            return;
        } else {
            let existingTag = comfortTagList.find(a => a.messageTagId === tagId);
            nat.tags.push({ "messageTagId": existingTag.messageTagId, "tagText": existingTag.tagText });
            nat.newTag = "";
            setNoteAndTag(nat);
        }
        focusRef.current.focus();
    }

    const RemoveTag = tagId => {
        let nat = { ...noteAndTag };
        nat.tags = nat.tags.filter(tag => {
            return tag.messageTagId !== tagId;
        });
        setNoteAndTag(nat);
        focusRef.current.focus();
    }

    return (
        <div>
            <form onSubmit={SaveEdit}>
                <div className="mb-1">
                    <div className="mb-1 d-flex">
                        <label
                            htmlFor={"notes:" + message.messageGroupId + ":" + message.messageId + ":" + groupInbox + ":" + currentInbox}
                            className="sr-only"
                        >Edit notes</label>

                        <input type="text" className="flex-fill tag-form-control"
                            id={"notes:" + message.messageGroupId + ":" + message.messageId + ":" + groupInbox + ":" + currentInbox}
                            placeholder="Message notes"
                            value={noteAndTag.note}
                            onChange={e => SetNote(e.target.value)}
                            maxLength="500"
                            size="15"
                        />
                    </div>
                    <div className="d-flex flex-wrap tag-manager rounded">
                        {noteAndTag.tags?.map(tag =>
                            <Tag key={tag.messageTagId} tag={tag} edit RemoveTag={RemoveTag} />
                        )}
                        <div className="autocomplete-parent flex-fill">
                            <label
                                htmlFor={"tags:" + message.messageGroupId + ":" + message.messageId + ":" + groupInbox + ":" + currentInbox}
                                className="sr-only"
                            >Add tags</label>
                            <input type="text"
                                id={"tags:" + message.messageGroupId + ":" + message.messageId + ":" + groupInbox + ":" + currentInbox}
                                ref={focusRef}
                                placeholder="Add tags"
                                size="15" //Set this to adjust the minimum size of the input field
                                maxLength="15"
                                className="tag-form-control w-100"
                                value={noteAndTag.newTag}
                                autoComplete="off"
                                onChange={e => AutoAddTag(e.target.value)}
                            />
                            <AutoComplete value={noteAndTag.newTag} handleSuggestionClick={AddTagById} />
                        </div>
                    </div>
                </div>
                <div className="d-flex flex-fill justify-content-end">
                    <button type="submit" className="btn btn-primary btn-sm me-1">Save</button>
                    <button type="button" className="btn btn-danger btn-sm" onClick={CancelEdit}>Cancel</button>
                </div>
            </form>
        </div>
    )
}