import React, { Component } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { Container } from 'reactstrap';
import ClearMeetingData from './ClearMeetingData';
import ImportPreMeeting from './ImportPreMeeting';
import { ForcedLogout } from '../ForceLogout';
import { RefreshBrowser } from '../RefreshBrowser';
import AttendeesList from '../AttendeesList';
import AdminContext from '../context/AdminContext';
import { IpResetter } from '../IpResetter/IpResetter';

export class EventAdministration extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true

        };
    }

    componentWillUnmount() {
        // fix Warning: Can't perform a React state update on an unmounted component
        this.setState = (state, callback) => {
            return;
        };
    }

    tabChange = (e) => {
        if (e === 2) {
            //VMID need to pass
            this.setState({ successMsg: '' });
            this.setState({ tabOrder: 1 })
            this.setState({ errors: [] })
        }

    }

    render() {
        const { isAuthorized } = this.context;

        return (
            <div>
                <div>
                    <Tabs defaultIndex={0} onSelect={this.tabChange}>
                        <TabList>

                            {isAuthorized('EligAttend', 'Read') && <Tab>Eligible Attendees</Tab>}
                            {isAuthorized('Premeeting', 'Read') && <Tab disabled={this.context.switches.hasPreMeetingData ? false : true}>PreMeeting Data Upload</Tab>}
                            {isAuthorized('MeetingReset', 'Read') && <Tab>Reset Meeting Data</Tab>}
                            {isAuthorized('AttendRefresh', 'Read') && <Tab>Refresh Browser</Tab>}
                            {isAuthorized('AttendLogout', 'Read') && <Tab>Force Logout</Tab>}
                            {isAuthorized('IpUnlock','Read') && <Tab>IP Reset</Tab> }
                           
                            
                        </TabList>  
                       
                        { //Eligible attendees
                            isAuthorized('EligAttend', 'Read') &&
                            <TabPanel >
                                    <AttendeesList isAuthorized={isAuthorized('EligAttend', 'Write')} />
                            </TabPanel >
                        }

                        { //Import Premeeting
                            isAuthorized('Premeeting', 'Read') &&
                            <TabPanel >
                                <Container fluid>
                                    <ImportPreMeeting isAuthorized={isAuthorized('Premeeting', 'Write')} />
                                </Container>
                            </TabPanel>
                        }
                        
                        {// Delete Meeting Data Tab
                            isAuthorized('MeetingReset', 'Read') &&
                            <TabPanel >
                                <Container fluid>
                                        <ClearMeetingData isAuthorized={isAuthorized('MeetingReset', 'Write')} />
                                </Container>
                            </TabPanel>
                        }

                        {//Refresh Browser
                            isAuthorized('AttendRefresh', 'Read') &&
                            <TabPanel >
                                <Container fluid>
                                    <RefreshBrowser isAuthorized={isAuthorized('AttendRefresh', 'Write')} />
                                </Container>
                            </TabPanel>
                        }

                        {//Force Logout
                            isAuthorized('AttendLogout', 'Read') && <TabPanel >
                                <Container fluid>
                                    <ForcedLogout isAuthorized={isAuthorized('AttendLogout', 'Write')} />
                                </Container>
                            </TabPanel>
                        }
                        {//IP Resetter
                            isAuthorized('IpUnlock', 'Read') && <TabPanel >
                                <Container fluid>
                                    <IpResetter />
                                </Container>
                            </TabPanel>
                        }
                       
                    </Tabs>
                </div>
            </div>
        );
    }
}
EventAdministration.contextType = AdminContext;