import React, { useState, useContext } from 'react';
import AccessList from './AccessList';
import AddClientAccess from './AddClientAccess';
import AddMeetingAccess from './AddMeetingAccess';
import AdminContext from '../../context/AdminContext';

const UserAccess = ({ isLoading, accessList, accessLevel, sectionId, handleClientSelection = null, handleActionClick, selectedUser }) => {

    //Component state//
    const [showAccessModal, setShowAccessModal] = useState(false);

    //Context//
    const adminContext = useContext(AdminContext);


    const handleAddAccessClick = () => {
        setShowAccessModal(true);
    }

    const handleAccessModal = () => {
        setShowAccessModal(false);
    }

    return (
        <div className="card m-2 flex-fill shadow-sm w-100 useradmincard permissionSection">
            <div className="card-header">
                {accessLevel}
            </div>
            <div className="card-body">
                <ul className="list-group list-group-flush" data-testid={`section-${sectionId}`}>
                    <AccessList
                        isLoading={isLoading}
                        accessList={accessList}
                        sectionId={sectionId}
                        handleClientSelection={handleClientSelection}
                        handleActionClick={handleActionClick}
                    />
                </ul>
            </div>
            <div className="card-footer text-muted float-right">
                <button
                    className="btn btn-primary btn-sm"
                    disabled={!selectedUser}
                    title={`Add ${sectionId === 1 ? `client` :`meeting`} permissions`}
                    onClick={() => {
                        handleAddAccessClick();
                    }}

                >+</button>
            </div>
            {
                sectionId === 1 ?
                    <AddClientAccess
                        showAccessModal={showAccessModal}
                        handleAccessModal={handleAccessModal}
                        sectionId={sectionId}
                        selectedUser={selectedUser}

                    />
                    :
                    <AddMeetingAccess
                        showAccessModal={showAccessModal}
                        handleAccessModal={handleAccessModal}
                        sectionId={sectionId}
                        selectedUser={selectedUser}
                    />
            }
        </div>
    )
}

export default UserAccess;