import React, {  useContext, useState, useEffect } from "react";
import { Accordion, Badge, Stack, Table } from "react-bootstrap";
import { InstanceContext } from "./InstanceContext";
import { SurveyAdminContext } from "./SurveyAdminContext";
import axios from "axios";

export default function SurveyAnalytics() {

    const { sdata, } = useContext(SurveyAdminContext)
    const { instanceData, } = useContext(InstanceContext)

    const [responseData, setResponseData] = useState(sdata.Survey.Question)

    const handleGetResponses = async () => {
        if ((instanceData.surveyId === sdata.Survey.id) && (instanceData.surveyId > -1) && sdata.Survey.Question && instanceData.hasSurvey) {
            const msurveyId = {
                surveyId: sdata.Survey.id
            }
            axios.post('api/GetResponsesByQuestion', msurveyId)
                .then(response => {
                    if (response.data) {
                        setResponseData(JSON.parse(response.data.jsonresponse))
                    }
                })
                .catch(function (error) { console.log(error); })
        }
    }

    useEffect(() => {
        handleGetResponses()
    }, [sdata.Survey.Question])

    const getResponseByQuestionType = (questionType, response) => {
        const parsed = JSON.parse(response.Text)
        switch (questionType) {
            case 1:
                return parsed.StringValue
            case 2:
                return parsed.StringValue
            case 3:
                return parsed.OptionTitle
            case 4:
                const selectedOptions = parsed
                    .filter((item) => item.Value === 1)
                    .map((item) => item.OptionTitle)

                return selectedOptions.join(", ")
            case 5:
                return parsed.StringValue
            case 6:
                return parsed.OptionTitle
            case 7:
                const rankingResponsesR1 = parsed
                rankingResponsesR1.sort((a, b) => a.Value - b.Value)
                const optionTitlesR1 = rankingResponsesR1.map((item) => item.OptionTitle)

                return optionTitlesR1.join(", ")
            case 8:
                const rankingResponsesR2 = parsed
                rankingResponsesR2.sort((a, b) => a.Value - b.Value)
                const optionTitlesR2 = rankingResponsesR2.map((item) => item.OptionTitle)

                return optionTitlesR2.join(", ")
            default: 
                return null
        }
    }

    return (
        <Accordion>
            {responseData
                .sort((a, b) => a.SortOrder - b.SortOrder)
                .map((question) => (
                <Accordion.Item key={question.QuestionId ? question.QuestionId : question.id} eventKey={question.QuestionId ? question.QuestionId : question.id}>
                    <Accordion.Header>
                        <Stack direction="horizontal" gap={2}>
                            {question.QuestionTitle}
                            {question.Mandatory && <Badge pill bg="primary">Required</Badge>}
                            <Badge pill bg="secondary">{question.Responses && question.Responses.length > 0 ? question.Responses.length : 0} Response(s)</Badge>
                        </Stack>
                    </Accordion.Header>
                        <Accordion.Body>
                            <div className="d-flex response-lists justify-content-between my-3">
                            <Table hover>
                            <thead>
                                <tr>
                                    <th>Response</th>
                                </tr>
                            </thead>
                            <tbody>
                                {question.Responses ? question.Responses.map((response) => (
                                    <tr key={response.ResponseId}>
                                        <td>{getResponseByQuestionType(question.QuestionTypeId, response)}</td>
                                    </tr>
                                )) : null}
                                    </tbody>
                                </Table>
                            </div>
                    </Accordion.Body>
                </Accordion.Item>
            ))}
        </Accordion>
    )
}