import React, { useState, useEffect, useContext } from 'react';
import { DragDropContext } from 'react-beautiful-dnd';
import { connection } from '../../SignalRController';
import { InboxGeneric } from './InboxGeneric';
import { NotificationManager } from 'react-notifications';
import { GetMessageGroupsForComfortInbox, MessageGroupForward, MessageGroupReorder, MessageMoveToOtherGroup, MessageReorder } from './ApiFunctions';
import AdminContext from '../../context/AdminContext';
import { InboxPermission } from './Constants';
import ChatContext from '../../context/ChatContext';


export function ComfortPresenter(props) {
    const { isAuthorized, switches } = useContext(AdminContext);
    const { GetComfortTagList } = useContext(ChatContext);
    const { summary, activeInbox } = props;
    const [loading, setLoading] = useState(true);
    const [groups, setGroups] = useState();


    const inboxSelection = () => {
        if (switches.enableComfortMonitor) {
            //If comfort monitor is on, we want presenters to see the CM but Mods to see triage
            return summary ? 3 : 1;
        } else {
            //Otherwise they both see the queue
            return 2;
        }
    }

    const FetchData = async () => {
        let data = await GetMessageGroupsForComfortInbox(0, inboxSelection());
        setGroupsSorted(data);
        setLoading(false);
    }

    const setGroupsSorted = data => {
        data = data.sort((a, b) => a.displayOrder > b.displayOrder ? 1 : -1);
        setGroups(data);
    }

    useEffect(() => {
        FetchData();
        GetComfortTagList();
        return () => { };
    }, [])

    useEffect(() => {
        //Setup connection trigger for SR
        if (connection) {
            connection.on("srHubAdm2Adm_ComfortUpdate", () => {
                FetchData();
            });
            connection.on("srHubAdm2Adm_ComfortTagUpdate", () => {
                GetComfortTagList();
            });
        }

        //Cleanup connection event listener
        return () => {
            connection.off("srHubAdm2Adm_ComfortUpdate");
            connection.off("srHubAdm2Adm_ComfortTagUpdate");
        };
    }, [connection])

    const onDragEnd = result => {
        const { destination, source, draggableId, type } = result;
        if (!destination) {
            //null destination, cancelled or invalid end point
            return;
        }
        if (
            destination.droppableId === source.droppableId &&
            destination.index === source.index
        ) {
            return;
        }
        //check write permissions for inbox the message is currently in
        let messageGroupInboxId = parseInt(draggableId.split(":")[3]);
        if (!isAuthorized(InboxPermission[messageGroupInboxId], 'Write')) {
            return;
        }

        if (type === "Group") {
            onDragEndGroup(result, groups, setGroupsSorted);
        }
        if (type === "Message") {
            onDragEndMessage(result);
        }
    }


    return (
        <DragDropContext onDragEnd={onDragEnd}>
            <InboxGeneric groups={groups} loading={loading} currentInbox={inboxSelection()} summary={summary} activeInbox={activeInbox} />
        </DragDropContext>
    )
}



const onDragEndGroup = (result, groups, setGroupsSorted) => {
    const { destination, source, draggableId } = result;
    let messageGroupId = parseInt(draggableId.split(":")[2]);

    if (destination.droppableId !== source.droppableId) {
        MoveMessageGroup(messageGroupId, parseInt(destination.droppableId), destination.index, groups, setGroupsSorted);
    }

    if (destination.droppableId === source.droppableId &&
        destination.index !== source.index
    ) {
        ReorderMessageGroup(messageGroupId, parseInt(destination.droppableId), source.index, destination.index, groups, setGroupsSorted);
    }
}



const onDragEndMessage = async (result) => {
    const { destination, source, draggableId } = result;
    let targetGroupId = parseInt(destination.droppableId.split(":")[1]);
    let messageId = parseInt(draggableId.split(":")[2]);

    if (destination.droppableId !== source.droppableId) {
        let response = await MessageMoveToOtherGroup(0, 0, messageId, targetGroupId, destination.index);
        if (response.statusID !== 1) {
            //trigger notification perhaps, maybe nothing, SR should handle the data update
            NotificationManager.error("Sorry, there was a problem updating the messages.", "Move messages", 4000);
        }
    }
    if (destination.droppableId === source.droppableId && destination.index !== source.index) {
        let response = await MessageReorder(0, 0, targetGroupId, messageId, source.index, destination.index);
        if (response.statusID !== 1) {
            //trigger notification perhaps, maybe nothing, SR should handle the data update
            NotificationManager.error("Sorry, there was a problem updating the messages.", "Reorder messages", 4000);
        }
    }

}


const MoveMessageGroup = async (messageGroupId, targetInbox, displayOrder, groups, setGroupsSorted) => {
    let obj = groups.find(a => a.messageGroupId === messageGroupId);
    if (obj.currentInbox !== targetInbox) {
        let newObj = [...groups];
        let indx = newObj.findIndex(obj => obj.messageGroupId === parseInt(messageGroupId));

        newObj.forEach((group, index) => {
            if (group.currentInbox === targetInbox && group.displayOrder >= displayOrder) {
                newObj[index].displayOrder = group.displayOrder + 1;
            }
        })
        newObj[indx].currentInbox = parseInt(targetInbox);
        newObj[indx].displayOrder = displayOrder;

        setGroupsSorted(newObj);

        let response = await MessageGroupForward(0, 0, messageGroupId, targetInbox, displayOrder);

        if (response.statusID !== 1) {
            //trigger notification perhaps, maybe nothing, SR should handle the data update
            NotificationManager.error("Sorry, there was a problem updating the inboxes.", "Move group", 4000);
        }
    }
}

const ReorderMessageGroup = async (messageGroupId, targetInbox, oldDisplayOrder, newDisplayOrder, groups, setGroupsSorted) => {
    let newObj = [...groups];
    let indx = newObj.findIndex(obj => obj.messageGroupId === parseInt(messageGroupId));
    if (newDisplayOrder < oldDisplayOrder) {
        newObj.forEach((group, index) => {
            if (group.currentInbox === targetInbox && group.displayOrder >= newDisplayOrder && group.displayOrder < oldDisplayOrder) {
                newObj[index].displayOrder = group.displayOrder + 1;
            }
        })
    } else {
        newObj.forEach((group, index) => {
            if (group.currentInbox === targetInbox && group.displayOrder > oldDisplayOrder && group.displayOrder <= newDisplayOrder) {
                newObj[index].displayOrder = group.displayOrder - 1;
            }
        })
    }
    newObj[indx].displayOrder = newDisplayOrder;
    setGroupsSorted(newObj);
    let response = await MessageGroupReorder(0, 0, messageGroupId, targetInbox, oldDisplayOrder, newDisplayOrder);
    if (response.statusID !== 1) {
        //trigger notification perhaps, maybe nothing, SR should handle the data update
        NotificationManager.error("Sorry, there was a problem updating the inboxes.", "Reorder groups", 4000);
    }
}