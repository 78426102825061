import React, {Fragment, Component } from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import axios from 'axios';
import './chat.css';
import DateTimeManager from './../Common/DateTimeManager';
import SignalRController from '../SignalRController.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperPlane, faComments } from '@fortawesome/free-solid-svg-icons'
import ScrollToBottom from 'react-scroll-to-bottom';
import ChatContext from './../context/ChatContext';

export class GroupChat extends Component {

    constructor(props) {
        super(props);
        
        this.state = {
            messageArray: [],
            message: null,
            chatForward: null,
            inputMessage: '',
            sendToPopup: false,
            showHideChat: false,
            messageCountOnLastOpen: 0,
            messageAlertCounter: 0,
            disableSend: false
        }

        this.sendMessage = this.sendMessage.bind(this);
        this.showHideChat = this.showHideChat.bind(this);
        this.updateChatHere = this.updateChatHere.bind(this);
        
        
    }

   async updateChatHere() {
       if (!this.context.loading) {
           await this.context.getChats();
           this.setState({
               messageArray: this.context.groupInboxItems,
               messageAlertCounter: this.state.showHideChat === true ? 0 : this.context.groupInboxItems.length - this.state.messageCountOnLastOpen,
               messageCountOnLastOpen: this.state.showHideChat === true ? this.context.groupInboxItems.length : this.state.messageCountOnLastOpen
           });
       }
    }

    async componentDidMount() {
        SignalRController.addCallbackAdm2Adm_MessageUpdate(this.updateChatHere);
        //SignalRController.ConnectionStart();
        await this.context.getChats();
        this.setState({
            messageArray: this.context.groupInboxItems,
            messageCountOnLastOpen: this.context.groupInboxItems ? this.context.groupInboxItems.length : 0
        });

    }

    componentWillUnmount() {
        SignalRController.removeCallbackAdm2Adm_MessageUpdate(() => this.context.getChats);
    }
  
    showHideChat() {
        // If show is currently false it's going to switch to true and show the window
        // So capture how many messages are in the queue to work out if there are any new messages when it is hidden
        
        if (this.state.showHideChat === false) {
            this.setState({
                messageCountOnLastOpen: typeof (this.context.groupInboxItems) == 'object' ? this.context.groupInboxItems.length : 0,
                messageAlertCounter: 0,
                showHideChat: true
            })
        }
        else {
            this.setState({ showHideChat: false });
        }
        
    }

    enterPressed(event) {
        var code = event.keyCode || event.which;
        if (code === 13) { //13 is the enter keycode
            if (!this.state.disableSend) {
                this.sendMessage();
            }
        }
    }

    maxLengthCheck = (object) => {
        if (object.target.value.length > object.target.maxLength) {
            object.target.value = object.target.value.slice(0, object.target.maxLength)
        }
        this.setState({ inputMessage: object.target.value });
    }

    customValidation() {
        if (this.state.inputMessage === "")
            this.setState({
                commentError: 'Message is required.'
            });

        else
            this.setState({
                commentError: ''
            });
    }

    async sendMessage() {
        this.setState({
            disableSend: true,
            inputMessage: this.state.inputMessage.trim()
        })
        let comment = this.state.inputMessage.trim();
        try {
            this.customValidation();

            if(comment.length > 0) {
                let vmID = parseInt(sessionStorage.getItem('VMId'));

                var chatResponse = {
                    AdminId: parseInt(this.context.user),
                    VMeetingId: vmID,
                    QuestionText: comment,
                    SubmittedById: parseInt(this.context.user),
                    SubmittedByName: 'notused',
                    messageDirection: 99
                };
                if (comment !== '') {
                    await axios.post(`api/SubmitGroupMessage`, chatResponse).then(res => {
                        this.setState({
                            inputMessage: ''
                        });
                    })
                };
            }

            //Added by Phil W to invoke signal at client
            //SignalRController.InvokeAdm2Adm_MessageUpdate();
        }

        catch (err) {
            console.log("Error: Saving chat", err.message);
        }
        setTimeout(() => {
            this.setState({
                disableSend: false
            })
        }, 250)

    }

    handleChange(event) {
        this.setState({ inputMessage: event.target.value });
    }

    render() {
        let sidebar = true; //Used to check whether the side bar exists and hence where the icon should be placed, needs method for new security options.
        const { authorize } = this.props;
        return (
            <Fragment>
                {this.state.showHideChat &&
                    <Fragment>
                        <Modal isOpen={true} className="messaging-modal-style" toggle={this.showHideChat} onClosed={this.showHideChat} fade={true}>
                            <ModalHeader className="messaging-modal-header">
                                <span className="msgTitlePink pinkIcon">Group Chat - only visible to administrative users</span>
                                <button className="btn closeBox" title="Close" onClick={this.showHideChat}>&times;</button>
                            </ModalHeader>
                            <ModalBody className="messaging-modal-body">
                                <div className="messaging-modal-body">
                                    <ScrollToBottom className="msg-container-window">
                                        
                                        {this.context.groupInboxItems ? this.context.groupInboxItems.sort((a, b) => a.messageDateTime > b.messageDateTime ? 1 : -1).map((chat, i) => {
                                            return (typeof chat.adminName === "undefined" ? <></> : chat.adminName !== this.context.name ?
                                                <div className="msg-container-attendee" key={"msgs" + i}>
                                                    <div className="DateTime">
                                                        {new DateTimeManager(chat.messageDateTime).getTimeDefaultFormat()}
                                                        <div className="Options"> {chat.adminName}</div>
                                                    </div>
                                                    <div className="MessageFull"> {chat.messageText}</div>
                                                </div>
                                                :
                                                <div className="msg-container-admin" key={"msgs" + i}>
                                                    <div className="MessageFull"> {chat.messageText}</div>
                                                    <div className="DateTime"> {new DateTimeManager(chat.messageDateTime).getTimeDefaultFormat()}</div>
                                                </div>)
                                        }
                                        ) : null}
                                    </ScrollToBottom>
                                </div>
                            </ModalBody>
                            <ModalFooter className="messaging-modal-footer">
                                    <div className="input-group">
                                        <textarea ref={this.messBoxRef}
                                            maxLength="500"
                                            onInput={this.maxLengthCheck.bind(this)}
                                            onChange={this.maxLengthCheck.bind(this)}
                                            onKeyUp={this.enterPressed.bind(this)}
                                            className="form-control type_msg"
                                            placeholder="Message to post"
                                            value={this.state.inputMessage} />

                                        <div className="input-group-append">
                                        <button
                                            onClick={this.sendMessage}
                                            title="Send message"
                                            disabled={(this.state.disableSend || !authorize) ? true : false}
                                            className="btn input-group-text send_btn">
                                            {
                                                !this.state.disableSend ?
                                                <FontAwesomeIcon icon={faPaperPlane} /> :
                                                <div className="groupChatloaderholder"><div className="loader" /></div>}
                                        </button>
                                        </div>
                                </div>
                            </ModalFooter>
                        </Modal>
                    </Fragment>
                    }

                <div data-tip="Group chat" className={`groupChat ${sidebar ? 'groupChatLeft' :'groupChatRight'}`} >
                    <button className="btn" title="Group chat" onClick={this.showHideChat}>
                        <FontAwesomeIcon icon={faComments} />
                        {this.state.messageAlertCounter>0 ? <div className="messageAlertCounter">{this.state.messageAlertCounter}</div> : ''}                        
                    </button>
                </div>
            </Fragment>
        );

    }
}
GroupChat.contextType = ChatContext;
