import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import { ButtonGroup, Col, ToggleButton, Row, Button } from 'react-bootstrap';
import React, { useContext, useState } from 'react';
import { LinearScaleFormat, RankingFormat, ShortAnswerFormat, LongAnswerFormat, SingleChoiceFormat, CheckboxFormat } from './QuestionFormat';
import { SurveyAdminContext } from './SurveyAdminContext';

export function AddQuestion({ id, disabled }) {

    const { sdata, setSdata } = useContext(SurveyAdminContext)

    const qst = sdata.Survey.Question.filter((q) => q.SortOrder === id)[0]

    const [questionTitle, setQuestionTitle] = useState(qst.QuestionTitle ? qst.QuestionTitle : "")

    const [mandatory, setMandatory] = useState(qst.Mandatory ? qst.Mandatory : false)

    const [questionType, setQuestionType] = useState(qst.QuestionType ? qst.QuestionType : "")

    function setBlur() {
        //q.id to q.SortOrder
        let qst = sdata.Survey.Question.filter((q) => q.SortOrder === id)[0]

        questionTitle ? qst.QuestionTitle = questionTitle : qst.QuestionTitle = ""
        mandatory ? qst.Mandatory = mandatory : qst.Mandatory = false
        mandatory ? qst.Validated = !mandatory : qst.Validated = true
        questionType ? qst.QuestionType = questionType : qst.QuestionType = ""

        //s.id to s.SortOrder
        let questions = sdata.Survey.Question.map((s) => (s.SortOrder === id ? qst : s))
        let updated = { ...sdata.Survey, Question: [...questions] }
        setSdata({ Survey: updated })
    }

    function handleRemove() {
        // item.id to item.SortOrder
        const questions = sdata.Survey.Question.filter((item) => item.SortOrder !== id)
        let updated = { ...sdata.Survey, Question: [...questions] }
        setSdata({ Survey: updated })
    }

    function questionFormat(qt) {
        switch (qt) {
            case "Short answer":
                return <ShortAnswerFormat id={id} disabled={disabled} />
            case "Long answer":
                return <LongAnswerFormat id={id} disabled={disabled} />
            case "Single choice":
                return <SingleChoiceFormat id={id} disabled={disabled} />
            case "Checkbox":
                return <CheckboxFormat id={id} disabled={disabled} />
            case "Linear scale":
                return <LinearScaleFormat id={id} disabled={disabled} />
            //case "Linear radio":
            //    return <SingleChoiceFormat id={id} disabled={disabled} />
            case "Ranking":
                return <RankingFormat id={id} disabled={disabled} />
            case "Drag and drop ranking":
                return <RankingFormat id={id} disabled={disabled} />
            default: return
        }
    }
    return (
        <Card className='card-div'>
            <Card.Body>
                <Card.Text as='div' className='card-div'>
                    <Form.Group>
                        <FloatingLabel label="Question title">
                            <Form.Control
                                type="text"
                                name={id}
                                value={questionTitle}
                                data-testid={id}
                                onChange={(evt) => setQuestionTitle(evt.target.value)}
                                onBlur={() => setBlur()}
                                required
                                disabled={disabled || !qst.Editable} />
                        </FloatingLabel>
                    </Form.Group>
                </Card.Text>

                <Card.Text as='div' className='card-div'>
                    <Row>
                        <Col md={6}>
                            <Form.Group>
                                <FloatingLabel label="Question type">
                                    <Form.Select
                                        onChange={(evt) => setQuestionType(evt.target.value)}
                                        name={id}
                                        defaultValue={questionType}
                                        onBlur={() => setBlur()}
                                        required
                                        disabled={disabled || !qst.Editable}
                                        data-testid={"select id " + id}>

                                        <option value="">Select one</option>
                                        <option value="Short answer">Short answer</option>
                                        <option value="Long answer">Long answer</option>
                                        <option value="Single choice">Single choice</option>
                                        <option value="Checkbox">Checkbox</option>
                                        <option value="Linear scale">Linear scale</option>
                                        {/*<option value="Linear radio">Linear radio</option>*/}
                                        <option value="Ranking">Ranking</option>
                                        <option value="Drag and drop ranking">Drag and drop ranking</option>
                                    </Form.Select>
                                </FloatingLabel>
                            </Form.Group>
                        </Col>
                        <Col md={4}>
                            <Form.Group>
                                <ButtonGroup className='button-margin'>
                                    <ToggleButton
                                        key={id / 100}
                                        id={id / 100}
                                        type="radio"
                                        variant={'outline-success'}
                                        name={id}
                                        value={false}
                                        checked={mandatory === false}
                                        onClick={() => setMandatory(false)}
                                        onBlur={() => setBlur()}
                                        disabled={disabled || !qst.Editable}>
                                        Optional
                                    </ToggleButton>
                                    <ToggleButton
                                        key={id / 10}
                                        id={id / 10}
                                        type="radio"
                                        variant={'outline-danger'}
                                        name={id}
                                        value={true}
                                        checked={mandatory === true}
                                        onClick={() => setMandatory(true)}
                                        onBlur={() => setBlur()}
                                        disabled={disabled || !qst.Editable}>
                                        Required
                                    </ToggleButton>
                                </ButtonGroup>
                            </Form.Group>
                        </Col>
                        <Col md={2}>
                            <Button
                                variant="outline-danger"
                                className='button-margin'
                                name={id}
                                onClick={() => handleRemove()}
                                disabled={disabled || !qst.Editable}>
                                Delete</Button>
                        </Col>
                    </Row>
                </Card.Text>

                <Card.Text as='div' className='card-div'>
                    {questionFormat(questionType)}
                </Card.Text>
            </Card.Body>
        </Card>
    )
}