import { useContext, useState } from "react";
import axios from 'axios';
import { FloatingLabel } from 'react-bootstrap';
import { NotificationManager } from 'react-notifications';
import UserPermissionContext from '../UserPermissionContext';

export default function NameEmail({ enableUpdate, toggleEnableUpdate }) {

    const Context = useContext(UserPermissionContext);
    const { selectedUser, defaultClient, selectedUserMeeting } = Context;

    let user = Context.userList.find(x => x.Id == selectedUser.Id) || selectedUser;

    const [name, setName] = useState((user.ForeName + ' ' + (user.LastName ?? '')).trim());
    let updated = (user.ForeName + ' ' + (user.LastName ?? '')).trim() === name.trim();
    const [isSaving, setIsSaving] = useState(false);


    const handleSubmit = async e => {
        setIsSaving(true);
        let isUpdated = await updateUserName(e, selectedUser.Id, name);
        if (isUpdated) {
            Context.setUser({ ...selectedUser,ForeName: name});
            toggleEnableUpdate();
            await UpdateUserList();
        }
        setIsSaving(false);
    }


    const UpdateUserList = async () => {
        if (selectedUserMeeting !== undefined && defaultClient !== undefined) {
            const client = selectedUserMeeting?.value == null ? defaultClient.value : null;
            const meeting = selectedUserMeeting?.value;
            //either client user list or meeting user list//
            await Context.GetUsersByMeeting(meeting, client);
        }
    }

    const handleCancelClick = () => {
        const { selectedUser } = Context;
        setName((selectedUser.ForeName + ' ' + (selectedUser.LastName ?? '')).trim());
        toggleEnableUpdate();
    }

    return (
        <div className="card m-2 flex-fill shadow-sm w-100 useradmincard">
            <div className="card-header">
                User details
            </div>
            <form onSubmit={(e) => handleSubmit(e)}>
                <div className="row p-2">
                    <FloatingLabel label="Display name">
                        <input type="text"
                            className="form-control"
                            value={name}
                            name="name"
                            required
                            aria-required="true"
                            minLength="3"
                            onChange={(e) => setName(e.target.value)}
                            disabled={!enableUpdate}
                        />                        
                    </FloatingLabel>
                </div>
                <div className="row p-2">
                    <FloatingLabel label="Email address">
                        <input type="email" className="form-control" disabled value={selectedUser.EmailAddress} />
                    </FloatingLabel>
                </div>
                {enableUpdate &&
                    <div className="p-2">
                        <button
                            className="btn btn-secondary btn-sm me-auto"
                            type="button"
                            onClick={handleCancelClick}
                        >
                            Cancel
                        </button>
                        <button disabled={updated || isSaving} className="btn btn-primary btn-sm float-end">Update user name</button>
                    </div>
                }
            </form>
        </div>
    )
}

async function updateUserName(e, targetUserId, targetNewName) {
    e.preventDefault();
    const axoptions = {
        url: 'api/UserAdminUpdateUserName',
        method: 'POST',
        data: {
            TargetUserId: targetUserId,
            TargetUserName: targetNewName.trim()
        }
    };

    let returnValue;
    await axios(axoptions)
        .then(response => {
            returnValue = response;
            return (response);
        }).catch((error) => {
            returnValue = "error"
            return ('error');
        });
    if (returnValue.status === 200 && returnValue.data?.statusID) {
        switch (returnValue.data.statusID) {
            case 1:
                NotificationManager.success("Name updated successfully", "Update display name", 4000);
                return true;
            case 2:
                NotificationManager.info("User doesn't exist", "Update display name", 4000);
                return false;
            default:
                NotificationManager.error("An error occurred, please try again", "Update display name", 4000);
                return false;
        }

    }
}