import React from 'react';
import BrandImage from './images/Background.png';

export const Logo = () => {
    return (
        <div className="brand">
            <img src={BrandImage} width="100%" alt='Civica Logo'/>
        </div>
    )
}
