import React, { Component, Fragment } from 'react';
import axios from 'axios';
import RReport from './RReport';
import ContestReport from './ContestReport';
export class RVReport extends Component {
    constructor(props) {
        super(props);
        this.state = {
            rreport: [],
            resolutions: []
            , polls: []
            , showofhands: []
            , resolutionOptions: []
            , loading: true
            , candidateVotes: []
        };
        this.getRReport = this.getRReport.bind(this);
    }
    componentDidMount() {
        this.getRReport();
    }


    getRReport() {
        const id = { VMId: parseInt(sessionStorage.getItem('VMId')) };

        axios.get("VMGetAllResolution", { params: { VMId: id.VMId } }).then(response => {
            const data = response.data;
            if (data != null && data !== "") {
                var sorteddata = data.sort((a, b) => a.displayOrder > b.displayOrder ? 1 : -1)
                // Filter to only include Closed,Finalised & Published
                this.setState({
                    resolutions: sorteddata.filter((res) => res.status >= 5 && res.isEnabled === true && res.contestTypeId === 1),
                    polls: sorteddata.filter((res) => res.status >= 5 && res.isEnabled === true && res.contestTypeId === 1 && !res.enableShowOfHands),
                    showofhands: sorteddata.filter((res) => res.status >= 5 && res.isEnabled === true && res.contestTypeId === 1 && res.enableShowOfHands),
                    candidateVotes: sorteddata.filter((res) => res.status >= 5 && res.isEnabled === true && (res.contestTypeId === 2 || res.contestTypeId === 3))
                });
            }
        }).catch(function (error) {
            console.log("rreport error");
        });
        axios.post("VMGetResolutionVoteOptions", id).then(response => {
            const data = response.data;
            if (data != null && data !== "") {
                this.setState({ resolutionOptions: data });
            }
        }).catch(function (error) {
            console.log("rreport error");
        });

        axios.get('VMResultReport?VMId=' + id.VMId)
            .then(response => {
                const data = response.data;
                var sorteddata = data.sort((a, b) => a.sortOrder > b.sortOrder ? 1 : -1)
                if (data != null && data !== "") {
                    this.setState({
                        rreport: sorteddata,
                        loading: false
                    });
                }
            }).catch(function (error) {
                console.log("rreport error");
            })


    }

    render() {
        const { rreport = [], resolutions = [], resolutionOptions = [], polls = [], showofhands = [], candidateVotes = [] } = this.state;
        let VmeetingId = parseInt(sessionStorage.getItem('VMId'))
        return (
            <Fragment>
                {!this.state.loading ?

                    <div>
                        {resolutions.length || candidateVotes.length ?
                            <div>
                                {resolutions.length && rreport.length ? <RReport authorized={this.props.authorized} res={resolutions} resoptions={resolutionOptions} polls={polls} sof={showofhands} rrpt={rreport} />
                                    : <p>No question/item results currently available, only question/item that are closed, finalised or published will be displayed here.</p>}
                                {candidateVotes.length ?
                                    <ContestReport VmeetingId={VmeetingId} authorized={this.props.authorized} /> : null}
                            </div>
                            :
                            <div>No results currently available, only items that are closed, finalised or published will be displayed here<br /></div>}
                    </div>
                    :
                    <div className="dashHeading">Loading....</div>
                }
            </Fragment>
        )
    }
}
export default RVReport;