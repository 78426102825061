import { faShapes, faStopwatch, faDesktop, faLandmark, faComment, faMicrophone, faMicrophoneSlash, faUserTie, faGavel } from '@fortawesome/free-solid-svg-icons';


export const InboxName = ["Make1Index", "Triage", "Queue", "Comfort Monitor", "History"];
export const NoCmInboxName = ["Make1Index", "Triage", "Presenter", "Error", "History"];
export const InboxPermission = ["Make1Index", "InboxTriage", "InboxQueue", "InboxQueue", "InboxQueue"];

export const ActiveInboxName = ["Inbox", "Moderators", "Presenter"];
export const ActiveInboxPermission = ["InboxPrim", "InboxMod", "InboxPres"];

//For inbox display buttons
export const ButtonIconSelector = ["Make1Index", faShapes, faStopwatch, faDesktop, faLandmark];

export const MessageIconSelector = (messageTypeId, ready) => {
    if (messageTypeId === -1) {
        return (faUserTie);
    }
    else if (messageTypeId === 2) {
        if (ready) {
            return (faMicrophone);
        } else {
            return (faMicrophoneSlash);
        }
    }
    else if (messageTypeId === 3) {
        return (faGavel);
    }
    else {
        return (faComment);
    }
}