import React, { Component, Fragment } from 'react';
import { Button, ButtonToolbar, Row, Col } from 'react-bootstrap';
import axios from 'axios'
import { NotificationManager } from 'react-notifications';
import swal from 'sweetalert';
import CheckBoxContest from '../Common/CheckBoxContest';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUsers } from '@fortawesome/free-solid-svg-icons';

export class Constituencies extends Component {

    constructor(props) {
        super(props);

        this.state = {
            constituencyList: [],
            contestList: [],
            allContests: [],
            selectedConstituencyID: 0,
            editRow: -1,
            addRow: 0,
            contentChanged: false,
            error: false,
            errorMsg: "",
            isAllSelected: false,
            isAnySelected: false,
            importingConstituencies: false
        }

        this.getConstituency = this.getConstituency.bind(this);
        this.getContest = this.getContest.bind(this);
        this.editHandler = this.editHandler.bind(this);
        this.constituencyChangeHandler = this.constituencyChangeHandler.bind(this);
        this.handleCheckConstituencyElement = this.handleCheckConstituencyElement.bind(this);
        this.cancelHandler = this.cancelHandler.bind(this);
        this.saveHandler = this.saveHandler.bind(this);
        this.checkForAllCheckBoxSelected = this.checkForAllCheckBoxSelected.bind(this);
    }

    componentDidMount() {
        this.getConstituency();
        this.getContest();
    }

    componentWillUnmount() {
        // fix Warning: Can't perform a React state update on an unmounted component
        this.setState = (state, callback) => {
            return;
        };
    }

    editHandler = (data) => {
        let { constituencyList } = this.state;
        this.setState({
            selectedConstituencyID: parseInt(data),
            editRow: data,
            constituencyList: constituencyList.filter((cl) => cl.id !== -1),
            addRow: 0
        });
    }

    cancelHandler() {
        let { addRow, editRow, constituencyList } = this.state;

        if (addRow > 0) {
            this.setState({
                constituencyList: constituencyList.filter((x) => x.id !== -1),
                addRow: 0
            });
        }

        if (editRow > 0) {
            this.getConstituency();
            this.getContest();
            this.setState({
                editRow: 0
            });
        }

        this.setState({
            error: false,
            errorMsg: ""
        });

    }

    saveHandler(data) {
        if (data.id) {
            const meetingConstituency = this.state.constituencyList.find(c => c.id === data.id);
            var dupConstituency = this.constituencyAlreadyExists(meetingConstituency.description);
            var dupConstituencyID = this.constituencyIdAlreadyExists(meetingConstituency.constituencyID);

            if (dupConstituencyID || dupConstituency) {
                return;
            }

            this.setState({
                errorMsg: '',
                error: false
            });

            if (meetingConstituency.id === -1) {
                this.addConstituency(meetingConstituency)
            }
            else {
                this.updateConstituency(meetingConstituency)
            }
        }
    }

    constituencyIdChangeHandler(event) {
        const fieldAndId = event.target.id.split('-');
        const fieldName = fieldAndId[0];
        const constituencies = this.state.constituencyList;

        if (parseInt(event.target.value) < 0) return;
        if (constituencies.filter((c) => event.target.value === c.constituencyID && c.id != parseInt(fieldAndId[1])).length > 0) {
            this.setState({
                error: true,
                errorMsg: "duplicate consituency number"
            });
            return
        }
        constituencies.forEach(c => {
            if (c.id === parseInt(fieldAndId[1]) || c.id === -1) {
                c[fieldName] = event.target.value;
            }
        });
        this.setState({
            constituencyList: constituencies,
            error: false
        });
    }

    constituencyChangeHandler(event) {
        const fieldAndId = event.target.id.split('-');
        const fieldName = fieldAndId[0];
        const constituencies = this.state.constituencyList;

        constituencies.forEach(c => {
            if (c.id === parseInt(fieldAndId[1]) || c.id === -1) {
                c[fieldName] = event.target.value;
            }
        });
        this.setState({
            constituencyList: constituencies,
            error: false
        });
    }

    getConstituency = () => {
        const VMId = parseInt(sessionStorage.getItem('VMId'))
        const options = {
            url: 'VMGetMeetingConstituencyList',
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            data: VMId
        };
        axios(options)
            .then(response => {
                if (response.data.length > 0) {
                    //selecting new or selected constituency
                    if (this.state.selectedConstituencyID <= 0) {
                        var objSelectedConstituency;
                        if (this.state.addRow > 0) {
                            objSelectedConstituency = response.data.find((c) => c.constituencyID === response.data[response.data.length - 1].constituencyID);
                        }
                        else {
                            objSelectedConstituency = response.data.find((c) => c.constituencyID === response.data[0].constituencyID);
                        }

                        this.setState({
                            selectedConstituencyID: parseInt(objSelectedConstituency.Id),
                            constituencyList: response.data,
                            contentChanged: false,
                            error: false,
                            errorMsg: '',
                            addRow: 0
                        });
                        objSelectedConstituency && objSelectedConstituency !== undefined && this.updateContestList(objSelectedConstituency);
                    }
                    else {
                        var objSelectedConstituency = response.data.find((c) => c.id === this.state.selectedConstituencyID);
                        this.setState({
                            constituencyList: response.data,
                            contentChanged: false,
                            error: false,
                            errorMsg: ''
                        });
                        objSelectedConstituency && objSelectedConstituency !== undefined && this.updateContestList(objSelectedConstituency);
                    }
                }
            }).catch(function (error) {
                NotificationManager.error("Sorry, there was a problem in getting constituency list.", "Constituency List", 2000);
            })
    }

    getContest = () => {
        const VMId = parseInt(sessionStorage.getItem('VMId'))
        const options = {
            url: 'VMGetMeetingContests',
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            data: VMId
        };
        axios(options)
            .then(response => {
                this.setState({ contestList: response.data });
                const list = [];
                if (response.data.length > 0) {
                    response.data.map((i) => {
                        var isIncluded = list.map(e => e.contestId).includes(i.contestId);
                        if (i !== undefined && !isIncluded) {
                            list.push({ contestId: i.contestId, contestName: i.contestName, contestStatusId: i.contestStatusId, isChecked: false })
                        }
                    });
                    this.setState({ allContests: list });
                    if (this.state.constituencyList && this.state.constituencyList.length > 0) {
                        if (this.state.constituencyList[0].id !== -1) {
                            if (this.state.selectedConstituencyID > 0) {
                                let objSelectedConstituency = this.state.constituencyList.find((x) => x.id === this.state.selectedConstituencyID);
                                objSelectedConstituency && objSelectedConstituency !== undefined && this.updateContestList(objSelectedConstituency);
                            }
                        }
                    }
                }
            }).catch(function (error) {
                NotificationManager.error("Sorry, there was a problem in getting contest list.", "Constituency List", 2000);
            })
    }

    importConstituencyFromPinstable = () => {
        let { constituencyList } = this.state;
        this.setState({ importingConstituencies: true })
        const meetingConstituency = { ConstituencyID: -1, VmeetingId: parseInt(sessionStorage.getItem('VMId')), Description: '', UserId: parseInt(sessionStorage.getItem('UserId')) };
        const options = {
            url: 'VMImportPintableConstituency',
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            data: meetingConstituency
        };
        axios(options)
            .then(response => {
                this.setState({ importingConstituencies: false })
                if (response.data.length > 0) {
                    constituencyList = response.data;
                    this.setState({ constituencyList: constituencyList });
                    this.getConstituency();
                }
            }).catch(function (error) {
                this.setState({ importingConstituencies: false })
                NotificationManager.error("Sorry, there was a problem in getting constituency list.", "Constituency List", 2000);
            })
    }

    addConstituency = (row) => {
        const meetingConstituency = { ConstituencyID: parseInt(row.constituencyID), VmeetingId: parseInt(sessionStorage.getItem('VMId')), Description: row.description, UserId: parseInt(sessionStorage.getItem('UserId')) };

        const options = {
            url: 'VMAddConstituency',
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            data: meetingConstituency
        };
        axios(options)
            .then(response => {
                this.getConstituency();
                this.getContest();
                this.setState({
                    editRow: 0
                });
                if (response.data.statusDescription === 'Success') {
                    NotificationManager.success("Constituency created", "Constituency", 2000);
                }
            }).catch(function (error) {
                NotificationManager.error("Sorry, there was a problem creating the constituency.", "Constituency List", 2000);
            })
    }

    updateConstituency = (row) => {
        const meetingConstituency = { ID: row.id, ConstituencyID: parseInt(row.constituencyID), VmeetingId: parseInt(sessionStorage.getItem('VMId')), Description: row.description, UserId: parseInt(sessionStorage.getItem('UserId')) };
        const options = {
            url: 'VMUpdateConstituency',
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            data: meetingConstituency
        };
        axios(options)
            .then(response => {
                this.getConstituency();
                this.getContest();
                this.setState({
                    editRow: 0,
                    addRow: 0
                });

                if (response.data.statusDescription === 'Success') {
                    NotificationManager.success("Constituency updated", "Update Constituency", 2000);
                }
            }).catch(function (error) {
                NotificationManager.error("Sorry, there was a problem in updating constituency.", "Update Constituency", 2000);
            })
    }

    deleteConstituency = (row) => {
        const meetingConstituency = { ID: row.id, ConstituencyID: row.constituencyID, VmeetingId: parseInt(sessionStorage.getItem('VMId')), UserId: parseInt(sessionStorage.getItem('UserId')) };
        const options = {
            url: 'VMDeleteConstituency',
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            data: meetingConstituency
        };
        axios(options)
            .then(response => {
                this.setState({ selectedConstituencyID: -1 });
                this.getConstituency();
                this.getContest();
                if (response.data.statusDescription === 'Success') {
                    NotificationManager.success("Constituency deleted", "Delete Constituency", 2000);
                } else {
                    NotificationManager.error(response.data.statusDescription, "Delete Constituency", 2000);
                }
            }).catch(function (error) {
                NotificationManager.error("Sorry, there was a problem in deleting constituency.", "Delete Constituency", 2000);
            })
    }

    updateContestList = (ccid) => {
        if (this.state.contestList && this.state.contestList.length > 0) {
            var selectedContests = this.state.contestList.filter(x => x.constituencyID === parseInt(ccid.constituencyID));
            let { allContests } = this.state;
            allContests.forEach(c => {
                var isIncluded = selectedContests.map(e => e.contestId).includes(c.contestId);
                if (isIncluded) {
                    c.isChecked = true;
                }
                else {
                    c.isChecked = false;
                }
            });

            this.setState({
                allContests: allContests,
                selectedConstituencyID: ccid.id
            })
            this.checkForAllCheckBoxSelected();
        }

    }

    handleCheckConstituencyElement = (c) => {
        if ((this.state.editRow > 0 || this.state.addRow > 0) && this.state.editRow !== c.id) {
            this.setState({
                error: true,
                errorMsg: "Save or cancel changes before selecting another constituency"
            })
            return;
        }
        if (this.state.contentChanged === true) {
            this.setState({
                error: true,
                errorMsg: "Save or reset changes before moving to another constituency"
            })
            return;
        }

        this.updateContestList(c);
    }

    handleAllContestChecked = (event) => {
        if (this.state.selectedConstituencyID) {
            let { allContests } = this.state;
            if (event.target.checked) {
                allContests.filter((c) => c.contestStatusId < 2).forEach(c => c.isChecked = event.target.checked)
            }
            else {
                allContests.filter((c) => c.contestStatusId < 2).forEach(c => c.isChecked = false)
            }

            this.setState({ allContests: allContests })
        }
        this.checkForAllCheckBoxSelected();
        this.setState({
            contentChanged: true
        })
    }

    checkForAllCheckBoxSelected() {
        let { allContests } = this.state;

        if (allContests.filter((c) => c.contestStatusId < 2).length === 0) {
            this.setState({
                isAllSelected: false,
                isAnySelected: false
            });
            return;
        }

        let unCheckedContests = allContests.filter((c) => c.contestStatusId < 2).filter(c => {
            return c.isChecked === false
        })

        if (unCheckedContests === null || unCheckedContests.length === 0) {
            this.setState({
                isAllSelected: true
            });
        }
        else {
            this.setState({
                isAllSelected: false,
                isAnySelected: true
            });
        }

        if (unCheckedContests.length === allContests.filter((c) => c.contestStatusId < 2).length) {
            this.setState({
                isAnySelected: false
            });
        }
        else {
            this.setState({
                isAnySelected: true
            });
        }
    }

    handleCheckContestElement = (event) => {
        let { allContests } = this.state;
        if (event.target != undefined) {
            allContests.forEach(c => {
                if (c.contestId === parseInt(event.target.id)) {
                    c.isChecked = event.target.checked;
                }
            })
        }
        this.setState({ allContests: allContests, contentChanged: true });
        this.checkForAllCheckBoxSelected();
    }

    updateContestConstituency() {
        let { allContests, constituencyList, selectedConstituencyID } = this.state;

        if (allContests && allContests.length > 0 && selectedConstituencyID > 0) {
            var activeConstituency = constituencyList.filter((c) => c.id === selectedConstituencyID);
            var contestConstituency = [];
            allContests.filter(x => x.isChecked === true).forEach((c) => {
                contestConstituency.push({
                    ContestId: parseInt(c.contestId), ConstituencyID: parseInt(activeConstituency[0].constituencyID),
                    VMId: parseInt(sessionStorage.getItem('VMId')), UserId: parseInt(sessionStorage.getItem('UserId'))
                });
            });

            if (!this.state.isAnySelected) {
                contestConstituency.push({
                    ContestId: parseInt(0), ConstituencyID: parseInt(activeConstituency[0].constituencyID),
                    VMId: parseInt(sessionStorage.getItem('VMId')), UserId: parseInt(sessionStorage.getItem('UserId'))
                });
            }

            if (contestConstituency.length === 0) {
                return;
            }

            const options = {
                url: 'VMSaveContestConstituency',
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                data: contestConstituency
            };
            axios(options)
                .then(response => {
                    if (response.data !== undefined) {
                        if (response.data.length > 0) {
                            this.setState({ contestList: response.data, addRow: 0 });
                            this.getConstituency();
                            this.getContest();
                        }
                        NotificationManager.success("Contest Constituency saved", "Save Contest Constituency", 2000);
                        this.setState({
                            error: false,
                            errorMsg: "",
                            contentChanged: false
                        })
                    } else {
                        NotificationManager.success("Sorry, there was a problem saving contest constituency", "Save display order", 2000);
                    }
                })
        }
    }

    handleAddConstituency = () => {
        let { constituencyList, allContests, contentChanged } = this.state;

        if (contentChanged === true || this.state.editRow > 0) {
            this.setState({
                error: true,
                errorMsg: "Save or cancel changes before adding another constituency"
            })
            return;
        }

        var isIncluded = constituencyList.map(e => e.id).includes(-1);
        if (!isIncluded) {
            const newValue = { id: -1, constituencyID: '', vmeetingId: parseInt(sessionStorage.getItem('VMId')), description: '' };
            var list = constituencyList.concat(newValue);
            allContests && allContests.forEach(c => c.isChecked = false)

            this.setState({
                constituencyList: list,
                addRow: 1,
                selectedConstituencyID: -1,
                editRow: -1,
                allContests: allContests
            });
        }
    }

    constituencyAlreadyExists = (description) => {
        if (description.trim() === "") {
            this.setState({
                error: true,
                errorMsg: 'Constituency is required field.'
            })
            return true;
        }

        if (description.trim().length > 100) {
            this.setState({
                error: true,
                errorMsg: 'Constituency length should be less than equal 100 characters.'
            })
            return true;
        }

        let { constituencyList } = this.state;
        if (constituencyList && constituencyList.length > 0) {
            for (var i = 0; i < constituencyList.length; i++) {
                for (var j = 0; j < constituencyList.length; j++) {
                    if (i !== j && constituencyList[i].description.toLowerCase().trim() === constituencyList[j].description.toLowerCase().trim()) {
                        this.setState({
                            error: true,
                            errorMsg: 'Duplicate constituency'
                        })
                        return true;
                    }
                }
            }
        }
        return false;
    }

    constituencyIdAlreadyExists = (constituencyID) => {
        let { constituencyList } = this.state;

        if (constituencyID === "" || parseInt(constituencyID) === 0) {
            this.setState({
                error: true,
                errorMsg: 'ConstituencyID is required field.'
            })
            return true;
        }

        if (!Number.isInteger(parseFloat(constituencyID))) {
            this.setState({
                error: true,
                errorMsg: 'Please enter only integer value.'
            })
            return true;
        }

        if (constituencyID.length >= 10) {
            this.setState({
                error: true,
                errorMsg: 'ConstituencyID length should be than 10 characters.'
            })
            return true;
        }

        if (constituencyList && constituencyList.length > 0) {
            if (constituencyID) {
                for (var i = 0; i < constituencyList.length; i++) {
                    for (var j = 0; j < constituencyList.length; j++) {
                        if (i !== j && parseInt(constituencyList[i].constituencyID) === parseInt(constituencyList[j].constituencyID)) {
                            this.setState({
                                error: true,
                                errorMsg: 'Duplicate constituency ID'
                            })
                            return true;
                        }
                    }
                }
                return false;
            }
        }
    }

    render() {
        let { constituencyList, allContests, contestList } = this.state;


        return (
            <React.Fragment>
                <Row >
                    <Col>
                        <Button disabled={(constituencyList.map(e => e.id).includes(-1))} size="sm" onClick={() => this.handleAddConstituency()}>Add Constituency</Button>
                        {(constituencyList.map(e => e.id).includes(-1) && (constituencyList && constituencyList.length === 1)) &&
                            <Button disabled={(!this.props.isAuthorized) || this.state.importingConstituencies} className="ml-2" size="sm" onClick={() => this.importConstituencyFromPinstable()}>Import constituencies from PINS table</Button>}

                    </Col>
                </Row>

                <Row>
                    <Col>
                        {constituencyList && constituencyList.length > 0 ?
                            constituencyList.map((cl, index) => {
                                return (
                                    <div className={(cl.id === this.state.selectedConstituencyID) ? "constituencyCard selected" : "constituencyCard"} key={index}>
                                        {
                                            <div className="mr-2 d-flex" onClick={() => this.handleCheckConstituencyElement(cl)}>
                                                {(cl.id !== this.state.editRow && cl.id !== -1) ? <div className="me-2">{cl.constituencyID}</div> :
                                                    <input className="constituencyNumber "
                                                        id={'constituencyID-' + cl.id}
                                                        value={cl.constituencyID}
                                                        type="number"
                                                        step="1"
                                                        required maxLength="10"
                                                        title={cl.constituencyID}
                                                        onChange={(e) => this.constituencyIdChangeHandler(e)}
                                                        placeholder="Id"
                                                        autoComplete="off"
                                                    />}
                                                {(cl.id !== this.state.editRow && cl.id !== -1) ?
                                                    <div className="me-3 flex-fill">{cl.description.replace(/ +/g, " ")}</div> :
                                                    <input type="text"
                                                        className={(cl.id !== this.state.editRow && cl.id !== -1) ? "flex-fill constituencyDescriptionReadOnly" : "flex-fill constituencyDescription"} id={'description-' + cl.id}
                                                        value={cl.description.replace(/ +/g, " ")}
                                                        required maxLength="100"
                                                        title={cl.description.replace(/ +/g, " ")}
                                                        onChange={(e) => this.constituencyChangeHandler(e)}
                                                        placeholder="Constituency"
                                                        autoComplete="off"
                                                    />
                                                }

                                                <div className="me-1">
                                                    <FontAwesomeIcon icon={faUsers}></FontAwesomeIcon>
                                                </div>
                                                <div className="me-3">
                                                    {cl.id > 0 ? cl.count : 0}
                                                </div>
                                                <div className="constituencyButtons">

                                                    {
                                                        (cl.id === this.state.editRow && cl.id === this.state.selectedConstituencyID) &&
                                                        <Fragment>
                                                            <Button size="sm" className="constituencyButton" variant="dark" disabled={!this.props.isAuthorized} onClick={() => this.saveHandler(cl)}>
                                                                Save
                                                            </Button>
                                                            <Button size="sm" className="constituencyButton" variant="dark" onClick={() => this.cancelHandler(cl)}>
                                                                Cancel
                                                            </Button>
                                                        </Fragment>
                                                    }
                                                    {
                                                        cl.id !== this.state.editRow && cl.id > 0 &&
                                                        <Fragment>
                                                            <Button size="sm" disabled={(!this.props.isAuthorized) || (cl.id !== this.state.selectedConstituencyID)}
                                                                className="constituencyButton" variant="dark" onClick={() => this.editHandler(cl.id)}>
                                                                Edit
                                                            </Button>
                                                            <Button size="sm" disabled={(!this.props.isAuthorized) || (cl.id !== this.state.selectedConstituencyID)}
                                                                className="constituencyButton" variant="danger"
                                                                onClick={() => swal({ title: "Are you sure you want to delete this constituency?", text: "This change will be reflected in the live attendee app", icon: "warning", buttons: true, dangerMode: true }).then((value) => { if (value) this.deleteConstituency(cl) })}>
                                                                Delete
                                                            </Button>
                                                        </Fragment>
                                                    }

                                                </div>
                                            </div>
                                        }
                                    </div>)
                            })
                            :
                            <div>
                                <span>No constituencies available for this meeting</span>
                            </div>
                        }
                        {this.state.error && <div className="errorMessage">{this.state.errorMsg}</div>}
                    </Col>
                    <Col className="constituencyContestList">
                        {contestList && !contestList.map((x) => x.id).includes(0) && contestList.length >= 1 ?
                            this.state.selectedConstituencyID && allContests ?
                                <Fragment>
                                    <div className="d-flex justify-content-between align-items-center my-2">
                                        <div><input type="checkbox" id="selectAllContests" disabled={this.state.selectedConstituencyID <= 0 || this.state.editRow > 0 || (allContests.filter((c) => c.contestStatusId < 2).length === 0)} value="checkedall" onClick={this.handleAllContestChecked} checked={this.state.isAllSelected} /><label htmlFor="selectAllContests">Select All Contests</label></div>
                                        <ButtonToolbar>
                                            <Button size="sm" disabled={(!this.props.isAuthorized) || (!this.state.contentChanged)} className="me-2" onClick={() => this.updateContestConstituency()} >Save</Button>
                                            <Button size="sm" disabled={!this.state.contentChanged} onClick={() => this.getConstituency()}>Reset</Button>
                                        </ButtonToolbar>
                                    </div>
                                    <ul className="nobullets">
                                        {
                                            allContests && allContests.map((contest, index) => {
                                                return (<CheckBoxContest key={index} constituencyID={this.state.selectedConstituencyID} editRow={this.state.editRow}
                                                    handleCheckContestElement={this.handleCheckContestElement} {...contest} />)
                                            })
                                        }
                                    </ul>

                                    <ButtonToolbar className="m-2">
                                        <Button size="sm" disabled={(!this.props.isAuthorized) || (!this.state.contentChanged)} className="me-2" onClick={() => this.updateContestConstituency()} >Save</Button>
                                        <Button size="sm" disabled={!this.state.contentChanged} onClick={() => this.getConstituency()}>Reset</Button>
                                    </ButtonToolbar>


                                </Fragment> :
                                <div>Please select a constituency and contests will appear</div>
                            : <div>Please create a contest, no contests available</div>
                        }
                    </Col>
                </Row>
            </React.Fragment>
        )
    }
}

export default Constituencies;

