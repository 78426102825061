import React from 'react';


import './styles.css'
const Equation = ({ attributes, element, children}) => {
    const {inline,math} = element; 
    return (
        <div/>
    )
}

export default Equation