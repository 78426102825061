import React, { useContext, useState } from 'react';
import { useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import Select from 'react-select';
import { NotificationManager } from 'react-notifications';
import UserPermissionContext from '../UserPermissionContext';

const AddClientAccess = ({ showAccessModal, handleAccessModal, sectionId, selectedUser  }) => {


    //Contexts//
    const Context = useContext(UserPermissionContext);

    //Component state//
    const [client, setClient] = useState({ value: 0, label:'' });
    const [template, setTemplate] = useState({ value: 0, label: '' });
    const [isLoading, setIsLoading] = useState(0);
    const [isValid, setIsValid] = useState(true);


    const handleSubmit = async (e) => {
        e.preventDefault();
        if (client?.value === 0) {
            setIsValid(false);
            return;
        }
        setIsLoading(true);
        const userParams = {
            ClientId: client.value,
            TemplateId: template.value == 0 ? null : template.value,
            Id: selectedUser.Id
        }
        const status = await Context.AddClientMeetingAccessPermissions(userParams);
        if (status?.errorMessage !== '') {
            NotificationManager.error(status?.errorMessage, "Permissions", 4000);
        }
        else {
            Context.setSelectedClient({ value: client.value, name: client.label });
            await Context.getUserAccessDetails(selectedUser.Id);
            handleAccessModal();
            setClient({ value: 0, label: '' });
            setTemplate({ value: 0, label: '' })
        }
        setIsLoading(false)
    }


    useEffect(() => {
        getSectionList();
    }, [])


    const getSectionList = async () => {
        await Context.getAddAccessClientList();
    }


    /**
    * Render function to render template options
    * */
    const getTemplateList = () => {
        const { templates } = Context;
        return templates.map(template => {
            return { value: template.TemplateId, label: template.Name }
        });
    };


    const getAccessList = () => {
        if (sectionId === 1) {
            const { accessClients } = Context;

            let validClients = accessClients.filter(client => {
                if (!isClientExist(client)) {
                    return { value: client.Id, label: client.Name }
                }
            });
            return validClients.map(client => {
                return { value: client.Id, label: client.Name }
            });
        }

    }

    const isClientExist = (client) => {
        const { userClients } = Context;
        let isExist = userClients.some(function (userClient) {
            return (userClient.Id === client.Id && userClient.Rank === 1)
        });
        return isExist;
    }


    const handleClientChange = client => {
        setClient(client);
        setIsValid(true);
    }

    const handleTemplateChange = template => {
        setTemplate(template)
    }

    const handleModalClose = () => {
        setClient({ value: 0, label: '' });
        setTemplate({ value: 0, label: '' });
        setIsValid(true);
        handleAccessModal();
    }


    return (
        <Modal show={showAccessModal}
            onHide={handleModalClose}
            size="md" centered
        >
            <form autoComplete="off" onSubmit={handleSubmit}>
                <Modal.Header closeButton>
                    <Modal.Title>Add Access</Modal.Title>
                </Modal.Header>
                <Modal.Body className="p-2">
                    <div className="container-fluid border shadow-sm p-2">
                        <div className="row">
                            <label  className="form-label">Client</label>
                            <Select
                                onChange={handleClientChange}
                                options={getAccessList()}
                                required={true}
                            />
                            {
                                !isValid &&
                                <span className="text-danger">This field is required</span>
                            }
                        </div>
                        <label className="form-label">Template</label>
                        <div className="row">
                            <Select
                                onChange={handleTemplateChange}
                                options={getTemplateList()}
                            />
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-secondary" type="button"
                        onClick={handleModalClose}
                    >
                        Cancel
                    </button>
                    <button className="btn btn-primary" type="submit" disabled={isLoading}>
                        {isLoading ? <span className="spinner-border spinner-border-sm" role="sss" aria-hidden="true"></span> : "Save"}
                    </button>
                </Modal.Footer>
            </form>
        </Modal>
    )
}

export default AddClientAccess;