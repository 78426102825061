import { Fragment, useContext, useState } from "react"
import AdminContext from "../../context/AdminContext"
import axios from 'axios';
import swal from 'sweetalert';
import { NotificationManager } from 'react-notifications';
import UserPermissionContext from '../UserPermissionContext';

export function DisableAccount() {
    const Context = useContext(UserPermissionContext);

    const { selectedUser, defaultClient, selectedUserMeeting } = Context;
    const { isGlobalAuthorized } = useContext(AdminContext);

    let user = Context.userList.find(x => x.Id == selectedUser.Id) || selectedUser;
    const [disabled, setDisabled] = useState(user?.LockoutEnabled)

    async function disableUser() {

        swal({
            title: `${disabled ? 'Enable' : 'Disable'} user account`,
            text: `Are you sure you wish to ${disabled ? 'enable' : 'disable'} this user account?`,
            icon: "warning",
            buttons: true,
            dangerMode: true
        }).then(async (value) => {
            if (value) {
                let success = await apiDisableUser(selectedUser.Id, !disabled);
                if (success) {
                    setDisabled(!disabled);
                    Context.setUser({ ...selectedUser, LockoutEnabled: !disabled });
                    await UpdateUserList();
                }
            }
        })
    }

    const UpdateUserList = async () => {
        if (selectedUserMeeting !== undefined && defaultClient !== undefined) {
            const client = selectedUserMeeting?.value == null ? defaultClient.value : null;
            const meeting = selectedUserMeeting?.value;
            //either client user list or meeting user list//
            await Context.GetUsersByMeeting(meeting, client);
        }
    }

    if (isGlobalAuthorized('UserAdmin', 'Write')) {
        return (
            <button
                className="btn btn-warning btn-sm"
                onClick={disableUser}
            >{disabled ? 'Enable' : 'Disable'} user account</button>
        )
    }
    else {
        return <Fragment />
    }
}

async function apiDisableUser(targetuserId, status) {
    const axoptions = {
        url: 'api/UserAdminDisableUser',
        method: 'POST',
        data: {
            TargetUserId: targetuserId,
            Disabled: status
        }
    };

    let returnValue;
    await axios(axoptions)
        .then(response => {
            returnValue = response;
            return (response);
        }).catch((error) => {
            returnValue = "error"
            return ('error');
        })
    let title = `${ !status ?'Enable': 'Disable'} account`
    if (returnValue.status === 200 && returnValue.data?.statusID) {
        let message = `Account ${ !status ? 'enabled' : 'disabled'} successfully`;
        switch (returnValue.data.statusID) {
            case 1:
                NotificationManager.success(message, title, 4000);
                return true;
            default:
                NotificationManager.error("An error occurred, please try again", title, 4000);
                return false;
        }
    } else {
        NotificationManager.error("An error occurred", title, 4000);
        return false;
    }
}

