import React, { useContext, useState } from 'react';
import { useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { NotificationManager } from 'react-notifications';
import Select from 'react-select';
import UserPermissionContext from '../UserPermissionContext';
import { UserTypes } from './UserAdminTypes';

const AddMeetingAccess = ({ showAccessModal, handleAccessModal, sectionId, selectedUser }) => {

    //Contexts//
    const Context = useContext(UserPermissionContext);
    const { clients } = Context;

    //Component state//
    const [meetings, setMeetings] = useState([]);
    const [selectedMeetings, setMeeting] = useState([]);
    const [client, setClient] = useState({ value: 0, label: '' });
    const [template, setTemplate] = useState({ value: 0, label: '' });
    const [isLoading, setIsLoading] = useState(false);
    const [isValid, setIsValid] = useState(true);


    const handleSubmit = async(e) => {
        e.preventDefault();
        if (selectedMeetings?.length === 0) {
            setIsValid(false);
            return;
        }
        setIsLoading(true);
        setIsValid(true);
        const userParams = {
            TemplateId: template.value == 0 ? null: template.value,
            Id: selectedUser.Id,
            MeetingList: selectedMeetings.map(meeting => meeting.value)
        }
        const status = await Context.AddClientMeetingAccessPermissions(userParams);
        if (status?.errorMessage !== '') {
            NotificationManager.error(status?.errorMessage, "Permissions", 4000);
            await Context.getUserAccessDetails(selectedUser.Id);
            await Context.GetUserMeetingsAccessDetails(selectedUser.Id, client.value);
        }
        else {
            Context.setSelectedClient({ value: client.value, name: client.label });
            await Context.getUserAccessDetails(selectedUser.Id);
            await Context.GetUserMeetingsAccessDetails(selectedUser.Id, client.value);
            handleAccessModal();
            setClient({ value: 0, label: '' });
            setTemplate({ value: 0, label: '' });
            setMeeting([]);
            setMeetings([]);
        }
        setIsLoading(false)
    }


    const getClientList = () => {
        return clients.map(client => {
            return { value: client.ClientID, label: client.ClientName }
        });
    }

    const getMeetingList = (meetingList) => {
        let validMeetings = meetingList?.filter(meeting => !isMeetingExist(meeting))
        let meetings = validMeetings?.map(meeting => {
                return { value: meeting.VMeetingID, label: meeting.MeetingName }
       });
        setMeetings(meetings)
    }


    const isMeetingExist = (meeting) => {
        const { userMeetings } = Context;
        let isExist = userMeetings.some(function (userMeeting) {
            return (userMeeting.Id === meeting.VMeetingID)
        });
        return isExist;
    }


    /**
     * event handler for client selection change
     * @param {any} e
     */
    const handleClientChange = async (selectedClient) => {
        setClient(selectedClient);
        let client = clients.find(client => client.ClientID == selectedClient.value);
        //If rank is global then no need to make an api call because app already knows
        //all the clients and client meetings
        if (client?.Rank == UserTypes.GLOBAL_USER) {
            const { Meetings } = client || [];
            getMeetingList(Meetings)
        }
        else {
            const meetingsResponse = await Context.getUserMeetings(selectedClient.value);
            getMeetingList(meetingsResponse);
        }
    }


    const handleMeetingChange = meeting => {
        if (meeting !== null) {
            setMeeting(meeting);
            setIsValid(true);
        }
        else {
            setMeeting([]);
            setIsValid(false);
        }
    }

    const handleTemplateChange = template => {
        setTemplate(template)
    }

    /**
    * Render function to render template options
    * */
    const getTemplateList = () => {
        const { templates } = Context;
        return templates.map(template => {
            return { value: template.TemplateId, label: template.Name }
        });
    };

    const handleModalClose = () => {
        setMeeting([]);
        setClient({ value: 0, label: '' });
        setTemplate({ value: 0, label: '' });
        setIsValid(true);
        handleAccessModal();
        setMeetings([]);
    }

    return (
        <Modal show={showAccessModal}
            onHide={handleModalClose}
            size="md" centered
        >
            <form autoComplete="off" onSubmit={handleSubmit}>
                <Modal.Header closeButton>
                    <Modal.Title>Add Access</Modal.Title>
                </Modal.Header>
                <Modal.Body className="p-2">
                    <div className="container-fluid border shadow-sm p-2">
                        <div className="row">
                            <label className="form-label">Client</label>
                            <Select
                                options={getClientList()}
                                onChange={handleClientChange}
                                name="Client"
                                required={true}
                            />
                        </div>
                        <div className="row">
                            <label className="form-label">Meeting</label>
                            <Select
                                options={meetings}
                                isMulti={true}
                                onChange={handleMeetingChange}
                                name="Meeting"
                                required={true}
                            />
                            {
                                !isValid &&
                                <span className="text-danger">This field is required</span>
                            }
                        </div>
                        <div className="row">
                            <label className="form-label">Template</label>
                            <Select
                                onChange={handleTemplateChange}
                                options={getTemplateList()}
                            />
                        </div>

                        <div className="row p-2">

                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-secondary" type="button"
                        onClick={handleModalClose}
                    >
                        Cancel
                    </button>
                     <button className="btn btn-primary" type="submit" disabled={isLoading}>
                        {isLoading ? <span className="spinner-border spinner-border-sm" role="sss" aria-hidden="true"></span> : "Save"}
                    </button>
                </Modal.Footer>
            </form>
        </Modal>
    )
}

export default AddMeetingAccess;