import React, { Fragment, useEffect } from "react";
import axios from 'axios';
import { useState } from "react";
import { Loader, SmallLoader } from '../SecondaryComponents';

async function GetContestQuorumData(contestId) {
    const axoptions = {
        url: 'api/VMGetContestQuorumData',
        method: 'POST',
        data: {
            ContestId: contestId
        }
    };
    let returnValue;
    await axios(axoptions)
        .then(response => {
            returnValue = JSON.parse(response.data.jsonResponse)
        }).catch((error) => {
            console.log('VMGetContestQuorumData error');
        });

    return returnValue;
}

function useCustomHook(contestId) {
    const [quorumData, setQuorumData] = useState('');
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        getData();
    }, [contestId])

    useEffect(() => {
        if (quorumData?.length > 0 && isLoading) {
            setIsLoading(false);
        }
    }, [quorumData])

    async function getData() {
        setIsLoading(true);
        setQuorumData(await GetContestQuorumData(contestId) ?? 'No data available');
    }

    return [quorumData, isLoading, getData];
}


export default function DisplayQuorumData(props) {
    const [quorumData, isLoading, getData] = useCustomHook(props.contestId)
    return (
        <div>
            <div>
                <div>This data only includes users of the web application.</div>
                <button
                    className="btn btn-secondary"
                    title="Refresh"
                    disabled={isLoading}
                    onClick={() => { getData() }}>
                    {isLoading ? <SmallLoader /> : "Refresh"}
                </button>
            </div>

            {isLoading && <Loader />}
            <div className="d-flex">
                {quorumData[0]?.ContestID === props.contestId && quorumData.map((group, i) => {
                    return (
                        <div className="w-md-30 m-1 p-2 flex-fill flex-grow-1 attendeeSummaryBoxPres" key={i}>
                            <div className="fs-5 fw-bold text-center">
                                {group.AttendeeRole?.replace('M', ' m').replace('nV', 'n-v') ?? <span title="an error has occurred, verify this data">Unknown role</span>}
                            </div>
                            <div className={`fs-3 text-center statH ${group.AttendeeRole === "VotingMember" && group.ConstituencyValid ? "fw-bold" : "fw-normal"}`}>
                                {group.CountedAttendees}
                            </div>
                            <div>In room: {group.IsInRoom}</div>
                            <div>In constituency: {group.ConstituencyValid ? "Yes" : "No"}</div>
                        </div>
                    )
                })}
                {!quorumData[0]?.ContestID && "No data yet exists for this contest"}
            </div>
        </div>
    )
}