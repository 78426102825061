import React from 'react'
import { faLock } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export const CheckBoxContest = props => {
    return (
        
        <li className={props.isChecked ? "contestCardChecked" : "contestCard"} onClick={props.contestStatusId > 1 ? () => { return false; } : props.handleCheckContestElement}>
            <div className="contestLock">
                {props.contestStatusId > 1 && <FontAwesomeIcon icon={faLock}>&nbsp;</FontAwesomeIcon>}
            </div>
            <input
                key={props.contestId}
                id={props.contestId}
                name="checkContestAll"
                onClick={props.contestStatusId > 1 ? () => { return false; } : props.handleCheckContestElement}
                type="checkbox"
                checked={props.isChecked}
                value={props.contestName}
                disabled={props.constituencyID <= 0 || props.editRow > 0}
            /> <label htmlFor={props.contestId}>{props.contestName}</label>
        </li>
    )
}

export default CheckBoxContest