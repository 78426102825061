import React, { Component, useEffect, useState, useRef } from 'react';
import AdminContext from './AdminContext';
import axios from 'axios';
import { useMsal, useAccount, useIsAuthenticated } from "@azure/msal-react";
import { getControllerRequestScopes, } from '../../authConfig';
import { auditLogin, UserAdminGetUserMeetingPermissions, UserAdminGetUserGlobalPermissions } from './ApiFunctions';
import { EventEmitter } from 'events';

export const eventEmitter = new EventEmitter();

export function AdminContextProvider(props) {
    const { instance, accounts } = useMsal();
    const account = useAccount(accounts[0] || []);
    const [ready, setReady] = useState(false);
    const [userData, setUserData] = useState();
    const [localAuthValue, setLocalAuthValue] = useState();
    const [localAccount, setLocalAccount] = useState(null);
    const refAccount = useRef();
    const isAuthenticated = useIsAuthenticated();
    const refControllerScope = useRef();


    useEffect(() => {
        if (isAuthenticated !== localAuthValue) {
            setLocalAuthValue(isAuthenticated);
        }
    }, [isAuthenticated])


    useEffect(() => {
        if (isAuthenticated && account && !localAccount) {
            setLocalAccount(account)
            refAccount.current = account;
        }
        if (isAuthenticated && localAuthValue && (!userData) && !ready) {
            fetchUserData();
            auditLogin('Login');
        } else if (!isAuthenticated) {

            setLocalAuthValue(false);
            setLocalAccount(null);
            setUserData(null);
            setReady(false);
            refAccount.current = null;
        }
    }, [localAuthValue])


    //get the controller token from MSAL
    const fetchControllerToken = async () => {
        let controllerRequest = {};
        if (!refControllerScope.current) {
            controllerRequest = await getControllerRequestScopes();
            refControllerScope.current = controllerRequest;
        } else {
            controllerRequest = refControllerScope.current;
        }

        let funcAccount = null;
        if (accounts[0]) {
            funcAccount = accounts[0];
        } else if (account) {
            funcAccount = account;
        } else if (localAccount) {
            funcAccount = localAccount;
        } else if (refAccount.current) {
            funcAccount = refAccount.current
        }
        if (funcAccount) {
            const request = {
                ...controllerRequest,
                account: funcAccount
            };
            let token = null;
            await instance.acquireTokenSilent(request).then((response) => {
                token = response.accessToken;
            }).catch(async (e) => {
                await instance.acquireTokenPopup(request).then((response) => {
                    token = response.accessToken;
                })
            })
            return token;
        } else return false;
    }

    const setUpAxiosInterceptor = () => {
        //axios interceptor, add headers to requests
        axios.interceptors.request.use(async request => {
            let token = await fetchControllerToken();
            let vMeetingId = sessionStorage.getItem('VMId') ? sessionStorage.getItem('VMId').toString() : 0;

            if (token) {
                request.headers.Authorization = `Bearer ${token}`;
                request.headers['VMeetingId'] = vMeetingId;
                return request;
            }
            return Promise.reject('No token found');
        }, error => {
            return Promise.reject(error);
        })
    }

    useEffect(() => {
        //console.log('setup axios interceptor')
        setUpAxiosInterceptor();
    }, [])


    async function fetchUserData() {
        if (isAuthenticated) {
            const axoptions = {
                url: 'api/UserAdminGetUserFromClaim',
                method: 'POST'
            };

            await axios(axoptions)
                .then(response => {
                    setUserData(JSON.parse(response.data.jsonResponse)[0]);
                    setReady(true);
                }).catch(() => { return; });
        }
    }

    return <AdminContextProviderClass
        {...props}
        isAuthenticated={isAuthenticated}
        fetchControllerToken={fetchControllerToken}
        userData={userData}
        ready={userData && ready}
    />

}


class AdminContextProviderClass extends Component {
    constructor(props) {
        super(props);
        this.state = {
            adminPermissions: null,
            globalPermissions: null,
            globalReady: false,
            meetingPermissions: null,
            meetingReady: false,
            switches: {},
            VmeetingId: 0,
            populated: false,
            signalRHub: null,
            userPages: []
        };
        this.switchesInterval = null;
        this.permissionsInterval = null;
    }


    componentDidMount() {
        this.switchesInterval = setInterval(this.CheckSwitches, 1000);
        this.permissionsInterval = setInterval(this.CheckPermissionsExist, 1000);
        this.globalPermissionsInterval = setInterval(this.CheckGlobalPermissionsExist, 1000);
    }

    componentWillUnmount() {
        if (this.switchesInterval) {
            clearInterval(this.switchesInterval);
        }
        if (this.permissionsInterval) {
            clearInterval(this.permissionsInterval);
        }
    }

    CheckSwitches = () => {
        if (this.isEmpty(this.state.switches) && this.VmeetingId() > 0 && this.UserId() > 0 && this.state.meetingPermissions) {
            this.populateswitchdata(true);
        } else if (!this.isEmpty(this.state.switches) && this.switchesInterval && this.VmeetingId() > 0 && this.UserId() > 0) {
            clearInterval(this.switchesInterval);
        }
    }

    CheckPermissionsExist = () => {
        //Ensure meeting permissions are populated
        if (!this.state.meetingPermissions && this.VmeetingId() > 0 && this.UserId() > 0 && !this.props.userData?.LockoutEnabled) {
            this.GetUserPermissions();
        } else if ((this.state.meetingPermissions && this.permissionsInterval && this.VmeetingId() > 0 && this.UserId() > 0) || (this.permissionsInterval && this.props.userData?.LockoutEnabled)) {
            clearInterval(this.permissionsInterval);
            this.setState({
                meetingReady: true
            })
        }
    }

    CheckGlobalPermissionsExist = () => {
        //Ensure global permissions are populated
        if (!this.state.globalPermissions && this.UserId() > 0 && !this.props.userData?.LockoutEnabled) {
            this.GetUserGlobalPermissions();
        } else if ((this.state.globalPermissions && this.globalPermissionsInterval && this.UserId() > 0) || (this.globalPermissionsInterval && this.props.userData?.LockoutEnabled)) {
            clearInterval(this.globalPermissionsInterval);
            this.setState({
                globalReady: true
            })
        }
    }

    GetUserPermissions = async (vMeetingId) => {
        let _vmMeetingId;
        if (vMeetingId) {
            _vmMeetingId = vMeetingId
        } else if (this.VmeetingId()) {
            _vmMeetingId = this.VmeetingId();
        }
        if (_vmMeetingId > 0) {
            let permissionsData = await UserAdminGetUserMeetingPermissions(_vmMeetingId);
            if (permissionsData && permissionsData.status === 200) {
                let jsonpermissions = permissionsData.data.jsonResponse;
                let permissions = JSON.parse(jsonpermissions);
                if (permissions.Permissions) {
                    this.setState({
                        meetingPermissions: permissions.Permissions
                    })
                } else return "no permissions";
            }
        } else {
            //Put generic non meeting specific permission fetch in here
            return;
        }
    }

    GetUserGlobalPermissions = async () => {
        let permissionsData = await UserAdminGetUserGlobalPermissions();
        if (permissionsData && permissionsData.status === 200) {
            let jsonpermissions = permissionsData.data.jsonResponse;
            let permissions = JSON.parse(jsonpermissions);
            this.setState({
                globalPermissions: permissions.Permissions ? permissions.Permissions : [{}]
            })
        }
    }

    CheckUserMeetingPermissions = (permission, readWrite) => {
        if (this.state.meetingPermissions && this.state.meetingPermissions.length > 0 && !this.props.userData?.LockoutEnabled) {
            let permissionSet = this.state.meetingPermissions.filter(t => t.ShortName === permission && t[readWrite] === true);
            if (permissionSet.length === 1 && permissionSet[0].ShortName === permission) {
                //console.log('found permission', permission, readWrite, permissionSet)
                return true;
            } else return false;

        } else return false;
    }

    CheckUserGlobalPermissions = (permission, readWrite) => {
        if (this.state.globalPermissions && this.state.globalPermissions.length > 0 && !this.props.userData?.LockoutEnabled) {
            let permissionSet = this.state.globalPermissions.filter(t => t.ShortName === permission && t[readWrite] === true);
            if (permissionSet.length === 1 && permissionSet[0].ShortName === permission) {
                //console.log('found permission', permission, readWrite, permissionSet)
                return true;
            } else return false;

        } else return false;
    }


    isEmpty = (obj) => {
        return Object.keys(obj).length === 0;
    }

    VmeetingId = () => {
        let vmID = parseInt(sessionStorage.getItem('VMId'));
        return vmID;
    }


    UserId = () => {
        if (this.props.userData && this.props.userData.Id) {
            let userId = this.props.userData.Id;
            sessionStorage.setItem('UserId', userId); //get rid of this ASAP
            return userId;
        } else return null;
    }


    GetUserAccessiblePages = () => {
        //Check how many pages the user has access to
        const pages = [];
        let permissionSet = this.state.meetingPermissions;
        if (permissionSet && !this.props.userData?.LockoutEnabled) {
            permissionSet.forEach(permission => { if (permission.ParentPage) { pages.push(permission.ParentPage) } });
            let uniquePages = [...new Set(pages)];
            return uniquePages;
        } else return false;
    }

    isUserHasPageAccess = (pageName, readWrite) => {
        if (this.state.meetingPermissions && this.state.meetingPermissions.length > 0 && !this.props.userData?.LockoutEnabled) {
            let permissionSet = this.state.meetingPermissions.filter(t => t.ParentPage === pageName && t[readWrite] === true);

            if (permissionSet.length > 0 && permissionSet[0].ParentPage === pageName) {
                //console.log('found permission', pageName, readWrite, permissionSet)
                return true;
            } else return false;

        } else return false;
    }


    populateswitchdata = (forceUpdate) => {
        const id = parseInt(this.VmeetingId());
        if (this.props.ready && (id !== this.state.VmeetingId || forceUpdate === true || !this.state.populated) && this.state.meetingPermissions) {
            this.setState({ VmeetingId: id })
            if (id > 0) {
                axios.get('VMGetMeetingSwitches?VMId=' + id)
                    .then(response => {
                        this.setState({
                            switches: response.data,
                            populated: true
                        })
                    }).catch(() => { return; });

            }
        }
    }



    render() {
        return (
            <AdminContext.Provider value={{
                fullState: this.state,
                switches: this.state.switches,
                populateswitchdata: this.populateswitchdata,
                signalRHub: this.state.signalRHub,
                populated: this.state.populated,
                meetingId: this.VmeetingId(),
                userId: this.UserId(),
                isAuthenticated: this.props.isAuthenticated,
                ready: this.props.ready && ((!!this.VmeetingId() && this.state.meetingReady) || !this.VmeetingId()) && this.state.globalReady,
                fetchControllerToken: this.props.fetchControllerToken,
                GetUserPermissions: this.GetUserPermissions,
                GetUserGlobalPermissions: this.GetUserGlobalPermissions,
                CheckUserMeetingPermissions: this.CheckUserMeetingPermissions,
                CheckUserGlobalPermissions: this.CheckUserGlobalPermissions,
                GetUserAccessiblePages: this.GetUserAccessiblePages,
                isAuthorized: this.CheckUserMeetingPermissions,
                isGlobalAuthorized: this.CheckUserGlobalPermissions,
                meetingPermissions: this.state.meetingPermissions,
                isUserHasPageAccess: this.isUserHasPageAccess,
                userPages: this.state.userPages,
                userData: this.props.userData
            }} >
                {this.props.children}
            </AdminContext.Provider>
        )
    }
}

