import React from 'react';
import { useNavigate } from 'react-router-dom';

export const LayoutRegistration = (props) => {
    const navigate = useNavigate();
    return (
        <div className="container">
        <div className="row">
            <div className="RegistrationLayout col-md-12">
                <div className="d-flex justify-content-between">
                    <button className="btn btn-primary btn-sm" onClick={() => navigate('/')}>Home</button>
                    <button disabled={!props.isAuthorized} className="btn btn-primary btn-sm" onClick={() => props.resetPage()}>Reset page</button>
                </div>
                <div className="registrationLogo">
                    <h1 className="adminTitle">CES<span className="pinkIcon">Registration</span></h1>
                    {props.phase > 1 && <div className="registrationSubTitle">{props.meetingTitle}</div>}
                </div>
                {props.children}
            </div>
        </div>
    </div>)
}

