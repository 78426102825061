import React, { Fragment, useContext, useEffect } from 'react';
import AdminContext from '../../context/AdminContext';
import { MessageGetTagsForGroup } from './ApiFunctions';
import { connection } from '../../SignalRController';
import { Tag } from './Tag';

export function GroupTagSummary(props) {
    const { messageGroupId, groupTags, setGroupTags, messageCount } = props;
    const { meetingId } = useContext(AdminContext);

    const FetchData = async () => {
        let data = await MessageGetTagsForGroup(meetingId, messageGroupId);
        setGroupTags(data);
    }

    useEffect(() => {
        //Setup connection trigger for SR
        connection.on("srHubAdm2Adm_ComfortUpdateGroup:" + messageGroupId, () => {
            FetchData();
        });

        //Cleanup connection event listener
        return () => {
            connection.off("srHubAdm2Adm_ComfortUpdateGroup:" + messageGroupId);
        };
    }, [])

    // fix Warning: Can't perform a React state update on an unmounted component
    useEffect(() => {
        return () => { };
    }, []);

    useEffect(() => {
        FetchData();
    }, [messageCount])

    if (groupTags) {
        return (
            <Fragment>
                {groupTags.map(tag => <Tag key={tag.messageTagId} tag={tag} />)}
            </Fragment>
        )
    } else {
        return <Fragment />
    }
}