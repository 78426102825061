import React, { useRef, useState, useEffect, useContext, Fragment } from 'react';
import AdminContext from '../../context/AdminContext';
import { InlineLoader } from '../../SecondaryComponents';
import { Message } from './Message';
import { GetMessagesForComfortGroup, MessageGroupForward, MessageGroupShowHide } from './ApiFunctions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGripVertical, faShare, faEyeSlash, faEye, faExclamationTriangle, faWindowClose } from '@fortawesome/free-solid-svg-icons';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import { InboxPermission } from './Constants';
import { GroupTagSummary } from './GroupTagSummary';
import { connection } from '../../SignalRController';

//props:-
//currentInbox:int
//group:ComfortMonitorMessageGroup
//index: int
//filter: int
export function MessageGroup(props) {
    let { group, currentInbox, index, filter, summary, activeInbox } = props;
    const intersectTarget = useRef(null);
    const { meetingId, userId, isAuthorized, switches } = useContext(AdminContext);
    const [loading, setLoading] = useState(true);
    const [messages, setMessages] = useState();
    const [groupTags, setGroupTags] = useState();
    const allReady = useRef([]);
    const [readyState, setReadyState] = useState(false);

    const SetReady = (index, ready) => {
        allReady.current[index] = ready;
        let checker = allReady.current.every(v => v === true);
        setReadyState(checker);
    }

    const FetchData = async (entries) => {
        const [entry] = entries;
        if (entry.isIntersecting && !messages) {
            let apidata = await GetMessagesForComfortGroup(meetingId, group.messageGroupId)
            setMessages(apidata);
            setLoading(false);
        }
    }

    const srFetchData = async () => {
        let apidata = await GetMessagesForComfortGroup(meetingId, group.messageGroupId)
        setMessages(apidata);
    }

    const PropChangeFetchData = async () => {
        if (messages && messages.length !== group.messageCount) {
            let apidata = await GetMessagesForComfortGroup(meetingId, group.messageGroupId);
            if (apidata.length !== messages.length) {
                setMessages(apidata);
            }
            allReady.current = [];
        }
    }

    const MoveMessageGroup = async () => {
        //Normally we just want it to move on one space
        let targetInbox = currentInbox + 1;
        if (currentInbox === 2 && !switches.enableComfortMonitor) {
            //but if comfort monitor is not in use, we want it to go straight from queue to history
            targetInbox = 4;
        }
        await MessageGroupForward(meetingId, userId, group.messageGroupId, targetInbox)
    }

    const ShowHideGroup = async () => {
        await MessageGroupShowHide(meetingId, userId, group.messageGroupId, !group.displayHidden);
    }

    useEffect(() => {
        //Setup connection trigger for SR
        connection.on("srHubAdm2Adm_ComfortUpdateGroup:" + group.messageGroupId, () => {
            srFetchData();
        });

        //Cleanup connection event listener
        return () => {
            connection.off("srHubAdm2Adm_ComfortUpdateGroup:" + group.messageGroupId);
        };
    }, [])



    useEffect(() => {
        PropChangeFetchData();
    }, [group.messageCount])

    useEffect(() => {
        //We don't want to load the group until the container is visible to the user
        const options = {
            root: null,
            rootMargin: "0px",
            threshold: 0.2 //This is the fraction from 0-1 of the object that should be visible before intersect triggers 0.2=20%
        };

        const observer = new IntersectionObserver(FetchData, options);
        let localTarget = intersectTarget.current; //Linter wants this, I'll give it a chance
        if (localTarget) {
            observer.observe(localTarget);
        }
        return () => {
            if (localTarget) {
                observer.unobserve(localTarget);
            }
        }
    }, [intersectTarget])

    if (filter > 0) {
        let findTag = groupTags?.find(a => a.messageTagId === parseInt(filter))
        if (!findTag) {
            return <div ref={intersectTarget}>
                <div className="w-100 m-1 p-1 d-flex flex-wrap d-none">
                    <GroupTagSummary
                        messageGroupId={group.messageGroupId}
                        groupTags={groupTags}
                        setGroupTags={setGroupTags}
                        messageCount={group.messageCount}
                    />
                </div>
            </div>
        }
    }

    const GroupClassNames = (snapshot) => {
        let baseClassNames = "message-group border rounded p-1 m-1 smaller bg-light";
        let dragging = snapshot.isDragging ? 'dragging' : '';
        return baseClassNames.concat(" ", dragging);
    }

    const DisableDrag = () => {
        return currentInbox !== 3 && (group.displayHidden || !readyState || !isAuthorized(InboxPermission[currentInbox], 'Write'));
    }

    const showButtonBlock = () => {
        return (currentInbox < 4 && isAuthorized(InboxPermission[group.currentInbox], 'Write'))
            || (activeInbox && isAuthorized("InboxPres", "Write"))
            || (summary && isAuthorized("PresInbox", "Write"));
    }

    //A message group should never be empty, they get cleaned up when all the messages are removed
    return (
        <Draggable
            draggableId={"grp:" + currentInbox + ":" + group.messageGroupId + ":" + group.currentInbox}
            index={index}
            isDragDisabled={DisableDrag()}
            type="Group">
            {(provided, snapshot) => (
                <div className={GroupClassNames(snapshot)}
                    {...provided.draggableProps}
                    ref={provided.innerRef}
                >
                    <div ref={intersectTarget}>
                        <div className="d-flex" {...provided.dragHandleProps}>
                            {isAuthorized(InboxPermission[group.currentInbox], 'Write') &&
                                <div className="m-1 p-1 grab-icon"><FontAwesomeIcon icon={faGripVertical} /></div>
                            }
                            <div className="flex-fill">
                                <div className={`w-100 m-1 p-1 d-flex flex-wrap ${messages?.length === 1 && !summary && 'd-none'} `}>
                                    {messages?.length > 1 &&
                                        <div className="me-1 px-2 rounded d-flex align-items-center smaller bg-brand-secondary text-light">{messages?.length}</div>
                                    }
                                    <GroupTagSummary
                                        messageGroupId={group.messageGroupId}
                                        groupTags={groupTags}
                                        setGroupTags={setGroupTags}
                                        messageCount={group.messageCount}
                                    />
                                </div>
                            </div>
                            {showButtonBlock() &&
                                <div className="m-1 d-flex">
                                    {group.displayHidden ?
                                        <button className={`btn btn-sm border bg-warning ${group.currentInbox === 3 ? "bg-danger" : ""}`} title="Show group" onClick={ShowHideGroup}><FontAwesomeIcon icon={faEye} /></button>
                                        :
                                        <Fragment>
                                            {currentInbox === 1 &&
                                                <button className="btn btn-sm border" title="Hide group" onClick={ShowHideGroup}><FontAwesomeIcon icon={faEyeSlash} /></button>
                                            }
                                            {readyState || currentInbox === 3 || summary ?
                                                <button className="btn btn-sm border" onClick={MoveMessageGroup} title={summary ? "Remove completed" : "Move group to next stage"}>
                                                    <FontAwesomeIcon icon={summary ? faWindowClose : faShare} />
                                                </button>
                                                :
                                                <div className="p-1">
                                                    <FontAwesomeIcon icon={faExclamationTriangle} title="One or more speakers are not ready" />
                                                </div>
                                            }
                                        </Fragment>
                                    }
                                </div>
                            }
                        </div>
                        <MessageDroppable currentInbox={currentInbox} group={group} messages={messages} loading={loading} SetReady={SetReady} summary={summary} />
                    </div>
                </div>
            )}
        </Draggable>
    )
}

function MessageDroppable(props) {
    const { currentInbox, group, messages, loading, SetReady, summary } = props;
    const [sortedData, setSortedData] = useState();    
    const { switches } = useContext(AdminContext);
    const dropDisabled = currentInbox > 3 || (!switches.enableComfortMonitor && currentInbox > 1);

    useEffect(() => {
        setSortedData(messages?.sort((a, b) => a.displayOrder > b.displayOrder ? 1 : -1))
    }, [messages])
     

    return (
        <Droppable
            droppableId={currentInbox + ":" + group.messageGroupId}
            isDropDisabled={dropDisabled}
            type="Message">
            {(provided, snapshot) => (
                <div ref={provided.innerRef} {...provided.droppableProps} className={snapshot.isDraggingOver ? "rounded drag-over" : ""}>
                    {loading ?
                        <InlineLoader />
                        :
                        <Fragment>
                            {sortedData?.map((message, index) => <Message
                                message={message}
                                key={message.messageId}
                                messageCount={messages.length}
                                groupInbox={group.currentInbox}
                                currentInbox={currentInbox}
                                SetReady={SetReady}
                                index={index}
                                summary={summary}
                            />
                            )}
                        </Fragment>
                    }
                    {provided.placeholder}
                </div>
            )}
        </Droppable>
    )

}