import React, { Component,Fragment } from 'react';
import ReactDatatable from './DataTable';
import axios from 'axios';
import { NotificationManager } from 'react-notifications';
import AdminContext from './context/AdminContext';
import { Loader } from './SecondaryComponents';

export class MembersList extends Component {
    constructor(props) {
        super(props);
        //this.loggedInCurrent = false;
        //this.VmeetingId = parseInt(sessionStorage.getItem('VMId'));
        this.state = {
            memberdata: [],
            filterValue: "",
            filterInProgress: null,
            loading: false,
            moreAvailable: false, // Set to true if we think more results are available to fetch
            pageNumber: 0 // The data page number note the table pagenumber
        };


        this.batchSize = 100; // No. of Attendee results to fetch each time from the server

        this.config = {
            page_size: 25,
            //length_menu: [10, 20, 50],
            language: {
                length_menu: "Show _MENU_ result per page",
                filter: "Search members...",
                info: "Showing _START_ to _END_ of _TOTAL_ members",
                pagination: {
                    first: "First",
                    previous: <span>&#9668;</span>,
                    next: <span>&#9658;</span>,
                    last: "Last"
                }
            },
            //show_pagination: true,
            show_length_menu: false,
            //show_filter: true,
            no_data_text: 'No records found',
            filename: "Members",

            button: {
                extra:  true
            }
        }

        this.extraButtons = []



        this.exportTocsv = this.exportTocsv.bind(this);
        this.convertToCSV = this.convertToCSV.bind(this);
        this.getMemberData = this.getMemberData.bind(this);
    }


    setExtraButtons = () => {
        const { isAuthorized } = this.context;

        if (!this.props.isloggedincurrent && isAuthorized('DataExport', 'Read')) {
            this.extraButtons.push(
                {
                    className: `btn btn-primary buttons-csv`,
                    title: "Full Export to CSV",
                    children: [
                        <span key="csvbutton" >
                            <i className="fa fa-file-text-o" aria-hidden="true"></i>
                        </span>
                    ],
                    onClick: (event) => {
                        this.exportTocsv();
                    },
                })
        }
        this.extraButtons.push(
            {
                className: `btn btn-primary buttons-csv`,
                title: "Refresh list",
                children: [
                    <span key="refreshbutton">
                        <i className="fa fa-refresh" aria-hidden="true"></i>
                    </span>
                ],
                onClick: (event) => {
                    this.getMemberData(this.state.filterValue, 0, this.batchSize);
                },
            })
    }

    convertToCSV(objArray, columns) {
        let array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
        let str = '';
        
        let validColumns = [];

        // Order the columns in the same order as the data
        Object.entries(array[0]).forEach(([datakey, datavalue]) => {
            let found = false;
            let foundkey = '';
            

            columns.forEach((col) => {
                if (col.key === datakey) {
                    foundkey=col.text;
                    found = true;
                }
            });
            if (found) {
                if (str !== '') str += ',';
                str += foundkey;
            }
            validColumns.push(found);
        });
        str += '\r\n';

        for (let i = 0; i < array.length; i++) {
            let line = '';
            let cnt = 0;
            for (let index in array[i]) {
                if (validColumns[cnt]===true) {
                    if (line != '') line += ','
                    line += array[i][index];
                }
                cnt++;
            }
            str += line + '\r\n';
        }
        return str;
    }

    exportTocsv() {

        /*Export complete list - shoulnd't be bigger than 10,000 attendees as thats the limit but set to 20,000 for those who might attendee and then log out*/

        const params = {
            pageLength: 20000,
            pageNumber: 0,
            filter: '',
            VMId: this.props.vmid, //parseInt(sessionStorage.getItem('VMId')),
            isLoggedInCurrent: this.props.isloggedincurrent,
            isHybrid: this.context.switches.hybrid
        }
        const options = {
            url: 'VMGetMemberDataReport',
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            data: params
        };

        this.setState({ loading: true });
        NotificationManager.info("Your attendee list is being processed for export", "Members list", 4000);
       
        axios(options)
            .then(response => {
                if (response.data.length > 0) {

                    let mb = [];
                    response.data.map((mbi, i) => mb.push({
                        index: i + 1,
                        attendeeSource: mbi.attendeeSource,
                        fullName: mbi.fullName,
                        id: mbi.id,
                        voteWeight: mbi.voteWeight,
                        memberActivity: mbi.memberActivity,
                        memberNo: mbi.memberNo,
                        memberType: mbi.memberType.replace('Member', ' Member'),
                        pollCardNumber: mbi.pollCardNumber
                    }));

                    let csv = this.convertToCSV(mb,this.getColumns());
                    let exportedFilename = 'AllAttendeeList.csv';
                    let blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
                    if (navigator.msSaveBlob) { // IE 10+
                        navigator.msSaveBlob(blob, exportedFilename);
                    } else {
                        let link = document.createElement("a");
                        if (link.download !== undefined) { // feature detection
                            // Browsers that support HTML5 download attribute
                            let url = URL.createObjectURL(blob);
                            link.setAttribute("href", url);
                            link.setAttribute("download", exportedFilename);
                            link.style.visibility = 'hidden';
                            document.body.appendChild(link);
                            link.click();
                            link.remove();
                        }
                    }
                }
                this.setState({ loading: false });
            }).catch(function (error) {
                NotificationManager.error("Sorry, there was a problem in exporting the members list.", "Members list", 4000);
                this.setState({ loading: false });
            })
    }



    getColumns = () => {
        var columns = [
            {
                key: "index",
                text: "Entry",
                align: "Left",
                sortable: true
            },
            {
                key: "memberNo",
                text: "Member No.",
                align: "Left",
                sortable: true
            },
            {
                key: "fullName",
                text: "Name",
                align: "Left",
                sortable: true
            },
            {
                key: "memberType",
                text: "Member Type",
                align: "Left",
                sortable: true
            },
            (this.context.switches.enableWeightedCount ? {
                key: "voteWeight",
                text: "Vote Weight",
                align: "center",
                sortable: true
            } : {}),
            {
                key: "attendeeSource",
                text: "Attendee Source",
                align: "Left",
                sortable: true
            },
            (this.context.switches.hybrid ? {
                key: "pollCardNumber",
                text: "Poll Card Number",
                align: "center",
                sortable: true
            } : {}),
            {
                key: "memberActivity",
                text: "Member Activity",
                align: "Left",
                sortable: true
            }]
        var returnColumns = columns.filter(value => Object.keys(value).length !== 0);
        return returnColumns;
    }

    componentDidMount() {
        this.getMemberData('', 0, this.batchSize);
        this.setExtraButtons();
    }

    getMemberData = (filter_value, page_number, page_size) => {
        /* changed as the table pages */
        
        if (this.state.loading) {
            //Just too busy at the moment - I will catch up in a bit!
            return;
        }
        const params = {
            pageLength: parseInt(page_size+1), // Try and get one more record to see more is available than we are requesting
            pageNumber: parseInt(page_number),
            filter: filter_value,
            VMId: this.props.vmid, //parseInt(sessionStorage.getItem('VMId')),
            isLoggedInCurrent: this.props.isloggedincurrent,
            isHybrid: this.context.switches.hybrid
        }
        const options = {
            url: 'VMGetMemberDataReport',
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            data: params
        };

        this.setState({ loading: true });
        this.setState({ filterInProgress: filter_value })
        axios(options)
            .then(response => {
                if (response.data.length > 0) {

                    let mb = [];
                    response.data.map((mbi, i) => i<page_size && mb.push({
                        index: this.state.pageNumber>0 ? 1+ i +this.state.memberdata.length : i+1,
                        attendeeSource: mbi.attendeeSource,
                        fullName: mbi.fullName,
                        id: mbi.id,
                        voteWeight: mbi.voteWeight,
                        memberActivity: mbi.memberActivity,
                        memberNo: mbi.memberNo,
                        memberType: mbi.memberType.replace('Member', ' Member'),
                        pollCardNumber: mbi.pollCardNumber
                    }));

                    if (page_number === 0) {
                        this.setState({
                            memberdata: mb,
                            loading: false,
                            moreAvailable: response.data.length >= page_size  // If we get page_size+1 record there is likely to be more to fetch
                        });
                    } else {
                        this.setState({
                            memberdata: [...this.state.memberdata, ...mb], // Add the new data to the existing data
                            loading: false,
                            moreAvailable: response.data.length >= page_size  // If we get page_size+1 record there is likely to be more to fetch
                        });
                    }
                    if (this.state.filterInProgress != this.state.filterValue) {
                        this.getMemberData(this.state.filterValue, 0, this.batchSize);
                        return;
                    }
                } else this.setState({ memberdata: [],loading: false, moreAvailable: false });
            }).catch(function (error) {
                NotificationManager.error("Sorry, there was a problem in exporting the members list.", "Members list", 4000);
                this.setState({ loading: false });
            })
    }
    tableChangeHandler = data => {
         
        // Only get new data if the filter changes
        if (data.filter_value !== this.state.filterValue) { // User has changed the search criteria
            this.setState({
                memberData: [],
                filterValue: data.filter_value,
                moreAvailable: false,
                pageNumber: 0
            });
            this.getMemberData(data.filter_value, 0, this.batchSize);
            return;
        }

        // If on last page and more available - go get more results - Note: the state page_number is not the same as the page_number
        if (this.state.moreAvailable && data.page_number * data.page_size >= (this.state.memberdata.length-1)) {
            let pn = this.state.pageNumber + 1;
            this.setState({
                filterValue: data.filter_value,
                pageNumber: pn
            });
            this.getMemberData(data.filter_value, pn, this.batchSize);
        }

    }



    render() {
        const { memberdata = [] } = this.state;
        return (
            <Fragment>             
                {this.state.loading === true ? <div className="loader" style={{ height: '75px', width: '75px' }}/> : <div />}
               
                    <div className="attendee-lists hMargin">
                        <ReactDatatable className="table small table-hover"
                            config={this.config}
                            columns={this.getColumns()}
                            extraButtons={this.extraButtons}
                            records={memberdata}
                            onChange={this.tableChangeHandler}
                            Lo />
                    </div>
                           
                {this.state.memberdata && memberdata.length === 0 && <div>No data found for this meeting.</div>}
                
                {this.state.moreAvailable ? < div className="attendee-lists hMargin">More results available....</div>:null}
            </Fragment>
            
            )
    }
}
MembersList.contextType = AdminContext;