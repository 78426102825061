import axios from 'axios';

export const ResetAttendeeIp = async (AttendeeIp) => {
    let attendeeIpUnlock = {
        UserId: 0,
        VmeetingId: 0,
        AttendeeIp: AttendeeIp
    }
    let response = await axios.post("api/UnlockAttendeeIp", attendeeIpUnlock).then(res => { return res.data });
    return response;
}