import React, { useContext, useState } from 'react';
import { Modal } from 'react-bootstrap';
import UserPermissionContext from './UserPermissionContext';
import AdminContext from '../context/AdminContext';
import { NotificationManager } from 'react-notifications';
import axios from 'axios';

const AddNewUser = ({ showUserModal, handleUserModal, selectedMeeting }) => {

    //Context//
    const Context = useContext(UserPermissionContext);

    //Compoenent State//
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [templateId, setTemplate] = useState("");
    const [isLoading, setIsloading] = useState(false);

    /**
     * Event handler for Permission template change dropdown
     * @param {any} e
     */
    const handleChange = async (e) => {
        //TODO - Need to set initial permission sets//
        setTemplate(e.target.value);
    }

     /**
      * Render function to render template options
      * */
    const renderTemplateOptions = () => {
        const { templates } = Context;
        return templates.map((template, index) => {
            return (
                <option key={template.TemplateId} value={template.TemplateId}>{template.Name}</option>
            )
        })
    }

    /**
     * Event handler to submit form. 
     * Function will invite user.
     * @param {any} props
     */

    const InviteNewUser = async (e) => {
            e.preventDefault();
            if (name === '' && email === '') {
                NotificationManager.error("Name and email address is required");
                return;
            }
            setIsloading(true);
            let request = {
                Name: name,
                EmailAddress: email,
                TemplateId: templateId === '' ? null : templateId,
                VMeetingId: selectedMeeting.value
            }
            try {
                const response = await axios.post("api/InviteNewUser", request);
                setIsloading(false);
                setName("");
                setEmail("");
                if (response.status == 200) {
                    if (response.data.statusID === 1) {
                        NotificationManager.success("User permissions saved", "User", 2000);
                    } 
                }

            } catch (error) {
                setIsloading(false);
                if (error?.response.data.statusID === 2) {
                    NotificationManager.error("Sorry, User already exists", "User", 4000);
                }
                else {
                    NotificationManager.error("Sorry, Something went wrong", "User", 4000);
                }
            }
        }

    return (
        <Modal show={showUserModal} onHide={handleUserModal} size="md" centered>
            <form autoComplete="off">
                <Modal.Header closeButton>
                    <Modal.Title>Add user</Modal.Title>
                </Modal.Header>
                <Modal.Body className="p-2">
                    <div className="container-fluid border shadow-sm p-2">
                        <div className="row p-2">
                            <div className="col">
                                <input type="text"
                                    className="form-control"
                                    id="floatingName"
                                    placeholder="Name"
                                    value={name}
                                    required
                                    onChange={(e) => setName(e.target.value)} />
                            </div>

                        </div>
                        <div className="row p-2">
                            <div className="col">
                                <input
                                    type="email"
                                    className="form-control"
                                    id="floatingInput"
                                    placeholder="name@example.com"
                                    value={email}
                                    required
                                    onChange={(e) => setEmail(e.target.value)} />
                            </div>
                        </div>

                        <div className="row p-2">
                            <div className="col">
                                <select
                                    onChange={e => handleChange(e)}
                                    className="form-select"
                                    defaultValue={0}>
                                    <option value="0">Permission Template</option>
                                    {renderTemplateOptions()}
                                </select>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-secondary" onClick={(e) => {
                        e.preventDefault();
                        handleUserModal();
                    }} >
                        Cancel
                    </button>
                    <button className="btn btn-primary" type="submit" onClick={(e) => InviteNewUser(e)} disabled={isLoading}>
                        {isLoading ? <span className="spinner-border spinner-border-sm" role="sss" aria-hidden="true"></span> : "Save"}
                    </button>
                </Modal.Footer>
            </form>
        </Modal>
    )

}

export default AddNewUser;