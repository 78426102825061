import React, { useState, useContext } from "react";
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';
import { Button } from "react-bootstrap";
import { ShortAnswer } from './ShortAnswer';
import { LongAnswer } from './LongAnswer';
import { SingleChoice } from "./SingleChoice";
import { Checkbox } from "./Checkbox";
import { LinearScale } from "./LinearScale";
import { LinearRadio } from "./LinearRadio";
import { Ranking } from "./Ranking";
import { DraggableRanking } from "./DraggableRanking";
import { SurveyAdminContext } from "./SurveyAdminContext";

export default function SurveyContainer({ active = false }) {

    const { sdata, setSdata } = useContext(SurveyAdminContext)

    const [disabled, setDisabled] = useState(false)

    const [validated, setValidated] = useState(false)

    const [displayMessage, setDisplayMessage] = useState(false)

    function handleChange(qst) {
        let questions = sdata.Survey.Question.map((s) => (s.id === qst.id ? qst : s))
        let updated = { ...sdata.Survey, Question: [...questions] }
        setSdata({ Survey: updated })
    }

    const handleSubmit = (evt) => {
        const form = evt.currentTarget
        if (form.checkValidity()) {
            evt.preventDefault()
            setDisabled(true)
            setDisplayMessage(true)
        }
        else {
            evt.preventDefault()
            setValidated(true)
        }
    }

    function questionList(question, i) {
        switch (question.QuestionType) {
            case "Short answer":
                return (<ShortAnswer
                    key={i}
                    question={question}
                    disabled={disabled}
                    handleChange={handleChange}/>)
            case "Long answer":
                return (<LongAnswer
                    key={i}
                    question={question}
                    disabled={disabled}
                    handleChange={handleChange}/>)
            case "Single choice":
                return (<SingleChoice
                    key={i}
                    question={question}
                    disabled={disabled}
                    handleChange={handleChange}/>)
            case "Checkbox":
                return (<Checkbox
                    key={i}
                    question={question}
                    disabled={disabled}
                    handleChange={handleChange}/>)
            case "Linear scale":
                return (<LinearScale
                    key={i}
                    question={question}
                    disabled={disabled}
                    handleChange={handleChange}/>)
            case "Linear radio":
                return (<LinearRadio
                    key={i}
                    question={question}
                    disabled={disabled}
                    handleChange={handleChange}/>)
            case "Ranking":
                return (<Ranking
                    key={i}
                    question={question}
                    disabled={disabled}
                    handleChange={handleChange}/>)
            case "Drag and drop ranking":
                return (<DraggableRanking
                    key={i}
                    question={question}
                    disabled={disabled}
                    handleChange={handleChange}/>)
            default: return
        }
    }

    if (sdata === null) {
        return <div>Loading</div>
    }
    else
        return (
            <React.Fragment>
                {sdata.Survey.AcceptingResponses || !active ?
                    <React.Fragment>
                        <React.Fragment>
                            <h2>{sdata.Survey.SurveyTitle}</h2>
                            <p>{sdata.Survey.SurveyDescription}</p>
                        </React.Fragment>
                        <Form noValidate validated={validated} onSubmit={handleSubmit}>
                            {sdata.Survey.Question ?
                                sdata.Survey.Question
                                    .sort((a, b) => a.SortOrder - b.SortOrder)
                                    .map((question) => {
                                return (questionList(question, question.id))
                            }) : ""}
                        </Form>
                        {displayMessage ? <Alert variant="success" className='alert-margin'>Your response has been received.</Alert> : ""}
                    </React.Fragment>
                    : <Alert variant="danger" className='alert-margin'>
                        {sdata.Survey.AcceptingResponsesMessage}
                    </Alert>}
            </React.Fragment>
        )
}