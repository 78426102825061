import React from 'react';
import GraphAttendance from './GraphAttendance';

export const GraphAttendanceContainer = (props) => {
    return (
        <div>
            <div className="Reports">

                <div className="chat-heading-am">
                    Active attendee users
                </div>
                <br />
                <GraphAttendance />
            </div>
        </div>
    )
}


