import React, { Component, Fragment, forwardRef, useContext } from 'react';
import { Button, ButtonToolbar, Row, Dropdown } from 'react-bootstrap';
import axios from 'axios';
import { ContestModal } from './ContestModal';
import { NotificationManager } from 'react-notifications';
import Switch from "react-switch";
import swal from 'sweetalert';
import ContestContext from './Context/ContestContext';

import {
    DndContext,
    rectIntersection,
    KeyboardSensor,
    PointerSensor,
    useSensor,
    useSensors
} from '@dnd-kit/core';
import {
    arrayMove,
    SortableContext,
    sortableKeyboardCoordinates,
    verticalListSortingStrategy,
    useSortable
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

export default class Contests extends Component {
    constructor(props) {
        super(props);

        this.state = {
            selectValue: "",
            contesttypes: [],
            show: false,
            modalShow: false,
            contests: [],
            actionType: '',
            statusId: 0,
            contestid: 0,
            contestname: "",
            additionalResText: '',
            displayOrder: 0,
            isactive: false,
            options: [],
            updateaction: '',
            enableWeightedCount: false,
            publishTypeId: 1,
            selectedContinuous: null,
            SelectedEnabled: null,
            dbcontestList: [],
            notificationExists: false,
            hybrid: false
        }

        this.publishTypes = ['Standard', 'Weighted', 'Both'];
    }

    componentDidMount() {
        this.populateContestTypes();
        this.populateContest();

        this.setState({
            enableWeightedCount: this.context.switches.enableWeightedCount,
            publishTypeId: this.context.switches.publishTypeId,
            hybrid: this.context.switches.hybrid
        });
    }

    componentWillUnmount() {
        // fix Warning: Can't perform a React state update on an unmounted component
        this.setState = (state, callback) => {
            return;
        };
    }


    populateContestTypes() {
        axios.get('VMGetContestTypes')
            .then(response => {
                this.setState({ contesttypes: response.data });
            }).catch(function (error) { })
    }

    populateContest() {
        let VMId = this.context.meetingId;

        axios.get('VMMeetingContestNoRes?VMId=' + VMId)
            .then(response => {
                if (response.data.length > 0) {
                    this.setState({
                        contests: response.data,
                        dbcontestList: response.data
                    });
                }
            }).catch(function (error) { })
    }



    editContest = (contest, action) => {
        this.setState({
            modalShowEdit: true
            , actionType: action
            , additionalResText: contest.additionalResolutionText
            , contestid: contest.contestId
            , contestname: contest.contestName
            , type: contest.contestTypeId
            , statusId: contest.contestStatusId
            , dorder: contest.displayOrder
            , isactive: contest.isActive
            , options: contest.options
            , contestData: contest
        })
    }

    deleteContest = (row) => {
        let { contests } = this.state;
        if (row) {
            const removeContest = {
                VmeetingId: Number(this.context.meetingId),
                UserId: this.context.userId,
                ContestId: row.contestId
            };
            const options = {
                url: 'VMDeleteMeetingContest',
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                data: removeContest
            };
            axios(options)
                .then(response => {
                    if (!this.state.notificationExists) {
                        if (response.data.statusDescription === 'Success') {
                            NotificationManager.success("Item deleted", "Contest Item", 2000);
                        } else {
                            NotificationManager.error("Sorry, there was a problem deleting the item", "Contest Item", 3000);
                        }
                        this.setState({ notificationExists: true });
                    }
                })

            if (contests !== undefined && contests.length > 0) {
                this.setState({ contests: contests.filter((c) => c.contestId !== row.contestId) });
                this.setState({ dbcontestList: contests.filter((c) => c.contestId !== row.contestId) });
            }
        }
    }

    enableContest = (row, e) => {
        if (row) {
            const enableContest = {
                contestId: Number(row.contestId),
                isEnabled: (row.isEnabled),
                VmeetingId: Number(this.context.meetingId),
                UserId: this.context.userId
            };
            var message = row.isEnabled ? 'Enabled item' : 'Disabled item';
            var title = row.isEnabled ? 'Item enable' : 'Item disable';
            const options = {
                url: 'VMEnableContest',
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                data: enableContest
            };
            axios(options)
                .then(response => {
                    this.populateContest();
                    if (!this.state.notificationExists) {
                        if (response.data.statusDescription === 'Success') {
                            NotificationManager.success(message, title, 2000);
                        } else {
                            NotificationManager.error("Sorry, there was a problem in " + message, title, 2000);
                        }
                        this.setState({ notificationExists: true });
                        setTimeout(() => {
                            if (this.state.notificationExists) {
                                this.setState({ notificationExists: false })
                            }
                        }, 2100);
                    }
                })
        }
    }

    setContinuous(contest, e) {
        if (contest) {
            const enableContest = {
                ContestId: Number(contest.contestId),
                IsContinuous: (contest.isContinuous),
                VmeetingId: Number(this.context.meetingId),
                UserId: this.context.userId
            };
            var message = "Item updated";
            var title = "Item update";
            const options = {
                url: 'VMsetContinuous',
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                data: enableContest
            };
            axios(options)
                .then(response => {
                    if (!this.state.notificationExists) {
                        if (response.data.statusDescription === 'Success') {
                            NotificationManager.success(message, title, 2000);
                        } else {
                            NotificationManager.error("Sorry, there was a problem in " + message, title, 2000);
                        }
                        this.setState({ notificationExists: true });
                        setTimeout(() => {
                            if (this.state.notificationExists) {
                                this.setState({ notificationExists: false })
                            }
                        }, 2500);
                    }
                })
            this.populateContest();
        }
    }

    enableShowOfHands(row, e) {
        if (row) {
            const enableShowOfHands = { contestId: Number(row.contestId), isEnabledShowOfHands: (row.enableShowOfHands) };
            var message = row.enableShowOfHands ? 'Enabled show of hands' : 'Disabled show of hands';
            var title = row.enableShowOfHands ? 'Show of hands enable' : 'Show of hands disable';
            const options = {
                url: 'VMEnableShowOfHands',
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                data: enableShowOfHands
            };
            axios(options)
                .then(response => {
                    this.populateContest();
                    if (!this.state.notificationExists) {
                        if (response.data.statusDescription === 'Success') {
                            NotificationManager.success(message, title, 2000);
                        } else {
                            NotificationManager.error("Sorry, there was a problem in " + message, title, 2000);
                        }
                        this.setState({ notificationExists: true });
                        setTimeout(() => {
                            if (this.state.notificationExists) {
                                this.setState({ notificationExists: false })
                            }
                        }, 2100);
                    }
                })
        }
    }

    enableRealTimeResults(row, e) {
        if (row) {
            const submitData = { ContestId: Number(row.contestId), SwitchValue: (row.enableRealTimeResults) };
            var message = row.enableRealTimeResults ? 'Enabled real time result' : 'Disabled real time result';
            var title = 'Real time result';
            const options = {
                url: 'VMEnableRealTimeResults',
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                data: submitData
            };
            axios(options)
                .then(response => {
                    this.populateContest();
                    if (!this.state.notificationExists) {
                        if (response.data.statusDescription === 'Success') {
                            NotificationManager.success(message, title, 2000);
                        } else {
                            NotificationManager.error("Sorry, there was a problem in " + message, title, 2000);
                        }
                        this.setState({ notificationExists: true });
                        setTimeout(() => {
                            if (this.state.notificationExists) {
                                this.setState({ notificationExists: false })
                            }
                        }, 2100);
                    }
                })
        }
    }


    updateDisplayOrder() {
        let { contests, dbcontestList } = this.state;

        if (contests && contests.length > 0) {
            var contestDisplayOrder = [];

            contests.forEach((item) => {
                if (item.displayOrder !== dbcontestList.filter((x) => x.contestId === item.contestId)[0].displayOrder) {
                    contestDisplayOrder.push({
                        contestId: parseInt(item.contestId),
                        displayOrder: parseInt(item.displayOrder),
                        VMeetingId: this.context.meetingId,
                        UserId: this.context.userId
                    });
                }
            });
            const options = {
                url: 'VMSwapDisplayOrder',
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                data: contestDisplayOrder
            };
            axios(options).then(response => {
                if (!this.state.notificationExists) {
                    if (response.data !== undefined) {
                        NotificationManager.success("Display Order Saved", "Save Display Order", 2000);
                        this.populateContest();
                    } else {
                        NotificationManager.error("Sorry, there was a problem saving display order", "Save Display Order", 2000);
                        this.setState({ notificationExists: true });
                        setTimeout(() => {
                            if (this.state.notificationExists) {
                                this.setState({ notificationExists: false })
                            }
                        }, 2100);
                    }
                }
            })
        }
    }

    ModalClose = () => {
        this.populateContest();
        this.setState({
            modalShowNew: false,
            modalShowEdit: false
        });
        this.context.ClearVoteOptions();
    };

    handleContinuousSwitchChange = (checked, currentContest, id) => {
        currentContest.isContinuous = checked;
        if (id === currentContest.contestId.toString()) {
            this.setContinuous(currentContest);
        }
    };

    handleEnabledSwitchChange = (checked, currentContest, id) => {
        currentContest.isEnabled = checked;
        if (id === currentContest.contestId.toString()) {
            this.enableContest(currentContest);
        }
    };

    handleEnabledShowOfHandsChange = (checked, currentContest, id) => {
        currentContest.enableShowOfHands = checked;
        if (id === currentContest.contestId.toString()) {
            this.enableShowOfHands(currentContest);
        }
    };

    handleEnabledRealTimeResultsChange = (checked, currentContest, id) => {
        currentContest.enableRealTimeResults = checked;
        if (id === currentContest.contestId.toString()) {
            this.enableRealTimeResults(currentContest);
        }
    };


    onSortEnd = (event) => {
        const { active, over } = event;
        const oldIndex = this.state.contests.findIndex(x => x.contestId === active.id);
        const newIndex = this.state.contests.findIndex(x => x.contestId === over.id);
        var newContests = [];
        let tmpContests = arrayMove(this.state.contests, oldIndex, newIndex);
        // Because of the sort in render we need to update the displayOrder.
        tmpContests.map((c, i) => newContests.push(Object.assign({}, c, { displayOrder: i })));
        this.setState(({
            contests: newContests
        }));
    };

    handleDropdownChange = (e) => {
        e.preventDefault();
        this.setState({
            selectValue: e.target.value
        });

    }

    openNewModal = (value) => {
        this.setState({
            selectValue: value,
            modalShowNew: true,
            actionType: 'new',
            options: [{ voteOption: '', voteDisplayOrder: 1, voteValue: 1, voteOptionId: -1 }]

        });
    }


    render() {
        let { contests, contesttypes, publishTypeId, enableWeightedCount } = this.state;
        const { switches } = this.context;
        let enableRealTimeResults = switches.enableRealTimeResults;

        return (
            <Fragment>
                <div>
                    <Row className="pt-3">
                        <div name="ListItems" className='col-12 DocumentList'>
                            <div className="d-flex align-items-center mb-3">
                                <div className="me-3">
                                    <h5 className="mb-0">Vote Items</h5>
                                </div>
                                <div>
                                    <Dropdown>
                                        <Dropdown.Toggle variant="primary" id="dropdown-basic">
                                            Add new...
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu>
                                            {contesttypes.length ?
                                                contesttypes.map(mL =>
                                                    <Dropdown.Item role="button" key={mL.contestTypeId} onClick={() => this.openNewModal(mL.contestTypeId)}>{mL.contestType}</Dropdown.Item>)
                                                : null
                                            }
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div>
                            </div>

                            {contests && contests.length > 0 ?
                                <div key={0}>
                                    <div key={1} className="grid-container-contest-header" >
                                        <div></div>
                                        <div className="row">
                                            <div className="col d-flex align-items-center" >Item Name</div>
                                            <div className="col-1 CenterHeading d-flex align-items-center justify-content-center" >Item Id</div>
                                            <div className="col-1 CenterHeading d-flex align-items-center justify-content-center" >Item Type</div>
                                            <div className="col-1 CenterHeading d-flex align-items-center justify-content-center" >Publish Type</div>
                                            <div className="col-1 CenterHeading d-flex align-items-center justify-content-center" >Vote Admin Status</div>
                                            <div className="col-1 CenterHeading d-flex align-items-center justify-content-center" >Display as Continuous</div>
                                            <div className="col-1 CenterHeading d-flex align-items-center justify-content-center" >Enable Item</div>
                                            {switches.hybrid ? <div className="col-1 CenterHeading d-flex align-items-center justify-content-center" >Show of Hands</div> : null}
                                            {enableRealTimeResults && <div className="col-1 CenterHeading d-flex align-items-center justify-content-center" >Real Time Results</div>}
                                            <div className="col-1 CenterHeading d-flex align-items-center justify-content-center" >Edit</div>
                                            <div className="col-1 CenterHeading d-flex align-items-center justify-content-center" >Delete</div>
                                        </div>
                                    </div>
                                </div>
                                : <div>Please add a vote item, no items available</div>}
                            {contests?.length > 0 &&
                                <SortableContestList
                                    onSortEnd={this.onSortEnd}
                                    items={contests}
                                    publishTypes={this.publishTypes}
                                    isAuthorized={this.props.isAuthorized}
                                    handleContinuousSwitchChange={this.handleContinuousSwitchChange}
                                    handleEnabledSwitchChange={this.handleEnabledSwitchChange}
                                    handleEnabledShowOfHandsChange={this.handleEnabledShowOfHandsChange}
                                    handleEnabledRealTimeResultsChange={this.handleEnabledRealTimeResultsChange}
                                    editContest={this.editContest}
                                    deleteContest={this.deleteContest}
                                />
                            }

                            {contests && contests.length > 0 ? <p>* Please note you can only disable items that are in a "Pending" state</p> : null}
                            <Fragment>
                                <ButtonToolbar>
                                    <Button size="sm me-3" disabled={(!this.props.isAuthorized) || contests && contests.length === 0} className="mr-2" onClick={() => swal({ title: "Confirmation", text: "Are you sure want to change display order, this change will be reflected in the live attendee app", icon: "warning", buttons: true, dangerMode: false }).then((value) => { if (value) this.updateDisplayOrder() })} >Save Display Order</Button>
                                    <Button size="sm" disabled={contests && contests.length === 0} onClick={() => this.populateContest()}>Reset Display Order</Button>
                                </ButtonToolbar>
                            </Fragment>
                        </div>


                    </Row>
                </div>
                <ContestModal
                    show={this.state.modalShowNew}
                    onHide={this.ModalClose}
                    type={this.state.selectValue}
                    meeting={this.context.meetingId}
                    atype={this.state.actionType}
                    id={this.state.contestId}
                    cname={this.state.contestName}
                    contestoptions={this.state.options}
                    enableweightedcount={enableWeightedCount.toString()}
                    publishtypeid={publishTypeId}
                    isAuthorized={this.props.isAuthorized}
                />

                <ContestModal
                    show={this.state.modalShowEdit}
                    onHide={this.ModalClose}
                    type={this.state.type}
                    status={this.state.statusId}
                    atype={this.state.actionType}
                    active={this.state.isactive ? this.state.isactive.toString() : false}
                    display={this.state.dorder}
                    meeting={this.context.meetingId}
                    id={this.state.contestid}
                    cname={this.state.contestname}
                    atext={this.state.additionalResText}
                    contestoptions={this.state.options}
                    contestdata={this.state.contestData}
                    publishtypeid={this.state.publishTypeId}
                    enableweightedcount={this.state.enableWeightedCount.toString()}
                    isAuthorized={this.props.isAuthorized}
                />

            </Fragment>
        )


    }
}


Contests.contextType = ContestContext;


const SortableContestList = ({ items, ...props }) => {
    const sensors = useSensors(
        useSensor(PointerSensor),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates,
        })
    );

    return (
        <DndContext
            sensors={sensors}
            //collisionDetection={closestCenter}
            collisionDetection={rectIntersection}
            onDragEnd={handleDragEnd}
        >
            <SortableContext
                items={items}
                strategy={verticalListSortingStrategy}
            >
                {items.map(item => {
                    item.id = item.contestId
                    return (
                        <SortableContest key={item.contestId} id={item.contestId}>
                            <ContestRow contest={item} {...props} />
                        </SortableContest>
                    )
                })}
            </SortableContext>
        </DndContext>
    );

    function handleDragEnd(event) {
        props.onSortEnd(event);
    }

}

const SortableContest = (props) => {
    const {
        attributes,
        listeners,
        setNodeRef,
        transform,
        transition,
    } = useSortable({ id: props.id });

    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
    };

    return (
        <div ref={setNodeRef} style={style}>
            <div className="d-flex align-items-center mb-2">
                <div className="dragHandle mx-2"  {...attributes} {...listeners}>||</div>
                <div className="flex-fill">
                    {props.children}
                </div>
            </div>
        </div>
    );
}

const ContestRow = forwardRef(({ id, item, ...props }, ref) => {
    const { contest } = props;
    const { switches } = useContext(ContestContext);
    let enableRealTimeResults = switches.enableRealTimeResults;
    return (
        <Fragment>
            <div className="row">
                <div className="col d-flex align-items-center" >{contest.contestName}</div>
                <div className="col-1 d-flex align-items-center CenterHeading justify-content-center" >{contest.contestId}</div>
                <div className="col-1 d-flex align-items-center CenterHeading justify-content-center" >{contest.contestType}</div>
                <div className="col-1 d-flex align-items-center CenterHeading justify-content-center" >{props.publishTypes[contest.publishTypeId - 1]}</div>
                <div className="col-1 d-flex align-items-center CenterHeading justify-content-center" >{contest.contestStatus}</div>
                <div className="col-1 d-flex align-items-center CenterHeading justify-content-center">
                    <ButtonToolbar>
                        <Switch
                            onChange={(checked, e, id) => props.handleContinuousSwitchChange(checked, contest, id)}
                            checked={contest.isContinuous}
                            name="ContinuousSwitch"
                            className="react-switch"
                            aria-label="Set continuous contest"
                            id={contest.contestId.toString()}
                            disabled={(!props.isAuthorized) || (contest.contestStatusId !== 1) || contest.enableShowOfHands}
                        />
                    </ButtonToolbar>
                </div>
                <div className="col-1 CenterHeading d-flex align-items-center justify-content-center">
                    <ButtonToolbar>
                        <Switch
                            onChange={(checked, e, id) => props.handleEnabledSwitchChange(checked, contest, id)}
                            name="EnabledSwitch"
                            checked={contest.isEnabled}
                            className="react-switch"
                            aria-label="Set contest enable"
                            id={contest.contestId.toString()}
                            disabled={(!props.isAuthorized) || (contest.contestStatusId !== 1)}
                        />

                    </ButtonToolbar>
                </div>
                {switches.hybrid &&
                    <div className="col-1 CenterHeading d-flex align-items-center justify-content-center">
                        {contest.contestTypeId === 1 &&
                            <ButtonToolbar>
                                <Switch
                                    onChange={(checked, e, id) => props.handleEnabledShowOfHandsChange(checked, contest, id)}
                                    name="EnabledShowOfHandsSwitch"
                                    checked={contest.enableShowOfHands}
                                    className="react-switch"
                                    aria-label="Set show of hands"
                                    id={contest.contestId.toString()}
                                    disabled={(!props.isAuthorized) || (contest.contestStatusId !== 1) || contest.isContinuous}
                                />

                            </ButtonToolbar>
                        }
                    </div>
                }
                {enableRealTimeResults &&
                    <div className="col-1 CenterHeading d-flex align-items-center justify-content-center">
                        {contest.contestTypeId === 1 &&
                            <ButtonToolbar>
                                <Switch
                                    onChange={(checked, e, id) => props.handleEnabledRealTimeResultsChange(checked, contest, id)}
                                    name="EnabledRealTimeResults"
                                    checked={contest.enableShowOfHands || contest.enableRealTimeResults} //Show of hands always gives real time results
                                    className="react-switch"
                                    aria-label="Set real time results"
                                    id={contest.contestId.toString()}
                                    disabled={contest.enableShowOfHands || !props.isAuthorized || (contest.contestStatusId !== 1 && !contest.enableRealTimeResults)}
                                />

                            </ButtonToolbar>
                        }
                    </div>
                }

                <div className="col-1 CenterHeading d-flex align-items-center justify-content-center">
                    <ButtonToolbar>
                        {contest.contestStatusId === 1 ?
                            <Button size="sm" variant="dark" onClick={() => props.editContest(contest, 'edit')}>Edit</Button> :
                            <Button size="sm" variant="dark" onClick={() => props.editContest(contest, 'view')}>View</Button>
                        }
                    </ButtonToolbar>
                </div>
                <div className="col-1 CenterHeading d-flex align-items-center justify-content-center">
                    <ButtonToolbar>
                        <Button size="sm" variant="danger" disabled={!props.isAuthorized || !contest.isDeleteAllowed} onClick={() => swal({ title: "Are you sure you want to delete this item?", text: "This change will be reflected in the live attendee app", icon: "warning", buttons: true, dangerMode: true }).then((value) => { if (value) props.deleteContest(contest) })} >Delete</Button>
                    </ButtonToolbar>
                </div>
            </div>
        </Fragment>
    )
})
