import Form from 'react-bootstrap/Form';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import { Col, Row } from 'react-bootstrap';
import React, { useState, useContext, useEffect } from 'react';
import { OptionList } from './OptionList';
import { SurveyAdminContext } from './SurveyAdminContext';

export function ShortAnswerFormat({ id, disabled }) {

    const { sdata, setSdata } = useContext(SurveyAdminContext)

    // q.id to s.SortOrder
    const qst = sdata.Survey.Question.filter((q) => q.SortOrder === id)[0]

    const [maximumLength, setMaximumLength] = useState((qst.MaximumLength >= 10 && qst.MaximumLength <= 256) ? qst.MaximumLength : 256)

    useEffect(() => {
        // q.id to s.SortOrder
        let qst = sdata.Survey.Question.filter((q) => q.SortOrder === id)[0]

        if (maximumLength && maximumLength >= 10 && maximumLength <= 256) {
            qst.MaximumLength = maximumLength
        }
        else {
            qst.MaximumLength = 256
        }

        // s.id to s.SortOrder
        let questions = sdata.Survey.Question.map((s) => (s.SortOrder === id ? qst : s))
        let updated = { ...sdata.Survey, Question: [...questions] }
        setSdata({ Survey: updated })
    }, [maximumLength])

    return (
        <Col md={4}>
            <Form.Group>
                <FloatingLabel label="Maximum characters">
                    <Form.Control
                        type="number"
                        value={maximumLength}
                        name={id}
                        onChange={(evt) => setMaximumLength(evt.target.value)}
                        required
                        min={10}
                        max={256}
                        disabled={disabled || !qst.Editable} />
                </FloatingLabel>
            </Form.Group>
        </Col>
    )
}

export function LongAnswerFormat({ id, disabled }) {

    const { sdata, setSdata } = useContext(SurveyAdminContext)

    // q.id to s.SortOrder
    const qst = sdata.Survey.Question.filter((q) => q.SortOrder === id)[0]

    const [maximumLength, setMaximumLength] = useState((qst.MaximumLength >= 256 && qst.MaximumLength <= 20000) ? qst.MaximumLength : 20000)

    useEffect(() => {
        // q.id to s.SortOrder
        let qst = sdata.Survey.Question.filter((q) => q.SortOrder === id)[0]

        if (maximumLength && maximumLength >= 256 && maximumLength <= 20000) {
            qst.MaximumLength = maximumLength
        }
        else {
            qst.MaximumLength = 20000
        }

        // s.id to s.SortOrder
        let questions = sdata.Survey.Question.map((s) => (s.SortOrder === id ? qst : s))
        let updated = { ...sdata.Survey, Question: [...questions] }
        setSdata({ Survey: updated })
    }, [maximumLength])

    return (
        <Col md={4}>
            <Form.Group>
                <FloatingLabel label="Maximum characters">
                    <Form.Control
                        type="number"
                        name={id}
                        value={maximumLength}
                        onChange={(evt) => setMaximumLength(evt.target.value)}
                        required
                        min={256}
                        max={20000}
                        disabled={disabled || !qst.Editable} />
                </FloatingLabel>
            </Form.Group>
        </Col>
    )
}

export function SingleChoiceFormat({ id, disabled }) {
    return (
        <OptionList id={id} disabled={disabled} />
    )
}

export function CheckboxFormat({ id, disabled }) {

    const { sdata, setSdata } = useContext(SurveyAdminContext)

    // q.id to s.SortOrder
    const qst = sdata.Survey.Question.filter((q) => q.SortOrder === id)[0]

    let optionNum = qst.Options.length ? qst.Options.length : 0

    const [minimum, setMinimum] = useState(qst.Minimum ? qst.Minimum : 0)

    const [maximum, setMaximum] = useState(qst.Maximum ? qst.Maximum : optionNum)

    useEffect(() => {
        if (maximum > optionNum) {
            setMaximum(optionNum)
        }
        if (optionNum <= minimum) {
            setMinimum(maximum - 2)
        }
        if (minimum < 1) {
            setMinimum(0)
        }
        setBlur()
        
    }, [optionNum])

    function setBlur() {
        let qst = sdata.Survey.Question.filter((q) => q.SortOrder === id)[0]

        minimum ? qst.Minimum = minimum : qst.Minimum = 0
        maximum ? qst.Maximum = maximum : qst.Maximum = optionNum

        let questions = sdata.Survey.Question.map((s) => (s.SortOrder === id ? qst : s))
        let updated = { ...sdata.Survey, Question: [...questions] }
        setSdata({ Survey: updated })
    }

    return (
        <React.Fragment>
            <Row>
                <OptionList id={id} disabled={disabled} />
            </Row>
            <Row className='form-div'>
                <Col md={4}>
                    <Form.Group>
                        <FloatingLabel label="Minimum">
                            <Form.Control
                                type="number"
                                name={id}
                                data-testid={"minimum " + id}
                                min={0}
                                max={minimum === maximum ? optionNum - 1 : maximum - 1}
                                value={minimum}
                                onChange={(evt) => setMinimum(evt.target.value)}
                                onBlur={() => setBlur()}
                                disabled={disabled || !qst.Editable} />
                        </FloatingLabel>
                    </Form.Group>
                </Col>
                <Col md={4}>
                    <Form.Group>
                        <FloatingLabel label="Maximum">
                            <Form.Control
                                type="number"
                                name={id * 1.5}
                                data-testid={"maximum " + id * 1.5}
                                min={minimum === maximum ? 1 : minimum}
                                max={optionNum}
                                value={maximum}
                                onChange={(evt) => setMaximum(evt.target.value)}
                                onBlur={() => setBlur()}
                                disabled={disabled || !qst.Editable}
                                required={!maximum} />
                        </FloatingLabel>
                    </Form.Group>
                </Col>
            </Row>
        </React.Fragment>
    )
}

export function LinearScaleFormat({ id, disabled }) {

    const { sdata, setSdata } = useContext(SurveyAdminContext)

    // q.id to s.SortOrder
    const qst = sdata.Survey.Question.filter((q) => q.SortOrder === id)[0]

    const [minimum, setMinimum] = useState(qst.Minimum ? qst.Minimum : 1)

    const [maximum, setMaximum] = useState(qst.Maximum ? qst.Maximum : 10)

    useEffect(() => {
        // q.id to s.SortOrder
        let qst = sdata.Survey.Question.filter((q) => q.SortOrder === id)[0]

        minimum ? qst.Minimum = minimum : qst.Minimum = 1

        // s.id to s.SortOrder
        let questions = sdata.Survey.Question.map((s) => (s.SortOrder === id ? qst : s))
        let updated = { ...sdata.Survey, Question: [...questions] }
        setSdata({ Survey: updated })
    }, [minimum])

    useEffect(() => {
        // q.id to s.SortOrder
        let qst = sdata.Survey.Question.filter((q) => q.SortOrder === id)[0]

        { maximum ? qst.Maximum = maximum : qst.Maximum = 10 }

        // s.id to s.SortOrder
        let questions = sdata.Survey.Question.map((s) => (s.SortOrder === id ? qst : s))
        let updated = { ...sdata.Survey, Question: [...questions] }
        setSdata({ Survey: updated })
    }, [maximum])

    return (
        <Row>
            <Col md={4}>
                <Form.Group>
                    <FloatingLabel label="Minimum">
                        <Form.Control
                            type="number"
                            name={id}
                            min={-10}
                            max={1}
                            value={minimum}
                            onChange={(evt) => setMinimum(evt.target.value)}
                            required
                            disabled={disabled || !qst.Editable} />
                    </FloatingLabel>
                </Form.Group>
            </Col>
            <Col md={4}>
                <Form.Group>
                    <FloatingLabel label="Maximum">
                        <Form.Control
                            type="number"
                            name={id}
                            min={5}
                            max={100}
                            value={maximum}
                            onChange={(evt) => setMaximum(evt.target.value)}
                            required
                            disabled={disabled || !qst.Editable} />
                    </FloatingLabel>
                </Form.Group>
            </Col>
        </Row>
    )
}

export function RankingFormat({ id, disabled }) {
    return (
        <OptionList id={id} disabled={disabled} />
    )
}
