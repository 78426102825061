import React, { Component, Fragment, Suspense, lazy } from 'react';
import queryString, { parse } from 'query-string';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faCheck, faRetweet } from '@fortawesome/free-solid-svg-icons'
//import QrReader from 'react-qr-reader'
import AdminContext from '../context/AdminContext';
const QrReader = lazy(() => import('../QrCodeReader/QrCodeReader'));
const LazyFallBack = <div className="loaderholder"> < div className="loader" /></div>

export class SelfRegistration extends Component {
    static displayName = SelfRegistration.name;

    constructor(props) {
        super(props);
        this.UserId = parseInt(sessionStorage.getItem('UserId'));
        this.VmeetingId = parseInt(sessionStorage.getItem('VMId'));
        this.state = {
            loading: false,
            phase: 1,
            name: null,
            role: null,
            VmId: null,
            userGuid: null,
            message: null,
            mounted: null,
            cameraFacing: "environment"
        }
        this.timeout = null;
    }


    componentDidMount() {
        this.resetPage();
        this.getVmTitle();
        this.setState({
            mounted: true
        })
    }

    async componentWillUnmount() {
        this.setState({
            mounted: false
        })
        // fix Warning: Can't perform a React state update on an unmounted component
        clearTimeout(this.timeout);
        this.setState = (state, callback) => {
            return;
        };
    }

    getVmTitle = async () => {
        var VMId = parseInt(sessionStorage.getItem('VMId'));
        if (VMId > 0) {
            try {
                await axios.get("api/GetTitleAndClient").then(response => {
                    if (response.status === 200) {
                        this.setState({ meetingTitle: response.data.meetingTitle });
                    }
                });
            } catch {
                console.log('Error getting meeting title');
            }
        }
    }

    startRegistration = () => {
        this.setState({
            phase: 2
        })
        this.startTimer();
    }

    startTimer = () => {
        clearTimeout(this.timeout);
        this.timeout = setTimeout(() => { this.resetPage(); }, 30000);
    }

    updateCameraFacing = () => {
        this.setState({
            cameraFacing: this.state.cameraFacing === "user" ? "environment" : "user"
        })
    }


    handleScan = data => {
        if (data) {
            var query = data.split("?")
            if (query[1]) {
                this.setState({
                    loading: true,
                    message: null
                })
                const values = queryString.parse(query[1]);
                if (values.vm && values.dl) {
                    if (parseInt(values.vm) === this.VmeetingId) {
                        const user = { VmeetingId: parseInt(values.vm), Guid: values.dl };
                        this.setState({
                            VmId: values.vm,
                            userGuid: values.dl
                        })
                        axios.post("VMGetUserFromGuid", user).then(response => {
                            if (response.data.name && response.data.attendeeRole) {
                                var role = response.data.attendeeRole.replace("Member", " Member")
                                this.setState({
                                    pinId: response.data.pinId,
                                    name: response.data.name,
                                    role: role,
                                    phase: 3
                                })
                            } else {
                                this.setState({
                                    message: "We were unable to find your record, please speak to a member of staff for assistance",
                                    phase: 5
                                })
                            }
                        })
                    } else {
                        this.setState({
                            message: "We were unable to find your record, please speak to a member of staff for assistance",
                            phase: 5
                        })
                    }
                } else {
                    this.setState({
                        message: "Please scan your invitation letter QR code"
                    })                    
                }
                this.setState({
                    loading: false
                })
            } else {
                this.setState({
                    message: "Please scan your invitation letter QR code"
                })
            }
        } 
        this.startTimer();
    }


    confirmDetails = () => {
        var attendee = {
            UserId: this.UserId,
            VmeetingId: parseInt(this.state.VmId),
            PinId: parseInt(this.state.pinId),
            PollCardNumber: null
        }
        axios.post("VMAddValidatedAttendee", attendee).then(response => {
            if (response.data.statusID === 1) {
                this.setState({
                    phase: 4
                })
            }
            else if (response.data.statusID === 2) {
                this.setState({
                    message: 'You are already registered, you will find your ticket in the attendee application when you login',
                    phase: 5
                })
            }
            else {
                this.setState({
                    message: null,
                    phase: 5
                })
            }
        })
        this.startTimer();
    }

    resetPage = () => {
        this.setState({
            phase: 1,
            name: null,
            role: null,
            message: null,
            VmId: null,
            userGuid: null,
            loading: false
        })
        clearTimeout(this.timeout);
    }

    handleError = err => {
        console.error(err)
    }

    render() {
        const { isAuthorized } = this.context;
        return (
            <div className="container">
                <div className="row">
                    <div className="RegistrationLayout col-md-12">
                        <div className="d-flex justify-content-between">
                            <button disabled={!isAuthorized('SelfRegistration', 'Write')} className="btn btn-primary btn-sm" onClick={() => this.resetPage()}>Restart</button>
                        </div>
                        <div className="registrationLogo"><h1 className="adminTitle">CES<span className="pinkIcon">Registration</span></h1></div>
                        <div className="">
                            {this.state.phase === 1 ?
                                <Fragment>
                                    <div className="registrationText">
                                        <h1>{this.state.meetingTitle}</h1>
                                        <p>Please have your invitation letter ready and press start to begin registration</p>
                                    </div>
                                    <div className="registrationButton d-flex justify-content-center">
                                        <button disabled={!isAuthorized('SelfRegistration', 'Write')} className="btn btn-primary btn-lg" onClick={() => this.startRegistration()}>START</button>
                                    </div>
                                </Fragment>
                                : this.state.phase === 2 ?
                                    <Fragment>
                                    <div className="registrationText">
                                        <p>Please place your QR code infront of the camera to scan</p>
                                    </div>
                                    <div className="d-flex justify-content-center d-lg-none">
                                        <button disabled={!isAuthorized('SelfRegistration', 'Write')} onClick={() => this.updateCameraFacing()} title="Change camera facing" className="btn btn-primary"><FontAwesomeIcon icon={faRetweet} /></button>
                                    </div>
                                    <div className="d-flex justify-content-center">
                                        {this.state.loading ? <div className="loaderholder"> < div className="loader" /></div> :
                                            <Suspense fallback={LazyFallBack}>
                                                {isAuthorized('SelfRegistration', 'Write') && this.state.mounted && <QrReader
                                                    className="QrReader"
                                                    delay={300}
                                                    onError={this.handleError}
                                                    onScan={this.handleScan}
                                                    facingMode={this.state.cameraFacing}
                                                />}
                                            </Suspense>
                                        }
                                    </div>
                                    {this.state.message &&
                                        <div className=" d-flex justify-content-center warning">
                                            <p>{this.state.message}</p>
                                        </div>
                                    }
                                    <div className=" d-flex justify-content-center">
                                        <button disabled={!isAuthorized('SelfRegistration', 'Write')}  className="btn btn-primary btn-lg" onClick={() => this.resetPage()}>Reset</button>
                                    </div>
                                </Fragment>
                                    : this.state.phase === 3 ?
                                        <Fragment>
                                            <div className="registrationText">
                                                <p>Please confirm the details below are correct to proceed with registration</p>
                                            </div>

                                            <div className="confirmationText">
                                                <div className="registrationKey">Name:</div><div className="registrationValue">&nbsp;{this.state.name}</div>
                                                <div className="registrationKey">Attendee Type:</div><div className="registrationValue">&nbsp;{this.state.role}</div>
                                            </div>

                                            <div className="registrationButton">
                                                <div className="d-flex justify-content-around">
                                                    <button disabled={!isAuthorized('SelfRegistration', 'Write')} className="btn btn-primary btn-lg" onClick={() => this.confirmDetails()}>YES THE DETAILS<br /> ARE CORRECT</button>
                                                    <button disabled={!isAuthorized('SelfRegistration', 'Write')} className="btn btn-primary btn-lg" onClick={() => this.setState({ phase: 5, message: 'Please speak to a member of staff if you believe your invitation letter did not identify you correctly' })}>NO THE DETAILS<br /> ARE INCORRECT</button>
                                                </div>
                                            </div>
                                            <div className=" d-flex justify-content-center">
                                                <button className="btn btn-primary btn-lg" onClick={() => this.resetPage()}>Reset</button>
                                            </div>
                                        </Fragment>
                                        : this.state.phase === 4 ?
                                            <Fragment>
                                                <div className="d-flex justify-content-center"> <FontAwesomeIcon icon={faCheck} size="7x" color="green" title="Success!" /></div>
                                                <div className="registrationText">
                                                    <p>Thank you, you have now successfully registered and your ticket will be available when you login to the attendee application</p>
                                                </div>
                                                <div className="registrationButton d-flex justify-content-center">
                                                    <button disabled={!isAuthorized('SelfRegistration', 'Write')} className="btn btn-primary btn-lg" onClick={() => this.resetPage()}>NEXT USER</button>
                                                </div>
                                            </Fragment>
                                            : <Fragment>
                                                <div className="d-flex justify-content-center"> <FontAwesomeIcon icon={faTimes} size="7x" color="red" title="Error!" /></div>
                                                <div className="registrationText">
                                                    {this.state.message ? <p>{this.state.message}</p> : <p>An error has occurred, please speak to a member of staff for assistance.</p>}
                                                </div>
                                                <div className="registrationButton d-flex justify-content-center">
                                                    <button disabled={!isAuthorized('SelfRegistration', 'Write')} className="btn btn-primary btn-lg" onClick={() => this.resetPage()}>NEXT USER</button>
                                                </div>
                                            </Fragment>
                            }

                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

SelfRegistration.contextType = AdminContext;