import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';
import React, { useState, useRef } from "react";
import Validate from "./Validate";

export function LinearScale({ question = [],
    handleChange = () => { },
    disabled = false,
    handleSave = () => { } }) {

    const [choice, setChoice] = useState(0)
    const [validated, setValidated] = useState(question.Validated)

    let errorMessage = Validate(question, handleChange, disabled)

    function onChange(evt) {
        setChoice(evt.target.value)
        setValidated(true)
        let qst = { ...question, Selection: evt.target.value }
        handleChange(qst)
        handleSave(qst)
    }

    return (
        errorMessage === "" ? <Form.Group className='form-div' >
            <Form.Label>{question.QuestionTitle}{question.Mandatory ? "*" : ""}</Form.Label>
            <Form.Range
                type="range"
                min={question.Minimum}
                max={question.Maximum}
                step="1"
                data-testid={question.id}
                onChange={(evt) => onChange(evt)}
                value={choice}
                disabled={disabled} />
            {!validated && question.Mandatory ? <Form.Text>On a scale of {question.Minimum} to {question.Maximum} where {question.Minimum} is negative and {question.Maximum} is positive.</Form.Text> : ""}
        </Form.Group> : <Alert variant="danger" className='alert-margin'>{errorMessage}</Alert>
    )
}