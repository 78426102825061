import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';
import React, { useState } from "react";
import ValidateOptions from "./ValidateOptions";

export function Checkbox({ question = [],
    handleChange = () => { },
    disabled = false,
    handleSave = () => { } }) {

    const [checkBox, setCheckBox] = useState(question)
    const [validated, setValidated] = useState(question.Validated)

    let errorMessage = ValidateOptions(question, handleChange, disabled)

    function onChange(option) {
        let localOptions = checkBox.Options.map((o) => (o.SortOrder === option.SortOrder ? { ...option, Checked: !option.Checked } : o))
        let qst = { ...checkBox, Options: [...localOptions] }
        let checkedFuture = localOptions.filter((c) => c.Checked === true).length

        if (checkBox.Mandatory) {
            if (checkedFuture > checkBox.Maximum) {
                setValidated(false)
            }
            if (checkedFuture < checkBox.Minimum) {
                setValidated(false)
            }
            else {
                setValidated(true)
            }

            if (checkedFuture > checkBox.Maximum) {
                return
            }
        }
        setCheckBox(qst)
        handleChange(qst)
    }

    function onBlur(evt) {
        let qst = { ...question, Checked: evt.target.value }
        handleSave(qst)
    }

    return (
        errorMessage === "" ?
            <Form.Group className='form-div'>
                <Form.Label>{checkBox.QuestionTitle}{checkBox.Mandatory ? "*" : ""}</Form.Label>
                {checkBox.Options.map((option) => (
                    <Form.Check key={option.id} id={option.id}>
                        <Form.Check.Input
                            key={option.id}
                            type="checkbox"
                            name={option.id}
                            id={option.id}
                            onChange={() => onChange(option)}
                            onBlur={(evt) => onBlur(evt)}
                            label={option.OptionTitle}
                            checked={option.Checked ? option.Checked : false}
                            required={!validated && checkBox.Mandatory}
                            disabled={disabled} />
                        <Form.Check.Label>{option.OptionTitle}</Form.Check.Label>
                    </Form.Check>
                ))}
                {!validated && checkBox.Mandatory ? <Form.Text>Please select a minimum of {checkBox.Minimum} and a maximum of {checkBox.Maximum}.</Form.Text> : ""}
            </Form.Group>
            : <Alert variant="danger" className='alert-margin'>{errorMessage}</Alert>
    )
}