import React, { useState, useContext, useEffect } from "react";
import { Accordion, Button, ButtonGroup, Dropdown, DropdownButton, FloatingLabel, Form, ListGroup, Modal, Offcanvas, ToggleButton } from "react-bootstrap";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { InstanceContext } from "./InstanceContext";
import { SurveyAdminContext } from "./SurveyAdminContext";
import SurveyContainer from "./SurveyContainer";
import AdminContainer from "./AdminContainer";
import axios from "axios";
import SurveyAnalytics from "./SurveyAnalytics";
import { NotificationManager } from "react-notifications";

export default function StartPage() {

    const { sdata, setSdata, surveyFromTemplate } = useContext(SurveyAdminContext)
    const { instanceData, setInstanceData } = useContext(InstanceContext)

    const [templateSurveys, setTemplateSurveys] = useState([])
    const [, setTemplateId] = useState()

    const [editMode, setEditMode] = useState(false)

    // For the template offcanvas
    const [show, setShow] = useState(false)
    const handleShow = () => setShow(true)
    const handleClose = () => setShow(false)
    const [showAD, setShowAD] = useState(false)

    // For delete modal
    const [deleteMsg, setDeleteMsg] = useState("")

    const [showModal, setShowModal] = useState(false)
    const handleModal = () => {
        setShowModal(false)
        setDeleteMsg("")
    }
    const handleModalShow = () => setShowModal(true)

    // For the status modal
    const [statusMsg, setStatusMsg] = useState(sdata.Survey.AcceptingResponsesMessage)
    const [status, setStatus] = useState(sdata.Survey.AcceptingResponses)

    useEffect(() => {
        setStatus(sdata.Survey.AcceptingResponses)
        setStatusMsg(sdata.Survey.AcceptingResponsesMessage)
    }, [sdata.Survey.AcceptingResponses])

    const [showStatusModal, setShowStatusModal] = useState(false)
    const handleStatusModal = () => {
        setShowStatusModal(false)
        setStatus(sdata.Survey.AcceptingResponses)
        setStatusMsg(sdata.Survey.AcceptingResponsesMessage)
    }
    const handleStatusShow = async () => {
        setShowStatusModal(true)
    }

    // For the clear responses modal
    const [clearResponsesMsg, setClearResponsesMsg] = useState("")

    const [showClearResponsesModal, setShowClearResponsesModal] = useState(false)
    const handleClearResponsesModal = () => {
        setShowClearResponsesModal(false)
        setClearResponsesMsg("")
    }
    const handleClearResponsesShow = () => setShowClearResponsesModal(true)

    const handleClick = (id) => {
        setTemplateId(id)
        surveyFromTemplate(id)
        handleShow()
    }

    const handleNew = () => {
        setSdata({
            Survey: {
                id: -1,
                Question: [],
                AcceptingResponses: false,
                AcceptingResponsesMessage: "This survey is no longer accepting responses.",
                isTemplate: false
            }
        })
        setShowAD(true)
    }

    const handleDelete = async () => {
        if (deleteMsg === "CONFIRM DELETE") {

            const msurveyId = {
                surveyId: sdata.Survey.id
            }

            axios.post('api/CheckSurveyDeletability', msurveyId)
                .then(response => {
                    let dt = JSON.parse(response.data.jsonresponse)

                    if (dt[0].CanDelete) {
                        const model = {
                            VMeetingId: instanceData.instanceId,
                            UserId: instanceData.userId,
                            surveyId: sdata.Survey.id,
                            instanceId: instanceData.instanceId
                        }

                        axios.post('api/DeleteSurvey', model)
                            .then(() => {
                                setShowModal(false)
                                setShowAD(false)
                            })
                            .then(() => {
                                setInstanceData(prevData => ({
                                    ...prevData,
                                    hasSurvey: false,
                                    surveyId: -1
                                }))

                                setSdata({
                                    Survey: {
                                        id: -1,
                                        Question: [],
                                        AcceptingResponses: false,
                                        AcceptingResponsesMessage: "This survey is no longer accepting responses.",
                                        isTemplate: false
                                    }
                                })
                            })
                            .catch(function (error) { console.log(error); })
                }
                else {
                    let msg = ""

                    if (dt[0].AcceptingResponses) {
                        msg += "This survey is still accepting responses, close the survey before continuing.\n\n"
                    }

                    if (dt[0].NumberOfResponses > 0) {
                        msg += "This survey is has " + dt[0].NumberOfResponses + " response(s). Clear the response(s) before continuing."
                    }

                    alert(msg)
                }
                })
                .catch(function (error) { console.log(error); })
        }
        else {
            alert("Enter the text exactly as displayed.")
        }
    }

    const handleEdit = () => {
        if (status) {
            alert("This survey is open, close it before editing.")
            setEditMode(false)
        }
        else {
            setEditMode((prevEditMode) => {
                const updatedEditMode = !prevEditMode

                let uneditable = false

                if (updatedEditMode) {
                    sdata.Survey.Question.forEach((question) => {
                        if (!question.Editable) {
                            uneditable = true
                        }
                    })

                    if (uneditable) {
                        NotificationManager.success("Questions that have responses cannot be edited. Clear responses if you would like to edit the question.",
                            "Survey actions", 4000);
                    }
                }
                return updatedEditMode
            })
        }
    }

    const handleStatus = () => {
        const Model = {
            surveyId: instanceData.surveyId,
            acceptingResponses: status,
            acceptingResponsesMessage: statusMsg
        }

        axios.post('api/UpdateStatusInfo', Model)
            .catch(function (error) { console.log(error); })

        handleStatusModal()
    }

    const handleSurveyStatus = (isOpen) => {
        const Model = {
            VMeetingId: instanceData.instanceId,
            UserId: instanceData.userId,
            surveyId: instanceData.surveyId,
            acceptingResponses: isOpen,
            acceptingResponsesMessage: statusMsg
        }

        if (!isOpen) {
            setStatus(false)

            axios.post('api/UpdateStatusInfo', Model)
                .catch(function (error) { console.log(error); })

            NotificationManager.success("Survey has closed successfully", "Survey actions", 4000);
        }
        else if (isOpen && sdata.Survey.Question !== undefined && Array.isArray(sdata.Survey.Question) && sdata.Survey.Question.length > 0) {
                if (!editMode) {
                    setStatus(true)

                    axios.post('api/UpdateStatusInfo', Model)
                        .catch(function (error) { console.log(error); })

                    NotificationManager.success("Survey has opened successfully", "Survey actions", 4000);
                }
                else {
                    alert("The survey cannot be opened while editing.")
                }
            }
            else {
                alert("Add questions before opening the survey.")
            }
    }

    const handleClearResponses = async () => {
        if (sdata.Survey.Question !== undefined && Array.isArray(sdata.Survey.Question) && sdata.Survey.Question.length > 0) {
            if (clearResponsesMsg === "CONFIRM DELETE") {
                const model = {
                    VMeetingId: instanceData.instanceId,
                    UserId: instanceData.userId,
                    surveyId: sdata.Survey.id
                }

                axios.post('api/ClearResponses', model)
                    .then(() => {
                        const updatedQuestions = sdata.Survey.Question.map((question) => {
                        return {
                            ...question,
                            Editable: true
                        }
                        })

                        setSdata({
                            ...sdata,
                            Survey: {
                                ...sdata.Survey,
                                Question: updatedQuestions
                            }
                        })
                        })
                    .catch(function (error) { console.log(error); })

                handleClearResponsesModal()
            }
            else {
                alert("Enter the text exactly as displayed.")
            }
        }
        else {
            alert("This survey does not have any responses.")
            handleClearResponsesModal()
        }
    }


    useEffect(() => {
        axios.post('api/GetSurveyTemplates', {})
            .then(response => {
                const transformedSurveys = JSON.parse(response.data.jsonresponse).map((survey) => {
                    return { Survey: survey }
                })
                setTemplateSurveys(transformedSurveys)
            })
            .catch(function (error) { console.log(error); })
    }, [])

    return (
        <Tabs>
            <TabList>
                <Tab>Home</Tab>
                <Tab disabled={!instanceData.hasSurvey}>Survey preview</Tab>
                <Tab disabled={!instanceData.hasSurvey || !sdata.Survey.Question}>Analytics</Tab>
            </TabList>
            <TabPanel title="Home">
                {instanceData.hasSurvey || showAD ? (
                    <React.Fragment>
                        <div className="d-flex justify-content-end">
                            <ButtonGroup className='button-margin-right'>
                                <ToggleButton
                                    key={1}
                                    id={1}
                                    type="radio"
                                    variant={'outline-danger'}
                                    name={1}
                                    value={false}
                                    onClick={() => { handleSurveyStatus(false) }}
                                    checked={status === false}
                                    disabled={!instanceData.hasSurvey || !sdata.Survey.Question}>
                                    Closed
                                </ToggleButton>
                                <ToggleButton
                                    key={2}
                                    id={2}
                                    type="radio"
                                    variant={'outline-success'}
                                    name={2}
                                    value={true}
                                    onClick={() => { handleSurveyStatus(true) }}
                                    checked={status === true}
                                    disabled={!instanceData.hasSurvey || !sdata.Survey.Question}>
                                    Open
                                </ToggleButton>
                            </ButtonGroup>

                            <ButtonGroup>
                                <DropdownButton as={ButtonGroup} title="Survey actions" className="button-margin-2" variant="outline-primary" disabled={!instanceData.hasSurvey}>
                                    <Dropdown.Item as={Button} eventKey="1" onClick={handleEdit}>Edit survey</Dropdown.Item>
                                    <Dropdown.Item as={Button} eventKey="2" onClick={handleModalShow}>Delete survey</Dropdown.Item>
                                    <Dropdown.Item as={Button} eventKey="3" onClick={handleStatusShow}>Survey status</Dropdown.Item>
                                    <Dropdown.Item as={Button} eventKey="5" onClick={handleClearResponsesShow}>Clear responses</Dropdown.Item>
                                </DropdownButton>
                            </ButtonGroup>

                            <Modal show={showModal} onHide={handleModal}>
                                <Modal.Header closeButton>
                                    <Modal.Title>Delete
                                    </Modal.Title>
                                </Modal.Header>
                                <Modal.Body className="p-2">
                                    This action will result in the permanent deletion of all data associated with this survey.

                                    <Form.Group className='form-text'>
                                        <FloatingLabel label='Type "CONFIRM DELETE" to confirm.'>
                                            <Form.Control
                                                type="text"
                                                name={sdata.Survey.id}
                                                value={deleteMsg}
                                                onChange={(evt) => setDeleteMsg(evt.target.value)} />
                                        </FloatingLabel>
                                    </Form.Group>
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button variant="secondary" onClick={handleModal}>
                                        Close
                                    </Button>
                                    <Button variant="primary" onClick={handleDelete}>
                                        Delete
                                    </Button>
                                </Modal.Footer>
                            </Modal>

                            <Modal show={showStatusModal} onHide={handleStatusModal}>
                                <Modal.Header closeButton>
                                    <Modal.Title>Status</Modal.Title>
                                </Modal.Header>
                                <Modal.Body className="p-2">
                                    <Form>
                                        <Form.Group>
                                            <FloatingLabel label="Message for closed surveys">
                                                <Form.Control
                                                    type="text"
                                                    name={sdata.Survey.id}
                                                    value={statusMsg}
                                                    onChange={(evt) => setStatusMsg(evt.target.value)} />
                                            </FloatingLabel>
                                        </Form.Group>
                                    </Form>
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button variant="secondary" onClick={handleStatusModal}>
                                        Close
                                    </Button>
                                    <Button variant="primary" onClick={handleStatus}>
                                        Save
                                    </Button>
                                </Modal.Footer>
                            </Modal>

                            <Modal show={showClearResponsesModal} onHide={handleClearResponsesModal}>
                                <Modal.Header closeButton>
                                    <Modal.Title>Clear responses</Modal.Title>
                                </Modal.Header>
                                <Modal.Body className="p-2">
                                    This action will result in the permanent deletion of all responses associated with this survey.

                                    <Form.Group className='form-text'>
                                        <FloatingLabel label='Type "CONFIRM DELETE" to confirm.'>
                                            <Form.Control
                                                type="text"
                                                name={sdata.Survey.id}
                                                value={clearResponsesMsg}
                                                onChange={(evt) => setClearResponsesMsg(evt.target.value)} />
                                        </FloatingLabel>
                                    </Form.Group>
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button variant="secondary" onClick={handleClearResponsesModal}>
                                        Close
                                    </Button>
                                    <Button variant="primary" onClick={handleClearResponses}>
                                        Clear responses
                                    </Button>
                                </Modal.Footer>
                            </Modal>

                        </div>
                        <AdminContainer editMode={editMode} setEditMode={setEditMode} />
                    </React.Fragment>
                ) : (
                    <Accordion defaultActiveKey={['0']} alwaysOpen>
                        <Accordion.Item eventKey="0">
                            <Accordion.Header>Add a new survey</Accordion.Header>
                            <Accordion.Body>
                                <Button variant="outline-primary" onClick={() => handleNew()}>New survey</Button>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="1">
                            <Accordion.Header>Add a survey from template</Accordion.Header>
                            <Accordion.Body>
                                <ListGroup>
                                    {templateSurveys.map((template) => (
                                        <ListGroup.Item key={template.Survey.id} action onClick={() => handleClick(template.Survey.id)}>
                                            <div className="fw-bold">{template.Survey.SurveyTitle}</div>
                                            {template.Survey.SurveyDescription}
                                        </ListGroup.Item>
                                    ))}

                                    <Offcanvas show={show} onHide={handleClose}>
                                        <Offcanvas.Header closeButton>
                                            <Offcanvas.Title>Template</Offcanvas.Title>
                                        </Offcanvas.Header>
                                        <Offcanvas.Body>
                                            <div>
                                                <SurveyContainer />
                                            </div>
                                        </Offcanvas.Body>
                                        <Offcanvas.Header>
                                            <Button variant="outline-secondary" onClick={handleClose}>
                                                Close
                                            </Button>{' '}
                                            <Button variant="outline-primary" onClick={() => { handleClose(); setShowAD(true) }}>
                                                Create survey from template
                                            </Button>
                                        </Offcanvas.Header>
                                    </Offcanvas>
                                </ListGroup>
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                )}
            </TabPanel>
            <TabPanel title="Survey preview" disabled={!instanceData.hasSurvey}>
                <SurveyContainer />
            </TabPanel>
            <TabPanel title="Analytics" disabled={!instanceData.hasSurvey || !sdata.Survey.Question}>
                <SurveyAnalytics/>
            </TabPanel>
        </Tabs>
    )
}