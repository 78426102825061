import React, { useContext, useEffect, useState } from 'react';
import ChatContext from '../../context/ChatContext';

//props required:
//Data source 
//Current value
//Select function
export function AutoComplete(props) {
    const { value, handleSuggestionClick } = props;
    const [suggestions, setSuggestions] = useState([]);
    const { comfortTagList } = useContext(ChatContext);

    useEffect(() => {
        if (value?.length > 1) {
            const filteredSuggestions = comfortTagList.filter(suggestion =>
                suggestion.tagText.toLowerCase().includes(value.toLowerCase())
            );
            setSuggestions(filteredSuggestions);
        } else {
            setSuggestions([]);
        }
    }, [value, comfortTagList])

    return (
        <div className="autocomplete">
            {suggestions.length > 0 && (
                <ul className="autocomplete-list" role="listbox">
                    {suggestions.map((suggestion) => {
                        return (
                            <li
                                key={suggestion.messageTagId}
                            >
                                <button type="button" onClick={() => handleSuggestionClick(suggestion.messageTagId)} className="btn px-3 py-1 mb-1">{suggestion.tagText}</button>                                
                            </li>
                        )
                    })}
                </ul>
            )}
        </div>
    )
}