export function timeBetween(dateIn, dateNowIn) {
    let date = Date.parse(dateIn);
    let dateNow = Date.parse(dateNowIn);
    let seconds = Math.floor((dateNow - date) / 1000);

    let interval = seconds / 31536000;
    //if more than a year
    if (interval >= 1) {
        return Math.floor(interval) + " year" + (Math.floor(interval) > 1 ? 's' : '') + " ago";
    }

    interval = seconds / 2592000;
    //if less than a year but more than a month
    if (interval >= 1) {
        return Math.floor(interval) + " month" + (Math.floor(interval) > 1 ? 's' : '') + " ago";
    }

    interval = seconds / 86400;
    //if less than a month but more than a day
    if (interval >= 1) {
        return Math.floor(interval) + " day" + (Math.floor(interval) > 1 ? 's' : '') + " ago";
    }

    interval = seconds / 3600;
    //if less than a day but more than an hour
    if (interval >= 1) {
        return Math.floor(interval) + " hour" + (Math.floor(interval) > 1 ? 's' : '') + " ago";
    }

    interval = seconds / 60;
    //is less than an hour but more than a minute
    if (interval >= 1) {
        return Math.floor(interval) + " minute" + (Math.floor(interval) > 1 ? 's' : '') + " ago";
    }

    //return Math.floor(seconds) + " seconds ago";
    return "Just now"
}