import { Component, Fragment } from 'react';
import axios from 'axios';
import SignalRController from './SignalRController';


export default class PublishShowOfHands extends Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: true,
            submitting: false,
            submitted: false,
            message: ''
        }
        this.VmeetingId = parseInt(sessionStorage.getItem('VMId'));
        this.UserId = parseInt(sessionStorage.getItem('UserId'));
    }

    componentDidMount() {
        this.getOutcomeOptions();
    }


    submitValue = async (publishType) => {
        this.setState({
            submitting: true,
            message: ''
        })
        //build api to submit
        var publishData = {
            UserId: this.UserId,
            VmeetingId: this.VmeetingId,
            ContestId: this.props.contestId,
            DeclareInRoomOutcomeId: publishType
        }
        if (publishData.UserId > 0 && publishData.VmeetingId > 0 && publishData.ContestId > 0 && publishData.DeclareInRoomOutcomeId > 0) {
            await axios.post("VMPublishShowOfHands", publishData).then(response => {
                if (response.data.statusID === 1) {
                    this.setState({
                        submitting: false
                    })
                    //SignalRController.InvokeAdm2All_VotePublish(this.props.contestId);
                    this.props.populateResolutionData();
                }
                else {
                    this.setState({
                        submitting: false,
                        message: 'Error publishing result'
                    })
                }
                return;
            })
        }
        else {
            this.setState({
                submitting: false,
                message: 'Error: Missing data, please refresh your page'
            })
        }
    }

    getOutcomeOptions = async () => {
        await axios.get("VMGetDeclaredOutcomeList").then(response => {
            const resdata = response.data;
            if (resdata.length > 1) {
                this.setState({
                    options: resdata,
                    loading: false
                })
            }
        })
    }



    render() {
        if (this.state.loading) {
            return (
                <Fragment>
                    <div className="aggregateData cloud">
                        <div className="loaderholder"> < div className="loader" /></div>
                    </div>
                </Fragment>
            )
        }
        return (
            <Fragment>
                <p>Publish result as:</p>
                <div className="cloud showOfHands">
                    {this.state.options.map(option => {
                        return (
                            <Fragment key={option.declareInRoomOutcomeId}>
                                <div>
                                    <button className="btn btn-primary btn-block" onClick={() => this.submitValue(option.declareInRoomOutcomeId)}>{option.declaredInRoomOutcome}</button>
                                </div>
                            </Fragment>
                        )
                    })}
                    {this.state.message && <span>{this.state.message}</span>}
                </div>
            </Fragment>
        )
    }
}

