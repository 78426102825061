
export const exportTableData = (tableId, filename) => {

    // Export to csv
    const csv = toCsv(tableId);

    // Download it
    download(csv, filename);
}
const download = function (text, fileName) {
    const link = document.createElement('a');
    link.setAttribute('href', `data:text/csv;charset=utf-8,${encodeURIComponent(text)}`);
    link.setAttribute('download', fileName);

    link.style.display = 'none';
    document.body.appendChild(link);

    link.click();

    document.body.removeChild(link);
};
const toCsv = function (table) {
    const container = document.querySelector(table);
    // Query all rows
    const rows = container.querySelectorAll("tr");

    return [].slice.call(rows)
        .map(function (row) {
            // Query all cells
            const cells = row.querySelectorAll('th,td');
            return [].slice.call(cells)
                .map(function (cell) {
                    return cell.textContent;
                })
                .join(',');
        })
        .join('\n');
};