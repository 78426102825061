import React, { useState, useRef, useEffect, useContext, Fragment } from 'react';
import './QuickPollAdmin.css';
import axios from 'axios';
import QuickPollCard from './QuickPollCard';
import SignalRController from '../SignalRController';
import { NotificationManager } from 'react-notifications';

var timers = [];
export default function QuickPollPresenter(props) {
    const [poll, setPoll] = useState([]);
    const [VmeetingId, setVMeetingId] = useState(parseInt(sessionStorage.getItem('VMId')));
    
    useEffect(() => {
        SignalRController.addCallbackAdm2Adm_QuickPollUpdate(getAllPollsFromServer);
        getAllPollsFromServer();

        return (() => {
            timers.forEach((t) => clearInterval(t));
            timers = [];
            SignalRController.removeCallbackAdm2Adm_QuickPollUpdate(getAllPollsFromServer);
        })
    }, []);

    function timerGetAllPollsFromServer() {
        getAllPollsFromServer();
    }

    function getAllPollsFromServer() {
        var obj = { VMId: VmeetingId };
        axios.post('VMGetAllQuickPolls', obj).then(res => {
            var poll = res.data.filter((poll) => poll.pollActive === 1 || poll.published === 1);
            if (poll.length > 0) {
                setPoll(poll[0]);
                if (timers.length === 0) {
                    timers = timers.concat(setInterval(() => timerGetAllPollsFromServer(), 1000));
                }
            }
            else {
                setPoll([]);
                timers.forEach((t) => clearInterval(t));
                timers = [];
            }
        }).catch(error => {
            NotificationManager.error("Sorry, poll that action failed:" + error, "Quick Poll", 2000);
        });
    }

    return (

        <div>
            {poll.pollId > 0 && (poll.published || poll.pollActive) &&
                <Fragment>
                <div className="whitecolumn"></div>
                <div className="chat-heading">QUICK POLL</div>
                <QuickPollCard vMeetingId={VmeetingId} poll={poll} key={poll.pollId} refresh={getAllPollsFromServer} presenter={true} />
                </Fragment>}
        </div>
    )

}

