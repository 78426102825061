import React, { Component } from 'react';
import { Button } from 'reactstrap';
import { Fragment } from 'react';
import axios from 'axios';
import CSVUpload from './../Common/CSVUpload';
import { NotificationManager } from 'react-notifications';
import swal from 'sweetalert';
import { SmallLoader } from '../SecondaryComponents';

const DEFAULT_OFFSET = 5000;

export class ImportPreMeeting extends Component {
    constructor(props) {
        super(props);

        this.userId = parseInt(sessionStorage.getItem('UserId'));
        this.VmeetingId = parseInt(sessionStorage.getItem('VMId'));

        this.state = {
            preMeetingList: [],
            error: "",
            warnings: "",
            success: "",
            isUploadedData: true,
            preMeetingListDB: [],
            dataExistsInPins: true,
            preMeetingStats: [],
            preMeetingDataValidation: null,
            loadingPreMeetingValidation: true,
            totalCount: 0,
            upLoading: false,
            isLoadingPreMeetingData: false,
            PreMeetingUploadInProgress: null,
            recordCount: 0,
            isResetting: false
        }


        this.fileImportOnClick = this.fileImportOnClick.bind(this);
    }

    async componentDidMount() {
        this.setState({ error: "" });
        this.getFileUploadStatus();
        this.getPreMeetingList();
        this.getPremeetingDataValidation();
        this.getVMResult();
        this.getUploadCount();
    }

    componentWillUnmount() {
        // fix Warning: Can't perform a React state update on an unmounted component
        this.setState = (state, callback) => {
            return;
        };
    }

    getPreMeetingList = () => {
        this.setState({
            isLoadingPreMeetingData: true
        })
        const VMId = parseInt(sessionStorage.getItem('VMId'))
        const options = {
            url: 'VMGetPreMeetingList',
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            data: VMId
        };
        axios(options)
            .then(response => {
                if (response.data.length > 1) {
                    this.setState({ preMeetingList: response.data.slice(0, 10000) });
                    var totalcount = response.data.length;
                    if (totalcount > 0) {
                        if (this.state.preMeetingList.length >= 1 && this.state.preMeetingListDB.length <= 1) {
                            this.setState({ warnings: 'Please verify the data you wish to upload below and click commit changes to proceed' });
                        }
                        else {
                            this.setState({ warnings: '' });
                        }
                    }
                }

                if (Number(response.data[0].resultId) === 2) {
                    this.setState({ dataExistsInPins: false });
                    this.setState({ error: "No Meeting Attendee information found for this meeting." });
                }
            }).catch(function (error) {
                NotificationManager.error("Sorry, there was a problem in getting Premeeting list.", "Get Premeeting list", 2000);
            })
    }

    getVMResult = () => {
        const VMId = parseInt(sessionStorage.getItem('VMId'))
        const options = {
            url: 'VMGetResult',
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            data: VMId
        };
        axios(options)
            .then(response => {
                if (response.data.length > 1) {
                    this.setState({ preMeetingListDB: response.data });
                    if (Number(response.data[0].vMeetingId) !== 0) {
                        this.setState({ isUploadedData: false });
                        var totalcount = response.data.length;
                        this.setState({
                            success: 'Below is the data you have uploaded to this meeting, if you wish to remove this and upload a new data file please click RESET to proceed',
                            totalCount: totalcount
                        });
                    }
                }

                if (Number(response.data[0].resultId) === 2) {
                    this.setState({ dataExistsInPins: false });
                    this.setState({ error: "No Meeting Attendee information found for this meeting." });
                }
            }).catch(function (error) {
                NotificationManager.error("Sorry, there was a problem in getting the Results list.", "Get Result list", 2000);
            })
    }

    getPremeetingDataValidation = () => {
        this.setState({
            loadingPreMeetingValidation: true
        });
        const VMId = parseInt(sessionStorage.getItem('VMId'))
        const options = {
            url: 'VMGetPremeetingDataValidation',
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            data: VMId
        };
        axios(options)
            .then(response => {
                this.setState({
                    loadingPreMeetingValidation: false
                })
                if (response) {
                    this.setState({ preMeetingDataValidation: response.data });
                }
            }).catch(function (error) {
                this.setState({
                    loadingPreMeetingValidation: false
                })
                NotificationManager.error("Sorry, there was a problem in getting premeeting data validation.", "Get Premeeting Validation", 2000);
            })
    }

    async fileImportOnClick(response) {
        if (response.status === 200) {
            this.setState({
                success: '',
                isUploadedData: true,
                error: ''
            }, async () => {
                this.getFileUploadStatus();
                await this.getUploadCount();
                this.getPreMeetingList();
                this.getPremeetingDataValidation();
            });
        }
        else {
            this.deletePreMeetingData(false);
            this.setState({
                preMeetingList: [],
                error: response.data.message
            }, async () => {
                this.getFileUploadStatus();
                await this.getUploadCount();
                this.getPreMeetingList();
                this.getPremeetingDataValidation();
            });
        }
    }


    CommitUploadRecords = async () => {
        this.setState({ upLoading: true })
        await this.batchInsertResult(parseInt(sessionStorage.getItem('VMId')), parseInt(sessionStorage.getItem('UserId')))
        this.getVMResult();
        this.setState({ upLoading: false })
    }

    async deletePreMeetingData(showMessage = true) {
        this.setState({ isResetting: true });
        await this.batchDeleteResult(parseInt(sessionStorage.getItem('VMId')), parseInt(sessionStorage.getItem('UserId')), showMessage, 0);
        this.setState({ isResetting: false });
    }

    getFileUploadStatus = async () => {
        try {
            const VmeetingId = parseInt(sessionStorage.getItem('VMId'));
            const options = {
                url: `GetPremeetingFileStatus/${VmeetingId}`,
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                }
            };
            const response = await axios(options);
            if (response.status === 200) {
                this.setState({
                    PreMeetingUploadInProgress: response.data.preMeetingUploadInProgress
                })
            }
        }
        catch {

        }
    }


    getUploadCount = async () => {
        const VMId = parseInt(sessionStorage.getItem('VMId'))
        const options = {
            url: `VMGetPreMeetingRecordCount/${VMId}`,
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        };
        try {
            let response = await axios(options);
            if (response.status === 200) {
                let recordCounter = response?.data.recordCount / DEFAULT_OFFSET;
                this.setState({
                    recordCount: Math.ceil(recordCounter)
                })
            }
        }
        catch {

        }
    }

    batchInsertResult = async (VMId, UserId, Offset = 0) => {
        console.log("Saving Batch ", Offset);
        const preMeetingData = { VMId, UserId, Offset };
        const options = {
            url: 'InsertIntoResultFromPremeeting',
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            data: preMeetingData
        };
        try {
            let response = await axios(options);
            if (response.data !== undefined) {
                if (Offset <= this.state.recordCount) {
                    Offset = Offset + 1;
                    await this.batchInsertResult(VMId, UserId, Offset)
                }
                else {
                    this.setState({ success: 'Below is the data you have uploaded to this meeting, if you wish to remove this and upload a new data file please click RESET to proceed' });
                    this.setState({ warnings: "" });
                    NotificationManager.success("Premeeting Saved", "Save Premeeting Data", 2000);
                }
            }
            else {
                NotificationManager.error("Sorry, there was a problem saving premeeting data", "Save Premeeting Data", 2000);
            }

        }
        catch {
            NotificationManager.error("Sorry, there was a problem saving premeeting data", "Save Premeeting Data", 2000);
        }
    }

    batchDeleteResult = async (VMId, UserId, showMessage = true, Offset = 0) => {
        const preMeetingData = { VMId, UserId, Offset };
        const options = {
            url: 'VMDeletePreMeetingData',
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            data: preMeetingData
        };
        try {
            let response = await axios(options);
            if (response.data.statusDescription === 'Success') {
                if (Offset <= this.state.recordCount) {
                    Offset = Offset + 1;
                    await this.batchDeleteResult(VMId, UserId, showMessage, Offset)
                }
                else {
                    this.getPreMeetingList();
                    this.getPremeetingDataValidation();
                    this.setState({
                        preMeetingListDB: [],
                        preMeetingStats: [],
                        preMeetingList: [],
                        success: showMessage ? 'Data has been reset successfully to this meeting, if you wish to upload a new data file please select file' : '',
                        totalCount: 0
                    });
                    showMessage && NotificationManager.success("Premeeting Data Deleted", "Premeeting Data", 2000);
                }
            }
            else {
                showMessage && NotificationManager.error("Sorry, there was a problem deleting PreMeeting Data, " + response.data.statusDescription, "Premeeting Data", 2000);
            }

        }
        catch {
            NotificationManager.error("Sorry, there was a problem deleteing premeeting data", "Delete Premeeting Data", 2000);
        }
    }


    render() {
        let { preMeetingList, preMeetingListDB, preMeetingDataValidation, loadingPreMeetingValidation } = this.state;

        var preMeetingData = preMeetingListDB.length > 1 ? preMeetingListDB : preMeetingList;

        return (
            <Fragment>
                <div id="openResDashboardb">
                    <div className="row">
                        <div className="col-12 hMargin">
                            {this.state.preMeetingListDB.length <= 1 && this.state.preMeetingList.length <= 1 && this.state.dataExistsInPins &&
                                <Fragment>
                                    <div className="alert alert-warning hMargin">
                                        <p>Pin table MUST be uploaded before pre-meeting data to set correct vote weights</p>
                                        <p>
                                            Premeeting data file should be in CSV format with the following column headings in this order:<br /> CESVotersID, OBOVoterID, VMeetingID, CESVMContestID, PreMeetingVote
                                        </p>
                                    </div>
                                    <div id="divFileUpload" className="fileNameText" >
                                        <CSVUpload
                                            id="pdfFileUpload"
                                            className="fileNameText"
                                            style={{ height: '100px', padding: '10px', cursor: 'pointer' }}
                                            text="Upload Document"
                                            accept=".csv"
                                            onUpload={(event) => { this.fileImportOnClick(event) }}
                                            isAuthorized={this.props.isAuthorized}
                                        />
                                        <span className="ml-1">{this.state.fileName ? this.state.fileName : null}</span>
                                    </div>
                                </Fragment>
                            }
                        </div>
                        <div className="d-flex justify-content-between hMargin">
                            {(this.state.preMeetingList.length >= 1 || this.state.totalCount >= 1) &&
                                <Button
                                    disabled={!this.props.isAuthorized || this.state.isResetting}
                                    onClick={() => swal({ title: "Are you sure you want to delete premeeting data?", text: "This change will be reflected in the live attendee app", icon: "warning", buttons: true, dangerMode: true }).then((value) => { if (value) this.deletePreMeetingData() })}
                                    variant="contained" className="mr-2 btn btn-primary btn-sm">
                                    {this.state.isResetting ? <SmallLoader /> : 'Reset'}
                                </Button>
                            }

                            {
                                !(loadingPreMeetingValidation || preMeetingDataValidation?.missingContestIDs || preMeetingDataValidation?.proxyNotInPins || preMeetingDataValidation?.oboNotInPins || preMeetingDataValidation?.totalInvalidOptions > 0) &&
                                this.state.isUploadedData && this.state.preMeetingList.length > 0 && this.state.preMeetingListDB.length <= 1 && !this.state.PreMeetingUploadInProgress &&
                                <Button disabled={!this.props.isAuthorized || this.state.upLoading} onClick={() => this.CommitUploadRecords()} variant="contained" className="mr-2 btn btn-primary btn-sm">
                                    {this.state.upLoading ? <SmallLoader /> : 'Commit changes'}
                                </Button>
                            }
                        </div>
                        {
                            this.state.PreMeetingUploadInProgress !== null && this.state.PreMeetingUploadInProgress && this.state.preMeetingList.length > 0 &&
                            <div className="col-12 hMargin">
                                <div className="alert alert-warning hMargin">
                                    <p>
                                        Voting data is in an inconsistent state due to aborted PreMeeting Upload.
                                    </p>
                                </div>
                            </div>
                        }
                    </div>
                    <div className="row">
                        <div className="col-12 hMargin">
                            {this.state.error &&
                                <div className="alert alert-danger hMargin">
                                    {this.state.error}
                                </div>
                            }
                            {(preMeetingDataValidation?.missingContestIDs || preMeetingDataValidation?.proxyNotInPins || preMeetingDataValidation?.oboNotInPins || preMeetingDataValidation?.totalInvalidOptions > 0) &&
                                <div className="alert alert-danger hMargin">
                                    You must resolve the data issues before you can proceed
                                </div>
                            }
                            {this.state.warnings && this.state.preMeetingList.length >= 1 && this.state.preMeetingListDB.length <= 1 && !this.state.PreMeetingUploadInProgress &&
                                <div className="alert alert-warning hMargin">
                                    <strong>Warning</strong> - {this.state.warnings}
                                </div>
                            }
                            {this.state.success &&
                                <div className="alert alert-success hMargin">
                                    {this.state.success}
                                </div>
                            }
                        </div>

                        <br />
                    </div>
                    

                    {loadingPreMeetingValidation && <div className="d-flex flex-row justify-content-center"> <SmallLoader />Loading Premeeting Stats and Records </div>}



                    {!loadingPreMeetingValidation &&
                        preMeetingDataValidation &&
                        <div className="row hMargin attendee-lists">
                            <table className="table table-bordered w-100">
                                <thead className="thead-light">
                                    <tr>
                                        <th scope="col" width="200px">Summary</th>
                                        <th scope="col" width="200px">Total</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td className="text-start">
                                            Total rows imported (count)
                                        </td>
                                        <td>
                                            {preMeetingDataValidation.totalRowsImported}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="text-start">
                                            Total contests (count)
                                        </td>
                                        <td>
                                            {preMeetingDataValidation.totalContests}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="text-start">
                                            Total proxies appointed (count)
                                        </td>
                                        <td>
                                            {preMeetingDataValidation.totalProxiesAppointed}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="text-start">
                                            Total proxy appointers (count)
                                        </td>
                                        <td>
                                            {preMeetingDataValidation.totalProxyAppointers}
                                        </td>
                                    </tr>
                                </tbody>
                                {(preMeetingDataValidation.missingContestIDs || preMeetingDataValidation.proxyNotInPins || preMeetingDataValidation.oboNotInPins || preMeetingDataValidation.totalInvalidOptions > 0) &&
                                    <Fragment>
                                        <thead className="thead-light">
                                            <tr>
                                                <th scope="col" width="200px">Issues</th>
                                                <th scope="col" width="200px">Detail</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {preMeetingDataValidation.missingContestIDs &&
                                                <tr>
                                                    <td className="text-start">
                                                        Missing ContestIDs (list)
                                                    </td>
                                                    <td>
                                                        {preMeetingDataValidation.missingContestIDs}
                                                    </td>
                                                </tr>
                                            }
                                            {preMeetingDataValidation.proxyNotInPins &&
                                                <tr>
                                                    <td className="text-start">
                                                        Missing CESvotersIds in Pins table (list)
                                                    </td>
                                                    <td>
                                                        {preMeetingDataValidation.proxyNotInPins}
                                                    </td>
                                                </tr>
                                            }
                                            {preMeetingDataValidation.oboNotInPins &&
                                                <tr>
                                                    <td className="text-start">
                                                        Missing or incorrectly assigned roles for OBOVoterIds in Pins table (list)
                                                    </td>
                                                    <td>
                                                        {preMeetingDataValidation.oboNotInPins}
                                                    </td>
                                                </tr>
                                            }
                                            {preMeetingDataValidation.totalInvalidOptions > 0 &&
                                                <tr>
                                                    <td className="text-start">
                                                        Total invalid resolution options exist in Results table (count)
                                                    </td>
                                                    <td>
                                                        {preMeetingDataValidation.totalInvalidOptions}
                                                    </td>
                                                </tr>
                                            }
                                        </tbody>
                                    </Fragment>
                                }
                            </table>
                        </div>
                    }


                    {this.state.preMeetingList.length > 1 && <div className="chat-heading-am">Premeeting Data</div>}

                    <div className="row hMargin attendee-lists">
                        <table className="table table-bordered">
                            {preMeetingData?.length >= 1 && preMeetingData[0].vMeetingId !== 0 ?

                                <thead className="thead-light">
                                    <tr>
                                        <th scope="col" width="150px">CESVotersId</th>
                                        <th scope="col" width="150px">OBOVoterId</th>
                                        <th scope="col" width="150px">VMeetingId</th>
                                        <th scope="col" width="150px">ContestId</th>
                                        <th scope="col" width="150px">PremeetingVoteAction</th>
                                    </tr>
                                </thead>
                                : null
                            }
                            {preMeetingData?.length >= 1 && preMeetingData[0].vMeetingId !== 0 ?
                                <tbody>
                                    {preMeetingData?.map((preMeeting, i) =>
                                        <tr key={i}>
                                            <td width="150px">{preMeeting.cesVotersId}</td>
                                            <td width="150px">{preMeeting.oboVoterId}</td>
                                            <td width="150px">{preMeeting.vMeetingId}</td>
                                            <td width="150px">{preMeeting.contestId}</td>
                                            <td width="150px">{preMeeting.preMeetingVoteAction}</td>
                                        </tr>)
                                    }
                                </tbody> : null
                            }

                        </table>
                    </div>
                </div>
            </Fragment>
        )
    }
};

export default ImportPreMeeting;

