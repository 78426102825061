import React, { useState, useEffect, useContext, useRef } from 'react';
import AdminContext from '../context/AdminContext';
import { OpenChatGetStats } from './JsFunctions/APIFunctions';
import { ChatMessage } from './Common/OpenChatPost';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown, faArrowUp, faEquals, faExpand, faStar } from '@fortawesome/free-solid-svg-icons';
import { Modal } from 'react-bootstrap';


export default function OpenChatStats(props) {
    let context = useContext(AdminContext);

    const [show, setShow] = useState(false);
    const [tabIndex, setTabIndex] = useState(0);
    const [openChatStats, setOpenChatStats] = useState([]);
    const [counter, setCounter] = useState(0);

    const refStateStats = useRef();
    refStateStats.current = openChatStats;
    let timeOut;
    let populateTimer;
    const countRef = useRef(counter);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const selectTab = (index) => setTabIndex(index);

    useEffect(() => {
        if (context.populated) {
            getStats();
            if (!timeOut) {
                timeOut = setInterval(() => { getStats() }, 25000);
            }
            if (populateTimer) {
                clearInterval(populateTimer);
            }
        } else {
            if (!populateTimer) {
                populateTimer = setInterval(() => { setCounter(countRef.current+1) }, 1000);
            }
        }
        return () => {
            if (timeOut) {
                clearInterval(timeOut);
            }
            if (populateTimer) {
                clearInterval(populateTimer);
            }
        }
    }, [counter])


    async function getStats() {
        try {
            let stats = await OpenChatGetStats(context.meetingId);
            if (stats) {
                if (stats.TopLikes) {
                    stats.TopLikes.forEach((stat, i) => {
                        if (refStateStats.current.TopLikes) {
                            let index = refStateStats.current.TopLikes.findIndex(post => post.PostId === stat.PostId);
                            if (index !== -1) {
                                stats.TopLikes[i].RankChange = index - i;
                            }
                        }
                    })
                }
                if (stats.TopDislikes) {
                    stats.TopDislikes.forEach((stat, i) => {
                        if (refStateStats.current.TopDislikes) {
                            let index = refStateStats.current.TopDislikes.findIndex(post => post.PostId === stat.PostId);
                            if (index !== -1) {
                                stats.TopDislikes[i].RankChange = index - i;
                            }
                        }
                    })
                }
                if (stats.Replies) {
                    stats.Replies.forEach((stat, i) => {
                        if (refStateStats.current.Replies) {
                            let index = refStateStats.current.Replies.findIndex(post => post.PostId === stat.PostId);
                            if (index !== -1) {
                                stats.Replies[i].RankChange = index - i;
                            }
                        }
                    })
                }
                setOpenChatStats(stats);
            }
        } catch {
            console.log('Error getting stats')
        }
    }


    return (
        <div className="w-100 h-100 p-1 d-flex flex-column">
            <div className="chat-heading d-flex">
                <div className="flex-grow-1">
                    CHAT STATS
                </div>
                {!props.presenter && <div>
                    <button className="btn btn-sm p-0" aria-label="Refresh chat" title="Refresh" onClick={() => handleShow()}><FontAwesomeIcon icon={faExpand} /></button>
                </div>}
            </div>
            <ChatStatsContent openChatStats={openChatStats} tabIndex={tabIndex} selectTab={selectTab} />
            <Modal
                scrollable={true}
                show={show}
                onHide={handleClose}
                dialogClassName="modal-80w"
                aria-labelledby="contained-modal-title-vcenter"
            >
                <Modal.Header closeButton>
                    <Modal.Title >CHAT STATS</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <ChatStatsContent openChatStats={openChatStats} tabIndex={tabIndex} selectTab={selectTab} />
                </Modal.Body>
                <Modal.Footer>

                </Modal.Footer>
            </Modal>
        </div>
    )
}

function ChatStatsContent({ openChatStats, tabIndex, selectTab }) {
    return (
        <Tabs className="d-flex flex-1 overflow-hidden flex-column" selectedIndex={tabIndex} onSelect={(index) => selectTab(index)}>
            <TabList>
                <Tab>Most Liked</Tab>
                <Tab>Most Disliked</Tab>
                <Tab>Most Replied</Tab>
            </TabList>
            <TabPanel className="flex-1 overflow-auto">
                <div>
                    {openChatStats.TopLikes && openChatStats.TopLikes.map((post) => {
                        return (
                            <div className="d-flex" key={post.PostId}>
                                <RankChange post={post} />
                                <div className="flex-grow-1">
                                    <ChatMessage key={post.PostId} post={post} noAdmin />
                                </div>
                            </div>
                        )
                    })}
                </div>
            </TabPanel>
            <TabPanel className="flex-1 overflow-auto">
                <div>
                    {openChatStats.TopDislikes && openChatStats.TopDislikes.map((post) => {
                        return (
                            <div className="d-flex" key={post.PostId} >
                                <RankChange post={post} />
                                <div className="flex-grow-1">
                                    <ChatMessage key={post.PostId} post={post} noAdmin />
                                </div>
                            </div>
                        )
                    })}
                </div>
            </TabPanel>
            <TabPanel className="flex-1 overflow-auto">
                <div>
                    {openChatStats.Replies && openChatStats.Replies.map((post) => {
                        return (
                            <div className="d-flex" key={post.PostId}>
                                <RankChange post={post} />
                                <div className="flex-grow-1">
                                    <ChatMessage key={post.PostId} post={post} noAdmin />
                                </div>
                            </div>
                        )
                    })}
                </div>
            </TabPanel>
        </Tabs>
    )
}


function RankChange({ post }) {

    return (
        <div className="statArrowsParent d-flex flex-column">
            {post.RankChange > 0 ?
                <div className="flex-grow-0 mt-auto mb-auto pt-3" title="Rising">
                    <FontAwesomeIcon icon={faArrowUp} color="green" title="Rising" />
                </div>
                : post.RankChange === 0 ?
                    <div className="flex-grow-0 mt-auto mb-auto pt-3" title="Non-mover">
                        <FontAwesomeIcon icon={faEquals} color="orange" title="Non-mover" />
                    </div>
                    : post.RankChange < 0 ?
                        <div className="flex-grow-0 mt-auto mb-auto pt-3" title="Falling">
                            <FontAwesomeIcon icon={faArrowDown} color="red" title="Falling" />
                        </div>
                        :
                        <div className="flex-grow-0 mt-auto mb-auto pt-3" title="New!">
                            <FontAwesomeIcon icon={faStar} color="gold" title="New!" />
                        </div>
            }
        </div>
    );
}