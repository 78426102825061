import React, { useState, useContext } from 'react';
import { OpenChatContent } from './Common/OpenChatPost';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import OpenChatContext from './Common/OpenChatContext/OpenChatContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExpand } from '@fortawesome/free-solid-svg-icons';
import { MutedUserList } from './MutedUsers';
import { Modal } from 'react-bootstrap';


export default function OpenChatModeration() {
    const [show, setShow] = useState(false);
    const [tabIndex, setTabIndex] = useState(0);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const selectTab = (index) => setTabIndex(index);
    return (
        <div className="w-100 h-100 p-1 d-flex flex-column">
            <div className="chat-heading d-flex">
                <div className="flex-grow-1">
                    MODERATION
                </div>
                <div>
                    <button className="btn btn-sm p-0" aria-label="Refresh chat" title="Refresh" onClick={() => handleShow()}><FontAwesomeIcon icon={faExpand} /></button>
                </div>
            </div>
            <OpenChatModerationContent tabIndex={tabIndex} selectTab={selectTab} />
            <Modal
                scrollable={true}
                show={show}
                onHide={handleClose}
                dialogClassName="modal-80w"
                aria-labelledby="contained-modal-title-vcenter"
                >
                <Modal.Header closeButton>
                    <Modal.Title >MODERATION</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <OpenChatModerationContent tabIndex={tabIndex} selectTab={selectTab}/>
                </Modal.Body>
                <Modal.Footer>

                </Modal.Footer>
            </Modal>
        </div>
    )
}


function OpenChatModerationContent({tabIndex, selectTab}) {
    const context = useContext(OpenChatContext);
    return (
        <Tabs className="d-flex flex-1 h-100 overflow-hidden flex-column moderatorTabs" selectedIndex={tabIndex} onSelect={(index) => selectTab(index)}>
            <TabList>
                <Tab>Active Flags</Tab>
                <Tab>Deleted Messages</Tab>
                <Tab>Muted Users</Tab>
            </TabList>
            <TabPanel className="overflow-auto ">
                <OpenChatContent flag />
            </TabPanel>
            <TabPanel className="overflow-auto">
                <OpenChatContent deleted />
                {context.contextState.DeletedPosts.filter(a => a.Hidden === true).length >= 20 &&
                    <button className="btn btn-primary btn-sm me-1" onClick={() => context.OpenChatGetDeletedPosts()} title="Check for next 20 deleted posts">Fetch more</button>
                }
            </TabPanel>
            <TabPanel className="overflow-auto ">
                <MutedUserList />
            </TabPanel>
        </Tabs>
    )
}