import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';
import React, { useState } from "react";
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import ListGroup from 'react-bootstrap/ListGroup';
import ValidateOptions from "./ValidateOptions";

export function Ranking({ question = [],
    handleChange = () => { },
    disabled = false,
    handleSave = () => { } }) {

    const [ranking, setRanking] = useState(question)
    const [validated, setValidated] = useState(question.Validated)

    let errorMessage = ValidateOptions(question, handleChange, disabled)

    function onChange(evt, option) {
        let localOptions = ranking.Options.map((o) => (o.SortOrder === option.SortOrder ? { ...option, Selection: evt.target.value } : o))
        let qst = { ...ranking, Options: [...localOptions] }
        let rankArray = []

        if (question.Mandatory) {
            {
                qst.Options.map((option) => (
                    rankArray.push(option.Selection)
                ))
            }
            rankArray.sort(function (a, b) { return a - b })

            for (let i = 1; i < rankArray.length; i++) {
                let num = +rankArray[i - 1] + 1
                if (rankArray[i] != num) {
                    setValidated(false)
                }
                else {
                    setValidated(true)
                }
            }
        }

        setRanking(qst)
        handleChange(qst)
    }

    function onBlur(evt) {
        let qst = { ...question, Selection: evt.target.value }
        handleSave(qst)
    }

    return (
        errorMessage === "" ?
            <Form.Group className='form-div'>
                <Form.Label>{question.QuestionTitle}{question.Mandatory ? "*" : ""}</Form.Label>
                <ListGroup>
                    {question.Options.map((option, a) => (
                        <ListGroup.Item key={option.id}>
                            <Row>
                                <Col sm={8}>{option.OptionTitle}</Col>
                                <Col sm={4}>
                                    <Form.Select
                                        disabled={disabled}
                                        onChange={(evt) => onChange(evt, option)}
                                        onBlur={(evt) => onBlur(evt)}
                                        required={!validated && question.Mandatory}
                                        data-testid={option.OptionTitle}>

                                        <option value="">Select one</option>
                                        {ranking.Options.map((option, b) => (
                                            <option key={option.id}
                                                name={option.id}
                                                value={b + 1}
                                                data-testid={(a + 1) + " " + (b + 1)}>
                                                {b + 1}</option>
                                        ))}
                                    </Form.Select>
                                </Col>
                            </Row>
                        </ListGroup.Item>
                    ))}
                </ListGroup>
                {/* Prevent repeated ranks */}
                <div hidden>
                    <Form.Check>
                        <Form.Check.Input
                            type="checkbox"
                            required={!validated && question.Mandatory} />
                    </Form.Check>
                </div>
                {!validated && question.Mandatory ? <Form.Text>Rank the items where 1 is the best.</Form.Text> : ""}
            </Form.Group>
            : <Alert variant="danger" className='alert-margin'>{errorMessage}</Alert>
    )
}