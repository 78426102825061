import React, { useState, useRef, useEffect, useContext } from 'react';
import './QuickPollAdmin.css';
import QuickPollCard from './QuickPollCard';
import axios from 'axios';
import { Row, Col } from 'react-bootstrap';
import QuickPollAddNew from './QuickPollAddNew';
import { Button, ButtonDropdown, DropdownToggle } from 'reactstrap'
import SignalRController from '../SignalRController';
import { NotificationManager } from 'react-notifications';

var timers = [];
export default function QuickPoll(props) {
    const [pollData, setPollData] = useState([]);
    const [addNewPollModal, setAddNewPollModal] = useState(false);
    const vMeetingId = parseInt(sessionStorage.getItem('VMId'));


    function timerGetAllPollsFromServer() {
        getAllPollsFromServer();
    }

    function getAllPollsFromServer() {
        var obj = { VMId: vMeetingId };
        axios.post('VMGetAllQuickPolls', obj).then(res => {
            setPollData(res.data);
            if (res.data.filter((poll) => poll.pollActive === 1).length > 0) {
                if (timers.length === 0) {
                    timers = timers.concat(setInterval(() => timerGetAllPollsFromServer(), 1000));
                }
            }
            else {
                timers.forEach((t) => clearInterval(t));
                timers = [];
            }

        }).catch(error => {
            NotificationManager.error("Sorry, poll that action failed:" + error, "Quick Poll", 2000);
          
        });
    }

    function addNewPoll() {
        setAddNewPollModal(!addNewPollModal);
    }

    useEffect(() => {
        SignalRController.addCallbackAdm2Adm_QuickPollUpdate(getAllPollsFromServer);
        getAllPollsFromServer();

        return (() => {
            timers.forEach((t) => clearInterval(t));
            timers = [];
            SignalRController.removeCallbackAdm2Adm_QuickPollUpdate(getAllPollsFromServer);
        })
    }, []);

    return (
        <div>
            <div className="chat-heading-am">QUICK POLLS</div><br />
            <ButtonDropdown disabled={!props?.authorize} direction={addNewPollModal ? "up" : "down"} toggle={addNewPoll} ><DropdownToggle caret>+Add</DropdownToggle></ButtonDropdown>
            {addNewPollModal === false || <QuickPollAddNew vMeetingId={vMeetingId} close={addNewPoll} refresh={getAllPollsFromServer} />}
            <Row className='quickPollsContainer' >
                {pollData.length == 0 || pollData.map((poll) => <QuickPollCard authorize={props?.authorize} vMeetingId={vMeetingId} poll={poll} key={poll.pollId} refresh={getAllPollsFromServer} />)}
                {pollData.length > 0 || <div>No poll currently set up </div>}
            </Row>
        </div>
    )

}

