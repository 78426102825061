import React, { Component } from 'react';
import axios from 'axios';
import { Container, Col, Form } from 'react-bootstrap';
import SimpleReactValidator from 'simple-react-validator';
import { NotificationManager } from 'react-notifications';
import Select from 'react-select';
import ResOptions from './ResolutionOptions/ResOptionsSort';

export default class ResolutionView extends Component {
    constructor(props) {
        super(props);

        this.publishTypes = [
            { value: 1, label: "Standard" },
            { value: 2, label: "Weighted" },
            { value: 3, label: "Both" }
        ];

        this.state = {
            ContestId: this.props.id ? this.props.id : 0,
            ContestName: this.props.name,
            ResText: this.props.atext ? this.props.atext : '',
            options: this.props.options,  //[{ VoteOption: '', VoteDisplayOrder: 0, VoteValue: 0, VoteOptionId: 0 }],
            VMeetingId: this.props.meeting,
            errorMessage: "",
            publishtypeid: this.props.publishtypeid,
            enableweightedcount: this.props.enableweightedcount,
            selectedPublishType: this.publishTypes.filter((x) => x.value === this.props.publishtypeid)[0],
            showButton: true,
            notificationExists: false,
            display: this.props.display ? this.props.display : 0
        };


        this.userId = parseInt(sessionStorage.getItem('UserId'));
        this.validator = new SimpleReactValidator({ autoForceUpdate: this, element: (message, className) => <div className="text-danger">{message}</div> });
        this.submitted = false;
    }

    onSubmit = (e) => {
        e.preventDefault();
        this.props.onHide(true);
    }

    render() {
        this.validator.purgeFields();
        return (
            <div>
                <Container>
                    <form onSubmit={this.onSubmit}>
                        <div className="modalContestContent">
                            <div className="modalContestBody">
                                <div>
                                    <Form.Group className="mb-3">
                                        <Form.Label htmlFor="ContestName">Item name</Form.Label>
                                        <input disabled={true} className="form-control" type="text" name="ContestName" style={{ width: '100%' }} onChange={this.onChange} value={this.state.ContestName} placeholder="Item name / question" onBlur={() => this.validator.showMessageFor('ContestName')} />
                                        {this.validator.message('ContestName', this.state.ContestName, 'required|max:1000')}
                                    </Form.Group>
                                    {
                                        this.state.enableweightedcount === "true" ?
                                            <Form.Group className="mb-3">
                                                <Form.Label htmlFor="PublishTypes">Publish Types</Form.Label>
                                                <Select
                                                    disabled={true}
                                                    name="PublishTypes"
                                                    value={this.state.selectedPublishType}
                                                    onChange={this.handlePublishTypeChange}
                                                    options={this.publishTypes}
                                                />
                                                {this.validator.message('PublishTypes', this.state.selectedPublishType, 'required')}

                                            </Form.Group>
                                            : null
                                    }
                                    <Form.Group className="mb-3">
                                        <Form.Label htmlFor="ResText">Additional text</Form.Label>
                                        <textarea disabled={true} className="form-control" name="ResText" style={{ width: '100%' }} onChange={this.onChange} value={this.state.ResText} placeholder="Additional text" rows={3} />
                                    </Form.Group>
                                </div>

                                <div className="mb-3">
                                    <Form.Group>
                                        <ResOptions disabled={true} Changed={this.handleInputChange} options={this.state.options} aType={"NEW"} OptionCount={this.state.options.length} />
                                    </Form.Group>
                                </div>
                            </div>
                            <div className="modalUpdateFooter">
                                <Form.Group className="row">
                                    <Col xs="9">
                                        <div className="error-message">{this.state.errorMessage && <span>{this.state.errorMessage}</span>}</div>
                                    </Col>
                                    <Col xs="3" className="modalUpdateFooterSubmit">
                                        <button className="btn btn-dark" type="submit" color="success">Close</button>
                                    </Col>
                                </Form.Group>
                            </div>
                        </div>
                    </form>

                </Container>
            </div>
        );

    }

}