import AdminUpdateUserDetails from './AdminUpdateUserDetails';
import React, { useContext, useState, useEffect } from 'react';
import UserPermissionContext from '../UserPermissionContext';
import UserAccessSection from './UserAccessSection'
import AdminAddUser from './AdminAddUser';

const UserDetails = ({ selectedUser, handlePermissionAction }) => {

    return (
        <div className="container-fluid p-2">
            <div className="row">
                <div className="col-md-4">
                    {selectedUser ?
                        <AdminUpdateUserDetails
                            selectedUser={selectedUser}
                            handlePermissionAction={handlePermissionAction}
                        /> :
                        <AdminAddUser />}
                </div>
                <div className="col-md-8">
                    <UserAccessSection handlePermissionAction={handlePermissionAction} />
                </div>

            </div>
        </div>
    )
}

export default UserDetails;
