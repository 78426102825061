import React, { Component } from 'react';
import { FloatingLabel, Row, Form } from 'react-bootstrap';
import { Fragment } from 'react';
import SeenAndHeardContext from './Context/SeenAndHeardContext';

export default class SeenAndHeardItem extends Component {
    constructor(props) {
        super(props)

        this.VmeetingId = parseInt(sessionStorage.getItem('VMId'));
        this.UserId = parseInt(sessionStorage.getItem('UserId'));
        this.state = {
            index: null,
            id: null,
            questionTypeId: null,
            shortDescription: null,
            longDescription: null
        };


    }
    componentDidMount() {
        this.setState({
            index: this.props.index,
            id: this.props.item.id
        })
    }

    componentWillUnmount() {
        this.setState = (state, callback) => {
            return;
        };
    }


    render() {

        return (
            <Fragment>
                <Row className="">
                    <Form.Group className="col-lg-4 mb-2">
                        <FloatingLabel controlId={"requestType" + this.props.index} label="Request type">
                            <Form.Select
                                aria-label="Request type"
                                required
                                onChange={(e) => this.context.changeField("questionTypeId", e.target.value, this.props.index)}
                                value={this.props.item.questionTypeId}>
                                <option value="">Select option</option>
                                <option value="2">Request to Speak</option>
                                <option value="3">Other request</option>
                                <option value="4">Ask a question</option>
                            </Form.Select>
                        </FloatingLabel>
                    </Form.Group>
                    <Form.Group className="col-10 col-lg-6 mb-2">
                        <FloatingLabel controlId={"requestTitle" + this.props.index} label="Request title">
                            <Form.Control type="text"
                                className="seenandheardtext"
                                placeholder="Enter title to display to attendees"
                                value={this.props.item.shortDescription}
                                onChange={(e) => this.context.changeField("shortDescription", e.target.value, this.props.index)}
                                required
                            />
                        </FloatingLabel>
                    </Form.Group>
                    <div className="col-2 mb-2 align-self-center text-center">
                        <button
                            type="button"
                            disabled={!this.props.isAuthorized}
                            className="btn btn-danger" onClick={() => this.context.removeItem(this.props.index)}>Remove item</button>
                    </div>
                </Row>
                <Row className="mb-4">
                    <Form.Group className="col-12">
                        <FloatingLabel controlId={"requestText" + this.props.index} label="Enter instructions to display to attendees">
                            <Form.Control
                                as="textarea"
                                className="seenandheardtext"
                                placeholder="Enter instructions to display to attendees"
                                style={{ height: '75px' }}
                                value={this.props.item.longDescription}
                                onChange={(e) => this.context.changeField("longDescription", e.target.value, this.props.index)}
                                required
                            />
                        </FloatingLabel>
                    </Form.Group>
                </Row>
            </Fragment>


        );
    }
}


SeenAndHeardItem.contextType = SeenAndHeardContext;
