import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';
import React, { useState } from "react";
import ValidateOptions from "./ValidateOptions";

export function LinearRadio({ question = [],
    handleChange = () => { },
    disabled = false,
    handleSave = () => { } }) {

    const [choice, setChoice] = useState("")

    let errorMessage = ValidateOptions(question, handleChange, disabled)

    function onChange(evt) {
        setChoice(evt.target.value)
        let qst = { ...question, Selection: evt.target.name }

        if (question.Mandatory) {
            qst = { ...question, Validated: true, Selection: evt.target.name }
        }
        handleChange(qst)
    }

    function onBlur(evt) {
        let qst = { ...question, Selection: evt.target.name }
        handleSave(qst)
    }

    return (
        errorMessage === "" ? <Form.Group className='form-div'>
            <Form.Label>{question.QuestionTitle}{question.Mandatory ? "*" : ""}</Form.Label>
            <div className="d-flex justify-content-between">
                {question.Options.map((option) => (
                    <Form.Check
                        key={option.id}
                        type="radio"
                        inline
                        name={option.id}
                        data-testid={option.id}
                        onChange={(evt) => onChange(evt)}
                        onBlur={(evt) => onBlur(evt)}
                        label={option.OptionTitle}
                        value={option.OptionTitle}
                        checked={choice === option.OptionTitle}
                        required={!question.Validated}
                        disabled={disabled}
                        feedback="Please select an option."
                        feedbackType="invalid" />
                ))}
            </div>
        </Form.Group> : <Alert variant="danger" className='alert-margin'>{errorMessage}</Alert>
    )
}